import {MapOutlined} from "@mui/icons-material";
import React from 'react';
import PropTypes from "prop-types";

function ButtonDiagramaUnilineal({fill_color}) {
        return (
            <MapOutlined alt="icon_cubiculo" style={{
                background: "rgba(0,0,0,0)",
                margin: '0px',
                padding: '0px',
                display: 'block',
                width: "2em",
                height: "2em",
                fill: fill_color,
            }}/>
        );
}

ButtonDiagramaUnilineal.propTypes = {
    fill_color: PropTypes.string,
};


ButtonDiagramaUnilineal.defaultProps = {
    fill_color: '#0e537b',
};


export default ButtonDiagramaUnilineal;