import { SVGImage } from "./SLDSVGImage";
import { PositionReferenceTypes, CableTypes, sizesByCableType } from "../../constants/single-line-diagram";
// eslint-disable-next-line import/no-webpack-loader-syntax
const simpleAerialSVG = require(`!!raw-loader!../../svg/single-line-diagram/simple-aerial.svg`).default;
const doubleAerialSVG = require(`!!raw-loader!../../svg/single-line-diagram/double-aerial.svg`).default;
const pipeCableSVG = require(`!!raw-loader!../../svg/single-line-diagram/pipe-cable.svg`).default;
// const ledAerialSVG = require(`!!raw-loader!../../svg/single-line-diagram/led-aerial.svg`).default; //TODO: Por ahora se reemplaza la simbología de los cables led por la de los cables aereos dobles.

export function SLDCableSymbol({
    id,
    x,
    y,
    type,
    positionReference = PositionReferenceTypes.CENTER,
    color,
}) {
    const simpleAerialSVGColor = simpleAerialSVG.replace("</svg>", `<polygon fill="${color}" points="100,6.3 5.5,114.5 100,222.7 194.5,114.5 "/></svg>`);
    const rawSVGsByType = {
        [CableTypes.AERIAL]: simpleAerialSVGColor,
        // [CableTypes.DOUBLE_AERIAL]: doubleAerialSVG,
        [CableTypes.PIPE_CABLE]: pipeCableSVG,
        [CableTypes.LED]: doubleAerialSVG,
    };

    const { width, height } = sizesByCableType[type];

    const rawSVG = rawSVGsByType?.[type] ?? null;

    if (!rawSVG) {
        return null
    }

    const alignedPositionByAlignType = {
        [PositionReferenceTypes.CENTER]: { x: x - width/2, y: y - height/2 },
        [PositionReferenceTypes.CENTER_LEFT]: { x: x, y: y - height/2 },
        [PositionReferenceTypes.CENTER_RIGHT]: { x: x  - width, y: y - height/2 }
    };

    const correctedPosition = alignedPositionByAlignType?.[positionReference];

    if (!correctedPosition) {
        return null;
    }

    const svgInfo = {
        URL: ("data:image/svg+xml;base64," + window.btoa(rawSVG)),
        ...correctedPosition,
        width,
        height,
    };

    return <SVGImage key={id} {...svgInfo} />
}