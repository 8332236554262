import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CONNECTION_STATES }  from "./DiagramaUnilineal/single-line-diagram-connection-point.js";
import InfoIcon from '@mui/icons-material/Info';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Tooltip, Typography, Paper } from "@mui/material";

let opacity = 1;

export function CableInfoDisplayer({
    info = {},
}) {
    const rows = Object.entries(info).map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
            return null;
        } else {
            return <InfoDisplayerRow key={key} label={key} value={value} />;
        }
    });

    // For every phase, create a InfoIcon with the corresponding color (red, yellow, green) and a tooltip with the alarm type. IN THE SAME ROW ALL THE ICONS.
    const voltageAlarms = info["voltage_alarms"];
    const voltageAlarmsRows = Object.entries(voltageAlarms).map(([key, value]) => {
        const alarmComponent = checkAlarm(value);
        if (alarmComponent === null) return null;

        return (
            <tr key={key}>
                <td>{key}:</td>
                <td>{alarmComponent}</td>
            </tr>
        )
    });

    return (
        <>
            <table style={{ width: '100%' }}>
                <tbody>{rows}</tbody>
            </table>
            {voltageAlarmsRows.length > 0 && (
                <Paper elevation={3} style={{ backgroundColor: "#EEEEEE", padding: "0.5em", marginTop: "1em", width: '100%' }}>
                    <strong>Alarmas de voltaje:</strong>
                    <table style={{ width: '100%' }}>
                        <tbody>{voltageAlarmsRows}</tbody>
                    </table>
                </Paper>
            )}
        </>
    );
}


export function InfoDisplayer({
    info = {},
    alarms = {},
}) {
    if (alarms && Object.keys(alarms).length > 0) {
        if (alarms.eclamp_no_data === CONNECTION_STATES.NO_DATA) {
            opacity = 0.8;
        }
        const rows = Object.entries(info).map(([key, value]) => {
            return <InfoDisplayerAlarmsRow key={key} label={key} value={value} opacity={opacity} />;
        });
    
        return (
            <>
                {alarms.eclamp_no_data === CONNECTION_STATES.NO_DATA && (
                    <Typography variant="body2" style={{ color: "red" }}>
                        El Eclamp no ha recibido datos en las últimas 24 horas.
                    </Typography>
                )}
                <table>
                    <tbody>{rows}</tbody>
                </table>
            </>
        );
    } else {
        const rows = Object.entries(info).map(([key, value]) => {
            return <InfoDisplayerRow key={key} label={key} value={value} />;
        });
    
        return (
            <table>
                <tbody>{rows}</tbody>
            </table>
        );
    }
}

function InfoDisplayerRow({ label, value }) {
    const [open, setOpen] = useState(true);
    const toggleOpen = () => setOpen((prevOpen) => !prevOpen);

    if (typeof value === 'object' && value !== null) {
        const Icon = open ? ExpandLessIcon : ExpandMoreIcon;
        return (<>
            <tr>
                <td colSpan={2}>
                    <Icon onClick={toggleOpen} style={{marginRight: '0.3em', verticalAlign: 'middle' }} />
                    <strong>{label}: </strong>
                </td>
            </tr>
            {open &&
                Object.entries(value).map(([k, v]) => (
                    <InfoDisplayerRow key={k} label={k} value={v} />
                ))
            }
        </>);
    }
    if (label === "inventory_state" && value === "error") {
        return (
            <Tooltip title="Cable en falla desde el inventario, favor de quitar con la opción de falla" arrow placement="top">
                <tr>
                    <td style={{ color: "red" }}>Estado Inventario:</td>
                    <td style={{ color: "red" }}>{displayValue(value)}</td>
                </tr>
            </Tooltip>
        );
    }

    return (
        <tr>
            <td>{(label === "inventory_state") ? "Estado Inventario" : label}:</td>
            <td>{displayValue(value)}</td>
        </tr>
    );
}


function checkAlarm(value) {
    // Check if value is in CONNECTION_STATES values
    const alarmFromStates = Object.values(CONNECTION_STATES).find((state) => state === value);
    const alarmDiffFromOk = (alarmFromStates !== "ok" && alarmFromStates !== undefined);
    const alarmColor = alarmFromStates?.includes("yellow") ? "#DDE051" : "red";
    const tooltipText = alarmFromStates?.includes("yellow") ? "Alarma amarilla" : "Alarma roja";
    return alarmDiffFromOk ? (
        <Tooltip title={tooltipText} arrow placement="right" style={{ display: "inline" }}>
            <InfoTwoToneIcon style={{ color: alarmColor }} />
        </Tooltip>
    ) : null;
}

function InfoDisplayerAlarmsRow({ label, value }) {
    const [open, setOpen] = useState(true);
    const toggleOpen = () => setOpen((prevOpen) => !prevOpen);

    if (typeof value === 'object' && value !== null) {
        const Icon = open ? ExpandLessIcon : ExpandMoreIcon;
        return (<>
            <tr>
                <td colSpan={2}>
                    <Icon onClick={toggleOpen} style={{marginRight: '0.3em', verticalAlign: 'middle' }} />
                    <strong>{label}: </strong>
                </td>
            </tr>
            {open &&
                Object.entries(value).map(([k, v]) => {
                    if (Array.isArray(v)) {
                        const alarmComponent = checkAlarm(v[1]);
                        return (
                            <tr key={k}>
                                <td>{k}:</td>
                                <td>{displayValue(v[0])}</td>
                                <td>{alarmComponent}</td>
                            </tr>
                        );
                    } else {
                        return (
                            <InfoDisplayerAlarmsRow key={k} label={k} value={v} />
                        );
                    }
                })
            }
        </>);
    }
    return (
        <tr>
            <td>{label}:</td>
            <td>{displayValue(value)}</td>
        </tr>
    );
}

const displayValue = (value) => {
    if (value === true) return "true";
    if (value === false) return "false";
    return value;
};
