import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";
import axios from "axios";
import { getSpecificSingleLineDiagramInfo } from "../../components/DiagramaUnilineal/api-calls";

export function useDiagramVersionsHistoric ({ setLoadingHistoricDiagram = () => {}, diagramZonePanel }) {
  const [versions, setVersions] = useState([]);
  const [selectedVersionId, setSelectedVersionId] = useState('');
  const [historicComponents, setHistoricComponents] = useState({});
  const [historicPaths, setHistoricPaths] = useState({});
  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthenticationContext);
  const { token } = authContext?.loginInfo;

  useEffect(() => {
    updateVersions().then();
  }, []);

  const updateVersions = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/version_diagrama/', {
        params: { diagramZonePanel }, // Enviar el diagramZone como parámetro de consulta
        headers: { Authorization: `Token ${token}` }
      });
      console.log("DEBUG USE DIAGRAM VERSION: VERSIONS RESPONSE: ", res.data);
      setVersions(res.data);
      setSelectedVersionId(res?.data?.[0]?.id ?? '');
      setLoading(false);
    } catch (e) {
      console.error("USE DIAGRAM VERSION: VERSIONS RESPONSE ERROR: " + e);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSelectedVersion().then();
  }, [selectedVersionId]);

  const loadSelectedVersion = async () => {
    if(!selectedVersionId && selectedVersionId === '') {
      return;
    }
    let wasLoading = false;
    setHistoricComponents({}); // Prevents re-utilizing components from current version when switching
    setHistoricPaths({});
    setLoadingHistoricDiagram(prevIsLoading => {
      wasLoading = prevIsLoading;
      return true;
    });
    const { display, components, paths, created_at } = await getSpecificSingleLineDiagramInfo({
      token,
      versionId: selectedVersionId
    });
    console.log("HISTORIC DIAGRAM DATA RESPONSE: ", { display, components, paths, created_at });

    // setDiagramVersion(created_at);
    setHistoricComponents(components);
    setHistoricPaths(paths);
    if(!wasLoading) {
        setLoadingHistoricDiagram(false);
    }
  };

  const selectCurrentVersion = ({ v = versions } = {}) => {
    console.log("SELECT CURRENT VERSION: ", v);
    console.log("SELECT CURRENT VERSION: ", v?.[0]?.id);

    setSelectedVersionId(v?.[0]?.id ?? '');
  };

  const currentIsSelected = () => {
    return selectedVersionId === versions?.[0]?.id;
  };

  return {
    versions,
    selectedVersionId,
    setSelectedVersionId,
    historicComponents,
    historicPaths,
    loading,
    selectCurrentVersion,
    currentIsSelected,
    updateVersions
  };
}