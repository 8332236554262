import { useEffect, useState } from "react";
import { TextLabelSizes } from "../constants/single-line-diagram";
import { isNullish } from "../utils/is-nullish";
import { clipValueToRange } from "../utils/clip-value-to-range";

/*
 * TextLabel:
 * {
 *     position: { x: number, y: number },
 *     value: string,
 *     textStyle: {
 *         bold: boolean,
 *         fontSize: number
 *     }
 * }
 *
 * state: TextLabel[]
 */
export const useSLDTextLabels = () => {
    const [state, setState] = useState({});

    const checkIdDoesNotExist = ({ id = null }) => {
        if (id === null) {
            console.log("Id cannot be null.");
            return false
        }
        if (Object.keys(state).includes(id)) {
            console.log(`Id ${id} already exist in useSLDTextLabels state`);
            return false
        }
        return true
    };

    const checkIdExist = ({ id = null }) => {
        if (id === null) {
            console.log("Id cannot be null.");
            return false
        }
        if (!Object.keys(state).includes(id)) {
            console.log(`Id ${id} does not exist in useSLDTextLabels state`);
            return false
        }
        return true
    };

    const createTextLabel = ({
        id = null,
        x = 0,
        y = 0,
        text = ""
    }) => {
        console.log("CREATE TEXT LABEL: ", { id, x, y });
        if (!checkIdDoesNotExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                position: { x, y },
                value: text,
                textStyle: {
                    bold: false,
                    fontSize: TextLabelSizes.DEFAULT_TEXT_LABEL,
                    color: "#000000",
                    backgroundColor: "#FFFFFF"
                }
            }
        }));
    };

    const deleteLabel = ({
        id
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => {
            const { [id]: value, ...newState} = p;
            return newState;
        });
    };

    const updateTextLabel = ({
        id,
        newValue
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                ...newValue
            }
        }));
    };

    const setText = ({
        id,
        value
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                value
            }
        }));
    };

    const setPosition = ({
        id,
        x,
        y
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        if (isNullish(x) || isNullish(y)) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                position: { x, y }
            }
        }));
    };

    const toggleBold = ({
        id
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                textStyle: {
                    ...p[id]["textStyle"],
                    bold: !p[id]["textStyle"]["bold"]
                }
            }
        }));
    };

    const setColor = ({
        id,
        color
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                textStyle: {
                    ...p[id]["textStyle"],
                    color
                }
            }
        }));
    };

    const setBackgroundColor = ({
        id,
        color
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => ({
            ...p,
            [id]: {
                ...p[id],
                textStyle: {
                    ...p[id]["textStyle"],
                    backgroundColor: color
                }
            }
        }));
    }

    const setFontSize = ({
        id,
        value,
        increment = false ,
        decrement = false,
        step = TextLabelSizes.STEP
    }) => {
        if (!checkIdExist({ id })) {
            return;
        }
        setState(p => {
            let newValue = value;
            if (isNullish(newValue) && increment) {
                newValue = p[id]["textStyle"]["fontSize"] + step;
            }
            if (isNullish(newValue) && decrement) {
                newValue = p[id]["textStyle"]["fontSize"] - step;
            }
            if(isNullish(newValue)) {
                throw Error(`Invalid values to setFontSize: ${{id, value, increment, decrement, step}}`);
            }
            newValue = clipValueToRange({ value: newValue, range: [
                TextLabelSizes.MIN_TEXT_LABEL,
                TextLabelSizes.MAX_TEXT_LABEL
            ] });

            return {
                ...p,
                [id]: {
                    ...p[id],
                    textStyle: {
                        ...p[id]["textStyle"],
                        fontSize: newValue
                    }
                }
            }
        });
    };

    return {
        state,
        loadState: setState,
        createTextLabel,
        deleteLabel,
        updateTextLabel,
        setText,
        setPosition,
        toggleBold,
        setColor,
        setBackgroundColor,
        setFontSize
    }
};