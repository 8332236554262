import {wait} from "../../utils/wait";
import {singleLineDiagramInventoryMock} from "./single-line-diagram-mock-data";
import axios from "axios";
import { hasSLDMockDataActivated } from "../../utils/flags";

export async function getInventoryData({token, diagramZonePanel}) {
  if (hasSLDMockDataActivated) {
    await wait(1_000);
    return singleLineDiagramInventoryMock;
  }
  try {
    const response = await axios.post('/inventario/diagrama_unilineal/', { diagramZonePanel }, {
      headers: { Authorization: `Token ${token}` }
    });
    return response.data;
  } catch (err) {
    throw new Error(`Failed to get inventory data: ${err.message}`);
  }
}



export async function getSingleLineDiagramInfo({ token, diagramZonePanel }) {
  try {
    const response = await axios.post('/version_diagrama/ultima/', {
      diagramZonePanel
    }, {
      headers: { Authorization: `Token ${token}` }
    });

    // Si la solicitud es exitosa, devolver los datos recibidos en la respuesta
    return response.data;
  } catch (error) {
    // Si hay un error, manejarlo y devolver un objeto con valores predeterminados
    console.error('Error al obtener la información del diagrama:', error);
    return {
      'display': '-',
      'components': {},
      'paths': {}
    };
  }
}


export async function getSpecificSingleLineDiagramInfo({token, versionId}) {

  return await axios.get(`/version_diagrama/${versionId}/`, {
    headers: {Authorization: `Token ${token}`}
  })
  .then((res) => {
    // console.log("RES:", res.data);
    return res.data;
  })
  .catch((err) => {
    console.log(err);
    return {
      'display': '-',
      'components': {},
      'paths': {}
    };
  });
}