import React from 'react';
import {Box, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconPala from "../Icons/IconPala";
import CableTooltip from "./CableTooltip";
import SplitterCadenas from "./SplitterCadenas";


const CadenaCablesEquipo = ({cubiculo, cables, setTopBarName, configuraciones}) => {
        const color_desactivado = '#565D60';
        if (cables.length >= 1) {
            const hay_equipo = cables[cables.length - 1].equipo_conectado !== null;
            const hay_splitter = cables[cables.length - 1].splitter_object !== null;
            return (
                <Box component={"span"} sx={{display: "inline-block"}}>
                    <Box display="flex" flexDirection="row"
                         width={`${cables.length * 246 + (hay_equipo ? 86 : 0) + (hay_splitter ? 100 : 0)}px`}>
                        {
                            cables.map((cable) => (
                                    <CableTooltip cable={cable} setTopBarName={setTopBarName}
                                                  configuraciones={configuraciones} key={cable.codigo_cliente}/>
                                )
                            )
                        }
                        {hay_equipo &&
                            <Box sx={{
                                backgroundColor: '#E4EBEF',
                                height: '66px',
                                width: '86px',
                                display: "block",
                                marginLeft: "2px"

                            }}>
                                <IconPala
                                    fill_color={color_desactivado}
                                    rotado={0}
                                    sx={{
                                        width: '3em',
                                        height: '3em',
                                        margin: '0'
                                    }}/>
                            </Box>
                        }
                        {hay_splitter &&
                            <SplitterCadenas splitter={cubiculo.splitters.filter((spl) => {
                                return spl.codigo_cliente === cables[cables.length - 1].splitter_object
                            })[0]} configuraciones={configuraciones} setTopBarName={setTopBarName} cubiculo={cubiculo}/>
                        }
                    </Box>
                </Box>
            );
        } else {
            return (
                <div></div>
            )
        }

    }
;

export default CadenaCablesEquipo;