import {ReactComponent as IconSVG} from '../../svg/subestacion.svg';
import React from 'react';
import PropTypes from "prop-types";
import IconPala from "./IconPala";

function IconSubestacion({fill_color}) {
    return (
        <IconSVG alt="subestacion" style={{
            background: "rgba(0,0,0,0)",
            margin: '0px',
            padding: '0px',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
            fill: fill_color,
        }}/>
    );
}

IconSubestacion.propTypes = {
        fill_color: PropTypes.string,
}

export default IconSubestacion;