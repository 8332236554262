import { SingleLineDiagramModes } from "../components/DiagramaUnilineal/constants";
import { useEffect, useState } from "react";

export default function useSLDMode () {
  const [mode, setMode] = useState(SingleLineDiagramModes.ONLY_VIEW);

  const [params, setParams] = useState({});

  const resetParams = () => setParams({});
  /******************************* NORMAL MODE *******************************/
  const inEditBaseMode = () => mode === SingleLineDiagramModes.BASE;
  const setEditBaseMode = () => {
    setMode(SingleLineDiagramModes.BASE);
  };

  useEffect(() => {
    if (mode === SingleLineDiagramModes.BASE) {
      resetParams();
    }
  }, [mode]);

  /******************************* DELETE MODE *******************************/
  const inDeleteMode = () => mode === SingleLineDiagramModes.DELETE;
  const setDeleteMode = () => {
    setMode(SingleLineDiagramModes.DELETE);
  };

  /******************************** EDIT MODE ********************************/
  const inEditCableMode = () => mode === SingleLineDiagramModes.EDIT_CABLE;
  const setEditCableMode = () => {
    setMode(SingleLineDiagramModes.EDIT_CABLE);
  };

  /******************************** REPLACE MODE  ****************************/
  const inReplaceCableMode = () => mode === SingleLineDiagramModes.REPLACE_CABLE;
  const setReplaceCableMode = () => {
    setMode(SingleLineDiagramModes.REPLACE_CABLE);
  };

  const selectCableToEdit = (cableId) => {
    setParams(prevState => {
      return {
        ...prevState,
        [SingleLineDiagramModes.EDIT_CABLE]: {
          ...prevState[SingleLineDiagramModes.EDIT_CABLE],
          cableId
        }
      }
    })
  }

  const getSelectedCableToEdit = () => {
    return params?.[SingleLineDiagramModes.EDIT_CABLE]?.cableId || null;
  };

  const cableIsSelected = (id) => {
    return (params?.[SingleLineDiagramModes.EDIT_CABLE]?.cableId || null) === id;
  };

  const hasSelectedCable = () => {
    const cableId = params?.[SingleLineDiagramModes.EDIT_CABLE]?.cableId;
    return cableId !== null && cableId !== undefined;
  }

  /******************************** VIEW MODE ********************************/
  const inOnlyViewMode = () => mode === SingleLineDiagramModes.ONLY_VIEW;
  const setOnlyViewMode = () => {
    setMode(SingleLineDiagramModes.ONLY_VIEW);
  };

  /******************************** HISTORIC MODE ********************************/
  const inHistoricMode = () => mode === SingleLineDiagramModes.HISTORIC;
  const setHistoricMode = () => {
    setMode(SingleLineDiagramModes.HISTORIC);
  };

  const inAnyEditMode = () => inDeleteMode() || inEditCableMode() || inEditBaseMode() || inReplaceCableMode();

  return {
    setEditBaseMode,
    setDeleteMode,
    setHistoricMode,
    setEditCableMode,
    setOnlyViewMode,
    inOnlyViewMode,
    inHistoricMode,
    inDeleteMode,
    inEditBaseMode,
    inEditCableMode,
    setReplaceCableMode,
    inReplaceCableMode,
    inAnyEditMode,
    params,
    selectCableToEdit,
    cableIsSelected,
    hasSelectedCable,
    getSelectedCableToEdit,
    mode,
  };

};