import React from 'react';
import {ButtonBase, Grid, Paper, Typography} from "@mui/material";
import IconSubestacion from "../Icons/IconSubestacion";
import {useTheme} from "@emotion/react";


const CardContadorSubestacion = ({color_icono, subestaciones, texto, setFiltroEstadoSub}) => {
    const theme = useTheme();
    const styles = {
        paper: {
            padding: theme.spacing(2),
            margin: 'auto',
            width: '17em',
            height: '6em',
        },
        containerIcono: {
            marginTop: '0em',
            marginLeft: '0em',
            height: '54px',
        }
    }


    return (
        <Grid item xs>
            <ButtonBase onClick={setFiltroEstadoSub}>
                <Paper sx={styles.paper}>
                    <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={5} sx={styles.containerIcono}>
                            <IconSubestacion fill_color={color_icono}/>
                        </Grid>
                        <Grid item xs={7} container direction="column" spacing={1}>
                            <Typography gutterBottom variant="subtitle2" style={{color: color_icono}}>
                                {subestaciones ? subestaciones.length : '0'} Subestaciones
                            </Typography>
                            <Typography gutterBottom style={{fontSize: 15}}>
                                {texto ? texto : 'Sin Texto'}
                            </Typography>
                        </Grid>
                    </Grid>

                </Paper>
            </ButtonBase>
        </Grid>
    );
};

export default CardContadorSubestacion;