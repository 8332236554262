import { Fade, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export function SwitchWithLabel({
    checked = false,
    disabled = false,
    onChange = (ev) => {},
    label = {
        "checked": "",
        "unchecked": ""
    }
}) {

    return (
        <Stack direction="row" spacing={6.5} alignItems='center'>
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                inputProps={{ 'aria-label': 'controlled' }}
            />
            <Fade in={checked}>
                <Typography variant="subtitle1" style={{ position: 'absolute' }}>{label?.checked ?? "checked"}</Typography>
            </Fade>
            <Fade in={!checked}>
                <Typography variant="subtitle1" style={{ position: 'absolute' }}>{label?.unchecked ?? "not checked"}</Typography>
            </Fade>
        </Stack>
    );
}

SwitchWithLabel.propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};