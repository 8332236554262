import React, {useEffect, useState} from 'react';
import { Box, Grid, Typography } from "@mui/material";
import WidgetContadorPalas from "../WidgetContadorPalas";
import WidgetPalas from "../WidgetPalas";
import axios from "axios";
import DeepEqual from "../../Utilities";
import LoadingGif from "../../assets/LoadingGif";
import {useTheme} from "@emotion/react";


const VistaPalas = ({token, setTopBarName}) => {
    const theme = useTheme();
    const styles = {
    root: {
        display: 'flex',
    },
    appBarSpacer: {
        height: '50px'
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        backgroundColor: '#E4EBEF',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        disableGutters: true,
        backgroundColor: '#E4EBEF',
        maxHeight: "100%",
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'row',
    },
    fixedHeight: {
        height: 240,
    },
}
    const [loading, setLoading] = useState(true);
    const [palas, setPalas] = useState([]);
    const [currentEstadoPala, setCurrentEstadoPala] = useState('');
    const [configuraciones, setConfiguraciones] = useState({});
    const [ultimaActualizacion, setUltimaActualizacion] = useState(Date.now());
    const [segsDesdeActualizacion, setSegsDesdeActualizacion] = useState(0);

    useEffect(() => {
        setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        let timerID = setInterval(() => {
            setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        }, 5000)
        return () => {
            clearInterval(timerID)
        }
    }, [ultimaActualizacion])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            axios.get(`/equipos/?voltaje_borde=true`, config),
            axios.get(`/configuracion_faenas/ultima/`, config),
        ])
            .then(resArr => {
                if (resArr[0].status === 200 && !DeepEqual(palas, resArr[0].data)) {
                    setPalas(resArr[0].data);
                }
                if (resArr[1].status === 200 && !DeepEqual(resArr[1].data, configuraciones)) {
                    setConfiguraciones(resArr[1].data);
                }
                setUltimaActualizacion(Date.now());
                setLoading(false);
            })
    }, [token])

    useEffect(() => {
        const intervalID = setInterval(function () {
            const config = {
                headers: {Authorization: `Token ${token}`}
            };
            axios.get(`/equipos/?voltaje_borde=true`, config)
                .then(res => {
                    if (!DeepEqual(palas, res.data) && res.status === 200) {
                        setPalas(res.data);
                    }
                    setUltimaActualizacion(Date.now());
                })
                .catch(function (error) {
                    console.log(error);
                })
        }, 30000)

        return () => {
            clearInterval(intervalID);
        }
    }, [token, palas])

    return (
        <Grid container spacing={5} direction="column" alignItems="center">
            {/* Contadores de Palas */}
            <Grid item xs>
                <WidgetContadorPalas setCurrentEstadoPala={setCurrentEstadoPala} palas={palas}/>
            </Grid>
            <Grid item xs container justifyContent={"flex-end"}>
                <Typography style={{paddingRight: 140}}>
                    Última Actualización : Hace {segsDesdeActualizacion} [s]</Typography>
            </Grid>
            {/* Palas */}
            {loading ? <Grid item xs={12}> <LoadingGif/></Grid> :
                <Grid item xs>
                <WidgetPalas palas={palas} currentEstadoPala={currentEstadoPala} setTopBarName={setTopBarName}/>
            </Grid>}

        </Grid>
    );
};

export default VistaPalas;