import React, { useEffect, useState } from "react";
import "./Diagrama.css";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import { SLDComponentEnum } from "./constants";
import { SLDComponentSizes } from "../../constants/single-line-diagram";
import axios from 'axios';
import { Box, 
  Grid, 
  Typography, 
  Select, 
  MenuItem, 
  IconButton, 
  Slider, 
  TextField, 
  Autocomplete, 
  Chip, 
  useMediaQuery, 
  SpeedDial, 
  SpeedDialAction, 
  SpeedDialIcon,
  Snackbar,
  Alert
} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InventoryIcon from '@mui/icons-material/Inventory';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InfoIcon from '@mui/icons-material/Info';
import { SLDSelectorButton } from "./SLDSelectorButton";
import { ReplaceSelector } from './SLDReplaceCableSelector';
import { downloadFile } from "../../utils/download-file";
import { inDebugMode } from "../../utils/flags";
import { AikoButton } from '../themes/AikoComponents';
import { SwitchWithLabel } from "../atoms/SwitchWithLabel"
import { SLDComponentSelector, SLDNoInventoryLoadForm } from "./SLDComponentSelector";
import { SLDVoltGraph } from "./SLDVoltGraph";
import { SLDAerialCableSelector } from "./SLDAerialCableSelector";
import { CableTypes } from '../../constants/single-line-diagram';
import { isCableConnectionPoint } from "./utils";
import { deepCopy } from "../../utils/deep-copy";
import { bool } from "prop-types";

const SLDToolbar = ({ token }) => {
  const {
    singleLineDiagramMode,
    components,
    SLDComponents,
    addComponent,
    activateCable,
    paths,
    pathsHaveLocalChanges,
    resetPathsLocalChanges,
    updateRemotePathsToLocal,
    diagramVersion,
    setDiagramVersion,
    inventoryEquipments,
    getAvailableComponents,
    setIsLoading,
    modal,
    resetInventoryLocalChanges,
    diagramHasLocalChanges,
    resetDiagramLocalChanges,
    updateRemoteDiagramToLocal,
    formatDateToDisplay,
    diagramVersionHistoric,
    stageInteractions,
    textLabels,
    downloadDiagramScreenshot,
    fitDiagram,
    fitCanvas,
    componentSelector,
    selectPathStartOnAir,
    selectPathStart,
    aerialCables,
    updatePathInfo,
    getComponentDisplayName,
    diagramHighlight,
    replaceCable, 
    setIsBeingReplace, 
    isBeingReplace,
    diagramZonePanel,
    diagramZone,
    voltGraphInstance,
    cableInventoryState,
  } = React.useContext(SingleLineDiagramContext);

  const [ultima, setUltima] = useState("");
  const [user, setUser] = useState("");
  const { versions, selectedVersionId, setSelectedVersionId, updateVersions } = diagramVersionHistoric;
  const [newCableId, setNewCableId] = useState("");

  const stageRef = stageInteractions.stageRef.current;

  const [selectedForSearch, setSelectedForSearch] = useState(null);
  const [currentTags, setCurrentTags] = useState([]);
  const isLongScreen = useMediaQuery('(min-width:1920px)');

  const getUltima = async () => {
    try {
      const response = await axios.post('/version_diagrama/ultima_unilineal/', {
        diagramZonePanel
      }, {
        headers: { Authorization: `Token ${token}` }
      });
      const url = response.data.url_archivo;
      const match = url.match(/\/(\d+)\.json$/);
      if (match) {
        const number = match[1];
        setUltima(number);
        setUser(response.data.username);
      }
    } catch (error) {
      console.log("ERROR AL SACAR ULTIMA: ", error);
    }
  }

  useEffect(() => {
    getUltima().then();
  }, []);

  useEffect(() => {
    getUltima().then();
  }, [diagramZonePanel]);

  function addZoneToInventoryEquipments(inventoryEquipments, zona) { //Add the zone to the inventoryEquipments ONLY FOR THE COMPONENTS THAT ARE IN THE DIAGRAM.
    const inventoryEquipmentsWithZone = deepCopy(inventoryEquipments);
    Object.entries(components).forEach(([componentId, component]) => {
      const { type, zona: currentZone } = component;
      if (currentZone !== zona) {
        inventoryEquipmentsWithZone[componentId] = { ...inventoryEquipmentsWithZone[componentId], zona };
      }
    });

    Object.entries(paths).forEach(([pathId, path]) => {
      const { zona: currentZone } = path;
      if (currentZone !== zona) {
        inventoryEquipmentsWithZone[pathId] = { ...inventoryEquipmentsWithZone[pathId], zona };
      }
    });
    return inventoryEquipmentsWithZone;
  }

  function saveComponentsPathsAndInventory({ components, paths, labels, inventoryEquipments, newDiagramVersion }) {
    const data = JSON.stringify(
      { components, paths, labels, diagramZone },
      (key, value) => {
        if (typeof value === 'string') {
          return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        return value;
      },
      2
    );

    const inventoryEquipmentsWithZone = addZoneToInventoryEquipments(inventoryEquipments, diagramZonePanel);

    const dataInventario = JSON.stringify(inventoryEquipmentsWithZone, null, 2);
    
    const formData = new FormData();
    formData.append('archivo', new Blob([data], { type: 'application/json' }));
    formData.append('inventario', dataInventario);
    formData.append('version', newDiagramVersion);
    formData.append('diagramZone', diagramZonePanel);
    formData.append('cableChanges', JSON.stringify(cableInventoryState.cableChanges));
    axios.post('/version_diagrama/nueva_version/', formData, {
      headers: { Authorization: `Token ${token}` }
    })
      .then((res) => {
        let newVersion = res.data.version;
        let newVersionId = newVersion.split(".")[0];
        setUltima(newVersionId);
        updateRemoteDiagramToLocal();
        updateRemotePathsToLocal();
        updateVersions()
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const [newVersion, setNewVersion] = useState('');

  const getNewVersion = async () => {
    try {
      const response = await axios.get('/version_diagrama/obtener_version_actual', {
        headers: { Authorization: `Token ${token}` }
      });
      const currentVersion = response.data.version;
      console.log("DEBUG VERSION ACTUAL DE RESPONSE: ", currentVersion);
      setNewVersion(currentVersion);
    } catch (error) {
      console.log("ERROR AL SACAR VERSION: ", error);
      setNewVersion('Error al obtener la versión');
    }
  }

  useEffect(() => {
    if (newVersion !== '') {
      const formattedDateTime = formatDateToDisplay(newVersion);
      modal.setModal({
        title: "Guardar diagrama",
        message: "¿Está seguro que desea guardar el diagrama?",
        anotherMessage: "Versión Diagrama: " + formattedDateTime,
        isOpen: true,
        onConfirm: () => {
          setIsLoading(true);
          setDiagramVersion(newVersion);
          saveComponentsPathsAndInventory({
            components,
            paths,
            labels: textLabels?.state ?? {},
            inventoryEquipments,
            newDiagramVersion: newVersion
          });

        }
      });
    }
  }, [newVersion]);

  const handleSave = () => {
    getNewVersion().then();
  };

  const addComponentButtonsInfo = {
    [SLDComponentEnum.SUBSTATION]: {
      display: "subestación",
      type: SLDComponentEnum.SUBSTATION
    },
    [SLDComponentEnum.SPLITTER]: {
      display: "splitter",
      type: SLDComponentEnum.SPLITTER
    },
    [SLDComponentEnum.CELL]: {
      display: "celda",
      type: SLDComponentEnum.CELL
    },
    [SLDComponentEnum.LOAD]: {
      display: "carga",
      type: SLDComponentEnum.LOAD
    },
    [SLDComponentEnum.CABLE]: {
      display: "cable",
      type: SLDComponentEnum.CABLE
    },

  };

  const addComponents = ({
      componentIds,
      componentType,
      xOffset = 150,
      yOffset = 150
  }) => {
      componentIds.forEach((componentId, index) => {
          const position = { x: (50 * index) + xOffset, y: (50 * index) + yOffset };
          const addComponentProps = {
              key: componentId,
              value: { type: componentType, ...position }
          };
          addComponent(addComponentProps);
      });
  };

  const handleConnectionPoint = (connectionPoint) => {
    if (connectionPoint) {
      const isCableConnection = isCableConnectionPoint(connectionPoint);
      if (isCableConnection) {
        selectPathStart(connectionPoint, true, true);
        return;
      } else {
        selectPathStart(connectionPoint, false, true);
        return;
      }
    }
    
  };

  const deployVoltGraph = () => {
    const { graphData, setGraphData, resetPlugsSelection } = voltGraphInstance;

    const isOpen = Object.keys(graphData).length > 0;

    const handleClose = () => {
      setGraphData({});
      resetPlugsSelection();
    }

    if (!isOpen) {
      return null;
    }

    return (
      <SLDVoltGraph
        isOpen={isOpen}
        handleClose={handleClose}
        data={graphData}
      />
    );
  }

  const deploySnackbar = () => {
    const { snackBarOpen, setSnackBarOpen } = voltGraphInstance;

    const handleClose = () => {
      setSnackBarOpen(false);
    }

    return (
      <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Enchufes seleccionados sin datos.
        </Alert>
      </Snackbar>
    );
  }


  const deployComponentSelector = () => {
    const { type, setType, position, resetPosition, isAirCable, setIsAirCable, connectionPoint, resetConnectionPoint } = componentSelector;

    const handleAddComponents = (selectedComponentIds) => {
      console.log("DEBUG: ", selectedComponentIds);
      addComponents({ componentIds: selectedComponentIds, componentType: type, xOffset: position.x, yOffset: position.y });
      setType(null);
      resetPosition();
    };

    const isOpen = type !== null;
    const info = addComponentButtonsInfo[type];

    if (!type) {
      return null;
    }

    const onCancel = () => {
      setType(null);
      setIsAirCable(false);
      resetPosition();
      resetConnectionPoint();
    }

    if (type === SLDComponentEnum.NO_INVENTORY_LOAD) {
      return (
        <SLDNoInventoryLoadForm
          onCancel={onCancel}
          onSelect={(info)=>{
            const { name } = info;
            console.log("NAME: ", name);
            SLDComponents.addNonInventoriedComponent({ name, position });
          }}
          isOpen={isOpen}
        />
      );
    }

    return (
      <SLDComponentSelector
          info={info}
          onCancel={onCancel}
          onSelect={ // if the type is a cable, use the activateCable function, else use the addComponents function
            (selectedComponentIds) => {
              if (type === SLDComponentEnum.CABLE) {
                cableInventoryState.addCable(selectedComponentIds[0].split("_")[1], "working");
                activateCable(selectedComponentIds[0]);
                handleConnectionPoint(connectionPoint);
                isAirCable && selectPathStartOnAir(position.x, position.y);
              } else {
                handleAddComponents(selectedComponentIds);
              }
            }
          }
          isMultiSelect={type === SLDComponentEnum.CABLE ? false : true}
          isOpen={isOpen}
      />
    );
  };

  const deployAerialSelector = () => {
    const allAerialCables = aerialCables.getAerialCables();
    const { cableIdtoAdd, setCableIdtoAdd, getAerialTypeByCableId, setPreviousState, parseAerialCableType } = aerialCables;

    // create a cableInfo const, but the paths could not have it, so check first.
    const cableInfo = paths[cableIdtoAdd]?.cableInfo ?? {};

    const deleteCableType = (cableType) => {
      const newCableInfo = { ...cableInfo };
      delete newCableInfo[cableType]; //Si el objeto queda vacio, eliminar cableInfo, no dejarlo en null, sino que se borre del objeto.
      if (Object.keys(newCableInfo).length === 0) {
          updatePathInfo({ key: cableIdtoAdd, info: { cableInfo: null } });
          return;
      }
      updatePathInfo({ key: cableIdtoAdd, info: { cableInfo: newCableInfo } });
    };

    const setCableType = (cableId) => {
      let cableType = getAerialTypeByCableId(cableId); //si se ha borrado el cable o no se ha seleccionado ninguno, cableType será null.
      let { cableType:cableTypePrefix, color } = parseAerialCableType(cableType);

      if (cableType === null) {
        deleteCableType(CableTypes.AERIAL);
        return;
      }

      console.log("DEBUG CABLE TYPE: ", cableType);
      console.log("DEBUG COLOR: ", color);

      console.log("DEBUG CABLE INFO: ", cableInfo);

      updatePathInfo({ key: cableId, info: { 
          cableInfo: {
            ...cableInfo,
            [cableTypePrefix]: {
              color: color
            }
          }
      }});
    };

    const handleCancel = () => {
      setCableIdtoAdd(null);
      setPreviousState();
    }

    return (
      <SLDAerialCableSelector
        onCancel={() => handleCancel()}
        onSelect={() => {
          setCableType(cableIdtoAdd);
          setCableIdtoAdd(null);
        }}
        isOpen={cableIdtoAdd !== null}
        aerialCables={aerialCables}
        allAerialsCables={allAerialCables}
      />
    );
  };

  const deployReplaceCableDialog = () => {
    const cableComponents = Object.entries(getAvailableComponents(SLDComponentEnum.CABLE));

    return (
      <ReplaceSelector cableComponents={cableComponents} />
    )
  };

  const handleTakeScreenshot = async () => {
    //get current version's user to display.
    const selectedVersionObj = versions.find(version => version.id === selectedVersionId);
    const versionUser = selectedVersionObj?.user.username ?? null;
    console.log("DEBUG VERSION USER: ", versionUser);
    downloadDiagramScreenshot({ ultima, user, versionUser });
  };

  const debugActions = (index, icon, tooltipTitle, onClick) => {
    return (
      <SpeedDialAction
        key={index}
        icon={icon}
        tooltipTitle={tooltipTitle}
        onClick={onClick}
      />  
    );
  };

  const deployDebugButtons = () => {
    return (
      <Box>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
          >
            {debugActions(0, <AccountTreeIcon />, "Print diagram info", () => {
              console.log({ components, paths, labels: textLabels?.state ?? {} });
            })}
            {debugActions(1, <InfoIcon />, "Print versions", () => {
              console.log("VERSION: ", versions);
              console.log("SELECTED VERSION ID: ", selectedVersionId);
              console.log("IS CURRENT: : ", diagramVersionHistoric.currentIsSelected());
            })}
            {debugActions(2, <InventoryIcon />, "Print inventory", () => {
              console.log("DEBUG: Equipments: ", inventoryEquipments)
            })}
            {debugActions(3, <CloudDownloadIcon />, "Exportar", () => {
              downloadFile({
                data: JSON.stringify({ components, paths }, null, 2),
                fileName: 'single-line-diagram-data.json',
                fileType: 'text/json',
              })
            })}
          </SpeedDial>
      </Box>
    );
  };

  const handleEditModeSwitch = (event) => {
    if (event.target.checked) {
      singleLineDiagramMode.setEditBaseMode();
      return;
    }

    const resetChangesAndSetOnlyViewMode = () => {
      resetDiagramLocalChanges();
      resetInventoryLocalChanges();
      resetPathsLocalChanges();
      singleLineDiagramMode.setOnlyViewMode();
    };

    const hasNoChanges = !diagramHasLocalChanges() && !pathsHaveLocalChanges(); //!inventoryHasLocalChanges() //TODO: fix problems with disponible and start using again.
    if (hasNoChanges) {
      resetChangesAndSetOnlyViewMode()
      return;
    }

    modal.setModal({
      title: "Desea perder sus cambios",
      message: "Está a punto de pasar a modo visualización con lo cual perderá sus cambios.",
      isOpen: true,
      onConfirm: resetChangesAndSetOnlyViewMode
    });

  };

  useEffect(() => {
    handleVersionChange();
    console.log("DEBUG SELECTED VERSION ID: ", selectedVersionId);
  }, [selectedVersionId]);

  const handleVersionChange = () => {
    const selectedVersionObj = versions.find(version => version.id === selectedVersionId);

    if (selectedVersionObj) {
      const match = selectedVersionObj.url_archivo.match(/\/(\d+)\.json$/);
      if (match) {
        const isLastVersion = match[1] == ultima;
        if (isLastVersion) {
          console.log("Setting new version: ", match[1]);
          singleLineDiagramMode.setOnlyViewMode();
        } else {
          singleLineDiagramMode.setHistoricMode();
        }
      }
    }
  };

  const deployableSwitchAndVersion = (g1, g2) => {
    const inEditMode = singleLineDiagramMode.inAnyEditMode();
    const label = {
      "checked": "Edit Mode",
      "unchecked": "View Mode"
    };
    const editModeDisabled = !diagramVersionHistoric.currentIsSelected() && diagramVersionHistoric.versions.length > 0;

    return (
      <>
        <Grid item xs={g1}>
          <SwitchWithLabel
            checked={inEditMode}
            disabled={editModeDisabled}
            onChange={handleEditModeSwitch}
            label={label}
          />
        </Grid>
        {!inEditMode && (
          <Grid item xs={g2}>
            <Box>
              <button
                disabled={diagramVersionHistoric.currentIsSelected()}
                onClick={diagramVersionHistoric.selectCurrentVersion}
              >
                Ir a actual
              </button>
              <Box>
                <Select
                  value={selectedVersionId}
                  onChange={(event) => {
                    setSelectedVersionId(event.target.value);
                  }}
                  style={{ width: '350px' }}
                >
                  {diagramVersionHistoric.loading ? (
                    <MenuItem> Cargando... </MenuItem>
                  ) : versions.map((version, index) => (
                    <MenuItem key={index} value={version.id}>
                      {formatDateToDisplay(version.created_at)} - {version.user.username}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          </Grid>
        )}
      </>
    )
  }


  const deployModeAndVersionController = () => {
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={12}>
        {isLongScreen ? (
          deployableSwitchAndVersion(4, 3)
        ) : (
          deployableSwitchAndVersion(6, 6)
        )}
      </Grid>
    );
  };

  const deployZoomScaleController = () => {
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2}>
            <Typography variant="body1">Zoom: {stageInteractions?.scale?.toFixed(1) ?? "-"}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => stageInteractions.setScale(stageInteractions.scale - 0.1)}>
                <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <Slider
                min={stageInteractions.ZOOM_RANGE[0]}
                max={stageInteractions.ZOOM_RANGE[1]}
                step={0.1}
                value={stageInteractions.scale}
                onChange={handleSliderChange}
                aria-label={stageInteractions?.scale?.toFixed(1) ?? "-"}
                valueLabelDisplay="auto"
                marks
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => stageInteractions.setScale(stageInteractions.scale + 0.1)}>
                <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <AikoButton 
                variant="contained"
                size="small"
                className="BotonesLaterales"
                onClick={resetSlider}
                sx={{ width: 50 }}
            >
                Reiniciar zoom
            </AikoButton>
          </Grid>
        </Grid>

    );
  };

  const identifyComponent = (component) => { //component => componente || cable.
    if (component in components) {
      const componentType = components[component].type;
      const { width = 0, height = 0 } = SLDComponentSizes?.[componentType] ?? {};

      return { xMin: components[component].x, xMax: components[component].x + width, yMin: components[component].y, yMax: components[component].y + height}

    } else if (component in paths) {
      const points = paths[component].points;

      return { xMin: points[0].x, xMax: points[points.length - 1].x, yMin: points[0].y, yMax: points[points.length - 1].y }

    } else {
      return null;

    }
  };

  const addOffsetToRange = (range) => {
    const offset = 200;

    return {
      xMin: range.xMin - offset,
      xMax: range.xMax + offset,
      yMin: range.yMin - offset,
      yMax: range.yMax + offset
    };
  };

  const moveToComponentSelected = (component) => {
    const componentPosition = identifyComponent(component);
    if (componentPosition) {
      const fixedComponentPosition = addOffsetToRange(componentPosition);
      fitDiagram({ range: fixedComponentPosition });
      diagramHighlight.addHighlightedComponent(component);

    }
  };

  const firstLetterToUppercase = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const resetSearch = () => {
    setSelectedForSearch(null);
  };

  const deployTagsForSearch = () => { //use SLDComponentEnum to get the component types and add them to the array of allTypesOfComponents
    const allTypesOfComponents = Object.values(SLDComponentEnum).filter(componentType => componentType !== SLDComponentEnum.NO_INVENTORY_LOAD);

    return allTypesOfComponents.map((componentType, index) => {
      return (
        <Grid item key={index}>
          <Chip
            key={index}
            label={firstLetterToUppercase(componentType)}
            clickable
            {...(currentTags.includes(componentType) ? {} : { variant: "outlined" })}
            {...(currentTags.includes(componentType) ? { color: "primary" } : {})}
            onClick={() => {
              if (currentTags.includes(componentType)) {
                setCurrentTags(currentTags.filter(tag => tag !== componentType));
                resetSearch();
              } else {
                setCurrentTags([...currentTags, componentType]);
                resetSearch();
              }
            }}
            size="small"
          />
        </Grid>
      );
    });
  };

  const deploySearcher = () => {
    const componentsAndPaths = { ...components, ...paths }; //Only search in the currentTags components
    const filteredComponentsAndPaths = Object.keys(componentsAndPaths).reduce((acc, key) => {
      if (componentsAndPaths[key]?.type === SLDComponentEnum.NO_INVENTORY_LOAD) {
        return acc;
      }
      if (currentTags.length === 0) {
        acc[key] = componentsAndPaths[key];
        return acc;

      }
      if (key in paths) {
        if (currentTags.includes(SLDComponentEnum.CABLE)) {
          acc[key] = componentsAndPaths[key];
        }
        return acc;

      } else if (key in components) {
        const componentType = components[key].type;
        if (currentTags.includes(componentType)) {
          acc[key] = componentsAndPaths[key];
        }
        return acc;

      }

      return acc;
    }, {});
    const sortedKeys = Object.keys(filteredComponentsAndPaths).sort((a, b) => a.localeCompare(b));
    const componentsLabels = Object.keys(filteredComponentsAndPaths).reduce((acc, key) => {
      const componentType = components[key]?.type;
      const displayType = componentType ? firstLetterToUppercase(componentType) : "Cable";

      acc[key] = displayType + " " + getComponentDisplayName(key);
      return acc;
    }, {});

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
              <Grid item>
                <IconButton onClick={() => setCurrentTags([])} size="small" disabled={currentTags.length === 0}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              {deployTagsForSearch()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedForSearch}
              onChange={(event, component) => {
                setSelectedForSearch(component);
                moveToComponentSelected(component);
              }}
              options={sortedKeys}
              size="small"
              getOptionLabel={(component) => componentsLabels[component]}
              renderInput={(params) => <TextField {...params} label="Buscar" variant="outlined" />}
              renderOption={(props, option) => (
                <li {...props}>
                  {componentsLabels[option]}
                </li>
              )}
              sx = {{ width: 350 }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  moveToComponentSelected(selectedForSearch);
                }
              }}
            />
          </Grid>
        </Grid>
    );
  };

  const handleSliderChange = (e) => {
    stageInteractions.setScale(e.target.value);
  };

  const resetSlider = () => {
    const defaultZoom = 1;
    stageInteractions.setScale(defaultZoom);
  };

  return (
    <>
      <Box className="ContenedorLateral">
        {deployModeAndVersionController()}
      </Box>
      {singleLineDiagramMode.inAnyEditMode() && (
        <Box className="Components">
          <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
            {/* {deployAddComponentButtons()} */}
            {/* {deployAddCableButton()} */}
            {deployComponentSelector()}
            {deployAerialSelector()}
            {deployReplaceCableDialog()}
          </Grid>
        </Box>
      )}
      {inDebugMode && deployDebugButtons()}
      {singleLineDiagramMode.inAnyEditMode() && (
        <Box>
          <AikoButton
            size="small"
            variant="contained"
            className="BotonesLaterales"
            clicked={singleLineDiagramMode.inDeleteMode() ? 'true' : null}
            onClick={() => {
              const isInDeleteMode = singleLineDiagramMode.inDeleteMode();
              if (isInDeleteMode) {
                singleLineDiagramMode.setEditBaseMode();
              } else {
                singleLineDiagramMode.setDeleteMode();
              }
            }}
          >
            {singleLineDiagramMode.inDeleteMode() ? "Salir modo eliminar" : "Eliminar elemento"}
          </AikoButton>
          <AikoButton
            size="small"
            variant="contained"
            className="BotonesLaterales"
            clicked={singleLineDiagramMode.inReplaceCableMode() ? 'true' : null}
            onClick={() => {
              const isInReplaceCableMode = singleLineDiagramMode.inReplaceCableMode();
              if (isInReplaceCableMode) {
                singleLineDiagramMode.setEditBaseMode();
              } else {
                singleLineDiagramMode.setReplaceCableMode();
              }
            }}
          >
            {singleLineDiagramMode.inReplaceCableMode() ? "Salir modo reemplazar cable" : "Reemplazar cable"}
          </AikoButton>
          <AikoButton size="small" variant="contained" onClick={fitDiagram}>Ajustar zoom</AikoButton>
          <AikoButton size="small" variant="contained" onClick={fitCanvas}>Ajustar canvas</AikoButton>
          <AikoButton size="small" variant="contained" onClick={handleTakeScreenshot}>Descargar diagrama</AikoButton>
          <AikoButton
            size="small"
            variant="contained"
            className="BotonesLaterales"
            onClick={handleSave}
            style={{ minWidth: "200px" }}
          >
            Guardar diagrama
          </AikoButton>
        </Box>
      )}
      {!singleLineDiagramMode.inAnyEditMode() && (
        <Box>
          <AikoButton size="small" variant="contained" onClick={fitDiagram}>Ajustar diagrama</AikoButton>
          <AikoButton size="small" variant="contained" onClick={fitCanvas}>Ajustar canvas</AikoButton>
          <AikoButton size="small" variant="contained" onClick={handleTakeScreenshot}>Descargar diagrama</AikoButton>
        </Box>
      )}
      {deployVoltGraph()}
      {deploySnackbar()}
      {/* depending of the size of the screen, fustifyContent could be space-between or space-around */}
      <Grid container direction="row" justifyContent={isLongScreen ? "space-between" : "flex-start"} alignItems="center" spacing={12}>
        {isLongScreen ? (
          <>
            <Grid item xs={4}>
              {!singleLineDiagramMode.inHistoricMode() && (
                <Typography variant='subtitle1'>Version actual: {formatDateToDisplay(diagramVersion)}</Typography>
              )}
              {deployZoomScaleController()}
            </Grid>
            <Grid item xs={3}>
              {deploySearcher()}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              {!singleLineDiagramMode.inHistoricMode() && (
                <Typography variant='subtitle1'>Version actual: {formatDateToDisplay(diagramVersion)}</Typography>
              )}
              {deployZoomScaleController()}
            </Grid>
            <Grid item xs={6}>
              {deploySearcher()}
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default SLDToolbar;