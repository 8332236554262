import React from 'react';

const Usuario = ({token, setTopBarName, topBarOpen}) => {
    setTopBarName('Usuario');
    return (
        <main style={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        }}>
            <div style={{height: '60px'}}/>
            <div>
                <h1>Esta es la nueva pagina de Usuario.</h1>
            </div>
        </main>

    );
};

export default Usuario;
