import './App.css';
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import React, { useContext, useEffect, useState } from "react";
import {Box, CssBaseline} from "@mui/material";
import axios from "axios";
import DeepEqual from "./Utilities";
import DashboardSubestaciones from "./components/subestacion/DashboardSubestaciones";
import DetalleSubestacion from "./components/subestacion/DetalleSubestacion";
import DetalleEnchufe from "./components/pala/DetalleEnchufe";
import DetalleCable from "./components/pala/DetalleCable";
import Configuracion from "./components/configuracion/Configuracion";
import Usuario from "./components/usuario/Usuario";
import TopBar from "./components/TopBar";
import Menu from "./components/Menu";
import Dashboard from "./components/Dashboard";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import darkScrollbar from '@mui/material/darkScrollbar';
import Pala from "./components/pala/Pala";
import { SingleLineDiagramPage } from './pages/SingleLineDiagramPage';
import { SingleLineDiagramContextProvider } from "./components/DiagramaUnilineal/SingleLineDiagramContext";
import Inventory from "./components/Inventory";
import { AuthenticationContext } from "./contexts/AuthenticationContext";

export const theme = createTheme(
    {
        palette: {
            primary: {
                main: '#0e537b',
            },
            secondary: {
                main: '#81d1ff',
            },
            aikoBlue: {
                main: '#55A1DD',
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: (themeParam) => ({
                    body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
                }),
            },
        }
    }
);

function App() {
    const [open, setOpen] = useState(true)
    const [user, setUser] = useState({})
    const [topBarName, setTopBarName] = useState('Dashboard')
    const authContext = useContext(AuthenticationContext);
    const { token } = authContext.loginInfo;
    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (token) {
            const config = {
                headers: { Authorization: `Token ${token}` }
            };
            axios.get(`/my_user/`, config)
                .then(res => {
                    if (!DeepEqual(res.data, user)) {
                        setUser(res.data);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        loadUser().then();
    }, [token])

    async function loadUser() {
        if (!token) {
            return;
        }
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        try {
            const { data } = await axios.get(`/my_user/`, config);
            if (!DeepEqual(data, user)) {
                setUser(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    if (!authContext.isLogged()) {
        return <Login />
    }

    return (<ThemeProvider theme={theme}>
        <CssBaseline />
        <Box style={{ width: '100hw' }}>
            <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/subestaciones/:subestacionId" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <DetalleSubestacion token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </div>
                } />
                <Route path="/subestaciones" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <DashboardSubestaciones token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </div>
                } />
                <Route path="/palas/:palaId" element={
                    <Box>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Pala token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </Box>
                } />
                <Route path="/enchufes/:codigoCliente" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <DetalleEnchufe token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </div>
                } />
                <Route path={`/cables/:codigoCliente`} element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <DetalleCable token={token} setTopBarName={setTopBarName}/>
                    </div>
                } />
                <Route path="/configuracion" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Configuracion setTopBarName={setTopBarName} token={token}/>
                    </div>

                } />
                <Route path="/usuario" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Usuario setTopBarName={setTopBarName} token={token}/>
                    </div>
                } />
                <Route path="/equipos" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Dashboard token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </div>
                } />
                <Route path="/inventario" element={
                    <div style={{display: 'flex', width: "auto"}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Inventory token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </div>
                } />
                <Route path="/diagrama-unilineal/:diagramZonePanel" element={
                    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', width: "100vw" }}>
                        {
                            user &&
                            <SingleLineDiagramContextProvider token={token}>
                            <Menu open={open} setTopBarName={setTopBarName} sx={{ flex: 0 }} />
                            <Box className="vertical-box" sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                height: '100vh',
                                marginLeft: '1em'
                            }}>
                                <TopBar
                                    open={open}
                                    handleDrawerOpen={() => handleDrawerOpen()}
                                    user={user}
                                    handleDrawerClose={() => handleDrawerClose()}
                                    topBarName={topBarName}
                                    setTopBarName={setTopBarName}
                                />
                                <Box sx={{
                                    flex: '1',
                                    flexDirection: "row"
                                }}>
                                    <SingleLineDiagramPage token={token} />
                                </Box>
                            </Box>
                        </SingleLineDiagramContextProvider>
                        }
                    </Box>
                } />
                <Route path="/" element={
                    <Box sx={{display: 'flex'}}>
                        <TopBar open={open} handleDrawerOpen={() => handleDrawerOpen()}
                                user={user} handleDrawerClose={() => handleDrawerClose()} topBarName={topBarName}
                                setTopBarName={setTopBarName}
                        />
                        <Menu open={open} setTopBarName={setTopBarName}/>
                        <Dashboard token={token} setTopBarName={setTopBarName} topBarOpen={open}/>
                    </Box>
                } />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </Box>
    </ThemeProvider>);
}

export default App;
