import { useState } from "react";

const useModal = (
    initState = {
        title: "",
        message: "",
        component: null,
        anotherMessage: "",
        isOpen: false,
        onConfirm: () => {},
        onClose: () => {}
    }
) => {
    const [info, setInfo] = useState(initState);

    const setModal = (newValues) => {
        const validKeys = ["isOpen", "onConfirm", "onClose", "title", "message", "component", "cancelLabel", "okLabel", "anotherMessage"];
        const isValid = Object.keys(newValues).every(key => validKeys.includes(key));
        if(!isValid) {
            const invalidKeys = Object.keys(newValues).filter(key => !validKeys.includes(key));
            throw new Error("useModal state should contain only the keys: " + validKeys + "\nbut you included: " + invalidKeys );
        }

        let {onConfirm = () => {}, onClose = () => {}, ...nonFunctionValues} = newValues;
        const onConfirmWithDefault = () => {
            resetModal();
            onConfirm();
        };
        const onCloseWithDefault = () => {
            resetModal();
            onClose();
        }
        setInfo((prev) => ({
            ...prev,
            ...nonFunctionValues,
            onConfirm: onConfirmWithDefault,
            onClose: onCloseWithDefault
        }));
    };

    const resetModal = () => setInfo(initState);

    return { info, setModal, resetModal };
};

export default useModal;