import {ReactComponent as IconSVG} from '../../svg/icono_pala.svg';
import {ReactComponent as IconSVGRotado} from '../../svg/icono_pala_rotado.svg';
import React from 'react';
import IconConexion from "./IconConexion";
import PropTypes from "prop-types";

function IconPala({fill_color, rotado}) {
    if (Boolean(rotado)) {
        return (
            <IconSVGRotado alt="icon_pala" style={{
                background: "rgba(0,0,0,0)",
                margin: '0px',
                padding: '0px',
                display: 'inline',
                maxWidth: '100%',
                maxHeight: '100%',
                fill: fill_color,
            }}/>
        );
    } else {
        return (
            <IconSVG alt="icon_pala" style={{
                background: "rgba(0,0,0,0)",
                margin: '0px',
                padding: '0px',
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
                fill: fill_color,
            }}/>
        );
    }
}

IconPala.propTypes = {
    fill_color: PropTypes.string,
    rotado: PropTypes.number,
};


IconPala.defaultProps = {
    fill_color: 'black',
    rotado: 0,
};


export default IconPala;