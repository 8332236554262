import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import dotenv from 'dotenv';
import { AuthenticationContextProvider } from "./contexts/AuthenticationContext";
dotenv.config()

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL ?? 'http://127.0.0.1:8000'
const rootElement = document.getElementById('root');

createRoot(rootElement).render(
    // <React.StrictMode>
        <BrowserRouter>
            <AuthenticationContextProvider>
                <App />
            </AuthenticationContextProvider>
        </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
