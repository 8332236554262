import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, 
    Divider, ListItem, 
    ListItemIcon, ListItemText, 
    List, Collapse, 
    Dialog, DialogActions, 
    DialogContent, DialogContentText, 
    DialogTitle, Button 
} from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonDashboard from "./dashboard/ButtonDashboard";
import LogoAikoVertical from "./LogoAikoVertical";
import LogoCollahuasiVertical from "./LogoCollahuasiVertical";
import IconGear from "./Icons/IconGear";
import LogoAikoHorizontal from "./LogoAikoHorizontal";
import LogoCollahuasiHorizontal from "./LogoCollahuasiHorizontal";
import ButtonSubestacion from "./Icons/ButtonSubestacion";
import ButtonDiagramaUnilineal from "./Icons/ButtonDiagramaUnilineal";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {useTheme} from '@emotion/react';
import { styled } from '@mui/material/styles';
import axios from "axios";
import IconConexion from "./Icons/IconConexion";
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import { Spinner } from "./atoms/Spinner";

function ConfirmNavigationListItem({ to, primary, onClick }) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const actualPath = window.location.pathname;

    const handleNavigationClick = (e) => {
        if (actualPath === to) {
            return;

        } else if (!actualPath.includes('diagrama-unilineal')) {
            e.preventDefault();
            navigate(to);
            onClick("Diagrama Unilineal 2.0 - " + primary);
            return;

        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = (e) => {
        navigate(to);
        setOpen(false);
        onClick("Diagrama Unilineal 2.0 - " + primary);
    };

    return (
        <>
            <ListItem button component={RouterLink} to="#" onClick={handleNavigationClick} sx = {{pl: 8}}>
                <ListItemText primary={primary} />
            </ListItem>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{"Confirmar Navegación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        ¿Quieres cambiar de Diagrama a {primary}?
                    </DialogContentText>
                    <DialogContentText>
                        Se perderán los cambios no guardados.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function ListItemLink(props) {
    const {icon, primary, to, onClick, loading = false} = props;

    const renderLink = React.useMemo(
        () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <ListItem button component={renderLink} onClick={onClick}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary}/>
            {loading && <Spinner />}
        </ListItem>
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    // to: PropTypes.string.isRequired,
};

function ListDigramLink(props) {
    const {icon, primary, onClick, unilinealZones, loading = false} = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleOnClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <ListItem button onClick={handleOnClick}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                    <ListItemText primary={primary}/>
                {loading && <Spinner />}
                {isOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit> 
                <List component="div" disablePadding>
                    {unilinealZones && unilinealZones.length > 0 && unilinealZones.filter(zone => zone.nombre !== null).map((zone) => (
                        <ConfirmNavigationListItem key={zone.nombre} to={`/diagrama-unilineal/${zone.nombre}`} primary={zone.nombre} onClick={onClick} />
                    ))}
                </List>
            </Collapse>
        </>
    );
}


const drawerWidth = 311;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const useMapRedirection = ({token}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ageosToken, setAgeosToken] = useState(null);

    const ageosExternalLoginBaseUrl = process.env?.REACT_APP_AGEOS_EXTERNAL_LOGIN_URL ?? "https://ageos.aikologic.com/external-login";
    const requestConfig = {
        headers: { Authorization: `Token ${token}` }
    };
    const getFullUrl = (token) => {
        return ageosExternalLoginBaseUrl + `?token={{${token}}}`;
    };
    const openInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const getAgeosToken = async () => {
        if(ageosToken) {
            const ageosExternalLoginFullUrl = getFullUrl(ageosToken);
            openInNewTab(ageosExternalLoginFullUrl);
            return
        }
        try {
            setIsLoading(true);
            const response = await axios.get('/ageos_login/get_ageos_token/', requestConfig);
            if (response.status !== 200 || !(response?.data?.token)) {
                throw new Error("Error in response. With info : ", response);
            }
            const { data } = response;
            const ageosExternalLoginFullUrl = ageosExternalLoginBaseUrl + `?token={{${data.token}}}`;
            setAgeosToken(data?.token);
            openInNewTab(ageosExternalLoginFullUrl);
        } catch (e) {
            console.error("Error in getAgeosToken: ", e);
        } finally {
            setIsLoading(false);
        }

    };

    return { isLoading, getAgeosToken };
};

const useEclampInventoryRedirection = ({token}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [eclampInventoryToken, setEclampInventoryToken] = useState(null);

    const eclampInventoryExternalLoginBaseUrl = process.env?.REACT_APP_ECLAMP_INVENTORY_EXTERNAL_LOGIN_URL;
    const requestConfig = {
        headers: { Authorization: `Token ${token}` }
    };
    const getFullUrl = (token) => {
        return eclampInventoryExternalLoginBaseUrl + `/?token={{${token}}}`;
    };
    const openInNewTab = (url) => {
        window.open(url, '_blank');
    };

    const getEclampInventoryToken = async () => {
        if(eclampInventoryToken) {
            const eclampInventoryExternalLoginFullUrl = getFullUrl(eclampInventoryToken);
            openInNewTab(eclampInventoryExternalLoginFullUrl);
            return
        }
        try {
            setIsLoading(true);
            const response = await axios.get('/eclamp_inventory_login/get_eclamp_inventory_token/', requestConfig);
            if (response.status !== 200 || !(response?.data?.token)) {
                throw new Error("Error in response. With info : ", response);
            }
            console.log("IM HEREEEEEEEE EEE")
            const { data } = response;
            const eclampInventoryExternalLoginFullUrl = eclampInventoryExternalLoginBaseUrl + `/?token={{${data.token}}}`;
            console.log("eclampInventoryExternalLoginFullUrl", eclampInventoryExternalLoginFullUrl);
            setEclampInventoryToken(data?.token);
            openInNewTab(eclampInventoryExternalLoginFullUrl);
        } catch (e) {
            console.error("Error in getEclampInventoryToken: ", e);
        } finally {
            setIsLoading(false);
        }

    };

    return { isLoading, getEclampInventoryToken };
};

const getUnilinealDiagramZones = async (token) => {
    const requestConfig = {
        headers: { Authorization: `Token ${token}` }
    };

    try {
        const response = await axios.get('/version_diagrama/get_zones/', requestConfig);
        console.log("response: ", response);
        if (response.status !== 200 || !(response?.data)) {
            throw new Error("Error in response. With info : ", response);
        }
        return response.data;
    } catch (e) {
        console.error("Error in getUnilinealDiagramZones: ", e);
    }
};


const Menu = ({ open, setTopBarName }) => {
    const authContext = useContext(AuthenticationContext);
    const { token } = authContext.loginInfo;
    const mapRedirection = useMapRedirection({token});
    const eclampInventoryRedirection = useEclampInventoryRedirection({token});
    const [unilinealZones, setUnilinealZones] = useState([]);

    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
            backgroundColor: theme.palette.primary.main
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },
        drawerPaper: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(9),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        bottomPush: {
            position: "absolute",
            bottom: 0,
            paddingBottom: 10,
            margin: 0,
            width: drawerWidth - 1,
        },
        topText: {
            fontSize: 16,
            fontWeight: 'bold',
            marginLeft: '39px',

        },
        bottomText: {
            fontSize: 16,
            marginLeft: '39px',
        }
    }
    useEffect(() => {
        getUnilinealDiagramZones(token).then((zones) => {
            setUnilinealZones(zones);
        });
    }, []);

    return (
        <Drawer variant="permanent" open={open}>
            <div>
                <Box>
                    {open ?
                        <Box marginTop={'28px'}>
                            <LogoAikoHorizontal maxWidth={'229px'} maxHeight={'106px'}/>
                            <Typography sx={styles.topText}>
                                MONITOREO DE FALLAS
                            </Typography>
                            <Typography sx={styles.bottomText}>
                                EN CABLES ELÉCTRICOS
                            </Typography>
                        </Box>

                        : <ListItem button>
                            <ListItemIcon>
                                <LogoAikoVertical/>
                            </ListItemIcon>
                        </ListItem>
                    }
                </Box>
                <Divider/>
                <ListItemLink to="/" primary="Equipos Conectados" icon={<ButtonDashboard/>}
                              onClick={() => setTopBarName('Equipos conectados')}/>
                <Divider/>
                <ListItemLink to="/subestaciones" primary="Subestaciones"
                              icon={<ButtonSubestacion fill_color={'#0e537b'}/>}
                              onClick={() => setTopBarName('Subestaciones')}/>
                <Divider/>
                <ListItemLink to="/enchufes/1" primary="Detalle enchufe"
                              icon={<IconConexion fill_color={'#0e537b'} rotado={true} width={48} />}
                              onClick={() => setTopBarName('Detalle enchufe')}/>
                <Divider/>
                {/* <ListItemLink to="/diagrama-unilineal" primary="Editor Diagrama Unilineal" icon={<ButtonDiagramaUnilineal/>}
                              onClick={() => setTopBarName('Diagrama Unilineal 2.0')}/> */}
                <ListDigramLink primary="Diagrama Unilineal" icon={<ButtonDiagramaUnilineal />} 
                              onClick={setTopBarName}
                              unilinealZones={unilinealZones} />
                <Divider />
                <ListItemLink primary="Inventario" icon={<ButtonDashboard/>}
                              onClick={eclampInventoryRedirection.getEclampInventoryToken} loading={eclampInventoryRedirection.isLoading}/>
                <Divider/>

                <ListItemLink primary="Cargar mapa" icon={<ButtonDiagramaUnilineal/>}
                              onClick={mapRedirection.getAgeosToken} loading={mapRedirection.isLoading}/>
                <Divider/>
            </div>

            <div style={styles.bottomPush}>
                <Divider/>
                <ListItemLink to="/configuracion" primary="Configuración" icon={<IconGear/>}/>
                <Divider/>
                {/*{open ?*/}

                {/*    <LogoCollahuasiHorizontal/>*/}
                {/*    :*/}
                {/*    <ListItemIcon>*/}
                {/*        <LogoCollahuasiVertical/>*/}
                {/*    </ListItemIcon>*/}
                {/*}*/}
            </div>
        </Drawer>
    );
};

export default Menu;
