import {
    CONNECTION_INPUT,
    CONNECTION_OUTPUT
} from "../../../constants/single-line-diagram";
import { deepCopy } from "../../../utils/deep-copy";

export class InvalidConnectionType extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_CONNECTION_TYPE'
        this.message = message
    }
}

export class InvalidProps extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_PROPS'
        this.message = message
    }
}

export class SLDComponentsManager {
    constructor({components}) {
        this.components = deepCopy(components);
    }

    replaceInputCable({ componentId, newCableId }) {
        const inputs = this.components[componentId].inputs;
        const inputKey = Object.keys(inputs)[0];
        inputs[inputKey].connection.pathId = newCableId;
    }

    replaceOutputCable({ componentId, outputId, newCableId }) {
        const outputs = this.components[componentId].outputs;
        outputs[outputId].connection.pathId = newCableId;
    }

    replaceCable({ componentId, connectionType, outputId=null, newCableId }) {
        if (connectionType === CONNECTION_INPUT) {
            this.replaceInputCable({componentId, newCableId});
            return;
        }
        if (connectionType === CONNECTION_OUTPUT) {
            if (outputId === null) {
                throw new InvalidProps(`
                    replaceCable with connectionType CONNECTION_OUTPUT (${CONNECTION_OUTPUT}) must receive an outputId.
                `);
            }
            this.replaceOutputCable({componentId, outputId, newCableId});
            return;
        }
        throw new InvalidConnectionType(`
            connectionType must be either CONNECTION_INPUT(${CONNECTION_INPUT}) or 
            CONNECTION_OUTPUT(${CONNECTION_OUTPUT})} but ${connectionType} was received instead.
        ` );
    }

    static toInventoryId({ componentId }) {
        if (componentId === null) {
            return null;
        }
        return componentId.split('_')[1];
    }
}