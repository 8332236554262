import {ReactComponent as IconSVG} from '../svg/logo_collahuasi_horizontal_2.svg';
import React from 'react';
// import logo from '../svg/logo_horizontal_2.png';
import {Container} from "@mui/material";

function LogoCollahuasiHorizontal() {
    return (
        <IconSVG alt="logo_collahuasi_horizontal" style={{
            backgroundColor:'white',
            borderRadius: "0%",
            margin: 'auto',
            display: 'block',
            maxWidth: '15em',
            maxHeight: 'auto',
        }}/>
    );
/*    return (
        <Container style={{backgroundColor:'#E65412', padding:'4px'}}>
            <img src={logo} alt="Logo" style={{height:'45px', backgroundColor:'#E65412', display:'block', marginLeft:'auto', marginRight:'auto'}}/>
        </Container>);*/
}

export default LogoCollahuasiHorizontal;
