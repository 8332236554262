import { useEffect, useState } from "react";

/**
 * position => {x: number, y: number}
 * items => [
 *     { label: string, action: function },
 *     ...
 * ]
 */
export const useContextualMenu = () => {
    const [position, setPosition] = useState(null)
    const [items, setItems] = useState(null);
    const [itemsWithCheckboxes, setItemsWithCheckboxes] = useState(null);

    const open = ({ position: newPosition, items: newItems, itemsWithCheckboxes: newItemsWithCheckboxes }) => {
        setPosition(newPosition);
        setItems(newItems);
        setItemsWithCheckboxes(newItemsWithCheckboxes);
    };

    const close = () => {
        setPosition(null);
        setItems(null);
        setItemsWithCheckboxes(null);
    };

    const isOpen = () => (Boolean(position) && Boolean(items) && Boolean(itemsWithCheckboxes));

    useEffect(() => {
        console.log("USE CONTEXTUAL MENU CHANGED: ", { position, items });
    }, [position, items]);

    return {
        position,
        items,
        open,
        close,
        isOpen,
        itemsWithCheckboxes,
    };
};