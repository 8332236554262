import React, {useEffect, useRef, useState} from 'react';
import { useParams, useNavigate} from "react-router-dom";
import {
    Box, Button, Checkbox, Container,
    FormControlLabel, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow, TextField
} from "@mui/material";
import 'chartjs-adapter-date-fns';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {ChevronRight} from "@mui/icons-material";
import axios from "axios";

import {pad} from "../../Utilities";
import DateFnsUtils from "@date-io/date-fns";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
    BarController,
    BarElement,
    Chart, Legend,
    LinearScale,
    LineController,
    LineElement, PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import {useTheme} from "@emotion/react";
import { getPlugs } from "../../services/api/inventory";

Chart.register(Tooltip, BarController, BarElement, TimeScale, LinearScale, Title, LineController, LineElement, PointElement, Legend);

const chartOptions = {
    scales: {
        x:
            {
                type: 'time',
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 11,
                },
                time: {
                    displayFormats: {
                        day: 'd-M-y H:mm',
                        minute: 'd-M-yy H:mm',
                        hour: 'd-M-yy H:mm'
                    }
                }
            },
        y:
            {
                type: 'linear',
                min: 0,
                suggestedMin: 0,
                suggestedMax: 7500,
                display: 'auto',
                position: 'left',
                title: {
                    display: true,
                    text: 'Tensión [V]'
                },
            },
        y1:
            {
                type: 'linear',
                suggestedMin: 0,
                suggestedMax: 30,
                display: 'auto',
                position: 'right',
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Temperatura [°C]'
                },

            },
        y2:
            {
                type: 'linear',
                suggestedMin: 0,
                suggestedMax: 100,
                display: 'auto',
                position: 'right',
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Ozono'
                },

            },
        percentage:
            {
                type: 'linear',
                suggestedMin: 0,
                suggestedMax: 100,
                display: 'auto',
                position: 'right',
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Humedad (%)'
                },

            }
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            labels: {
                filter: function (item, chart) {
                    // Logic to remove a particular legend item goes here
                    return !item.text.includes('Umbral');
                }
            }
        },
    },
    animation: {
        resize: {
            durarion: 0,
        }
    },

}

const FilaSeleccion = ({texto, checked, setChecked}) => {
    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={setChecked} name="checkedA" size="small"
                                   color={"primary"} style={{colorPrimary: 'red'}}/>}
            label={texto}
        />
    );
};

const useSelectPlugs = ({ token, defaultValue = null }) => {
    const [plugs, setPlugs] = useState([]);
    const [selectedPlugId, setSelectedPlugId] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getPlugs({ token })
            .then(r => {
                console.log(r);
                setPlugs(r.sort((a, b) => a?.['display_name']?.localeCompare(b?.['display_name']) ?? 1));
                setIsLoading(false);
            }).catch(e => {
                setIsLoading(false);
            });
    }, [token]);

    const selectPlug = id => {
        setSelectedPlugId(id);

    };

    return {
        plugs,
        selectedPlugId,
        selectedPlug: plugs[selectedPlugId],
        selectPlug,
        isLoading
    };
};

const DetalleEnchufe = ({token, setTopBarName, topBarOpen}) => {
    const config = {
        headers: {Authorization: `Token ${token}`}
    };
    const navigate = useNavigate();
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
        },
        root2: {
            display: 'flex',
            flexGrow: 1,
        },
        containerFiltros: {
            flexDirection: 'column',
            height: '77vh',
        },
        containerInformacion: {
            width: '290px',
            minHeight: '105px',
            maxHeight: '120px',
            backgroundColor: 'white',
            marginBottom: '12px',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            disableGutters: true,
            backgroundColor: '#E4EBEF',
            maxHeight: "100%",
        },
        containerFechas: {
            width: '290px',
            minHeight: '155px',
            maxHeight: '170px',
            backgroundColor: 'white',
            marginBottom: '12px',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px'

        },
        containerSeleccion: {
            width: '290px',
            minHeight: '250px',
            backgroundColor: 'white',
            paddingLeft: '14px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column'
        },
        containerGrafico: {
            padding: '16px',
            display: "block",
            height: '77vh',
            flexGrow: 1,
        },
        barraOcultaFiltro: {
            width: 18,
            height: '77vh',
            backgroundColor: '#0e537b',
            marginLeft: '12px',
            marginRight: '12px',
            padding: 0,
        },
        table: {
            padding: '0px',
            margin: '8px',
            maxWidth: '200px',
            minWidth: '200px',
            borderBottom: 'none',
        },
        tableCell: {
            padding: '1px',
            borderBottom: 'none',
        },
        rowSeleccion: {},
        topButtons: {
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            // border: '1px solid purple'
        },
        paper: {
            backgroundColor: 'gray',
            width: 'auto',
            flexGrow: 1,

        },
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        buttonAplicar: {
            borderWidth: 1,
            marginTop: '12px',
            backgroundColor: '#0e537b',
            fontColor: '#fff',
            color: theme.palette.getContrastText('#0e537b'),
            '&:hover': {
                backgroundColor: '#377599',
            }

        }
    }
    let { codigoCliente } = useParams();
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'};
    const [open, setOpen] = useState(true);
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    // Datos API
    const [datosEnchufe, setDatosEnchufe] = useState(null);
    const [configuraciones, setConfiguraciones] = useState(null);
    const [lecturasVoltaje, setLecturasVoltaje] = useState([]);
    const [lecturasTemperatura, setLecturasTemperatura] = useState([]);
    const [lecturasSonido, setLecturasSonido] = useState([]);
    const [lecturasHumedad, setLecturasHumedad] = useState([]);
    const [lecturasOzono, setLecturasOzono] = useState([]);

    // Filtros [dato antiguo, dato nuevo]
    const [selectedStartDate, setSelectedStartDate] = useState([new Date(new Date() - 86400000), new Date(new Date() - 86400000)]);
    const [selectedEndDate, setSelectedEndDate] = useState([new Date(), new Date()]);
    const [showVoltajeR, setShowVoltajeR] = useState([true, true]);
    const [showVoltajeS, setShowVoltajeS] = useState([true, true]);
    const [showVoltajeT, setShowVoltajeT] = useState([true, true]);
    const [showTIR, setShowTIR] = useState([false, false]);
    const [showTIS, setShowTIS] = useState([false, false]);
    const [showTIT, setShowTIT] = useState([false, false]);
    const [showTE, setShowTE] = useState([false, false]);
    const [showSound, setShowSound] = useState([false, false]);
    const [showHumidity, setShowHumidity] = useState([false, false]);
    const [showOzoneR, setShowOzoneR] = useState([false, false]);
    const [showOzoneS, setShowOzoneS] = useState([false, false]);
    const [showOzoneT, setShowOzoneT] = useState([false, false]);

    const selectPlugState = useSelectPlugs({ token, defaultValue: codigoCliente });

    const widthGraph = () => {
        if (open) {
            if (topBarOpen) {
                return 'calc(90vw - 290px - 310px)'
            } else {
                return 'calc(90vw - 290px)'
            }

        } else {
            if (topBarOpen) {
                return 'calc(90vw - 310px)'
            } else {
                return '90vw'
            }

        }
    };

    const handleStartDateChange = (event) => {
        setSelectedStartDate([selectedStartDate[0], new Date(event.target.value)]);
    };

    const handleEndDateChange = (event) => {
        setSelectedEndDate([selectedEndDate[0], new Date(event.target.value)]);
    };

    const getVoltageData = (start, end) => {
        axios.get(`/mediciones/voltaje/filtradas/?fecha_inicio=${start}&fecha_final=${end}&enchufe=${codigoCliente}`, config)
            .then(res => {
                if (res.status === 200) {
                    setLecturasVoltaje(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getSoundData = (start, end) => {
        axios.get(`/mediciones/sonido/filtradas/?fecha_inicio=${start}&fecha_final=${end}&enchufe=${codigoCliente}`, config)
            .then(res => {
                if (res.status === 200) {
                    setLecturasSonido(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getTempData = (start, end) => {
        axios.get(`/mediciones/temperatura/filtradas/?fecha_inicio=${start}&fecha_final=${end}&enchufe=${codigoCliente}`, config)
            .then(res => {
                if (res.status === 200) {
                    setLecturasTemperatura(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getHumidityData = (start, end) => {
        axios.get(`/mediciones/humedad/filtradas/?fecha_inicio=${start}&fecha_final=${end}&enchufe=${codigoCliente}`, config)
            .then(res => {
                if (res.status === 200) {
                    console.log("GET HUMIDITY DATA: ", res.data);
                    setLecturasHumedad(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getOzoneData = (start, end) => {
        axios.get(`/mediciones/ozono/filtradas/?fecha_inicio=${start}&fecha_final=${end}&enchufe=${codigoCliente}`, config)
            .then(res => {
                if (res.status === 200) {
                    console.log("GET OZONE DATA: ", res.data);
                    setLecturasOzono(res.data);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() =>
    {
        if (chartContainer?.current) {
            if (chartInstance !== null) {
                chartInstance.destroy();
            }

            const newChartInstance = new Chart(
                chartContainer.current,
                {
                    type: 'line',
                    data: {
                        datasets: [
                            {
                                label: 'Voltaje AB',
                                data: lecturasVoltaje.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.voltajeR
                                })),
                                fill: 'none',
                                backgroundColor: '#0e537b',
                                pointRadius: 2,
                                borderColor: '#0e537b',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showVoltajeR[1],
                                yAxisID: 'y',

                            },
                            {
                                label: 'Voltaje BC',
                                data: lecturasVoltaje.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.voltajeS
                                })),
                                fill: 'none',
                                backgroundColor: '#d2ae1f',
                                pointRadius: 2,
                                borderColor: '#d2ae1f',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showVoltajeS[1],
                                yAxisID: 'y',

                            },
                            {
                                label: 'Voltaje CA',
                                data: lecturasVoltaje.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.voltajeT
                                })),
                                fill: 'none',
                                backgroundColor: '#64ac35',
                                pointRadius: 2,
                                borderColor: '#64ac35',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showVoltajeT[1],
                                yAxisID: 'y',

                            },
                            {
                                label: 'T° Interior AB',
                                data: lecturasTemperatura.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.temperaturaInteriorR
                                })),
                                fill: 'none',
                                backgroundColor: '#0e537b',
                                pointRadius: 2,
                                borderColor: '#0e537b',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showTIR[1],
                                yAxisID: 'y1',
                            },
                            {
                                label: 'T° Interior BC',
                                data: lecturasTemperatura.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.temperaturaInteriorS
                                })),
                                fill: 'none',
                                backgroundColor: '#d2ae1f',
                                pointRadius: 2,
                                borderColor: '#d2ae1f',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showTIS[1],
                                yAxisID: 'y1',
                            },
                            {
                                label: 'T° Interior CA',
                                data: lecturasTemperatura.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.temperaturaInteriorT
                                })),
                                fill: 'none',
                                backgroundColor: '#64ac35',
                                pointRadius: 2,
                                borderColor: '#64ac35',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showTIT[1],
                                yAxisID: 'y1',
                            },
                            {
                                label: 'T° Exterior',
                                data: lecturasTemperatura.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.temperaturaExterior
                                })),
                                fill: 'none',
                                backgroundColor: '#0e537b',
                                pointRadius: 2,
                                borderColor: '#0e537b',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showTE[1],
                                yAxisID: 'y1',
                            },
                            {
                                label: 'Umbral Peligro Voltaje',
                                data: configuraciones !== null ? [{
                                    x: selectedStartDate,
                                    y: configuraciones.umbral_diferencia_voltaje_peligro
                                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_peligro}] : [],
                                fill: 'none',
                                backgroundColor: color.peligro,
                                borderColor: color.peligro,
                                borderWidth: 1,
                                lineTension: 1,
                                borderDash: [10, 10],
                                hidden: !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1])),
                                yAxisID: 'y',

                            },
                            {
                                label: 'Umbral Crítico Voltaje',
                                data: configuraciones !== null ? [{
                                    x: selectedStartDate,
                                    y: configuraciones.umbral_diferencia_voltaje_critico
                                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_critico}] : [],
                                fill: 'none',
                                backgroundColor: color.peligro_maximo,
                                borderColor: color.peligro_maximo,
                                borderWidth: 1,
                                lineTension: 1,
                                borderDash: [10, 10],
                                hidden: !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1])),
                                yAxisID: 'y',
                            },
                            {
                                label: 'Umbral Crítico T°',
                                data: configuraciones !== null ? [{
                                    x: selectedStartDate, y: configuraciones.umbral_diferencia_tinterior_critico
                                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_critico}] : [],
                                fill: 'none',
                                backgroundColor: color.peligro_maximo,
                                borderColor: color.peligro_maximo,
                                borderWidth: 1,
                                lineTension: 1,
                                borderDash: [10, 10],
                                hidden: !(configuraciones !== null && (showTIS[1] || showTIT[1] || showTIR[1] || showTE[1])),
                                yAxisID: 'y1',
                            },
                            {
                                label: 'Umbral Peligro T°',
                                data: configuraciones !== null ? [{
                                    x: selectedStartDate,
                                    y: configuraciones.umbral_diferencia_tinterior_peligro
                                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_peligro}] : [],
                                fill: 'none',
                                backgroundColor: color.peligro,
                                borderColor: color.peligro,
                                borderWidth: 1,
                                lineTension: 1,
                                borderDash: [10, 10],
                                hidden: !(configuraciones !== null && (showTIS[1] || showTIT[1] || showTIR[1] || showTE[1])),
                                yAxisID: 'y1',
                            },
                            {
                                label: 'Eventos Sonido',
                                data: lecturasSonido.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.medicion
                                })),
                                fill: 'none',
                                backgroundColor: '#0e537b',
                                pointRadius: 2,
                                borderColor: '#0e537b',
                                showLine: false,
                                lineTension: 0,
                                hidden: !showSound[1],
                                yAxisID: 'y1',
                            },
                            {
                                label: 'Humedad',
                                data:  lecturasHumedad.map((lectura) => ({
                                    x: lectura.fecha,
                                    y: lectura.medicion
                                })),
                                fill: 'none',
                                backgroundColor: '#00909f',
                                pointRadius: 2,
                                borderColor: '#00909f',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showHumidity[1],
                                yAxisID: 'percentage',
                            },
                            {
                                label: 'Ozono AB',
                                data: lecturasOzono.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.medicionR
                                })),
                                fill: 'none',
                                backgroundColor: '#0e537b',
                                pointRadius: 2,
                                borderColor: '#0e537b',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showOzoneR[1],
                                yAxisID: 'y2',

                            },
                            {
                                label: 'Ozono BC',
                                data: lecturasOzono.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.medicionS
                                })),
                                fill: 'none',
                                backgroundColor: '#d2ae1f',
                                pointRadius: 2,
                                borderColor: '#d2ae1f',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showOzoneS[1],
                                yAxisID: 'y2',

                            },
                            {
                                label: 'Ozono CA',
                                data: lecturasOzono.map((lectura) => ({
                                    x: lectura.fechaLecturaSensor,
                                    y: lectura.medicionT
                                })),
                                fill: 'none',
                                backgroundColor: '#64ac35',
                                pointRadius: 2,
                                borderColor: '#64ac35',
                                borderWidth: 1,
                                lineTension: 0,
                                hidden: !showOzoneT[1],
                                yAxisID: 'y2',

                            },
                        ]
                    },
                    options: chartOptions
                }
            )
            setChartInstance(newChartInstance);
        }
    }, [chartContainer])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            axios.get(`/enchufes/${codigoCliente}/`, config),
            axios.get(`/configuracion_faenas/ultima/`, config)
        ])
            .then(resArr => {
                if (resArr[0].status === 200) {
                    setDatosEnchufe(resArr[0].data);
                }
                if (resArr[1].status === 200) {
                    setConfiguraciones(resArr[1].data);
                }
            })
            .catch(err => {
                console.log(err)
            })
        getVoltageData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
        getSoundData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
        getTempData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
        getHumidityData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
        getOzoneData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
    }, [token, codigoCliente])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        const timerId = setInterval(function () {
            axios.all([
                axios.get(`/enchufes/${codigoCliente}/`, config),
                axios.get(`/configuracion_faenas/ultima/`, config)
            ])
                .then(resArr => {
                    if (resArr[0].status === 200) {
                        setDatosEnchufe(resArr[0].data);
                    }
                    if (resArr[1].status === 200) {
                        setConfiguraciones(resArr[1].data);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }, 30000)
        return () => {
            clearInterval(timerId);
        };
    }, [token, codigoCliente])

    useEffect(() => {
        if (chartInstance !== null) {
            if (showSound[1]) {
                chartInstance.data.datasets[11].data = [];
                lecturasSonido.forEach((lect) => {
                    chartInstance.data.datasets[11].data.push({x: parseInt(lect.fecha) * 1000, y: lect.medicion});
                })
            }
            chartInstance.update();
        }
    }, [chartInstance, lecturasSonido])

    useEffect(() => {
        if (chartInstance !== null) {
            if (showTIR[1] || showTIS[1] || showTIT[1] || showTE[1]) {
                const isNotValidTemperature = t => t === null || t < -50 || t > 100;

                chartInstance.data.datasets[3].data = [];
                lecturasTemperatura.forEach((lect) => {
                    if (isNotValidTemperature(lect.interior_ab)) {
                        return;
                    }
                    chartInstance.data.datasets[3].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.interior_ab });
                })

                chartInstance.data.datasets[4].data = [];
                lecturasTemperatura.forEach((lect) => {
                    if (isNotValidTemperature(lect.interior_bc)) {
                        return;
                    }
                    chartInstance.data.datasets[4].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.interior_bc });
                })

                chartInstance.data.datasets[5].data = [];
                lecturasTemperatura.forEach((lect) => {
                    if (isNotValidTemperature(lect.interior_ca)) {
                        return;
                    }
                    chartInstance.data.datasets[5].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.interior_ca });
                })

                chartInstance.data.datasets[6].data = [];
                lecturasTemperatura.forEach((lect) => {
                    if (isNotValidTemperature(lect.exterior)) {
                        return;
                    }
                    const innerTemperatureAverage = (lect.interior_ab + lect.interior_bc + lect.interior_ca)/3;
                    if (Math.abs(innerTemperatureAverage - (lect.exterior + 3)) > 10) {
                        lect.exterior = (innerTemperatureAverage + lect.exterior)/2
                    }
                    chartInstance.data.datasets[6].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.exterior });
                })
                console.log("TEMP EXT: ", chartInstance.data.datasets[6].data);
            }
            chartInstance.update();
        }
    }, [chartInstance, lecturasTemperatura])

    useEffect(() => {
        if (chartInstance !== null) {
            if (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1]) {
                if (lecturasVoltaje.length > 0) {
                    chartInstance.data.datasets[0].data = [];
                    lecturasVoltaje.forEach((lect) => {
                        chartInstance.data.datasets[0].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.ab });
                    });

                    chartInstance.data.datasets[1].data = [];
                    lecturasVoltaje.forEach((lect) => {
                        chartInstance.data.datasets[1].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.bc });
                    });

                    chartInstance.data.datasets[2].data = [];
                    lecturasVoltaje.forEach((lect) => {
                        chartInstance.data.datasets[2].data.push({ x: parseInt(lect.fecha) * 1000, y: lect.ca });
                    });
                }
            }
            chartInstance.update();
        }
    }, [chartInstance, lecturasVoltaje])

    useEffect(() => {
        if (chartInstance !== null) {
            if (showHumidity[1]) {
                const humidity_dataset = chartInstance.data.datasets[12];
                if (lecturasHumedad.length > 0) {
                    humidity_dataset.data = [];
                    lecturasHumedad.forEach((lect) => {
                        humidity_dataset.data.push({
                            x: parseInt(lect.fecha) * 1000,
                            y: Math.min(lect.medicion / 10, 100)
                        });
                    });
                }
            }
            chartInstance.update();
        }
    }, [chartInstance, lecturasHumedad])

    useEffect(() => {
        if (chartInstance !== null) {
            if (showOzoneR[1] || showOzoneS[1] || showOzoneT[1]) {
                if (lecturasOzono.length > 0) {
                    const ozoneRChartIndex = 13;
                    const ozoneRDataset = chartInstance.data.datasets[ozoneRChartIndex];
                    ozoneRDataset.data = [];
                    lecturasOzono.forEach((lect) => {
                        if (lect['medicion_r'] > 10000) {
                            return;
                        }
                        ozoneRDataset.data.push({ x: parseInt(lect.fecha) * 1000, y: lect['medicion_r'] });
                    });
                    const ozoneSChartIndex = 14;
                    const ozoneSDataset = chartInstance.data.datasets[ozoneSChartIndex];
                    ozoneSDataset.data = [];
                    lecturasOzono.forEach((lect) => {
                        if (lect['medicion_s'] > 10000) {
                            return;
                        }
                        ozoneSDataset.data.push({ x: parseInt(lect.fecha) * 1000, y: lect['medicion_s'] });
                    });
                    const ozoneTChartIndex = 15;
                    const ozoneTDataset = chartInstance.data.datasets[ozoneTChartIndex];
                    ozoneTDataset.data = [];
                    lecturasOzono.forEach((lect) => {
                        if (lect['medicion_t'] > 10000) {
                            return;
                        }
                        ozoneTDataset.data.push({ x: parseInt(lect.fecha) * 1000, y: lect['medicion_t'] });
                    });
                }
            }
            chartInstance.update();
        }
    }, [chartInstance, lecturasOzono])

    useEffect(() => {
        if (chartInstance !== null) {
            chartInstance.data.datasets[7].hidden = !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1]));
            chartInstance.data.datasets[8].hidden = !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1]));
            chartInstance.data.datasets[9].hidden = !(configuraciones !== null && (showTIR[1] || showTIT[1] || showTIS[1] || showTE[1]));
            chartInstance.data.datasets[10].hidden = !(configuraciones !== null && (showTIR[1] || showTIT[1] || showTIS[1] || showTE[1]));
            chartInstance.data.datasets[11].hidden = !showSound[1];
            chartInstance.data.datasets[7].data = configuraciones !== null ? [{
                x: selectedStartDate,
                y: configuraciones.umbral_diferencia_voltaje_peligro
            }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_peligro}] : [];
            chartInstance.data.datasets[8].data = configuraciones !== null ? [{
                x: selectedStartDate,
                y: configuraciones.umbral_diferencia_voltaje_critico
            }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_critico}] : [];
            chartInstance.data.datasets[9].data = configuraciones !== null ? [{
                x: selectedStartDate, y: configuraciones.umbral_diferencia_tinterior_critico
            }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_critico}] : [];
            chartInstance.data.datasets[10].data = configuraciones !== null ? [{
                x: selectedStartDate,
                y: configuraciones.umbral_diferencia_tinterior_peligro
            }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_peligro}] : [];
            chartInstance.update()
        }

    }, [chartInstance, configuraciones])

    const updateDataSet = (chartInstance, showVoltajeR, showVoltajeS, showVoltajeT, selectedStartDate, selectedEndDate, configuraciones, showTIR, showTIS, showTIT, showTE, showSound) => {
        if (chartInstance !== null) {
            if (showVoltajeR[0] !== showVoltajeR[1] || showVoltajeS[0] !== showVoltajeT[1] || showVoltajeT[0] !== showVoltajeT[1] || selectedStartDate[0] !== selectedStartDate[1] || selectedEndDate[0] !== selectedStartDate[1]) {
                chartInstance.data.datasets[0].hidden = !showVoltajeR[1];
                chartInstance.data.datasets[1].hidden = !showVoltajeS[1];
                chartInstance.data.datasets[2].hidden = !showVoltajeT[1];
                chartInstance.data.datasets[7].hidden = !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1]));
                chartInstance.data.datasets[8].hidden = !(configuraciones !== null && (showVoltajeR[1] || showVoltajeS[1] || showVoltajeT[1]));
                chartInstance.data.datasets[7].data = configuraciones !== null ? [{
                    x: selectedStartDate,
                    y: configuraciones.umbral_diferencia_voltaje_peligro
                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_peligro}] : [];
                chartInstance.data.datasets[8].data = configuraciones !== null ? [{
                    x: selectedStartDate,
                    y: configuraciones.umbral_diferencia_voltaje_critico
                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_voltaje_critico}] : [];
                getVoltageData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
            }
            if (showTIR[0] !== showTIR[1] || showTIS[0] !== showTIS[1] || showTIT[0] !== showTIT[1] || showTE[0] !== showTE[1] || selectedStartDate[0] !== selectedStartDate[1] || selectedEndDate[0] !== selectedStartDate[1]) {
                chartInstance.data.datasets[3].hidden = !showTIR[1];
                chartInstance.data.datasets[4].hidden = !showTIS[1];
                chartInstance.data.datasets[5].hidden = !showTIT[1];
                chartInstance.data.datasets[6].hidden = !showTE[1];
                chartInstance.data.datasets[9].hidden = !(configuraciones !== null && (showTIR[1] || showTIT[1] || showTIS[1] || showTE[1]));
                chartInstance.data.datasets[10].hidden = !(configuraciones !== null && (showTIR[1] || showTIT[1] || showTIS[1] || showTE[1]));
                chartInstance.data.datasets[9].data = configuraciones !== null ? [{
                    x: selectedStartDate, y: configuraciones.umbral_diferencia_tinterior_critico
                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_critico}] : [];
                chartInstance.data.datasets[10].data = configuraciones !== null ? [{
                    x: selectedStartDate,
                    y: configuraciones.umbral_diferencia_tinterior_peligro
                }, {x: selectedEndDate, y: configuraciones.umbral_diferencia_tinterior_peligro}] : [];
                getTempData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
            }
            if (showSound[0] !== showSound[1] || selectedStartDate[0] !== selectedStartDate[1] || selectedEndDate[0] !== selectedStartDate[1]) {
                chartInstance.data.datasets[11].hidden = !showSound[1];
                getSoundData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
            }
            if(showHumidity[0] !== showHumidity[1] || selectedStartDate[0] !== selectedStartDate[1] || selectedEndDate[0] !== selectedStartDate[1]) {
                chartInstance.data.datasets[12].hidden = !showHumidity[1];
                getHumidityData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
            }
            if(showOzoneR[0] !== showOzoneR[1] || showOzoneS[0] !== showOzoneS[1] || showOzoneT[0] !== showOzoneT[1] || selectedStartDate[0] !== selectedStartDate[1] || selectedEndDate[0] !== selectedStartDate[1]) {
                chartInstance.data.datasets[13].hidden = !showOzoneR[1];
                chartInstance.data.datasets[14].hidden = !showOzoneS[1];
                chartInstance.data.datasets[15].hidden = !showOzoneT[1];

                getOzoneData(selectedStartDate[1].getTime() / 1000, selectedEndDate[1].getTime() / 1000);
            }
            setShowSound([showSound[1], showSound[1]]);
            setShowTIR([showTIR[1], showTIR[1]]);
            setShowTIS([showTIS[1], showTIS[1]]);
            setShowTIT([showTIT[1], showTIT[1]]);
            setShowTE([showTE[1], showTE[1]]);
            setShowVoltajeR([showVoltajeR[1], showVoltajeR[1]]);
            setShowVoltajeS([showVoltajeS[1], showVoltajeS[1]]);
            setShowVoltajeT([showVoltajeT[1], showVoltajeT[1]]);
            setShowHumidity([showHumidity[1], showHumidity[1]]);

            setShowOzoneR([showOzoneR[1], showOzoneR[1]]);
            setShowOzoneS([showOzoneS[1], showOzoneS[1]]);
            setShowOzoneT([showOzoneT[1], showOzoneT[1]]);

            setSelectedEndDate([selectedEndDate[1], selectedEndDate[1]]);
            setSelectedStartDate([selectedStartDate[1], selectedStartDate[1]]);
            chartInstance.update();
        }
    };

    return (
        <main style={styles.content}>
            <div style={styles.appBarSpacer}/>
            <Container maxWidth="xl" style={styles.container}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box style={styles.topButtons}>
                        <Button
                            variant="contained"
                            startIcon={<ChevronLeftIcon/>}
                            onClick={() => {
                                setTopBarName();
                                navigate(-1);
                            }}>
                            Regresar
                        </Button>
                        <Select
                            value={selectPlugState.selectedPlugId}
                            onChange={event => {
                                const selectedPlugId = event?.target?.value;
                                selectPlugState.selectPlug(selectedPlugId);
                                navigate(`/enchufes/${selectedPlugId}`);
                                window.location.reload();
                            }}
                            style={{ minWidth: '150px' }}
                        >
                            {selectPlugState.isLoading
                                ? (<MenuItem value={codigoCliente}>...</MenuItem>)
                                : selectPlugState.plugs
                                .filter(plug => plug['display_name'])
                                .map((plug) => {
                                    return <MenuItem value={plug.id}>{plug['display_name']}</MenuItem>
                            })}
                        </Select>
                    </Box>
                    <Box style={styles.root}>
                        <Box component="div" style={styles.containerFiltros} display={open ? "flex" : "none"}>
                            <TableContainer component={Paper} style={styles.containerInformacion}>
                                <Table style={styles.table} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow hover={true}>
                                            <TableCell scope="row" padding='none' align="left"
                                                       style={styles.tableCell}>
                                                Id Eclamp:
                                            </TableCell>
                                            <TableCell padding='none' align="left" style={styles.tableCell}>
                                                {datosEnchufe ? datosEnchufe.codigo_cliente : '-'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow hover={true}>
                                            <TableCell scope="row" padding='none' align="left"
                                                       style={styles.tableCell}>
                                                Pareja:
                                            </TableCell>
                                            <TableCell padding='none' align="left" style={styles.tableCell}>
                                                {datosEnchufe ? datosEnchufe.enchufePareado ? datosEnchufe.enchufePareado : '-' : '-'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow hover={true}>
                                            <TableCell scope="row" padding='none' align="left"
                                                       style={styles.tableCell}>
                                                Extremo:
                                            </TableCell>
                                            <TableCell padding='none' align="left" style={styles.tableCell}>
                                                {datosEnchufe ? datosEnchufe.enchufeConectado ? datosEnchufe.enchufeConectado : '-' : '-'}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow hover={true}>
                                            <TableCell scope="row" padding='none' align="left"
                                                       style={styles.tableCell}>
                                                Instalación:
                                            </TableCell>
                                            <TableCell padding='none' align="left" style={styles.tableCell}>
                                                {datosEnchufe ? datosEnchufe.tipoConexion ? datosEnchufe.tipoConexion : '-' : '-'}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Paper style={styles.containerFechas}>
                                <form style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                                    <TextField
                                        id="date-picker-inline-1"
                                        label="Fecha Inicio"
                                        type="datetime-local"
                                        value={`${selectedStartDate[1].getFullYear()}-${pad(selectedStartDate[1].getMonth() + 1, 2)}-${pad(selectedStartDate[1].getDate(), 2)}T${pad(selectedStartDate[1].getHours(), 2)}:${pad(selectedStartDate[1].getMinutes(), 2)}`}
                                        onChange={handleStartDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                                <form style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                                    <TextField
                                        id="date-picker-inline-2"
                                        label="Fecha Final"
                                        type="datetime-local"
                                        value={`${selectedEndDate[1].getFullYear()}-${pad(selectedEndDate[1].getMonth() + 1, 2)}-${pad(selectedEndDate[1].getDate(), 2)}T${pad(selectedEndDate[1].getHours(), 2)}:${pad(selectedEndDate[1].getMinutes(), 2)}`}
                                        onChange={handleEndDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </form>
                            </Paper>
                            <Paper style={styles.containerSeleccion}>
                                <FilaSeleccion texto={'Voltaje AB          '} checked={showVoltajeR[1]}
                                               setChecked={() => setShowVoltajeR([showVoltajeR[0], !showVoltajeR[1]])}/>
                                <FilaSeleccion texto={'Voltaje BC          '} checked={showVoltajeS[1]}
                                               setChecked={() => setShowVoltajeS([showVoltajeS[0], !showVoltajeS[1]])}/>
                                <FilaSeleccion texto={'Voltaje CA          '} checked={showVoltajeT[1]}
                                               setChecked={() => setShowVoltajeT([showVoltajeT[0], !showVoltajeT[1]])}/>
                                <FilaSeleccion texto={'Temperatura Interior AB   '} checked={showTIR[1]}
                                               setChecked={() => setShowTIR([showTIR[0], !showTIR[1]])}/>
                                <FilaSeleccion texto={'Temperatura Interior BC   '} checked={showTIS[1]}
                                               setChecked={() => setShowTIS([showTIS[0], !showTIS[1]])}/>
                                <FilaSeleccion texto={'Temperatura Intertior CA  '} checked={showTIT[1]}
                                               setChecked={() => setShowTIT([showTIT[0], !showTIT[1]])}/>
                                <FilaSeleccion texto={'Temperatura Exterior'} checked={showTE[1]}
                                               setChecked={() => setShowTE([showTE[0], !showTE[1]])}/>
                                <FilaSeleccion texto={'Ozono AB          '} checked={showOzoneR[1]}
                                               setChecked={() => setShowOzoneR([showOzoneR[0], !showOzoneR[1]])}/>
                                <FilaSeleccion texto={'Ozono BC          '} checked={showOzoneS[1]}
                                               setChecked={() => setShowOzoneS([showOzoneS[0], !showOzoneS[1]])}/>
                                <FilaSeleccion texto={'Ozono CA          '} checked={showOzoneT[1]}
                                               setChecked={() => setShowOzoneT([showOzoneT[0], !showOzoneT[1]])}/>
                                <FilaSeleccion texto={'Humedad'} checked={showHumidity[1]}
                                                setChecked={() => setShowHumidity(p => [p[0], !p[1]])}/>
                                <FilaSeleccion texto={'Ocurrencias Arcos   '} checked={showSound[1]}
                                               setChecked={() => setShowSound([showSound[0], !showSound[1]])}/>
                            </Paper>
                            <Button variant="contained" color="primary" style={styles.buttonAplicar}
                                         onClick={() => updateDataSet(chartInstance, showVoltajeR, showVoltajeS, showVoltajeT, selectedStartDate, selectedEndDate, configuraciones, showTIR, showTIS, showTIT, showTE, showSound)}>
                                Aplicar Cambios
                            </Button>
                        </Box>
                        <Button style={styles.barraOcultaFiltro} onClick={() => setOpen(!open)}>
                            {open ? <ChevronLeftIcon style={{color: 'white'}}/> :
                                <ChevronRight style={{color: 'white'}}/>}
                        </Button>
                        <Paper style={styles.containerGrafico}>
                            {
                                open ?
                                    <Container style={{width: `${widthGraph()}`}}>
                                        <Box style={{
                                            position: "relative",
                                            height: '65vh',
                                            width: `auto`,
                                            display: "block"
                                        }}>
                                            <canvas ref={chartContainer}/>
                                        </Box>
                                    </Container>
                                    :
                                    <Container style={{width: `${widthGraph()}`}}>
                                        <Box style={{
                                            position: "relative",
                                            height: '65vh',
                                            width: `auto`,
                                            display: "block"
                                        }}>
                                            <canvas ref={chartContainer}/>
                                        </Box>
                                    </Container>
                            }
                        </Paper>
                    </Box>
                </LocalizationProvider>
            </Container>
        </main>
    );
};

export default DetalleEnchufe;
