import { useContext, useEffect, useRef, useState } from "react";
import { Label, Text, Tag } from "react-konva";
import { Html } from "react-konva-utils";
import { TextLabelSizes } from "../../constants/single-line-diagram";
import { clipValueToRange } from "../../utils/clip-value-to-range";
import { withCancelBubble } from "../../utils/with-cancel-bubble";
import { MouseButtons } from "./constants";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';


const useEditableText = ({textStyle = {}  } = {}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localFontSize, setLocalFontSize] = useState(textStyle.fontSize);

    useEffect(() => {
        console.log("CHANGE ON isEditing: ", isEditing);
    }, [isEditing]);

    useEffect(() => {
        setLocalFontSize(textStyle.fontSize);
    }, [textStyle.fontSize]);

    return {
        isEditing,
        startEditing: () => setIsEditing(true),
        stopEditing: () => setIsEditing(false),
        localFontSize,
        setLocalFontSize
    }
};

export function SLDSimpleText({
    value,
    position,
    textStyle,
    setText,
    setPosition,
    toggleBold,
    setColor,
    setBackgroundColor,
    setFontSize,
    deployMenu = () => {}
}) {
    const editableTextState = useEditableText({ textStyle });
    const textRef = useRef(null);
    const inputRef = useRef(null);
    const { singleLineDiagramMode } = useContext(SingleLineDiagramContext);
    const colorPickerRef = useRef(null);
    const backgroundColorPickerRef = useRef(null);

    const handleDoubleClick = () => {
        if(!singleLineDiagramMode.inAnyEditMode()) {
            return;
        }
        editableTextState.startEditing();
        setTimeout(() => {
            inputRef.current.focus();
        }, 50);
    };

    const onOkButton = () => {
        editableTextState.stopEditing()
    };

    const handleDragEnd = () => {
        if(!singleLineDiagramMode.inAnyEditMode()) {
            return;
        }
        console.log("DRAG END: ", textRef.current);
        const { x = 0, y = 0 } = textRef?.current?.attrs;
        console.log("DRAG END: ", x, y);
        setPosition({ x, y });
    };

    const onClickLabel = withCancelBubble(event => {
        if(!singleLineDiagramMode.inAnyEditMode()) {
            return;
        }
        const { button } = event?.evt;
        if (button === MouseButtons.RIGHT) {
            deployMenu({ evt: event?.evt });
        }
    });

    const styles = {
        htmlContainer: {
            display: "flex",
            flexDirection: "column",
            position: 'absolute',
            backgroundColor: "red",
            top: position.y,
            left: position.x,
        },
        buttonsContainer: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            backgroundColor: "#FFF"
        },
        boldButton: {
            fontWeight: "bold",
            backgroundColor: textStyle.bold ? "#bbe" : null
        },
    };

    return (
        <>
            {editableTextState.isEditing && singleLineDiagramMode.inAnyEditMode() ? (
                <Html>
                    <div style={styles.htmlContainer}
                    >
                        <div style={styles.buttonsContainer}>
                            <button
                                style={styles.boldButton}
                                onClick={toggleBold}
                            >
                                N
                            </button>
                            <Tooltip title="Color de Texto" placement="top">
                                <input
                                    type="color"
                                    id="colorpicker"
                                    ref={colorPickerRef}
                                    defaultValue={textStyle?.color}
                                    onBlur={e => setColor({ color: e.target.value })}
                                />
                            </Tooltip>
                            <Tooltip title="Color de Fondo" placement="top">
                                <input
                                    type="color"
                                    id="backgroundcolorpicker"
                                    ref={backgroundColorPickerRef}
                                    defaultValue={textStyle?.backgroundColor}
                                    onBlur={e => setBackgroundColor({ color: e.target.value })}
                                />
                            </Tooltip>
                            <button
                                onClick={() => setFontSize({ decrement: true })}
                            >
                                ▼
                            </button>
                            <button
                                onClick={() => setFontSize({ increment: true })}
                            >
                                ▲
                            </button>
                            <input
                                type={"number"}
                                step={TextLabelSizes.STEP}
                                min={TextLabelSizes.MIN_TEXT_LABEL}
                                max={TextLabelSizes.MAX_TEXT_LABEL}
                                style={{maxWidth: "5em"}}
                                value={editableTextState?.localFontSize}
                                onChange={e => {
                                    editableTextState.setLocalFontSize(e.target.value);
                                }}
                                onBlur={e => {
                                    const value = clipValueToRange({
                                        value: e.target.value,
                                        range: [
                                            TextLabelSizes.MIN_TEXT_LABEL,
                                            TextLabelSizes.MAX_TEXT_LABEL
                                        ]
                                    });
                                    editableTextState.setLocalFontSize(value);
                                    setFontSize({ value });
                                }}
                                onKeyDown={e => {
                                    if (e.key !== 'Enter')
                                        return;
                                    const value = clipValueToRange({
                                        value: e.target.value,
                                        range: [
                                            TextLabelSizes.MIN_TEXT_LABEL,
                                            TextLabelSizes.MAX_TEXT_LABEL
                                        ]
                                    })
                                    editableTextState.setLocalFontSize(value);
                                    setFontSize({ value });
                                }}
                            />
                            <button
                                style={{justifySelf: "flex-end"}}
                                onClick={onOkButton}
                            >
                                OK
                            </button>
                        </div>
                        <TextField
                            id="outlined-basic"
                            multiline
                            maxRows={4}
                            style={{
                                backgroundColor: textStyle?.backgroundColor ?? "white",
                                width: "100%",          
                            }}
                            InputProps={{
                                style: {
                                    color: textStyle?.color ?? "black",
                                    transition: "color 0.5s",
                                    fontSize: textStyle?.fontSize ?? TextLabelSizes.DEFAULT_TEXT_LABEL,
                                    fontWeight: textStyle?.bold ? "bold" : "normal",
                                }
                            }}
                            value={value}
                            onChange={e => setText({ value: e?.target?.value ?? ""})}
                            ref={inputRef}
                        />
                    </div>
                </Html>
            ) : (
                <Label
                    x={position?.x ?? 0}
                    y={position?.y ?? 0}
                    draggable={singleLineDiagramMode.inAnyEditMode()}
                    onClick={onClickLabel}
                    onDblClick={handleDoubleClick}
                    onDragEnd={handleDragEnd}
                    ref={textRef}
                >
                    <Tag
                        fill={textStyle?.backgroundColor ?? "white"}
                        lineJoin="round"
                        cornerRadius={5} // Ajusta este valor según tus preferencias
                    />
                    <Text 
                        text={value || "<texto editable>"}
                        fontSize={textStyle?.fontSize ?? TextLabelSizes.DEFAULT_TEXT_LABEL}
                        fill={textStyle?.color ?? "black"}
                        fontVariant={textStyle?.bold ? "bold" : "normal"}
                        padding={5}
                    />
                </Label>
            )}
        </>
    );
}