import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {DataGrid} from '@mui/x-data-grid'
import {pad} from "../../Utilities";
import axios from "axios";
import {DeepEqual} from "../../Utilities";
import {useTheme} from "@emotion/react";
import {LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

const columns = [
    {field: 'fecha', headerName: 'Fecha', width: 300},
    {field: 'tipo_evento', headerName: 'Tipo de Evento', width: 300},
    {field: 'resuelto', headerName: 'Estado Resolución', width: 200},
    {field: 'fechaResolucion', headerName: 'Fecha de Resolución', type: 'text', width: 300},
];

const DetalleCable = ({token, setTopBarName}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: '12px',
        },
        botonRegresar: {
            marginBottom: '10px',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            disableGutters: true,
            backgroundColor: '#E4EBEF',
            maxHeight: "100%",
        },
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        containerInformacion: {
            width: 190,
            height: 160,
            backgroundColor: 'white',
            alignItems: 'center',
            padding: '5px'
        },
        tableInformacion: {
            padding: '0px',
            maxWidth: '180px',
            minWidth: '180px',
            borderBottom: 'none',
        },
        cellInformacion: {
            padding: '1px',
            borderBottom: 'none',
        },
        nameCellInformacion: {
            padding: '0px',
            borderBottom: 'none',
            width: '40px',
        },
        containerFechas: {
            width: 400,
            height: 160,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            padding: 5,
            marginLeft: '16px',

        },
    }
    const {codigoCliente} = useParams();
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'};
    const color_desactivado = '#565D60';
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date() - 86400000));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [eventos, setEventos] = useState([]);
    const [idEventoSeleccionado, setIdEventoSeleccionado] = useState(null);

    const [configuraciones, setConfiguraciones] = useState(null);

    const handleStartDateChange = (event) => {
        setSelectedStartDate(new Date(event.target.value));
    };
    const handleEndDateChange = (event) => {
        setSelectedEndDate(new Date(event.target.value));
    };

    const handleRead = function (token) {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            axios.get(`/configuracion_faenas/ultima/`, config),
            axios.get(`/lecturas/eventos/`, config)
        ])
            .then(resArr => {
                if (resArr[0].status === 200) {
                    if (!DeepEqual(resArr[0].data, configuraciones)) {
                        setConfiguraciones(resArr[0].data);
                    }
                }
                if (resArr[1].status === 200) {
                    if (!DeepEqual(resArr[1].data, eventos)) {
                        setEventos(resArr[1].data);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        handleRead(token);

        let timerId = setInterval(() => {
            handleRead(token);
        }, 30000)
        return () => {
            clearInterval(timerId);
        };
    }, [token])

    return (
        <main style={styles.content}>
            <div style={styles.appBarSpacer}/>
            <Container maxWidth="xl" style={styles.container}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box style={styles.botonRegresar}>
                        <Button
                            variant="contained"
                            startIcon={<ChevronLeftIcon/>}
                            onClick={() => {
                                setTopBarName();
                                navigate(-1);
                            }}>
                            Regresar
                        </Button>
                    </Box>
                    <Container style={styles.root} maxWidth="xl">
                        <TableContainer component={Paper} style={styles.containerInformacion}>
                            <Table style={styles.tableInformacion} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={4}>
                                            ESTADO ACTUAL
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2} style={{padding: '0px',}}>
                                            {codigoCliente}M
                                        </TableCell>
                                        <TableCell align="center" colSpan={2} style={{padding: '0px',}}>
                                            {codigoCliente}H
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow hover={true}>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.nameCellInformacion}>
                                            VR:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.nameCellInformacion}>
                                            VR:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover={true}>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            VS:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            VS:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover={true}>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            VT:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            VT:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover={true}>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            T°:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                        <TableCell scope="row" padding='none' align="left"
                                                   style={styles.cellInformacion}>
                                            T°:
                                        </TableCell>
                                        <TableCell padding='none' align="center" style={styles.cellInformacion}>
                                            {'-'}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Paper style={styles.containerFechas}>
                            <form style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                                <TextField
                                    id="date-picker-inline"
                                    label="Fecha Inicio"
                                    type="datetime-local"
                                    value={`${selectedStartDate.getFullYear()}-${pad(selectedStartDate.getMonth() + 1, 2)}-${pad(selectedStartDate.getDate(), 2)}T${pad(selectedStartDate.getHours(), 2)}:${pad(selectedStartDate.getMinutes(), 2)}`}
                                    onChange={handleStartDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>
                            <form style={{display: "flex", flexDirection: "row", margin: "8px"}}>
                                <TextField
                                    id="date-picker-inline"
                                    label="Fecha Final"
                                    type="datetime-local"
                                    value={`${selectedEndDate.getFullYear()}-${pad(selectedEndDate.getMonth() + 1, 2)}-${pad(selectedEndDate.getDate(), 2)}T${pad(selectedEndDate.getHours(), 2)}:${pad(selectedEndDate.getMinutes(), 2)}`}
                                    onChange={handleEndDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </form>

                        </Paper>
                    </Container>
                    <Paper style={{marginLeft: 24}}>
                        <div style={{height: 'calc(100vh - 350px)', width: '100%'}}>
                            <DataGrid rows={eventos} columns={columns}/>
                        </div>
                    </Paper>
                </LocalizationProvider>
            </Container>
        </main>
    );
};

DetalleCable.propTypes = {
    token: PropTypes.string.isRequired,
};

export default DetalleCable;
