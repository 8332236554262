import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField, FormControl, InputLabel } from "@mui/material";
import { useState, useEffect } from "react";


const CreateCableModal = ({
    isOpen,
    onClose,
    listOfEclamps,
    handleCreate
}) => {

    const [eclampM, setEclampM] = useState('');
    const [eclampH, setEclampH] = useState('');
    const [codigoCliente, setCodigoCliente] = useState('');
    const [largo, setLargo] = useState('');

    useEffect(() => {
        if (!isOpen) {
            setEclampM('');
            setEclampH('');
            setCodigoCliente('');
            setLargo('');
        }
    }, [isOpen]);

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Crear Cable</DialogTitle>
            <DialogContent>
                <TextField
                    label="Código Cliente"
                    value={codigoCliente}
                    onChange={(e) => setCodigoCliente(e.target.value)}
                    fullWidth
                    required
                    margin="dense"
                />
                <TextField
                    type="number"
                    label="Largo"
                    value={largo}
                    onChange={(e) => setLargo(Number(e.target.value))}
                    fullWidth
                    required
                    margin="dense"
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="eclamp-label">Enchufe Eclamp Hembra</InputLabel>
                    <Select
                        labelId="eclamp-label"
                        value={eclampH}
                        onChange={(e) => setEclampH(Number(e.target.value))}
                        displayEmpty
                    >
                        {listOfEclamps.map((eclamp, index) => (
                            <MenuItem value={eclamp.id} key={index}>{eclamp.serial_number}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="eclamp-label">Enchufe Eclamp Macho</InputLabel>
                    <Select
                        labelId="eclamp-label"
                        value={eclampM}
                        onChange={(e) => setEclampM(Number(e.target.value))}
                        displayEmpty
                    >
                        {listOfEclamps.map((eclamp, index) => (
                            <MenuItem value={eclamp.id} key={index}>{eclamp.serial_number}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button color="primary" onClick={
                    () => handleCreate({ codigoCliente, largo, eclampH, eclampM})}>
                    Crear
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateCableModal;
