import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import axios from "axios";
import {Box, Button, Container, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Enchufe from "./Enchufe";
import IconPala from "../Icons/IconPala";
import IconFuente from "../Icons/IconFuente";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DetalleEnchufe from "./DetalleEnchufe";
import Cable from "./Cable";
import DetalleCable from "./DetalleCable";
import DateFnsUtils from '@date-io/date-fns';
import DeepEqual from "../../Utilities";
import LoadingGif from "../../assets/LoadingGif";
import Cubiculo from "../subestacion/Cubiculo";



const Pala = ({token, setTopBarName, topBarOpen}) => {
    let {palaId} = useParams();
    const [loading, setLoading] = useState(true);
    const [palaEnchufe, setPalaEnchufe] = useState({});
    const [configuraciones, setConfiguraciones] = useState({});
    const [ultimaActualizacion, setUltimaActualizacion] = useState(Date.now());
    const [segsDesdeActualizacion, setSegsDesdeActualizacion] = useState(0);
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'};
    const color_desactivado = '#565D60';

    useEffect(() => {
        setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        let timerID = setInterval(() => {
            setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        }, 5000)
        return () => {
            clearInterval(timerID)
        }
    }, [ultimaActualizacion])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            //axios.get(`/equipos/${palaId}/con_enchufes/`, config),
            axios.get(`/cubiculos/${palaId}/?detalle=true`, config),
            axios.get(`/configuracion_faenas/ultima/`, config),
        ])
            .then(resArr => {
                if (resArr[0].status === 200 && !DeepEqual(resArr[0].data, palaEnchufe)) {
                    setPalaEnchufe(resArr[0].data);
                    setUltimaActualizacion(Date.now());
                }
                if (resArr[1].status === 200 && !DeepEqual(resArr[1].data, configuraciones)) {
                    setConfiguraciones(resArr[1].data);
                }
                setLoading(false)

            })
            .catch(function (error) {
                console.log(error)
            })
    }, [])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        const intervalID = setInterval(function () {
            axios.all([
                //axios.get(`/equipos/${palaId}/con_enchufes/`, config),
                axios.get(`/cubiculos/${palaId}/?detalle=true`, config),
                axios.get(`/configuracion_faenas/ultima/`, config),
            ])
                .then(resArr => {
                    if (resArr[0].status === 200 && !DeepEqual(resArr[0].data, palaEnchufe)) {
                        setPalaEnchufe(resArr[0].data);
                        setUltimaActualizacion(Date.now());
                    }
                    if (resArr[1].status === 200 && !DeepEqual(resArr[1].data, configuraciones)) {
                        setConfiguraciones(resArr[1].data);
                    }

                })
                .catch(function (error) {
                    console.log(error)
                })
        }, 30000)

        return () => {
            clearInterval(intervalID);
        }

    }, [token,])

    return (
        <div>
            <Box style={{display: "flex", flexDirection: "row"}}>
                <Box sx={{
                    marginBottom: '10px',
                }}>
                    <RouterLink to="/" style={{textDecoration: 'none'}}>
                        <Button
                            variant="contained"
                            startIcon={<ChevronLeftIcon/>}
                            onClick={() => setTopBarName('Dashboard')}>
                            Regresar
                        </Button>
                    </RouterLink>
                </Box>
                <Typography style={{position: "absolute", right: 0, paddingRight: 30, margin: 0}}>
                    Última Actualización : Hace {segsDesdeActualizacion} [s]</Typography>
            </Box>
            {loading ? <Container maxWidth={"sm"}><LoadingGif/></Container> :
                <Cubiculo cubiculo={palaEnchufe} setTopBarName={setTopBarName} configuraciones={configuraciones}/>

            }
        </div>
    );
};

export default Pala;
