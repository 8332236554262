export const SLDComponentEnum = {
  "SUBSTATION": "substation",
  "SPLITTER": "splitter",
  "CELL": "cell",
  "LOAD": "load",
  "NO_INVENTORY_LOAD": "no-code-load",
  "CABLE": "cable"
};

export const SingleLineDiagramModes = { //TODO: implement modes
  "BASE": "base",
  "DELETE": "delete",
  "EDIT_CABLE": "edit-cable",
  "REPLACE_CABLE": "replace-cable",
  "ONLY_VIEW": "only-view",
  "HISTORIC": "historic",
  "DOCKING": "docking"
};

export const MouseButtons = {
  "LEFT": 0,
  "MIDDLE": 1,
  "RIGHT": 2
}