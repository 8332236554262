import React, { useContext, useEffect, useRef, useState } from "react";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import { Group, Rect, Text, Transformer } from "react-konva";
import {
    MATRIX_GRID_SIZE,
    PositionReferenceTypes, PRINT_DIAGRAM_HORIZONTAL_MARGIN_RATIO,
    PRINT_DIAGRAM_INFO_HEIGHT_RATIO, PRINT_DIAGRAM_VERTICAL_MARGIN_RATIO
} from "../../constants/single-line-diagram";

import { SVGImage } from "./SLDSVGImage";
import { withCancelBubble } from "../../utils/with-cancel-bubble";
import { MouseButtons } from "./constants";
const clientLogoSVG = require(`!!raw-loader!../../svg/single-line-diagram/logo-collahuasi.svg`).default;
const legendSVG = require(`!!raw-loader!../../svg/single-line-diagram/legend-2.svg`).default;

export const SLDPrintInfoPositions = {
    TOP: "top",
    BOTTOM: "bottom"
};

function useSLDPrintInfoArea() {
    const [position, setPosition] = useState(SLDPrintInfoPositions.TOP);

    return {
        position,
        togglePosition: () => setPosition(p => (
            p === SLDPrintInfoPositions.TOP
            ? SLDPrintInfoPositions.BOTTOM
            : SLDPrintInfoPositions.TOP)
        )
    }
}

const SLDLegendPositions = {
    "TOP_LEFT": "top-left",
    "TOP_RIGHT": "top-right",
    "BOTTOM_LEFT": "bottom-left",
    "BOTTOM_RIGHT": "bottom-right",
};

function useSLDLegend({ diagramZone, printAreaInfoPosition, printAreaSize }) {
    const [position, setPosition] = useState(SLDLegendPositions.BOTTOM_RIGHT);

    const getRenderProps = () => {
        const commonProps = {
            position,
            height: 0.12 * diagramZone.width * (150/210),
            width: 0.12 * diagramZone.width
        };
        const horizontalMargin = diagramZone.width * PRINT_DIAGRAM_HORIZONTAL_MARGIN_RATIO;
        const x = position.includes('left')
            ? diagramZone.x + horizontalMargin
            : diagramZone.x + diagramZone.width - horizontalMargin;

        const yOffset = (() => {
            const hasYOffset = position.includes(printAreaInfoPosition);
            if (!hasYOffset) return 0;
            const { height = 0 } = printAreaSize;
            if (position.includes('top')) return height;
            if (position.includes('bottom')) return -height;
            return 0;
        })();
        const verticalMargin = diagramZone.width * PRINT_DIAGRAM_VERTICAL_MARGIN_RATIO;
        const y = [SLDLegendPositions.TOP_LEFT, SLDLegendPositions.TOP_RIGHT].includes(position)
            ? diagramZone.y + yOffset + verticalMargin
            : diagramZone.y + yOffset + diagramZone.height - verticalMargin;

        const positionReference = ({
            [SLDLegendPositions.TOP_LEFT]: PositionReferenceTypes.TOP_LEFT,
            [SLDLegendPositions.TOP_RIGHT]: PositionReferenceTypes.TOP_RIGHT,
            [SLDLegendPositions.BOTTOM_LEFT]: PositionReferenceTypes.BOTTOM_LEFT,
            [SLDLegendPositions.BOTTOM_RIGHT]: PositionReferenceTypes.BOTTOM_RIGHT,
        })[position]

        return {
            x, y, positionReference, ...commonProps
        }
    };

    useEffect(() => {
        console.log("CHANGE IN DIAGRAM ZONE: ", diagramZone);
    }, [diagramZone]);

    return {
        position,
        setPosition,
        renderProps: getRenderProps()
    }
}

export function SLDPlotArea() {
    const { diagramZone, setDiagramZone, downloadingDiagram, setPrintInfoPosition } = useContext(SingleLineDiagramContext);

    const handleResize = (props) => {
        setDiagramZone(p => ({
            ...p,
            ...props
        }));
    };

    const shapeRef = useRef();
    const trRef = useRef();

    useEffect(() => {
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
    }, []);

    /***************************** Print Area Info *****************************/
    const printInfoState = useSLDPrintInfoArea();
    const printAreaSize = {
        width: diagramZone.width,
        height: PRINT_DIAGRAM_INFO_HEIGHT_RATIO * diagramZone.height,
    };
    const printAreaInfoProps = {
        x: diagramZone.x,
        y: diagramZone.y + (printInfoState.position === SLDPrintInfoPositions.BOTTOM
            ? diagramZone.height - printAreaSize.height
            : 0),
        leftMargin: diagramZone.width * PRINT_DIAGRAM_HORIZONTAL_MARGIN_RATIO,
        ...printAreaSize,
        position: printInfoState.position,
        togglePosition: printInfoState.togglePosition
    };

    useEffect(() => {
        if (Object.values(SLDPrintInfoPositions).includes(printInfoState.position)) {
            setPrintInfoPosition(printInfoState.position);
        }
    }, [printInfoState.position]);

    /********************************* Legend *********************************/
    const legend = useSLDLegend({
        diagramZone,
        printAreaInfoPosition: printInfoState.position,
        printAreaSize
    });

    return (
        <>
            <Rect
                ref={shapeRef}
                x={diagramZone.x}
                y={diagramZone.y}
                width={diagramZone.width}
                height={diagramZone.height}
                stroke={"#666"}
                strokeWidth={3}
                fill={"white"}
                onTransformEnd={(e) => {
                    const node = e.target;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    const didGrow = scaleX > 1 || scaleY > 1;
                    const scaleFunction = didGrow ? Math.max : Math.min;
                    const scale = scaleFunction(scaleX, scaleY);

                    node.scaleX(1);
                    node.scaleY(1);

                    handleResize({
                        x: node?.attrs?.x ?? 0,
                        y: node?.attrs?.y ?? 0,
                        width: node.width() * scale,
                        height: node.height() * scale
                    });
                }}
            />
            <SLDLegend {...legend.renderProps} position={legend.position} setPosition={legend.setPosition} />
            <SLDPrintInfo {...printAreaInfoProps} showInfoPreview={!downloadingDiagram} />
            <Transformer
                ref={trRef}
                // enabledAnchors={['bottom-right']}
                rotateEnabled={false}
                flipEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                    console.log("BOXES: ", { oldBox, newBox });
                    if (newBox.width < MATRIX_GRID_SIZE || newBox.height < MATRIX_GRID_SIZE) {
                        return oldBox;
                    }
                    return newBox;
                }}
            />
        </>
    );
}


function SLDPrintInfo({
    x = 0,
    y = 0,
    leftMargin = 0,
    height = 100,
    width = 1000,
    position,
    togglePosition,
    showInfoPreview = false
}) {
    const strokeWidth = 2;
    const clientLogoProps = {
        URL: ("data:image/svg+xml;base64," + window.btoa(clientLogoSVG)),
        x: leftMargin,
        y: height/4,
        width: 2*height,
        height: height/2
    };

    const { contextualMenu, diagramVersionHistoric, formatDate } = useContext(SingleLineDiagramContext);
    const { versions, selectedVersionId } = diagramVersionHistoric;
    const selectedVersionObj = versions.find(version => version.id === selectedVersionId);
    const versionDate = new Date(selectedVersionObj?.created_at) ?? new Date();
    const formattedDate = formatDate(versionDate);
    const versionUser = selectedVersionObj?.user?.username ?? "-----";

    const onClick = withCancelBubble(({ evt }) => {
        if(evt.button === MouseButtons.RIGHT ) { // && !diagramMode.inAnyEditMode()
            console.log("ON RIGHT CLICK: ", evt);
            contextualMenu.open({
                position: { top: evt.y, left: evt.x },
                items: [
                    {
                        label: "Cambiar posición de viñeta a " + (
                            position === SLDPrintInfoPositions.TOP
                            ? "abajo"
                            : "arriba"),
                        action: togglePosition
                    }
                ]
            });
        }
    });

    return (
        <Group
            x={x} y={y}
            onClick={onClick}
        >
            <Rect
                x={0}
                y={0}
                width={width}
                height={height}
                stroke={"#666"}
                strokeWidth={strokeWidth}
                fill={"white"}
            />
            <SVGImage {...clientLogoProps} />
            { showInfoPreview && (
                <>
                    <SLDTextWithLabel
                        x={clientLogoProps.width * 5/3}
                        y={height/4}
                        label={"Fecha Versión:"}
                        text={formattedDate}
                        fontSize={width*0.012}
                    />
                    <SLDTextWithLabel
                        x={clientLogoProps.width * 4.5}
                        y={height/4}
                        label={"Usuario Versión:"}
                        text={versionUser}
                        fontSize={width*0.012}
                    />
                </>
            )}
            <TextWithPositionReference
                x={width - clientLogoProps.x}
                y={height/4}
                text={"www.eclamp.tech"}
                fill={"#2c3a55ff"}
                fontSize={clientLogoProps.width*0.15}
                positionReference={PositionReferenceTypes.TOP_RIGHT}
            />
        </Group>
    );
}


export function TextWithPositionReference({
    positionReference = PositionReferenceTypes.TOP_LEFT,
    ...props
}) {
    const textRef = useRef(null);
    const [textSize, setTextSize] = useState(100);
    useEffect(() => {
        // console.log("LABEL REF USE EFFECT: ", labelRef.current)
        setTextSize({
            width: textRef.current?.textWidth ?? 200,
            height: textRef.current?.textHeight ?? props.fontSize
        })
    },[textRef, props.fontSize]);
    const { x, y } = props;
    const correctedPosition = {
        [PositionReferenceTypes.TOP_LEFT]: { x, y },
        [PositionReferenceTypes.TOP_RIGHT]: { x: x-textSize.width, y },
        [PositionReferenceTypes.BOTTOM_LEFT]: { x, y: y-textSize.height },
        [PositionReferenceTypes.BOTTOM_LEFT]: { x: x-textSize.width, y: y-textSize.height },
    }[positionReference] ?? { x, y };
    return (
        <Text ref={textRef} {...props} {...correctedPosition} fontVariant={"bold"} />
    )
}



function SLDTextWithLabel({
    x = 0,
    y = 0,
    fontSize = 20,
    label,
    text,
    positionReference = PositionReferenceTypes.TOP_LEFT,
}) {
    const labelRef = useRef(null);
    const [labelSize, setLabelSize] = useState(100);
    useEffect(() => {
        // console.log("LABEL REF USE EFFECT: ", labelRef.current)
        setLabelSize({
            width: labelRef.current?.textWidth ?? 200,
            height: labelRef.current?.textHeight ?? fontSize
        })
    },[labelRef, fontSize]);

    const xPadding = fontSize;
    const correctedPosition = {
        [PositionReferenceTypes.TOP_LEFT]: { x, y },
        [PositionReferenceTypes.BOTTOM_LEFT]: { x, y: y-labelSize.height },

    }[positionReference] ?? { x, y };
    return (
        <>
            <Text
                ref={labelRef}
                {...correctedPosition}
                text={label}
                fill={"#2c3a55ff"}
                fontVariant={"bold"}
                fontSize={fontSize}
            />
            <Text
                x={correctedPosition.x + labelSize.width + xPadding}
                y={correctedPosition.y}
                text={text}
                fill={"#444"}
                fontSize={fontSize}
            />
        </>
    );
}

function SLDLegend({
    x = 0,
    y = 0,
    height = 100,
    width = 1000,
    position,
    positionReference = PositionReferenceTypes.TOP_LEFT,
    setPosition
}) {
    const { contextualMenu } = useContext(SingleLineDiagramContext);

    const correctedPosition = ({
        [PositionReferenceTypes.TOP_LEFT]: {
            x,
            y
        },
        [PositionReferenceTypes.TOP_RIGHT]: {
            x: x - width,
            y
        },
        [PositionReferenceTypes.BOTTOM_LEFT]: {
            x,
            y: y - height
        },
        [PositionReferenceTypes.BOTTOM_RIGHT]: {
            x: x - width,
            y: y - height
        }
    })[positionReference];

    const onClick = withCancelBubble(({ evt }) => {
        if(evt.button === MouseButtons.RIGHT ) { // && !diagramMode.inAnyEditMode()
            console.log("ON RIGHT CLICK: ", evt);

            const positionNames = {
                [SLDLegendPositions.TOP_LEFT]: "arriba a la izquierda",
                [SLDLegendPositions.TOP_RIGHT]: "arriba a la derecha",
                [SLDLegendPositions.BOTTOM_LEFT]: "abajo a la izquierda",
                [SLDLegendPositions.BOTTOM_RIGHT]: "abajo a la derecha",
            };
            const items = Object.values(SLDLegendPositions)
                .filter(p => p !== position)
                .map(p => ({
                    label: "Cambiar posición de simbología a " + positionNames?.[p] + ".",
                    action: () => setPosition(p)
                }));
            contextualMenu.open({
                position: { top: evt.y, left: evt.x },
                items
            });
        }
    });

    const legendSVGProps = {
        URL: ("data:image/svg+xml;base64," + window.btoa(legendSVG)),
        x: 5,
        y: 5,
        width: width - 10,
        height: height - 10
    };

    return (
        <Group
            {...correctedPosition}
            onClick={onClick}
        >
            <Rect
                x={0}
                y={0}
                width={width}
                height={height}
                stroke={"#2c3a55ff"}
                strokeWidth={2}
                fill={"#ebf8ff"}
            />
            <SVGImage {...legendSVGProps} />
        </Group>
    );
}