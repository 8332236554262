import React, { useContext, useEffect, useRef, useState } from "react";
import { Rect, Text, Group } from "react-konva";
import {
    SLDConnection,
    CONNECTION_STATES
} from "../single-line-diagram-connection-point";
import { SingleLineDiagramContext } from "../SingleLineDiagramContext";
import { getConnectionOffsets, getOutputYOffset } from "./helpers";
import { MouseButtons, SLDComponentEnum } from "../../../constants/single-line-diagram"
import {
    PositionReferenceTypes, GENERATOR_RADIUS,
    roundToGridSize,
    SLDSymbols,
    TransformerSize,
    CONNECTION_INPUT,
    CONNECTION_OUTPUT,
} from "../../../constants/single-line-diagram";
import { SLDInfoParser } from "../../../managers/single-line-diagram/InfoParser";
import { InfoDisplayer } from "../../InfoDisplayer";
import { withCancelBubble } from "../../../utils/with-cancel-bubble";
import { ConnectionIdReader } from "../../../managers/single-line-diagram/ConnectionIdReader";
import { SLDTransformer } from "../SLDTransformer";
import { SLDGenerator } from "../SLDGenerator";
import { SVGImage } from "../SLDSVGImage";
import { SLDCursor } from "../SLDCursor";
import { useKonvaShapeCache } from "../../../hooks/single-line-diagram/useKonvaShapeCache";

// eslint-disable-next-line import/no-webpack-loader-syntax
const substationSVG = require(`!!raw-loader!../../../svg/single-line-diagram/substation.svg`).default;
const cubicleSVG = require(`!!raw-loader!../../../svg/single-line-diagram/cubicle.svg`).default;
const substationLowResolutionSVG = require(`!!raw-loader!../../../svg/single-line-diagram/substation-low-resolution.svg`).default;
const cubicleLowResolutionSVG = require(`!!raw-loader!../../../svg/single-line-diagram/cubicle-low-resolution.svg`).default;
const splitterInputSVG = require(`!!raw-loader!../../../svg/single-line-diagram/splitter-input.svg`).default;
const splitterOutputSVG = require(`!!raw-loader!../../../svg/single-line-diagram/splitter-output.svg`).default;
const loadSVG = require(`!!raw-loader!../../../svg/single-line-diagram/load.svg`).default;

const LOW_RESOLUTION_SVG_THRESHOLD = 0.6;
const POP_SVG_THRESHOLD = 0.25;
const LIGHT_ORANGE = "#FFE6CC";


export function SLDComponent({
    id = null,
    info,
    width = 100,
    height = 100,
    selectedConnection = null,
    initState = { x: 0, y: 0 },
    displayInfoObject = null,
    SVGList = [],
    children = null,
    isHistoric = false,
    backgroundColor = "#FFFFFF",
    strokeColor = "#000000",
    contextualMenuItems = []
}) {
    const [state, setState] = useState(initState);
    const [isHovered, setIsHovered] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const {
        focusedConnectionTypes,
        selectPathStart,
        selectPathEnd,
        updateComponentAndPaths,
        isConnectionAvailable,
        singleLineDiagramMode,
        deleteComponent,
        components,
        getConnectionPathId,
        updateEditedPath,
        hoveredComponent,
        inventoryEquipments,
        modal, 
        contextualMenu,
        dockComponentsInstance,
        handleUndockComponents,
        stageInteractions,
        downloadingDiagram,
        diagramHighlight,
        componentSelector,
        voltGraphInstance,
        goToPlugsGraph
    } = useContext(SingleLineDiagramContext);

    const groupCache = useKonvaShapeCache({ defaultCache: false });
    useEffect(() => {
		if(stageInteractions.isDragging || stageInteractions.isZooming) {
			groupCache.enableCache();
		} else {
			groupCache.disableCache();
		}
	}, [stageInteractions.isDragging, stageInteractions.isZooming]);

    useEffect(() => {
        initializeConnectionPositions();
    }, [isHistoric, height]);

    const initializeConnectionPositions = () => {
        const newPosition = { x: initState.x, y: initState.y };
        const newState = calculateState({ newPosition, prevState: state });
        if (singleLineDiagramMode.inAnyEditMode()) {
            updateComponentAndPaths({ key: id, value: newState });
        }
        setState((prevState) => {
            return calculateState({ newPosition, prevState });
        });
    };

    useEffect(() => {
        if (isHistoric) {
            return;
        }
        setState(prevState => {
            const outputs = {};
            Object.entries(prevState.outputs).forEach(([key, value]) => {
                outputs[key] = {
                    ...value,
                    connection: components?.[id]?.outputs?.[key]?.connection || { pathId: null }
                };
            });
            const inputs = {};
            Object.entries(prevState.inputs).forEach(([key, value]) => {
                const connection = components?.[id]?.inputs?.[key]?.connection || { ...value.connection }
                inputs[key] = {
                    ...value,
                    connection
                };
            });

            return {
                ...prevState,
                outputs,
                inputs,
            }
        });
    }, [components]);

    const calculateState = ({ newPosition, prevState }) => {
        const outputs = { ...prevState.outputs };

        const outputsVerticalDistance = height / Object.keys(outputs).length;
        Object.entries(outputs).forEach(([key, value], index) => {
            const outputPosition = {
                type: "out",
                x: newPosition.x + width,
                y: newPosition.y + (0.5 + index) * outputsVerticalDistance,
            };
            outputs[key] = { ...outputs[key], ...outputPosition };
        });

        const inputs = { ...prevState.inputs };
        const inputsVerticalDistance = height / Object.keys(inputs).length;
        Object.entries(inputs).forEach(([key, value], index) => {
            const inputPosition = {
                x: newPosition.x,
                y: newPosition.y + (0.5 + index) * inputsVerticalDistance,
            };
            inputs[key] = { ...inputs[key], ...inputPosition };
        });

        return {
            ...prevState,
            ...newPosition,
            outputs,
            inputs,
        };
    };

    const onClickWhileEditMode = (event) => {
        const { button } = event?.evt;
        if (isDeleteHovered() && button === MouseButtons.LEFT) {
            deleteComponent(id);
        }
    };

    const getComponentInfo = () => {
        const getInfoById = id => {
            const info = inventoryEquipments?.[id] ?? null;
            try {
                if (!Object.keys(info ?? {}).includes("cable_entrada_id")) { //To add voltages of input cable on splitters and loads
                    return info;
                }
                // console.log("GET COMPONENT INFO: INFO: ", info);
                const inputCableId = "cable_" + info?.["cable_entrada_id"] ?? null;
                // console.log("GET COMPONENT INFO: inputCableId: ", inputCableId);
                const inputCable = inventoryEquipments?.[inputCableId] ?? null;
                // console.log("GET COMPONENT INFO: inputCable: ", inputCable);

                if(!inputCable){
                    return info;
                }
                let femalePlugId = inputCable?.["enchufe_hembra"] ?? null
                // console.log("GET COMPONENT INFO: femalePlugId: ", femalePlugId);

                if(!femalePlugId) {
                    return info;
                }
                femalePlugId = "plug_" + femalePlugId;
                // console.log("GET COMPONENT INFO: femalePlugId: ", femalePlugId);

                const femalePlugInfo = inventoryEquipments?.[femalePlugId] ?? null;
                // console.log("GET COMPONENT INFO: femalePlugInfo: ", femalePlugInfo);

                const voltageKeys = ["voltaje_ab", "voltaje_bc", "voltaje_ca"]
                const { input_voltages = {}} = info;
                voltageKeys.forEach((key) => {
                    console.log("VOLTAGES FOR EACH: ", { key, input_voltages, femalePlugInfo })
                    console.log("VOLTAGES FOR EACH: ", { femaleVoltage: femalePlugInfo[key], prevInput: input_voltages[key] });

                    input_voltages[key] = femalePlugInfo[key]
                        ? femalePlugInfo[key]
                        : input_voltages?.[key] ?? null;
                });
                info["input_voltages"] = input_voltages;
                // console.log("GET COMPONENT INFO: INFO: ", info);
                return info;
            } catch (e) {
                return info;
            }

        }
        if (!Array.isArray(id)) {
            return getInfoById(id);
        }
        return id.map(getInfoById);
    };

    const openComponentInfo = () => {
        const componentInfo = getComponentInfo();
        if (!componentInfo) {
            return;
        }
        const prefix = SLDInfoParser.getSLDComponentTypeName(componentInfo);
        const parsedInfo = SLDInfoParser.parseSLDComponentInfo(componentInfo);

        const component = InfoDisplayer({ info: parsedInfo });
        modal.setModal({
            title: `${prefix} ${componentInfo?.["nombre"]}`,
            component,
            isOpen: true,
            cancelLabel: "volver",
            onClose: modal.resetModal
        });
    };

    const onRightClickComponent = ({ event, id }) => {
        const { evt } = event;
        const menuItems = [];
        if ( info ) {
            menuItems.push({
                label: "Ver información",
                action: openComponentInfo
            });
        }
        const openMenu = (items) => items?.length && contextualMenu.open({
            position: { top: evt.y, left: evt.x },
            items
        });
        if (!singleLineDiagramMode.inAnyEditMode()) {
            openMenu(menuItems);
            return;
        }

        openMenu([
            ...menuItems,
            ...contextualMenuItems
        ]);
    };

    const onClick = withCancelBubble(event => {
        const { button } = event?.evt;
        if (button === MouseButtons.RIGHT) {
            onRightClickComponent({ event, id });
        }
        if (singleLineDiagramMode.inAnyEditMode()) {
            onClickWhileEditMode(event);
        }
    });
            
    const deployConnections = () => {
        const addIndexToEntryValue = ([key, value], index) => [key, { ...value, index }];
        const inputEntries = Object.entries(state?.inputs ?? {}).map(addIndexToEntryValue);
        const outputEntries = Object.entries(state?.outputs ?? {}).map(addIndexToEntryValue);
        const connectionEntries = [...inputEntries, ...outputEntries];

        const componentId = id;
        return connectionEntries.map(([key, value]) => {
            const { type, index } = value;

            const connectionId = type + ":" + key;
            const compoundId = componentId + ":" + connectionId;
            const isSelected = type === CONNECTION_OUTPUT && selectedConnection === key;
            const isAvailable = () => isConnectionAvailable(compoundId);

            const connectedCableId = getConnectionPathId(compoundId);

            const isEndEditingPoint = type === CONNECTION_INPUT
                && singleLineDiagramMode.inEditCableMode()
                && connectedCableId === singleLineDiagramMode.getSelectedCableToEdit()
                && connectedCableId !== null;

            const onClick = withCancelBubble(event => { //TODO: Aqui se hace la logica de acople cuando lo inicia el cable.
                if (dockComponentsInstance.isFirstComponentInDocking()) {
                    dockComponentsInstance.dockComponents(compoundId);
                    return;
                }
                if (voltGraphInstance.isFirstPlugSelected()) {
                    if (!connectionWithEclamp) {
                        return;
                    }
                    const plugId = getPlugId(compoundId);
                    if (plugId) {
                        voltGraphInstance.selectPlug(plugId);
                        return;
                    } else {
                        console.error("No plugId found for compoundId: ", compoundId);
                    }
                }
                if (isAvailable()) {
                    onConnectToNewCable(event);
                }

                if (isEndEditingPoint) { // TODO: check and delete... there's no edit cable button anymore
                    if (type === CONNECTION_INPUT) {
                        updateEditedPath();
                    }
                }
                if (event.evt.button === MouseButtons.RIGHT) {
                    onRightClick(event);
                }
            });

            const onRightClick = (event) => { //TODO: Aqui se inicia el acople cuando lo inicia el componente.
                const plugId = getPlugId(compoundId);
                const displayPlugName = inventoryEquipments?.["plug_"+plugId]?.["display_name"] ?? "-";
                const menuItems = [];

                if (hasEclamp()) {
                    menuItems.push({
                        label: "Ver información",
                        action: openConnectionInformation
                    });
                }

                if (isAvailable() && singleLineDiagramMode.inAnyEditMode()) {
                    menuItems.push({
                        label: "Agregar cable",
                        action: () => {
                            componentSelector.setType(SLDComponentEnum.CABLE);
                            componentSelector.setConnectionPoint(compoundId);
                        }
                    })
                }

                if (!isAvailable() && singleLineDiagramMode.inAnyEditMode()) {
                    menuItems.push({
                        label: "Desacoplar conexión",
                        action: () => {
                            console.log("Desacoplar conexión ", compoundId);
                            handleUndockComponents(compoundId);
                        }

                    });
                }

                if (connectionWithEclamp && !isAvailable()) {
                    menuItems.push({
                        label: `Gráfico Enchufe ${displayPlugName}`,
                        action: () => goToPlugsGraph("plug_"+plugId)

                    }, {
                        label: "Caída de Tensión", //Init the voltage graph with the selected plug.
                        action: () => {
                            const plugId = getPlugId(compoundId);
                            if (plugId) {
                                voltGraphInstance.selectPlug(plugId);
                            }
                        }
                    });
                }

                const openMenu = (items) => contextualMenu.open({
                    position: { top: event.evt.y, left: event.evt.x },
                    items
                });

                // push more menuItems to be exclusive for edit mode here...
                if (menuItems.length > 0) { 
                    openMenu(menuItems);
                }
            };



            const openConnectionInformation = () => {
                const plugId = getPlugId(compoundId);
                if (!plugId) {
                    return;
                }
                const plugInfo = inventoryEquipments?.["plug_"+plugId] ?? null;
                const parsedInfo = SLDInfoParser.parsePlugInfo(plugInfo, plugInfo?.["alarms"]);
                console.log("parsedInfo: ", parsedInfo);
                const alarms = plugInfo?.["alarms"] ?? null;
                const component = InfoDisplayer({ info: parsedInfo, alarms });
                modal.setModal({
                    title: `Enchufe ${plugInfo?.['display_name'] ?? "-"}`,
                    component,
                    isOpen: true,
                    cancelLabel: "volver",
                    onClose: modal.resetModal
                });
            };

            const getPlugId = compoundId => {
                try {
                    const idReader = new ConnectionIdReader({ compoundId });
                    const { componentId, connectionType, connectionId } = idReader.getParsedComponentConnection();
                    const component = inventoryEquipments[componentId];
                    if (!component) { //mainly for "no-code-load" components
                        return null;
                    }
                    let cableId = null;
                    if (component.type === SLDComponentEnum.SUBSTATION && connectionType === CONNECTION_OUTPUT) {
                        cableId = component["cubiculos"][connectionId]["cable_salida_id"];
                    } else if(component.type === SLDComponentEnum.SPLITTER && connectionType === CONNECTION_INPUT) {
                        cableId = component["cable_entrada_id"];
                    } else if(component.type === SLDComponentEnum.SPLITTER && connectionType === CONNECTION_OUTPUT) {
                        cableId = component["splitter_salidas"][connectionId]["cable_salida_id"];
                    } else if(component.type === SLDComponentEnum.LOAD && connectionType === CONNECTION_INPUT) {
                        cableId = component["cable_entrada_id"];
                    }
                    if (!cableId) {
                        return null;
                    }
                    let cable = inventoryEquipments["cable_"+cableId];

                    let plugId = null;
                    if (connectionType === CONNECTION_OUTPUT) {
                        plugId = cable["enchufe_macho"];
                    } else if (connectionType === CONNECTION_INPUT) {
                        plugId = cable["enchufe_hembra"];
                    }

                    return plugId;
                } catch (e) {
                    console.error(e);
                    return null;
                }
            };

            const hasEclamp = () => {
                const getPlug = (plugId) => {
                    return inventoryEquipments?.["plug_"+plugId] ?? null;
                };
    
                const plugHasEclamp = (plug) => {
                    return plug?.["eclamp"] ?? false;
                };
                
                const componentPlugId = getPlugId(compoundId);
                if (componentPlugId) {
                    const plug = getPlug(componentPlugId);
                    const isEclamp = plugHasEclamp(plug);
                    if (isEclamp) {
                        return true;
                    }
                }
                return false;
            };

            let connectionWithEclamp = hasEclamp();

            const onConnectToNewCable = (event) => {
                if (type === CONNECTION_OUTPUT && focusedConnectionTypes.includes(CONNECTION_OUTPUT)) {
                    selectPathStart(compoundId);
                }
                if (type === CONNECTION_INPUT && focusedConnectionTypes.includes(CONNECTION_INPUT)) {
                    selectPathEnd(compoundId);
                }
                event.cancelBubble = true;
            };

            const connectionTypeAmount = Object.keys(state[type === CONNECTION_INPUT ? "inputs" : "outputs"])?.length || 0;
            const position = getConnectionOffsets({
                connectionType: type,
                connectionTypeAmount,
                index, width, height
            });

            const getConnectionAlarmStatus = () => {
                const plugId = getPlugId(compoundId);

                if (!connectionWithEclamp || !plugId) {
                    return CONNECTION_STATES.NO_SENSOR;
                }
    
                const alarmIds = [
                    ["eclamp_no_data"],
                    ["humidity"],
                    ["inner_temperature", "temp_interior_ab"],
                    ["inner_temperature", "temp_interior_bc"],
                    ["inner_temperature", "temp_interior_ca"],
                    ["voltages", "voltaje_ab"],
                    ["voltages", "voltaje_bc"],
                    ["voltages", "voltaje_ca"],
                ];

                const getPlugAlarms = (plug) => alarmIds.map((keysArray) => {
                    if (plug === null)
                        return [];
                    return ["alarms", ...keysArray].reduce((prev, key) => {
                        return prev?.[key] ?? null;
                    }, plug);
                });
                const alarmsStatus = getPlugAlarms(inventoryEquipments?.["plug_"+plugId]);
    
                if (alarmsStatus.includes(CONNECTION_STATES.NO_DATA)) {
                    return CONNECTION_STATES.NO_DATA;
                }
    
                if (alarmsStatus.includes(CONNECTION_STATES.RED_ALARM)) {
                    return CONNECTION_STATES.RED_ALARM;
                }
    
                if (alarmsStatus.includes(CONNECTION_STATES.YELLOW_ALARM)) {
                    return CONNECTION_STATES.YELLOW_ALARM;
                }
    
                return CONNECTION_STATES.OK;
            }

            return (
                <SLDConnection
                    compoundId={compoundId}
                    key={type + ":" + key}
                    {...position}
                    type={type}
                    isSelected={isSelected}
                    isAvailable={isAvailable()}
                    isEndEditingPoint={isEndEditingPoint}
                    state={getConnectionAlarmStatus()}
                    onClick={onClick}
                    hasEclamp={connectionWithEclamp}
                />
            );
        });
    };

    const deploySVGList = () => {
        const { scale } = stageInteractions;
        if(scale < POP_SVG_THRESHOLD && !downloadingDiagram) { // Reduce level of detail for performance.
            return null
        }
        return SVGList.map((svgObject, index) => {
            const x = svgObject.xOffset;
            const y = svgObject.yOffset;

            const { xOffset, yOffset, ...imageProps } = { ...svgObject, x, y };

            return (
                <SVGImage key={index.toString()} {...imageProps} />
            );
        })
    };

    const onDragMove = (e) => {
        if (!isDragging) {
            setIsDragging(true);
        }
        if (isHistoric) {
            return;
        }
        const newPosition = { x: e.target.x(), y: e.target.y() };
        setState((prevState) => {
            return calculateState({ newPosition, prevState });
        });
    };

    const onDragEnd = (e) => {
        setIsDragging(false);
        if (!singleLineDiagramMode.inAnyEditMode()) {
            return;
        }
        const newPosition = {
            x: roundToGridSize(e.target.x()),
            y: roundToGridSize(e.target.y())
        };
        setState((prevState) => {
            const newState = calculateState({ newPosition, prevState });
            updateComponentAndPaths({ key: id, value: newState });
            return newState;
        });
    };

    const hoverMethods = {
        onMouseEnter: event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'pointer';
            setIsHovered(true);
            hoveredComponent.setHoveredComponentId(id);
            diagramHighlight.removeHighlightedComponent(id);
        },
        onMouseLeave: event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'default';
            setIsHovered(false);
            hoveredComponent.resetHoveredComponent();
        }
    };

    const isDeleteHovered = () => singleLineDiagramMode.inDeleteMode() && isHovered;
    const getFillColor = () => {
        if (diagramHighlight.isHighlighted(id)) {
            return LIGHT_ORANGE;
        }
        if (isDeleteHovered()) {
            return "pink";
        }
        return backgroundColor;
    }
    const getStrokeColor = () => {
        if (diagramHighlight.isHighlighted(id)) {
            return "orange";
        }
        if (isDeleteHovered()) {
            return "red";
        }
        return strokeColor;
    }
    const rectStyleProps = {
        width,
        height,
        fill: getFillColor(),
        strokeWidth: diagramHighlight.isHighlighted(id) ? 2 : 1,
        stroke: getStrokeColor(),
    };

    const deployCursorLines = () => {
        if (!isDragging) {
            return null;
        }
        const cursors = [];
        Object.values(state.inputs).forEach(({x, y}) => {
            cursors.push({ x, y, direction: "left" });
        });
        Object.values(state.outputs).forEach(({x, y}) => {
            cursors.push({ x, y, direction: "right" });
        });
        return cursors.map(({ x, y, direction}) => (
            <SLDCursor
                point={{ x, y }}
                offset={{ x: state.x, y: state.y }}
                direction={direction}
            />
        ));
    };

    return (
        <Group
            ref={groupCache.ref}
            draggable={singleLineDiagramMode.inAnyEditMode()}
            x={state.x}
            y={state.y}
            onDragStart={() => { singleLineDiagramMode.inAnyEditMode() && setIsDragging(true) }}
            onDragMove={singleLineDiagramMode.inAnyEditMode() ? onDragMove : null}
            onDragEnd={singleLineDiagramMode.inAnyEditMode() ? onDragEnd : null}
            onClick={onClick}
            onMouseDown={withCancelBubble(event => {event.evt.preventDefault()})}
            {...hoverMethods}
        >
            <Rect
                x={0}
                y={0}
                {...rectStyleProps}
            />
            {children}
            {deployConnections()}
            {deploySVGList()}
            {deployCursorLines()}
        </Group>
    );
}


const deployLabels = (labelObjects) => labelObjects.map((el, index) => {
    return <Text key={index.toString()} {...el} />
});


export function SLDSubstation(props) {
    const { x0 = 100, y0 = 100, info, id } = props;

    const outputs = {};

    Object.keys(info?.cubiculos || {}).forEach(key => { //TODO: check this. it's setting pathId always to null instead of checking if there's already something path linked.
        outputs[key] = { type: "out", connection: { pathId: null } };
    });

    const { inDiagramInfo } = props;
    const keys = Object?.keys(inDiagramInfo) ?? [];
    const initState = keys.includes("inputs") && keys.includes("outputs") ?
        props.inDiagramInfo : {
            type: "substation",
            model: "-",
            x: x0,
            y: y0,
            outputs
        };

    const displayInfoObject = {
        nombre: {},
        zona_nombre: {
            displayKey: "zona nombre"
        },
        type: {
            displayKey: "tipo"
        }
    };

    const componentSize = {
        height: 400,
        width: 400
    };
    const { stageInteractions, downloadingDiagram } = useContext(SingleLineDiagramContext);
    const useLowResolution = !downloadingDiagram && stageInteractions.scale < LOW_RESOLUTION_SVG_THRESHOLD;

    const substationSVGObject = {
        URL: ("data:image/svg+xml;base64," + window.btoa(useLowResolution ? substationLowResolutionSVG : substationSVG)),
        xOffset: 0,
        yOffset: componentSize.height * 0.0125,
        width: componentSize.width * 0.7,
        height: componentSize.height * 0.975
    };

    const getCubicleSVGObject = (index) => {
        const width = componentSize.width - substationSVGObject.width;
        const height = componentSize.height * 0.06;
        let yOffset = getOutputYOffset({
            connectionType: CONNECTION_OUTPUT,
            connectionTypeAmount: Object.entries(outputs).length,
            index: index,
            width: componentSize.width,
            height: componentSize.height
        });
        yOffset = yOffset - height / 2;

        return {
            URL: ("data:image/svg+xml;base64," + window.btoa(useLowResolution ? cubicleLowResolutionSVG :  cubicleSVG)),
            xOffset: substationSVGObject.width,
            yOffset,
            width,
            height
        };
    };

    const cubicleSVGObjects = Object.keys(outputs).map(
        (output, index) => getCubicleSVGObject(index)
    );

    const SVGList = [
        substationSVGObject,
        ...cubicleSVGObjects
    ];

    const textMargin = {
        x: componentSize.width * 0.02,
        y: componentSize.height * 0.02
    };

    const substationLabel = {
        x: textMargin.x,
        y: textMargin.y,
        width: substationSVGObject.width - 2 * textMargin.x,
        wrap: "word",
        align: "center",
        fontSize: 20,
        text: `SUBESTACIÓN: ${info?.["nombre"]}\n`
    };

    const substationInput = info?.['input_label'] ?? "-";
    const maxInputLabelWidth = 400;
    const substationInputLabel = {
        x: -textMargin.x - maxInputLabelWidth,
        y: (substationSVGObject.height / 2) - 10,
        wrap: "word",
        align: "right",
        width: maxInputLabelWidth,
        fontSize: 20,
        text: substationInput.split(" ").join("\n")
    };

    const substationPowerMVA = info?.['power_MVA'] ?? "-";
    const substationPowerLabel = {
        x: textMargin.x,
        y: textMargin.y + 50,
        width: (substationSVGObject.width / 2) - (2 * textMargin.x),
        align: "center",
        wrap: "word",
        fontSize: 16,
        text: `${substationPowerMVA} MVA`
    };

    const primaryVoltageKV = info?.['primary_voltage_KV'] ?? "-";
    const secondaryVoltageKV = info?.['secondary_voltage_KV'] ?? "-";
    const secondaryMaxCurrent = info?.['secondary_current_A'] ?? "-";

    const substationTransformerLabel = {
        x: textMargin.x,
        y: textMargin.y + 70,
        width: (substationSVGObject.width / 2) - (2 * textMargin.x),
        align: "center",
        wrap: "word",
        fontSize: 16,
        text: `${primaryVoltageKV} / ${secondaryVoltageKV} kV`
    };

    const substationSecondaryLabel = {
        x: textMargin.x + (substationSVGObject.width / 2),
        y: textMargin.y + 50,
        width: (substationSVGObject.width / 2) - (2 * textMargin.x),
        wrap: "word",
        align: "center",
        fontSize: 16,
        text: `${secondaryVoltageKV}kV\n${secondaryMaxCurrent}A`
    };

    const getCubicleLabel = ([cubicleKey, cubicle], index) => {
        const width = componentSize.width - substationSVGObject.width - (2 * textMargin.x);
        const yOffset = getOutputYOffset({
            connectionType: CONNECTION_OUTPUT,
            connectionTypeAmount: Object.entries(outputs).length,
            index: index,
            width: componentSize.width,
            height: componentSize.height
        }) + textMargin.y + (componentSize.height * 0.06) / 2;

        return {
            x: substationSVGObject.width + textMargin.x,
            y: yOffset,
            width,
            wrap: "word",
            align: "center",
            fontSize: 14,
            text: `CUBÍCULO: ${cubicle?.["nombre"]}\n`,
        }
    };

    const cubicleLabels = Object.entries(info?.cubiculos ?? {})
        .map(getCubicleLabel);

    const labelObjects = [
        substationLabel,
        substationInputLabel,
        substationPowerLabel,
        substationTransformerLabel,
        substationSecondaryLabel,
        ...cubicleLabels
    ];

    return (
        <>
            <SLDComponent {...props} {...componentSize} initState={initState} displayInfoObject={displayInfoObject} SVGList={SVGList}>
                {deployLabels(labelObjects)}
            </SLDComponent>
        </>
    );
}


export function SLDSplitter(props) {
    const { x0 = 800, y0 = 200, info } = props;
    const splitterOutputs = info?.["splitter_salidas"] || [];
    const getConnectionFromIds = (ids, type) => { //TODO: add cable id if already connected
        const connections = {};

        ids.forEach((id) => {
            connections[id] = {
                type, connection: { pathId: null } //TODO: check this. it's setting pathId always to null instead of checking if there's already something path linked.
            }
        });

        return connections;

    }

    const inputIds = ['cable_entrada_id'];
    const outputIds = splitterOutputs.map((el, index) => index);

    const inputs = getConnectionFromIds(inputIds, CONNECTION_INPUT);
    const outputs = getConnectionFromIds(outputIds, CONNECTION_OUTPUT);

    const { inDiagramInfo } = props;
    const keys = Object?.keys(inDiagramInfo) ?? [];
    const initState = keys.includes("inputs") && keys.includes("outputs") ?
        props.inDiagramInfo : {
            type: "splitter",
            model: "-",
            x: x0,
            y: y0,
            inputs,
            outputs
        };

    const displayInfoObject = {
        "codigo_cliente": {
            displayKey: "codigo cliente"
        },
        "cubiculo_id": {
            displayKey: "cubiculo"
        },
        "type": {
            displayKey: "tipo"
        }
    };

    const componentSize = {
        height: 75,
        width: 50
    };

    const splitterInputSVGObject = {
        URL: ("data:image/svg+xml;base64," + window.btoa(splitterInputSVG)),
        xOffset: 0,
        yOffset: componentSize.height * 0.05,
        width: componentSize.width * 0.5,
        height: componentSize.height * 0.9
    };

    const getSplitterOutputSVGObject = (index) => {
        const width = componentSize.width - splitterInputSVGObject.width;
        const height = 2;//componentSize.height * 0.05;
        let yOffset = getOutputYOffset({
            connectionType: CONNECTION_OUTPUT,
            connectionTypeAmount: Object.entries(outputs).length,
            index: index,
            width: componentSize.width,
            height: componentSize.height
        });
        yOffset = yOffset - height / 2;
        return {
            URL: ("data:image/svg+xml;base64," + window.btoa(splitterOutputSVG)),
            xOffset: splitterInputSVGObject.width,
            yOffset,
            width,
            height
        };
    };

    const splitterOutputSVGObjects = Object.keys(outputs).map(
        (output, index) => getSplitterOutputSVGObject(index)
    );

    const SVGList = [
        splitterInputSVGObject,
        ...splitterOutputSVGObjects
    ];

    const labelObjects = [{
        x: 0,
        y: -16,
        width: componentSize.width,
        align: "center",
        fontSize: 14,
        text: `SPL-${info?.["codigo_cliente"]}`
    }];
    return (
        <>
            <SLDComponent {...props} {...componentSize} initState={initState} displayInfoObject={displayInfoObject} SVGList={SVGList}>
                {deployLabels(labelObjects)}
            </SLDComponent>
        </>
    );
}


export function SLDCell(props) {
    const { x0 = 700, y0 = 700 } = props;

    const { inDiagramInfo } = props;
    const keys = Object.keys(inDiagramInfo ?? {}) ?? [];
    const initState = keys.includes("inputs") && keys.includes("outputs") ? props.inDiagramInfo
        : {
            type: "cell",
            model: "-",
            x: x0,
            y: y0,
            inputs: {
                0: { type: "in", connection: { pathId: null } },
            },
            outputs: {
                0: { type: "out", connection: { pathId: null } },
            },
        };

    const displayInfoObject = {
        // nombre: {},
        // estado: {},
        // tipo_equipo: {
        //     displayKey: "tipo equipo"
        // },
        // cubiculo_nombre: {
        //     displayKey: "cubiculo"
        // },
        // type: {
        //     displayKey: "tipo"
        // },
    };

    const componentSize = {
        height: 50,
        width: 50
    };

    const labelObjects = [
    {
        x: 0,
        y: -16,
        fontSize: 14,
        text: `CELL-${props?.info?.["codigo_cliente"]}`,
        width: componentSize.width,
        align: "center"
    },
    {
        x: 0,
        y: componentSize.height*.3,
        fontSize: 20,
        text: "52",
        fontWeight: 900,
        width: componentSize.width,
        height: componentSize.height,
        wrap: "word",
        align: "center",
        backgroundColor: "red"
    }
    ];


    return (
        <>
            <SLDComponent {...props}  {...componentSize} backgroundColor={"#FFC000"} initState={initState} SVGList={[]} displayInfoObject={displayInfoObject}>
                {deployLabels(labelObjects)}
            </SLDComponent>
        </>
    );
}


export function SLDLoad(props) {
    const { x0 = 1200, y0 = 200 } = props;

    const { inDiagramInfo, id } = props;

    const { linkedSymbols =  {
        // transformer: {},
        // generator: {}
    }} = inDiagramInfo;

    const keys = Object?.keys(inDiagramInfo) ?? [];
    const initState = keys.includes("inputs") && keys.includes("outputs") ? props.inDiagramInfo
        : {
            type: "load",
            model: "-",
            x: x0,
            y: y0,
            inputs: {
                0: { type: "in", connection: { pathId: null } }, //TODO: check this. it's setting pathId always to null instead of checking if there's already something path linked.
            },
        };

    const displayInfoObject = {
        nombre: {},
        estado: {},
        tipo_equipo: {
            displayKey: "tipo equipo"
        },
        cubiculo_nombre: {
            displayKey: "cubiculo"
        },
        type: {
            displayKey: "tipo"
        },
    };

    const hasTransformer = Object.keys(linkedSymbols).includes(SLDSymbols.TRANSFORMER);
    const hasGenerator = Object.keys(linkedSymbols).includes(SLDSymbols.GENERATOR);

    const symbolsWidth = Math.max(
        ( hasTransformer ? TransformerSize.WIDTH : 0),
        ( hasGenerator ? GENERATOR_RADIUS * 2 : 0)
    );

    const symbolsHeight = ( hasTransformer ? TransformerSize.HEIGHT : 0) +
        ( hasGenerator ? GENERATOR_RADIUS * 2 : 0);

    const { toggleSymbolFromComponents } = useContext(SingleLineDiagramContext);

    const baseComponentSize = {
        height: 25,
        width: 75
    }

    const componentSize = {
        height: Math.max(baseComponentSize.height, symbolsHeight),
        width: baseComponentSize.width + symbolsWidth,
    };

    const loadSVGObject = {
        URL: ("data:image/svg+xml;base64," + window.btoa(loadSVG)),
        xOffset: symbolsWidth,
        yOffset: Math.max(symbolsHeight - baseComponentSize.height, 0) / 2,
        ...baseComponentSize
    };

    const SVGList = [loadSVGObject];

    const labelObjects = [{
        x: componentSize.width * 1.05,
        y: Math.max(symbolsHeight - baseComponentSize.height, 0) / 2,
        fontSize: 20,
        text: `${props.info?.["nombre"]}`,
    }];

    const contextualMenuItems = [
        {
            label: hasTransformer ? "Retirar transformador" : "Agregar transformador",
            action: () => {
                toggleSymbolFromComponents({
                    componentId: id,
                    symbol: SLDSymbols.TRANSFORMER,
                    componentSize
                });
            }
        },
        {
            label: hasGenerator ? "Retirar generador" : "Agregar generador",
            action: () => {
                toggleSymbolFromComponents({
                    componentId: id,
                    symbol: SLDSymbols.GENERATOR,
                    componentSize
                });
            }
        }
    ];

    return (
        <>
            <SLDComponent {...props}  {...componentSize} contextualMenuItems={contextualMenuItems} linkedSymbols={linkedSymbols} initState={initState} SVGList={SVGList} displayInfoObject={displayInfoObject} backgroundColor={"#FFFFFF"} strokeColor={"#000000"}>
                {deployLabels(labelObjects)}
                { hasTransformer && (
                    <SLDTransformer
                        y={componentSize.height / 2 - 2.5}
                        alignType={hasGenerator ? PositionReferenceTypes.BOTTOM_LEFT : PositionReferenceTypes.CENTER_LEFT}

                    />
                )}
                { hasGenerator && (
                    <SLDGenerator
                        y={componentSize.height / 2 + 2.5}
                        alignType={hasTransformer ? PositionReferenceTypes.TOP_LEFT : PositionReferenceTypes.CENTER_LEFT}
                    />
                )}
            </SLDComponent>
        </>
    );
}


export function SLDNoInventoryLoad(props) {
    const { x0 = 0, y0 = 0 } = props;

    const { inDiagramInfo, id } = props;

    const { linkedSymbols =  {
        // transformer: {},
        // generator: {}
    }} = inDiagramInfo;

    const keys = Object?.keys(inDiagramInfo) ?? [];
    const initState = keys.includes("inputs") && keys.includes("outputs") ? props.inDiagramInfo
        : {
            type: "load",
            model: "-",
            x: x0,
            y: y0,
            inputs: {
                0: { type: "in", connection: { pathId: null } }
            },
        };

    const displayInfoObject = {
        nombre: {},
        estado: {},
        tipo_equipo: {
            displayKey: "tipo equipo"
        },
        cubiculo_nombre: {
            displayKey: "cubiculo"
        },
        type: {
            displayKey: "tipo"
        },
    };

    const hasTransformer = Object.keys(linkedSymbols).includes(SLDSymbols.TRANSFORMER);
    const hasGenerator = Object.keys(linkedSymbols).includes(SLDSymbols.GENERATOR);
    const hasSymbols = hasTransformer || hasGenerator;

    const symbolsWidth = Math.max(
        ( hasTransformer ? TransformerSize.WIDTH : 0),
        ( hasGenerator ? GENERATOR_RADIUS * 2 : 0)
    );

    const symbolsHeight = ( hasTransformer ? TransformerSize.HEIGHT : 0) +
        ( hasGenerator ? GENERATOR_RADIUS * 2 : 0);

    const { toggleSymbolFromComponents } = useContext(SingleLineDiagramContext);

    const baseComponentSize = {
        height: 50,
        width: 200
    }

    const fontSize = 20;
    const textRef = useRef(null);
    const [textSize, setTextSize] = useState(100);
    useEffect(() => {
        console.log("USE EFFECT TEXT REF: ", textRef.current );
        setTextSize({
            width: textRef.current?.textWidth ?? 200,
            height: (textRef.current?.textArr?.length + 1 ) * fontSize ?? fontSize * 2
        });
    },[textRef]);

    const componentSize = {
        height: Math.max(baseComponentSize.height, symbolsHeight, textSize.height),
        width: Math.max(baseComponentSize.width, textSize.width + 20) + symbolsWidth,
    };

    let lineCharacters = 0;
    const maxLineCharacters = 20;
    const processedName = (inDiagramInfo?.["name"] ?? "-").split("")
        .map(c => {
            if (lineCharacters >= maxLineCharacters && c === " ") {
                lineCharacters = 0;
                return "\n" + c;
            }
            lineCharacters++;
            return c;
        }).join("");

    const labelObject = {
        x: hasSymbols ? symbolsWidth : 0,
        y: 0,
        ...componentSize,
        align: "center",
        wrap: "word",
        fontSize,
        text: processedName
    };

    const contextualMenuItems = [
        {
            label: hasTransformer ? "Retirar transformador" : "Agregar transformador",
            action: () => {
                toggleSymbolFromComponents({
                    componentId: id,
                    symbol: SLDSymbols.TRANSFORMER,
                    componentSize
                });
            }
        },
        {
            label: hasGenerator ? "Retirar generador" : "Agregar generador",
            action: () => {
                toggleSymbolFromComponents({
                    componentId: id,
                    symbol: SLDSymbols.GENERATOR,
                    componentSize
                });
            }
        }
    ];

    return (
        <>
            <SLDComponent {...props}  {...componentSize} contextualMenuItems={contextualMenuItems} linkedSymbols={linkedSymbols} initState={initState} displayInfoObject={displayInfoObject} backgroundColor={"#FFFFFF"} strokeColor={"#000000"}>
                <Text ref={textRef} {...labelObject} />
                { hasTransformer && (
                    <SLDTransformer
                        y={componentSize.height / 2 - 2.5}
                        alignType={hasGenerator ? PositionReferenceTypes.BOTTOM_LEFT : PositionReferenceTypes.CENTER_LEFT}

                    />
                )}
                { hasGenerator && (
                    <SLDGenerator
                        y={componentSize.height / 2 + 2.5}
                        alignType={hasTransformer ? PositionReferenceTypes.TOP_LEFT : PositionReferenceTypes.CENTER_LEFT}
                    />
                )}
            </SLDComponent>
        </>
    );
}


export const SLDComponentsByType = {
    [SLDComponentEnum.SUBSTATION]: SLDSubstation,
    [SLDComponentEnum.SPLITTER]: SLDSplitter,
    [SLDComponentEnum.CELL]: SLDCell,
    [SLDComponentEnum.LOAD]: SLDLoad,
    [SLDComponentEnum.NO_INVENTORY_LOAD]: SLDNoInventoryLoad
};