import { useState } from "react";
import {
  CONNECTION_INPUT,
  CONNECTION_OUTPUT
} from "../constants/single-line-diagram";
import { SLDComponentEnum } from "../components/DiagramaUnilineal/constants";
import { parseConnectionCompoundId } from "../components/DiagramaUnilineal/utils";
import { deepCopy } from "../utils/deep-copy";
import { isDeepEqual } from "../utils/is-deep-equal";
import { InsertCableSides } from "../constants/single-line-diagram";

export function useInventory2(initState) {
  const [ remoteEquipments, setRemoteEquipments ] = useState(initState);
  const [ localEquipments, setLocalEquipments ] = useState(initState);

  const setRetrievedEquipments = (equipments) => {
    setRemoteEquipments(equipments);
    setLocalEquipments(equipments);
  };

  const hasLocalChanges = () => {
    return !isDeepEqual(remoteEquipments, localEquipments);
  };

  const resetLocalChanges = () => setLocalEquipments(remoteEquipments);

  const updateRemoteToLocal = () => setRemoteEquipments(deepCopy(localEquipments));

  /*********************  CONSUME COMPONENTS FROM INVENTORY *********************/

  const consumeComponentOutput = ({componentId, outputId, cableId, type}) => {
    if (type === SLDComponentEnum.SUBSTATION) {
      consumeCubicle({substationId: componentId, cubicleId: outputId, cableId});
    } else if (type === SLDComponentEnum.SPLITTER) {
      consumeSplitterOutput({splitterId: componentId, outputIndex: outputId, cableId});
    }
  };

  const consumeComponentInput = ({componentId, cableId, type}) => {
    if (type === SLDComponentEnum.LOAD) {
      consumeLoad({loadId: componentId, cableId});
    } else if (type === SLDComponentEnum.SPLITTER) {
      consumeSplitterInput({splitterId: componentId, cableId});
    }
  };

  const releaseComponentOutput = ({componentId, outputId, type}) => {
    if (type === SLDComponentEnum.SUBSTATION) {
      releaseCubicle({substationId: componentId, cubicleId: outputId});
    } else if (type === SLDComponentEnum.SPLITTER) {
      releaseSplitterOutput({splitterId: componentId, outputIndex: outputId});
    }
  };

  const releaseComponentInput = ({componentId, type}) => {
    if (type === SLDComponentEnum.LOAD) {
      releaseLoad({loadId: componentId});
    } else if (type === SLDComponentEnum.SPLITTER) {
      releaseSplitterInput({splitterId: componentId});
    }
  };

  const consumeCubicle = ({substationId, cubicleId, cableId}) => {
    const substation = localEquipments[substationId];
    if (!substation) {
      throw new Error(`Substation with id ${substationId} not found`);
    }
    const cubicle = localEquipments[substationId].cubiculos[cubicleId];
    if (!cubicle) {
      throw new Error(`Cubicle with id ${substationId} not found at substation ${substationId}`);
    }

    setLocalEquipments((prev) => {
      const newState = {...prev};
      newState[substationId]["cubiculos"][cubicleId]["cable_salida_id"] = cableId;
      return newState;
    });
  }

  const releaseCubicle = ({ substationId, cubicleId }) => {
    setLocalEquipments((prev) => {
      const newState = deepCopy(prev);
      if (
        newState[substationId] &&
        newState[substationId].cubiculos &&
        newState[substationId].cubiculos[cubicleId]
      ) {
        newState[substationId].cubiculos[cubicleId].cable_salida_id = null;
        newState[substationId].cubiculos[cubicleId].disponible = true;
      }
      return newState;
    });
  };

  const consumeSplitterInput = ({ splitterId, cableId }) => {
    const splitter = localEquipments[splitterId];
    if (!splitter) {
      throw new Error(`Splitter with id ${splitterId} not found`);
    }

    setLocalEquipments((prev) => {
      const newState = {...prev};
      newState[splitterId]["cable_entrada_id"] = cableId;
      return newState;
    });
  };

  const releaseSplitterInput = ({ splitterId }) => {
    setLocalEquipments((prev) => {
      const newState = { ...prev };
      const splitter = newState[splitterId];
      if (splitter) {
        splitter.cable_entrada_id = null;
      }
      return newState;
    });
  };

  const consumeSplitterOutput = ({ splitterId, outputIndex, cableId }) => {
    const splitter = localEquipments[splitterId];
    if (!splitter) {
      throw new Error(`Splitter with id ${splitterId} not found`);
    }
    const splitterOutput = splitter["splitter_salidas"][outputIndex];
    if (!splitterOutput) {
      throw new Error(`Splitter output at index ${outputIndex} not found at splitter ${splitterId}`);
    }

    setLocalEquipments((prev) => {
      const newState = {...prev};
      newState[splitterId]["splitter_salidas"][outputIndex]["cable_salida_id"] = cableId;
      return newState;
    });
  };

  const releaseSplitterOutput = ({ splitterId, outputIndex }) => {
    setLocalEquipments((prev) => {
      const newState = { ...prev };
      const splitter = newState[splitterId];
      if (splitter && splitter.splitter_salidas && splitter.splitter_salidas[outputIndex]) {
        splitter.splitter_salidas[outputIndex].cable_salida_id = null;
      }
      return newState;
    });
  };

  const consumeLoad = ({ loadId, cableId }) => {
    const load = localEquipments[loadId];
    if (!load) {
      throw new Error(`Load with id ${loadId} not found`);
    }

    setLocalEquipments((prev) => {
      const newState = { ...prev };
      newState[loadId]["cable_entrada_id"] = cableId;
      return newState;
    });
  };

  const releaseLoad = ({ loadId }) => {
    setLocalEquipments((prev) => {
      const newState = deepCopy(prev);
      const load = newState[loadId];
      if (load) {
        load.cable_entrada_id = null;
      }
      return newState;
    });
  };

  /************************************ CABLES ************************************/

  const insertCable = ({originalCableId, newCableId, side=InsertCableSides.BEFORE}) => {
    console.log("INVENTORY: INSERT CABLE: ", { originalCableId, newCableId, side, localEquipments });
    const newCableCompoundId = "cable_" + newCableId;
    const originalCableCompoundId = "cable_" + originalCableId;
    setLocalEquipments((prev) => {
      const newState = deepCopy(prev);

      if (side === InsertCableSides.BEFORE) {
        const prevCableId = Object.entries(prev)
          .filter(([key, value]) => value.type === "cable")
          .find(([key, value]) => value.cable_siguiente === originalCableId)?.[0] ?? null;
        if(prevCableId)
          newState[prevCableId].cable_siguiente = newCableId;

        const updatePrevComponentOutput = () => {
          const sourceCubicle = Object.values(newState)
            .filter((value) => value.type === "substation")
            .reduce((prevValue, value) => {
              if (prevValue)
                return prevValue

              return Object.values(value?.cubiculos)
                .find(({cable_salida_id}) => cable_salida_id === originalCableId);
            }, undefined);
          if (sourceCubicle)
            sourceCubicle.cable_salida_id = newCableId

          const sourceSplitterOutput = Object.values(newState)
            .filter((value) => value.type === "splitter")
            .reduce((prevValue, value) => {
              if (!prevValue)
                return value?.splitter_salidas?.cable_salida_id === originalCableId;
            }, undefined);
          if (sourceSplitterOutput)
            sourceSplitterOutput.cable_salida_id = newCableId
        };
        updatePrevComponentOutput();

        newState[newCableCompoundId].cable_siguiente = originalCableId;
        newState[newCableCompoundId].disponible = false;
        newState[newCableCompoundId].zona = prev[originalCableCompoundId].zona;
      }

      if (side === InsertCableSides.AFTER) {
        newState[originalCableCompoundId].cable_siguiente = newCableId;

        newState[newCableCompoundId].cable_siguiente = prev[originalCableCompoundId].cable_siguiente;
        newState[newCableCompoundId].disponible = false;
        newState[newCableCompoundId].zona = prev[originalCableCompoundId].zona;

        const nextComponent = Object.values(newState)
          .filter((value) => ["load", "splitter"].includes(value.type))
          .find((value) => value?.cable_entrada_id === originalCableId);
        if(nextComponent)
          nextComponent.cable_entrada_id = newCableId;
      }

      console.log("INVENTORY: INSERT CABLE NEW_STATE: ", newState);

      return newState;
    });
  };

  /***************************************  ***************************************/

  const propagateAddCubicle = ({ sourceConnectionKey, destinyConnectionKey = null, cubicleId = null }) => {
    // TODO: check if source is type OUT and destiny is type IN
    if ( cubicleId === null) {
      cubicleId = getCubicleIdBySourceConnection(sourceConnectionKey);
    }

    let destinyConnectionKeys = [];
    if (destinyConnectionKey === null) {
      destinyConnectionKeys = getDestinyConnectionsFromSource(sourceConnectionKey);
    } else {
      destinyConnectionKeys = [destinyConnectionKey];
    }

    destinyConnectionKeys.forEach((connectionId) => {
        const {
          componentId: destinyComponentId,
          connectionType: destinyConnectionType,
          connectionId: destinyConnectionId
        } = parseConnectionCompoundId(connectionId);

      const destinyComponentType = localEquipments[destinyComponentId].type;
      setLocalEquipments((prevState) => {
        const newState = { ...prevState };
        if (destinyComponentType === SLDComponentEnum.SPLITTER) {
          newState[destinyComponentId]["cubiculo_nombre"] = cubicleId;
        }
        if (destinyComponentType === SLDComponentEnum.LOAD) {
          newState[destinyComponentId]["cubiculo_nombre"] = cubicleId;
        }
        return newState;
      });

      propagateAddCubicle({sourceConnectionKey: connectionId, cubicleId });
    });
  };

  const getCubicleIdBySourceConnection = (sourceConnectionKey) => {
    const { componentId, connectionId } = parseConnectionCompoundId(sourceConnectionKey);
    const componentType = localEquipments[componentId].type;
    if (componentType === SLDComponentEnum.SUBSTATION) {
      return connectionId;
    }
    if (componentType === SLDComponentEnum.SPLITTER) {
      return localEquipments[componentId]["cubiculo_nombre"];
    }
  };

  const getDestinyConnectionsFromSource = (sourceConnectionKey) => {
    const outputCableIds = getCableIdsFromSourceConnection(sourceConnectionKey)
        .filter((cableId) => cableId !== null);

    const getLastLinkedCable = cableId => {
      while (true) {
        const nextCableId = localEquipments[cableId]["cable_siguiente_id"];
        if (nextCableId === null || nextCableId === undefined) {
          break;
        }
        cableId = nextCableId;
      }
      return cableId;
    };

    const getComponentEntryFromInputCable = (cableId) => {
      return Object.entries(localEquipments)
        .filter(([componentKey, componentInfo]) => {
          const hasInput = [SLDComponentEnum.SPLITTER, SLDComponentEnum.LOAD]
              .includes(componentInfo.type);
          return hasInput;
        })
        .find(([componentKey, componentInfo]) => {
          return componentInfo["cable_entrada_id"] === cableId;
        });
    };

    const destinyConnections = outputCableIds.map((cableId) => {
      const lastCableId = getLastLinkedCable(cableId);
      const destinyComponentEntry = getComponentEntryFromInputCable(lastCableId);

      if (destinyComponentEntry === undefined) {
        return undefined;
      }
      const [destinyComponentId, destinyComponentInfo] = destinyComponentEntry;

      if (destinyComponentInfo.type === SLDComponentEnum.LOAD) {
        const loadInputCompoundId = destinyComponentId + ":" + CONNECTION_INPUT + ":" + "0";
        return loadInputCompoundId; //TODO: create parser
      }
      if (destinyComponentInfo.type === SLDComponentEnum.SPLITTER) {
        const splitterInputConnectionId = destinyComponentId + ":" + CONNECTION_INPUT + ":" + "cable_entrada_id";
        return splitterInputConnectionId; //TODO: create parser
      }
    });

    return destinyConnections.filter(connection => connection !== undefined);


  };

  const getCableIdsFromSourceConnection = (sourceConnectionKey) => {
    const { componentId, connectionId } = parseConnectionCompoundId(sourceConnectionKey);
    const componentType = localEquipments[componentId].type;
    if (componentType === SLDComponentEnum.SUBSTATION) {
      const outputCableId = localEquipments?.[componentId]?.["cubiculos"]?.[connectionId]?.["cable_salida_id"];
      return outputCableId ? [outputCableId] : [];
    }
    if (componentType === SLDComponentEnum.SPLITTER) {
      const splitterOutputs = localEquipments?.[componentId]?.["splitter_salidas"] || [];
      return splitterOutputs.map((splitterOutputInfo) => splitterOutputInfo["cable_salida_id"]);
    }
    return [];
  };

  const propagateDeleteCubicle = () => {
  // TODO: fill propagateDeleteCubicle
  };

  const transformArrayToObject = (arrayOfObjects) => {
    const objectOfObjects = arrayOfObjects.reduce((obj, item) => {
      obj[item.keys] = item;
      return obj;
    }, {});
    return objectOfObjects;
  };

  const updateCubiclesInSubstation = (substation, objectsInDiagramIds, componentId) => { //TODO: reemplazar por objeto
    const {cubiculos} = substation;

    Object.entries(cubiculos).forEach(([cubiculoId, cubiculo]) => {
      const currentObjectSubstation = objectsInDiagramIds[componentId];

      if (currentObjectSubstation?.activeOutputs?.includes(cubiculoId) ?? false) {
        cubiculo["disponible"] = false;
      } else {
        cubiculo["disponible"] = true;
      }
    })
    
  };

  const setDisponibilidadTrueComponents = (componentObject, newState) => {//TODO: Dejar newState dentro del componen
    const {type, componentId, component} = componentObject;

    if (!Object.values(SLDComponentEnum).includes(type)) {
      return;
    }

    if (type === SLDComponentEnum.SUBSTATION) {
      const {cubiculos} = component;
      Object.values(cubiculos).forEach((cubiculo) => {
        cubiculo["disponible"] = true;
      })
    }

    newState[componentId]["disponible"] = true;
    newState[componentId]["zona"] = null;
  };

  const setDisponibilidadFalseComponents = (newState, objectsInDiagramIds) => {
    Object.entries(newState).forEach(([componentId, component]) => {//Pasarle: objectsInDiagramIds, newState

      const {type} = component;

      if (objectsInDiagramIds.hasOwnProperty(componentId)) {
        newState[componentId]["disponible"] = false;
        newState[componentId]["zona"] = objectsInDiagramIds[componentId].zona;

        if (type === SLDComponentEnum.SUBSTATION){ //Usa component, objectsInDiagramIds
          updateCubiclesInSubstation(component, objectsInDiagramIds, componentId);
        }

        return;
      }

      setDisponibilidadTrueComponents({
        type, 
        component, 
        componentId,
      }, newState)
    });
  }

  const updateInUseComponents = (inDiagramComponentsIds) => {
    const objectsInDiagramIds = transformArrayToObject(inDiagramComponentsIds);

    const getNewLocalEquipment = (prev) => {
      const newState = deepCopy(prev);
        
      setDisponibilidadFalseComponents(newState, objectsInDiagramIds);

      return newState;
    }

    setLocalEquipments(
      getNewLocalEquipment
    )
  };

  const updateCubiclesToInventory = ({ components, paths }) => {

    const activeConnectionIdsGroupBySubstation = Object.entries(localEquipments)
      .filter(([id, equipmentInf]) => Object.keys(components).includes(id))
      .filter(([id, equipmentInfo]) => equipmentInfo.type === SLDComponentEnum.SUBSTATION)
      .map(([substationId, substationInfo]) => {
        const cubiclesId = Object.keys(substationInfo.cubiculos);
        return cubiclesId.map(cubicleId => substationId + ":outputs:" + cubicleId);
      });

    const activeConnectionIds = activeConnectionIdsGroupBySubstation.flat();
    const childLineComponents = {};
    activeConnectionIds.forEach((connectionId) => {
      const childLineComponentsByCubicle = getChildLineComponentIds({
        sourceComponentConnectionId: connectionId,
        components, paths
      });
      childLineComponents[connectionId] = childLineComponentsByCubicle;
    });

    const resetCubicles = inventory => {
      Object.entries(inventory)
        .filter(([componentId, componentInfo]) => {
          return [SLDComponentEnum.SPLITTER, SLDComponentEnum.LOAD, SLDComponentEnum.CABLE].includes(componentInfo.type)
        })
        .forEach(([componentId, componentInfo]) => {
          inventory[componentId]["cubiculo_id"] = null;
        });
    };

    setLocalEquipments((prevState) => {
      const newState = deepCopy(prevState);
      console.log("SET LOCAL EQUIPMENTS: NEW STATE: ", newState);
      // set all cubicles to null
      resetCubicles(newState);
      // update in use cubicles
      Object.entries(childLineComponents).forEach(([cubicleConnectionId, childComponentIds]) => {
        childComponentIds.forEach((childComponentId, index) => {
          const {connectionId: cubicleId} = parseConnectionCompoundId(cubicleConnectionId);
          if (!childComponentId || !cubicleId) {
            return;
          }
          const isCable = childComponentId.startsWith('cable');
          const cubicleKey = isCable ? 'cubiculo' : 'cubiculo_id';

          if (newState[childComponentId]) {
            newState[childComponentId][cubicleKey] = cubicleId;
          }
          if (isCable) return;

          const prevCableId = childComponentIds?.[index-1] ?? null;
          const prevCableCleanId = prevCableId ? prevCableId.replace("cable_", "") : prevCableId;
          if (newState[childComponentId]) {
            newState[childComponentId]["cable_entrada_id"] = prevCableCleanId;
          }
        });
      });

      return newState;
    });


  };

  const getChildLineComponentIds = ({ sourceComponentConnectionId, components, paths }) => {
     const directChildIds = getCableChainAndConnectedComponentIds({
       sourceComponentConnectionId, components, paths
     });

    let childLineComponentIds = [...directChildIds.cables, directChildIds.nextComponent];

    const nextComponentOutputConnections = getComponentOutputConnectionIds(directChildIds.nextComponent);

    const addChildById = (connectionId) => {
      childLineComponentIds = [
        ...childLineComponentIds,
        ...getChildLineComponentIds({
          sourceComponentConnectionId: connectionId, components, paths
        })
      ];
    };
    nextComponentOutputConnections.forEach(addChildById);

    return childLineComponentIds;
  };

  const getCableChainAndConnectedComponentIds = ({ sourceComponentConnectionId, components, paths }) => {
    const { componentId, connectionType, connectionId } = parseConnectionCompoundId(sourceComponentConnectionId);

    const ids = {
      cables: [],
      nextComponent: null
    };

    let cableId = components?.[componentId]?.outputs?.[connectionId]?.connection?.pathId ?? null;
    const getNextCableId = (id) => paths?.[id]?.nextCableId ?? null;

    while(getNextCableId(cableId) !== null) {
      ids.cables.push(cableId);
      cableId = getNextCableId(cableId);
    }
    if(cableId) {
      ids.cables.push(cableId);
    }

    const lastCable = paths?.[cableId] ?? {};
    const destinyConnectionId = lastCable?.destinyConnectionId ?? null
    if(destinyConnectionId) {
      const {
        componentId: nextComponentId,
      } = parseConnectionCompoundId(destinyConnectionId);
      ids.nextComponent = nextComponentId;
    }
    return ids;
  };

  const getComponentOutputConnectionIds = (componentId) => {
    if(!componentId) {
      return [];
    }
    const component = localEquipments[componentId];
    if(!component) {
      return [];
    }
    const type = component.type;
    if (type === SLDComponentEnum.SUBSTATION) {
      const substationOutputs = component?.outputs ?? {};
      return Object.entries(substationOutputs).map(([outputId, value]) => {
        return [componentId, CONNECTION_OUTPUT, outputId].join(":");
      });
    }
    if (type === SLDComponentEnum.SPLITTER) {
      const splitterOutputs = component?.["splitter_salidas"] ?? {};
      return splitterOutputs.map((outputId, index) => {
        return [componentId, CONNECTION_OUTPUT, index].join(":");
      });
    }
    return [];
  };

  const updatePlugsReadings = (newPlugsInfo) => {
    setLocalEquipments((prevState) => {
      return {
        ...prevState,
        ...newPlugsInfo
      }
    });
    setRemoteEquipments((prevState) => {
      return {
        ...prevState,
        ...newPlugsInfo
      }
    });
  }

  const deleteComponentInOuts = (componentId) => {
    setLocalEquipments((prevState) => {
      const newState = deepCopy(prevState);
      const component = newState[componentId];
      if (!componentId || !component) {
        return prevState;
      }
      if (component.type === SLDComponentEnum.SUBSTATION) {
        Object.values(component.cubiculos).forEach((cubiculo) => {
          cubiculo.cable_salida_id = null;
        });
      }
      if (component.type === SLDComponentEnum.SPLITTER) {
        component.cable_entrada_id = null;
        component.splitter_salidas.forEach((splitterSalida) => {
          splitterSalida.cable_salida_id = null;
        });
      }
      if (component.type === SLDComponentEnum.LOAD) {
        component.cable_entrada_id = null;
      }
      return newState;
    });
  }

  return {
    equipments: localEquipments,
    setRetrievedEquipments,
    hasLocalChanges,
    resetLocalChanges,
    updateRemoteToLocal,
    consumeComponentOutput,
    consumeComponentInput,
    releaseComponentOutput,
    releaseComponentInput,
    propagateCubicle: propagateAddCubicle,
    updateInUseComponents,
    updateCubiclesToInventory,
    updatePlugsReadings,
    deleteComponentInOuts,
    insertCable
  };
}