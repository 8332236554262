import { useEffect, useState } from 'react';

export function useDockComponents() {
    const [firstComponentConnection, setFirstComponentConnection] = useState(null);
    const [secondComponentConnection, setSecondComponentConnection] = useState(null);
    const [flagBothComponentsDocked, setFlagBothComponentsDocked] = useState(false);

    const resetDocking = () => {
        setFirstComponentConnection(null);
        setSecondComponentConnection(null);
        setFlagBothComponentsDocked(false);
    };

    const isFirstComponentInDocking = () => {
        return firstComponentConnection !== null;
    }

    const dockComponents = (component) => {
        if (!firstComponentConnection) {
            setFirstComponentConnection(component);
        } else if (!secondComponentConnection) {
            setSecondComponentConnection(component);
        } else {
            resetDocking();
            setFirstComponentConnection(component);
        }
    };

    const getDockedComponents = () => {
        return {firstComponentConnection, secondComponentConnection};
    };

    const isDockingTheSameComponent = (compoundId) => {
        return firstComponentConnection === compoundId;
    }

    useEffect(() => {
        if (firstComponentConnection && secondComponentConnection) {
            setFlagBothComponentsDocked(true);
        }
    }, [firstComponentConnection, secondComponentConnection]);

    return { dockComponents, resetDocking, isFirstComponentInDocking, getDockedComponents, flagBothComponentsDocked, isDockingTheSameComponent };
};