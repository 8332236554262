import React, {useEffect, useState} from 'react';
import {Container, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import CardContadorSubestacion from "./CardContadorSubestacion";
import CardSubestacion from "./CardSubestacion";
import {get} from "axios";
import LoadingGif from "../../assets/LoadingGif";
import {useTheme} from "@emotion/react";


const DashboardSubestaciones = ({token, setTopBarName, topBarOpen}) => {
    const theme = useTheme();
    const styles = {
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            disableGutters: true,
            backgroundColor: '#E4EBEF',
            maxHeight: "100%",
        },
    }
    const [loading, setLoading] = useState(true);
    const [subestaciones, setSubestaciones] = useState([]);
    const [filtroEstadoSub, setFiltroEstadoSub] = useState('');

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        get('/subestaciones/?cubiculos=true', config)
            .then((res) => {
                if (res.status === 200) {
                    setSubestaciones(res.data)
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }, [token,])

    useEffect(() => {

    }, [token, subestaciones])

    return (
        <main style={styles.content}>
            <div style={styles.appBarSpacer}/>
            <Container maxWidth="xl" style={styles.container}>
                <Grid container spacing={5} direction="column" alignItems="center">
                    <Grid item container xs={12} spacing={1} direction="row">
                        <Grid container spacing={2} justifyContent="space-evenly">
                            <CardContadorSubestacion texto={'Total'}
                                                     color_icono="#0E537B"
                                                     subestaciones={subestaciones}
                                                     setFiltroEstadoSub={() => setFiltroEstadoSub('')}/>
                            <CardContadorSubestacion texto={'Operativas'}
                                                     color_icono="#529454"
                                                     subestaciones={subestaciones}
                                                     setFiltroEstadoSub={() => setFiltroEstadoSub('operativo')}/>
                            <CardContadorSubestacion texto={'Zona Peligrosa'}
                                                     color_icono="#f8991e"
                                                     subestaciones={subestaciones}
                                                     setFiltroEstadoSub={() => setFiltroEstadoSub('peligro')}/>
                            <CardContadorSubestacion texto={'Peligro Máximo'}
                                                     color_icono="#B51111"
                                                     subestaciones={subestaciones}
                                                     setFiltroEstadoSub={() => setFiltroEstadoSub('critico')}/>
                        </Grid>
                    </Grid>
                    {loading ? <Grid item xs={12}><LoadingGif/> </Grid> :
                        <Grid item container xs>

                            <div>
                                <Grid item container spacing={2} direction="row">
                                    {
                                        subestaciones.map((subestacion) => (
                                            <Grid item key={subestacion.nombre}>
                                                <Link to={`${subestacion.nombre}`}
                                                      style={{textDecoration: 'none'}}>
                                                    <CardSubestacion subestacion={subestacion}
                                                                     setTopBarName={() => setTopBarName(`${subestacion.nombre}`)}/>
                                                </Link>
                                            </Grid>

                                        ))
                                    }
                                </Grid>
                            </div>

                        </Grid>}

                </Grid>

            </Container>
        </main>
    );
};

export default DashboardSubestaciones;