import { SLDComponentEnum } from "./constants";

export function parseConnectionCompoundId (compoundId) {
  const [componentId, connectionType, connectionId] = compoundId.split(":");
  return { componentId, connectionType, connectionId };
}

export function getComponentTypeByConnection (compoundId) {
  return compoundId.split("_")[0];
}

export function isComponentConnectionPoint(compoundId) {
  try {
    const type = getComponentTypeByConnection(compoundId);
    return Object.values(SLDComponentEnum).includes(type);
  } catch (e) {
    return false;
  }
}

export function isCableConnectionPoint(compoundId) {
  try {
    return compoundId.startsWith(SLDComponentEnum.CABLE);
  } catch (e) {
    return false;
  }
}

export function parseCableConnectionId(compoundId) {
  const [cableId, connectionType] = compoundId.split(":");
  return { cableId, connectionType };
}

export function getCableSourceConnectionPosition(compoundId) { // compoundId is like: "cable_342:cable-source:649,256"
  const [cableId, connectionType, x, y] = compoundId.split(":");
  return { cableId, connectionType, x, y };
}

export function isCableSourceConnection(compoundId) {
  return compoundId.includes("cable-source");
}