import React from 'react';
import {Link} from "react-router-dom";
import EnchufeToolTip from "../pala/EnchufeToolTip";
import Cable from "../pala/Cable";
import Grid from "@mui/material/Grid";
import {Box, ButtonBase, Container} from "@mui/material";

const CableTooltip = ({cable, configuraciones, setTopBarName}) => {
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'}

    return (
        <Container
            style={{
                marginLeft: '2px',
                display: 'flex',
                flexDirection: 'row',
                width: "244px",
                padding: '0px',
                marginRight: 0
            }}>

            <Link to={`/enchufes/${cable.enchufe_macho.id}/`}
                  style={{textDecoration: 'none'}}>
                <EnchufeToolTip enchufe={cable.enchufe_macho}
                                rotado={false}
                                setTopBarName={() => setTopBarName(`Enchufe ${cable.enchufe_macho.id}`)}
                                configuraciones={configuraciones}
                                nombreEnchufe={cable.codigo_cliente + 'M'}
                />
            </Link>
            <Link to={`/cables/${cable.codigo_cliente}/`}
                  style={{textDecoration: 'none'}}>
                <ButtonBase onClick={() => setTopBarName(`Cable ${cable.codigo_cliente}`)}>
                    <Box sx={{
                        width: '41.98px',
                        height: '13.58px',
                        marginTop: '19px',
                        marginRight: '1px',
                        marginLeft: '1px',
                    }} style={{backgroundColor: color['operativo']}}>
                    </Box>
                </ButtonBase>
            </Link>
            <Link to={`/enchufes/${cable.enchufe_hembra.id}/`}
                  style={{textDecoration: 'none'}}>
                <EnchufeToolTip enchufe={cable.enchufe_hembra}
                                rotado={true}
                                setTopBarName={() => setTopBarName(`Enchufe ${cable.enchufe_hembra.id}`)}
                                configuraciones={configuraciones}
                                nombreEnchufe={cable.codigo_cliente + 'H'}
                />
            </Link>
        </Container>
    );
};

export default CableTooltip;