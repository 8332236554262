import {
    Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, InputLabel, ListItemText, MenuItem, Select, Button, OutlinedInput, TextField
} from "@mui/material";
import React, { useState } from "react";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";

export function SLDComponentSelector({
    info,
    onCancel = () => {},
    onSelect,
    isMultiSelect,
    isOpen,
}) {

    const { getAvailableComponents } = React.useContext(SingleLineDiagramContext);

    const componentOptions = Object.entries(getAvailableComponents(info.type))
        .map(([key, values]) => {
            return { id: key, ...values };
        });

    const [selectedComponentIds, setSelectedComponentIds] = useState([]);

    const handleClose = () => {
        onCancel();
        setSelectedComponentIds([]);
    }

    const handleConfirm = () => {
        onSelect(selectedComponentIds);
        handleClose();
    }

    const getSelectOptions = () => {
        return componentOptions.map(({ id, codigo_cliente, nombre, type, inventory_state }) => {
            const cableHasError = type === "cable" && inventory_state === "error";
            const toggleSelect = () => setSelectedComponentIds((prev) => {
                if (prev.includes(id)) {
                return prev.filter((el) => el !== id);
                }
                return [...prev, id];
            });
            const isSelected = selectedComponentIds.indexOf(id) > -1;
            return (
                <MenuItem key={id} value={id} onClick={toggleSelect} disabled={cableHasError}>
                    {isMultiSelect && <Checkbox checked={isSelected} />}
                    <ListItemText
                        primary={(nombre ?? codigo_cliente ?? id) + (cableHasError ? " (No disponible)" : "")}
                        onClick={(event) => cableHasError && event.stopPropagation()}
                    />
                </MenuItem>
            );
        }); 
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Confirmar selección</DialogTitle>
            <DialogContent>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="component-select-label">{info.display}</InputLabel>
                <Select
                labelId="component-select-label"
                multiple={isMultiSelect}
                value={selectedComponentIds}
                input={<OutlinedInput label={info.display} />}
                renderValue={(selected) => {
                    return selected.map((id) => {
                    const component = componentOptions.find((c) => c.id === id);
                    return component?.nombre ?? component?.codigo_cliente ?? id;
                    }).join(", ");
                }}
                >
                {getSelectOptions()}
                </Select>
            </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirm} disabled={!selectedComponentIds.length}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
}

export function SLDNoInventoryLoadForm({
    onCancel = () => {},
    onSelect = (info) => {},
    isOpen,
}) {
    const [name, setName] = useState("");

    const handleClose = () => {
        onCancel();
        setName("");
    }

    const handleConfirm = () => {
        onSelect({ name });
        handleClose();
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Confirmar selección</DialogTitle>
            <DialogContent>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    {/*<InputLabel htmlFor="non-inventory-load">Nombre</InputLabel>*/}
                    <TextField
                        id="non-inventory-load"
                        value={name}
                        onChange={event => setName(event.target.value)}
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirm} disabled={!name || !name.length}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
}