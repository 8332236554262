import React, { useEffect, useRef, useState } from "react";
/**************************************************** CUSTOM HOOKS ****************************************************/
import { useSLDComponents } from "../../hooks/useSLDComponents";
import useSLDMode from "../../hooks/useSLDMode";
import { getInventoryData, getSingleLineDiagramInfo } from "./api-calls";
import { useInventory2 } from "../../hooks/useSLDInventory";
import { SLDComponentEnum } from "./constants";
import { isCableConnectionPoint, parseCableConnectionId, parseConnectionCompoundId, getCableSourceConnectionPosition, isCableSourceConnection } from "./utils";
import { useSingleLineDiagramCurrentCable } from "../../hooks/useSLDCurrentCable";
import { useStageInteractions } from "../../hooks/useStageInteractions";
import { useSLDHoveredComponent } from "../../hooks/useSLDHoveredComponent";
import useModal from "../../hooks/useModal";
import { isDeepEqual } from "../../utils/is-deep-equal";
import { deepCopy } from "../../utils/deep-copy";
import { getPlugReadings, getPlugsLast24hReadings } from "../../services/api/inventory";
import { SLDComponentsManager } from "../../managers/single-line-diagram/ComponentsManager";
import { ConnectionIdReader } from "../../managers/single-line-diagram/ConnectionIdReader";
import { PathNode } from "../../managers/single-line-diagram/PathNode";
import {
    CANVAS_WIDTH_HEIGHT_RATIO,
    InsertCableSides,
    NodeTypes,
    SLDComponentSizes,
    CONNECTION_CABLE_DESTINY,
    CONNECTION_INPUT,
    CONNECTION_OUTPUT,
    CONNECTION_AIR,
    CONNECTION_CABLE_SOURCE, PRINT_DIAGRAM_INFO_HEIGHT_RATIO
} from "../../constants/single-line-diagram";
import { useDiagramVersionsHistoric } from "../../hooks/single-line-diagram/useDiagramVersionsHistoric";
import { useContextualMenu } from "../../hooks/useContextualMenu";
import useCableSelector from "../../hooks/useCableSelector";
import { useSLDTextLabels } from "../../hooks/useSLDTextLabels";
import { useSLDComponentSelector } from "../../hooks/useSLDComponentSelector";
import { useDockComponents } from "../../hooks/useDockComponents";
import { useVoltGraph } from "../../hooks/useVoltGraph";
import { useAerialCables } from "../../hooks/useAerialCables";
import { useSLDHightlight } from "../../hooks/single-line-diagram/useSLDHighlight";
import useSLDCableInventoryState from "../../hooks/useSLDCableInventoryState";
import { SLDPrintInfoPositions } from "./SLDPlotArea";
import { useParams } from "react-router-dom";

export const SingleLineDiagramContext = React.createContext(null);
// TODO: delete and replace with useInventory2 when ready
function useInventory(initState) {
    const [inventory, setInventory] = useState(initState);

    const consumeItem = (key) => {
        setInventory(prev => {
            return {
                ...prev,
                [key]: {
                    ...prev[key],
                    local: prev[key].local - 1
                }
            };
        })
    }

    return { inventory, setInventory, consumeItem };
}

function parseInventoryData(data) {
    const { store: s, version: v, ...equipmentsByType } = data;
    let flattenEquipments = {};
    Object.entries(equipmentsByType).forEach(([groupKey, typeEquipments]) => {
        const compoundIdEquipments = {};
        Object.entries(typeEquipments).forEach(([key, equipment]) => {
            const { type } = equipment;
            // if(groupKey === "cables") console.log("CABLES: ", {key, type, equipment}) ;
            compoundIdEquipments[type + "_" + key] = equipment;
            compoundIdEquipments[type + "_" + key].id = type + "_" + key;
        });
        flattenEquipments = { ...flattenEquipments, ...compoundIdEquipments };
    });
    return flattenEquipments;
}

const useFailedLoading = ({ initState= null} = {}) => {
    /* info
    * {
    *   message: str
    *   isFatal: bool
    * }
    * */
    const [info, setInfo] = useState(initState);

    const reset = () => setInfo(initState);

    return {
        info,
        setInfo,
        reset
    }
};

export const SingleLineDiagramContextProvider = (props) => {
    const { inventory, setInventory, consumeItem: consumeInventoryItem } = useInventory({}); //TODO: delete after complete replace by useInventory2
    const [diagramVersion, setDiagramVersion] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const failedLoading = useFailedLoading();
    const { diagramZonePanel } = useParams();
    const diagramVersionHistoric = useDiagramVersionsHistoric({ setLoadingHistoricDiagram: setIsLoading, diagramZonePanel });

    const inventoryInstance = useInventory2({});
    const {
        equipments: inventoryEquipments,
        setRetrievedEquipments,
        hasLocalChanges: inventoryHasLocalChanges,
        resetLocalChanges: resetInventoryLocalChanges,
        updateRemoteToLocal: updateRemoteInventoryToLocal,
        propagateCubicle: propagateInventoryCubicle,
        updateInUseComponents: updateInUseComponentsInInventory,
        deleteComponentInOuts: deleteComponentInOutsInInventory,
        updateCubiclesToInventory,
        updatePlugsReadings
    } = inventoryInstance;

    const hoveredComponent = useSLDHoveredComponent({ inventoryEquipments });

    useEffect(() => {
        initializeState(props.token).then();

        return () => {
            refreshPlugsInterval.current && clearInterval(refreshPlugsInterval.current);
        }
    }, []);

    useEffect(() => {
        initializeState(props.token).then();
        diagramVersionHistoric.updateVersions().then();

        return () => {
            refreshPlugsInterval.current && clearInterval(refreshPlugsInterval.current);
        }
    }, [diagramZonePanel]);

    const initializeState = async (token) => {
        try {
            // Inventory needs to be ready before the SingleLineDiagram to deploy correctly
            // once solved this should be moved to each custom hook
            // console.log("INITIALIZE STATE");
            await initializeInventory(token);
            await initializeSingleLineDiagram(token);
            setIsLoading(false);
        } catch (e) {
            //TODO: manage error
            failedLoading.setInfo({message: "La carga del diagrama ha fallado", isFatal: true});
            modal.setModal({
                title: `Error al cargar unilineal`,
                message: `Hubo un error al cargar el diagrama unilineal. Por favor contactar al equipo de soporte.`,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        }
    };

    const initializeInventory = async (token) => {
        console.log("INITIALIZE INVENTORY");
        const inventoryDataResponse = await getInventoryData({ token, diagramZonePanel });
        console.log("INVENTORY DATA RESPONSE: ", inventoryDataResponse);
        const parsedInventoryData = parseInventoryData(inventoryDataResponse);
        console.log("PARSED INVENTORY: ", parsedInventoryData);
        setRetrievedEquipments(parsedInventoryData);
    };

    const initializeSingleLineDiagram = async (token) => {
        const { components = {}, paths = {}, labels = {}, diagram_zone = DEFAULT_DIAGRAM_ZONE, created_at } = await getSingleLineDiagramInfo({ token, diagramZonePanel });

        console.log("ON INITIALIZE SINGLE LINE DIAGRAM: ", { diagram_zone, diagramZone, setDiagramZone });
        setDiagramVersion(created_at);
        loadComponents(components);
        textLabels.loadState(labels);
        setPaths(paths);
        setRemotePaths(paths);
        if (diagram_zone)
            setDiagramZone(diagram_zone);
    };

    const SLDComponentsInstance = useSLDComponents();
    const {
        components,
        loadComponents,
        hasLocalChanges: diagramHasLocalChanges,
        resetLocalChanges: resetDiagramLocalChanges,
        updateRemoteToLocal: updateRemoteDiagramToLocal,
        addComponent,
        updateComponent,
        updateComponentConnection,
        getComponentOutputPoints,
        getInputPoint: getComponentInputPoint,
        deleteComponent: deleteComponentFromDiagram,
        getConnectionPathId,
        deleteCable: deleteCableFromComponents,
        replaceCable: replaceCableFromComponents,
        toggleSymbol: toggleSymbolFromComponents
    } = SLDComponentsInstance;

    const [mouseCoordinates, setMouseCoordinates] = useState({
        x: null,
        y: null,
    });

    const singleLineDiagramMode = useSLDMode();
    const componentSelector = useSLDComponentSelector();

    /***************************************************** PATHS *****************************************************/
    const [remotePaths, setRemotePaths] = useState({});
    const [paths, setPaths] = useState({});

    const refreshPlugsInterval = useRef(null);
    const refreshPlugsInfo = async (paths) => { //needs to receive paths, since the value is not updated in this scope
        // console.log("refreshPlugsInfo: ", (Date()));
        // console.log("ids: ", paths);
        const { token } = props;
        const cableIds = Object.keys(paths).map(id => id.split("_")[1]);
        const plugsInfo = await getPlugReadings({ token, cableIds });
        // console.log("plugs info: ", plugsInfo);
        updatePlugsReadings(plugsInfo);
    };

    const scheduleRefreshPlugs = (paths) => {
        if (refreshPlugsInterval.current) {
            clearInterval(refreshPlugsInterval.current);
        }
        refreshPlugsInterval.current = setInterval(
            () => refreshPlugsInfo(paths), 15_000
        );
    };

    useEffect(() => {
        // console.log("CHANGE IN PATHS: ", paths, Object.keys(paths));
        if (!Object.keys(paths)?.length) {
            return;
        }
        scheduleRefreshPlugs(paths);
    }, [paths]);


    const pathsHaveLocalChanges = () => {
        // console.log("PATH HAVE LOCAL CHANGES: ", {remotePaths, paths});
        return !isDeepEqual(remotePaths, paths);
    };

    const resetPathsLocalChanges = () => setPaths(remotePaths);

    const updateRemotePathsToLocal = () => setRemotePaths(deepCopy(paths));

    const addPath = ({
        id, points,
        sourceConnectionId = null,
        destinyConnectionId = null,
        nextCableId = null,
        prevCableId = null
    }) => { //TODO: receive input and output ids
        // console.log("ADD PATH: ", { id, points, sourceConnectionId, destinyConnectionId, nextCableId, prevCableId });
        setPaths((prev) => ({
            ...prev,
            [id]: {
                points,
                sourceConnectionId,
                destinyConnectionId,
                nextCableId,
                prevCableId
            }
        }));
        return id;
    };

    const deletePath = (id) => {
        setPaths((prev) => {
            if (!Object.keys(prev).includes(id)) {
                return;
            }
            const newState = { ...prev };
            delete newState[id];
            return newState;
        });
    }

    const updatePathPoints = ({ id, points }) => {
        setPaths((prev) => {
            const { labelPosition, ...prevPathState } = prev[id];
            return {
                ...prev,
                [id]: {
                    ...prevPathState,
                    points
                }
            }
        });
    };

    const updatePathInput = ({ id, x, y, vertexPosition }) => {
        setPaths((prev) => {
            try {
                const newValue = { ...prev };
                const updatedChain = PathNode.updatePathsChain({
                    pathsInfo: newValue, firstCableId: id, newFirstPoint: { x, y }
                });
                updatedChain.forEach(p => {
                    const { points } = p;
                    const { labelPosition,...rest } = newValue[p.id];
                    newValue[p.id] = {
                        ...rest,
                        points
                    }
                });
                return newValue;
            } catch (e) {
                console.warn("CATCH ERROR ON UPDATE PATH INPUT");
                return prev;
            }
        });
    };

    const updatePathOutput = ({ id, x, y, }) => {
        setPaths((prev) => {
            try {
                const newValue = { ...prev };
                const updatedChain = PathNode.updatePathsChain({
                    pathsInfo: newValue, lastCableId: id, newFirstPoint: { x, y }
                });

                updatedChain.forEach(p => {
                    const { points } = p;
                    const { labelPosition,...rest } = newValue[p.id];
                    newValue[p.id] = {
                        ...rest,
                        points
                    }
                });
                return newValue;
            } catch (e) {
                console.warn("CATCH ERROR ON UPDATE PATH OUTPUT");
                return prev;
            }
        });
    };

    const updateComponentAndPaths = ({ key, value }) => {
        if(!singleLineDiagramMode.inAnyEditMode()) {
            console.error("Component and its paths should only be updated in an edit mode");
            return false;
        }
        updateComponent({ key, value });

        // update paths connected to output
        Object.entries(value.outputs).forEach(([outputId, output]) => {
            const { x, y, connection } = output;
            const { pathId } = connection;

            if (pathId === null || pathId === undefined) {
                return;
            }
            updatePathInput({ id: pathId, x, y, vertexPosition: true });
        });

        // update paths connected to input
        Object.entries(value.inputs).forEach(([inputId, input]) => {
            const { x, y, connection } = input;
            const { pathId } = connection;

            if (pathId === null || pathId === undefined) {
                return;
            }
            updatePathOutput({ id: pathId, x, y });
        });
    };

    const updatePathInfo = ({ key, info }) => {

        const getNewState = prevState => {
            if (!Object.keys(prevState).includes(key)) {
                return prevState;
            }
            return {
                ...prevState,
                [key]: {
                    ...prevState[key],
                    ...info
                }
            };
        };

        console.log("UPDATED PATH:", getNewState(paths));

        setPaths(getNewState);
    };

    const updatePathId = ({ oldId, newId }) => {
        setPaths((prev) => {
            const newState = { ...prev };
            newState[newId] = newState[oldId];
            delete newState[oldId];
            return newState;
        });
    };

    /**
     * updatePathConnectionsOnComponentDelete works as follows:
     *
     * A component has inputs and outputs, which are paths.
     * When a component is deleted, the paths connected to the inputs and outputs need to be updated
     * because they have sourceConnectionId and destinyConnectionId.
     * The sourceConnectionId and destinyConnectionId are composed of the componentId, connectionType, and connectionId.
     *
     * Example: A substation has 4 cubicles, both the substation and the cubicles ONLY have outputs.
     * In one of those outputs, there's a path with id: "cable_127" that has this sourceConnectionId: "substation_ROLEC N1:out:SE 01 CB-A".
     * When the substation is deleted, the path needs to be updated to have a null sourceConnectionId.
     *
     * @param {Object} params - The parameters for the function.
     * @param {string} params.componentId - The ID of the component being deleted.
     */
    const updatePathConnectionsOnComponentDelete = ({ componentId }) => {
        const component = components[componentId];
        console.log("DEBUG UPDATE PATH CONNECTIONS ON COMPONENT DELETE: ", { componentId, component });
        const { outputs, inputs } = component;


        Object.entries(outputs).forEach(([outputId, output]) => {
            const { connection } = output;
            console.log("DEBUG OUTPUT: ", { outputId, output, connection });
            const { pathId } = connection;
            if (pathId === null || pathId === undefined) {
                return;
            }
            updatePathInfo({ key: pathId, info: { sourceConnectionId: null } });
        });

        Object.entries(inputs).forEach(([inputId, input]) => {
            console.log("DEBUG INPUT: ", input);
            const { connection } = input;
            const { pathId } = connection;
            if (pathId === null || pathId === undefined) {
                return;
            }
            updatePathInfo({ key: pathId, info: { destinyConnectionId: null } });
        });
    };

    /****************************************** EDIT CABLES *****************************************/

    const dockComponentsInstance = useDockComponents();
    const voltGraphInstance = useVoltGraph();

    const insertCable = ({ originalCableId, newCableId, side = InsertCableSides.BEFORE, splittedPoints }) => {
        console.log("INSERT CABLE: ORIGINAL_CABLE: ", originalCableId, paths[originalCableId]);
        if (!Object.keys(paths).includes(originalCableId)) {
            throw new Error(`${originalCableId} cable_id does not exist in paths: ${paths}`);
        }
        if (Object.keys(paths).includes(newCableId)) {
            throw new Error(`Cable ${newCableId} already in diagram. paths: ${paths}`);
        }

        const sideIsBefore = side === InsertCableSides.BEFORE;
        const newCablePoints = sideIsBefore ? splittedPoints[0] : splittedPoints[1];
        const currentCableNewPoints = sideIsBefore ? splittedPoints[1] : splittedPoints[0];

        setPaths((prevPaths) => {
            const newPaths = PathNode.insertCableToSide({
                pathsInfo: deepCopy(prevPaths), newCableId, side, originalCableId
            });
            newPaths[originalCableId].points = currentCableNewPoints;
            newPaths[newCableId].points = newCablePoints;

            return newPaths;
        });

        const updateComponentConnection = () => {
            try {
                if (side === InsertCableSides.BEFORE) {

                    const sourceConnectionId = paths[originalCableId]?.sourceConnectionId ;
                    if (!sourceConnectionId) {
                        return;
                    }
                    const connectionIdReader = new ConnectionIdReader({compoundId: sourceConnectionId });
                    const { componentId: sourceComponentId } = connectionIdReader.getParsedComponentConnection();
                    const updatedSourceComponent = deepCopy(components[sourceComponentId]);
                    Object.entries(updatedSourceComponent.outputs).forEach(([outputId, output]) => {
                        if (updatedSourceComponent?.outputs?.[outputId]?.connection?.pathId === originalCableId) {
                            updatedSourceComponent.outputs[outputId].connection.pathId = newCableId;
                        }
                    });
                    updateComponent({ key: sourceComponentId, value: updatedSourceComponent })
                    console.log("INSERT CABLE: BEFORE: ", { sourceComponentId, updatedSourceComponent });
                    return;
                }
                if (side === InsertCableSides.AFTER) {
                    const destinyConnectionId = paths[originalCableId].destinyConnectionId;
                    if (!destinyConnectionId) {
                        return;
                    }
                    const connectionIdReader = new ConnectionIdReader({compoundId: destinyConnectionId });
                    const { componentId: destinyComponentId } = connectionIdReader.getParsedComponentConnection();
                    const updatedDestinyComponent = deepCopy(components[destinyComponentId]);
                    Object.entries(updatedDestinyComponent.inputs).forEach(([inputId, input]) => {
                        if (updatedDestinyComponent?.inputs?.[inputId]?.connection?.pathId === originalCableId) {
                            updatedDestinyComponent.inputs[inputId].connection.pathId = newCableId;
                        }
                    });
                    updateComponent({ key: destinyComponentId, value: updatedDestinyComponent })
                    console.log("INSERT CABLE: AFTER: ", { destinyComponentId, updatedDestinyComponent });
                }
            } catch (e) {
                console.error(e);
                return;
            }

        };
        updateComponentConnection();

        const originalCableStrippedId = originalCableId.replace("cable_", "");
        const newCableStrippedId = newCableId.replace("cable_", "");
        inventoryInstance.insertCable({
            originalCableId: originalCableStrippedId,
            newCableId: newCableStrippedId,
            side
        });
    };

    const moveSourceConnectionPoint = ({ cableId, dropPoint }) => {
        const updatePaths = PathNode.toUpdateSourceConnectionPoint({
            pathsInfo: paths,
            cableId,
            dropPoint
        });
        updatePathInfo({ key: cableId, info: updatePaths[cableId] });
    };

    const moveDestinyConnectionPoint = ({ cableId, dropPoint }) => {
        const updatedPaths = PathNode.toUpdatedConnectionPoint({
            pathsInfo: paths,
            prevCableId: cableId,
            dropPoint
        });
        const nextCableId = paths[cableId].nextCableId;

        console.log("MOVE DESTINY CONNECTION POINT: ", { cableId, dropPoint, updatedPaths, nextCableId });

        updatePathInfo({ key: cableId, info: updatedPaths[cableId] });
        updatePathInfo({ key: nextCableId, info: updatedPaths[nextCableId] });
    };

    /***************************************** CURRENT CABLE ****************************************/
    const currentCable = useSingleLineDiagramCurrentCable({ paths, SLDComponents: SLDComponentsInstance });
    const aerialCables = useAerialCables({ paths });
    const cableInventoryState = useSLDCableInventoryState();

    const [isBeingReplace, setIsBeingReplace] = useState(false);

    const startEditingOnEnterEditCableMode = () => {
        if (!singleLineDiagramMode.inEditCableMode()) {
            return;
        }
        const cableId = singleLineDiagramMode.getSelectedCableToEdit(); // This info could be moved to currentCable custom hook
        if (!cableId) {
            return;
        }
        currentCable.startEditingCable({ cableId, paths });
    }
    useEffect(() => {
        startEditingOnEnterEditCableMode();
    }, [singleLineDiagramMode.getSelectedCableToEdit()]);

    const updateEditedPath = () => { // TODO: move to currentCable custom hook
        const cableId = singleLineDiagramMode.getSelectedCableToEdit();
        const points = currentCable.editingPath.points;
        updatePathPoints({ id: cableId, points });

        singleLineDiagramMode.setEditBaseMode();
        currentCable.editingPath.reset();
    };

    const handleSelectPlugs = async () => {
        try {
            const { firstPlugConnection, secondPlugConnection } = voltGraphInstance.getSelectedPlugs();
            let firstPlugConnectionStripped = firstPlugConnection;
            let secondPlugConnectionStripped = secondPlugConnection;
            // The plugs could be strings or numbers, if is a string has the format "plug_id", if is a number is the id of the plug. Extract the id of the plug.
            if (typeof firstPlugConnection === "string") {
                firstPlugConnectionStripped = parseInt(firstPlugConnection.replace("plug_", ""));
            }

            if (typeof secondPlugConnection === "string") {
                secondPlugConnectionStripped = parseInt(secondPlugConnection.replace("plug_", ""));
            }

            if (firstPlugConnection === null || secondPlugConnection === null || firstPlugConnection === secondPlugConnection) {
                return;
            }
            
            const plugsInfo = await getPlugsLast24hReadings({ token: props.token, plugs_ids: [firstPlugConnectionStripped, secondPlugConnectionStripped] });
            if (plugsInfo == 204) {
                voltGraphInstance.setSnackBarOpen(true);
                voltGraphInstance.resetPlugsSelection();
                return;
            }

            if (plugsInfo) {
                voltGraphInstance.setGraphData(plugsInfo);
            }
    
        } catch (error) {
            console.error("Error on handleSelectPlugs: ", error);
        }
    };
    

    const handleDockComponents = () => { //Se puede seleccionar un SourceConnection y conectarlo a una conexión de componente o a un DestinyConnection de un cable. También un DestinyConnection de un cable se puede unir a un SourceConnection de un cable o a un componente.
        try {
            const { firstComponentConnection, secondComponentConnection } = dockComponentsInstance.getDockedComponents();
            console.log("DOCK COMPONENTS on handleDockComponents: ", { firstComponentConnection, secondComponentConnection });
            if (firstComponentConnection && secondComponentConnection) {

                //Verify the type of component of the firstComponentConnection and secondComponentConnection. FirstComponentConnection could be a cable or a component. SecondComponentConnection could be a cable or a component, but both can't be components at the same time.
                
                if (isCableConnectionPoint(firstComponentConnection) && !isCableConnectionPoint(secondComponentConnection)) { //Caso 1: El primer componente es un cable y el segundo es un componente.

                    if (isCableSourceConnection(firstComponentConnection)) { //El primer componente es un cable y su conexión es un SourceConnection, por lo tanto se hará el movimiento únicamente del sourceConnection del cable.
                        const { cableId } = getCableSourceConnectionPosition(firstComponentConnection);
                        const secondComponentOutput = SLDComponentsInstance.getOutputPoint(secondComponentConnection);
                        const secondComponentOutputPoints = { x: secondComponentOutput.x, y: secondComponentOutput.y };

                        moveSourceConnectionPoint({ cableId, dropPoint: secondComponentOutputPoints });
                        updatePathInfo({ key: cableId, info: { sourceConnectionId: secondComponentConnection } });
                        updateComponentConnection({ compoundKey: secondComponentConnection, pathId: cableId });
                        consumeConnection({ compoundKey: secondComponentConnection, cableId });
                    } else { //El primer componente es un cable y su conexión es un DestinyConnection, por lo tanto se hará el movimiento únicamente del destinyConnection del cable.
                        const { cableId } = parseCableConnectionId(firstComponentConnection);
                        const secondComponentInput = SLDComponentsInstance.getInputPoint(secondComponentConnection);
                        const secondComponentInputPoints = { x: secondComponentInput.x, y: secondComponentInput.y };

                        moveDestinyConnectionPoint({ cableId, dropPoint: secondComponentInputPoints });
                        updatePathInfo({ key: cableId, info: { destinyConnectionId: secondComponentConnection } });
                        updateComponentConnection({ compoundKey: secondComponentConnection, pathId: cableId });
                        consumeConnection({ compoundKey: secondComponentConnection, cableId });

                    }
                } else if (isCableConnectionPoint(firstComponentConnection) && isCableConnectionPoint(secondComponentConnection)) { //Caso 2: Ambos componentes son cables.
                    if (isCableSourceConnection(firstComponentConnection)) { //El primer componente es un cable y su conexión es un SourceConnection, por lo tanto se hará el movimiento únicamente del sourceConnection del cable.
                        const { cableId: firstSeelectedCable } = getCableSourceConnectionPosition(firstComponentConnection);
                        const { cableId: secondSelectedCable, x, y } = getCableSourceConnectionPosition(secondComponentConnection);

                        const flattedXY = { x: parseInt(x), y: parseInt(y) };

                        const compoundSecondDestinyId = `${secondSelectedCable}:${CONNECTION_CABLE_DESTINY}`;

                        moveSourceConnectionPoint({ cableId: firstSeelectedCable, dropPoint: { x: flattedXY.x, y: flattedXY.y } });
                        updatePathInfo({ key: firstSeelectedCable, info: { 
                            sourceConnectionId: compoundSecondDestinyId,
                            prevCableId: secondSelectedCable } 
                        });
                        updatePathInfo({ key: secondSelectedCable, info: {
                            nextCableId: firstSeelectedCable }
                        });
                        consumeConnection({ compoundKey: compoundSecondDestinyId, cableId: firstSeelectedCable });

                    } else { //El primer componente es un cable y su conexión es un DestinyConnection, por lo tanto se hará el movimiento únicamente del destinyConnection del cable.
                        const { cableId: firstSeelectedCable } = getCableSourceConnectionPosition(firstComponentConnection);
                        const { cableId: secondSelectedCable, x, y } = getCableSourceConnectionPosition(secondComponentConnection);

                        const flattedXY = { x: parseInt(x), y: parseInt(y) };

                        const compoundFirstDestinyId = `${firstSeelectedCable}:${CONNECTION_CABLE_DESTINY}`;

                        moveDestinyConnectionPoint({ cableId: firstSeelectedCable, dropPoint: { x: flattedXY.x, y: flattedXY.y } });
                        updatePathInfo({ key: firstSeelectedCable, info: { 
                            nextCableId: secondSelectedCable }
                        });
                        updatePathInfo({ key: secondSelectedCable, info: {
                            prevCableId: firstSeelectedCable,
                            sourceConnectionId: compoundFirstDestinyId }
                        });
                        consumeConnection({ compoundKey: compoundFirstDestinyId, cableId: secondSelectedCable });
                    }
                    

                }

                dockComponentsInstance.resetDocking();
            }
        } catch (error) {
            console.error("Error on selectDockingPathEnd: ", error);
            dockComponentsInstance.resetDocking();
        }
    };

    const handleUndockComponents = (compoundKey) => { //Se puede seleccionar una conexión de un componente o de un cable (que si o si será destinyConnectionPoint) y desvincularla de la conexión de un cable.
        //compoundKey ejemplo de una subestación "substation_EATON N10:out:10 CB-E" (en caso de que sea out, la separación con el cable debe generar un sourceConnectionPoint y moverlo levemente hacia la derecha)
        try {
            if (!isCableConnectionPoint(compoundKey)) {
                const connectionIdReader = new ConnectionIdReader({compoundId: compoundKey});
                const { componentId, connectionType, connectionId } = connectionIdReader.getParsedComponentConnection();
                console.log("HANDLE UNDOCK COMPONENTS: ", { componentId, connectionType, connectionId });
                const updatedComponent = deepCopy(components[componentId]);
                const updatedConnection = (connectionType === CONNECTION_OUTPUT) ? updatedComponent.outputs[connectionId] : updatedComponent.inputs[connectionId];
                const componentOutputCableId = updatedConnection.connection.pathId;

                updatedConnection.connection.pathId = null;

                const componentOutputX = updatedConnection.x;
                const componentOutputY = updatedConnection.y;

                if (connectionType === CONNECTION_OUTPUT) { //si es output, se crea un sourceConnectionPoint y se mueve a la derecha. Si es input, se crea un destinyConnectionPoint y se mueve a la izquierda.
                    const newPoint = { x: componentOutputX + 40, y: componentOutputY };
                    updatePathInput({ id: componentOutputCableId, x: newPoint.x, y: newPoint.y, vertexPosition: true });
                    updatePathInfo({ key: componentOutputCableId, info: { sourceConnectionId: CONNECTION_AIR } });
                    updateComponent({ key: componentId, value: updatedComponent });
                    inventoryInstance.releaseComponentOutput({ componentId, outputId: connectionId, type: updatedComponent.type });

                    setRetrievedEquipments(prev => {
                        const newState = deepCopy(prev);
                        newState[componentOutputCableId].cubiculo = null; 
                        return newState;
                    });
                } else {
                    const newPoint = { x: componentOutputX - 40, y: componentOutputY };
                    updatePathOutput({ id: componentOutputCableId, x: newPoint.x, y: newPoint.y });
                    updatePathInfo({ key: componentOutputCableId, info: { destinyConnectionId: null } });
                    updateComponent({ key: componentId, value: updatedComponent });
                    inventoryInstance.releaseComponentInput({ componentId, inputId: connectionId, type: updatedComponent.type });
                }
            } else { //En este caso se pasará el compundId del cable con la posición del destinyConnectionPoint. Solo serán conexiones destinyConnectionPoint.
                const { cableId, connectionType, x, y } = getCableSourceConnectionPosition(compoundKey);
                const nextCableId = paths[cableId].nextCableId;

                const newPoint = { x: x - 60, y };
                const newPointNextCable = { x: parseFloat(x)+20, y: parseFloat(y) };

                moveDestinyConnectionPoint({ cableId, dropPoint: newPoint });
                
                updatePathInfo({ key: cableId, info: { 
                    nextCableId: null }
                });
                
                updatePathInfo({ key: nextCableId, info: {
                    prevCableId: null,
                    sourceConnectionId: CONNECTION_AIR,
                    points: [newPointNextCable, ...paths[nextCableId].points.slice(1)]}
                });

                setRetrievedEquipments(prev => {
                    const newState = deepCopy(prev);
                    newState[cableId].cable_siguiente = null;
                    return newState;
                });
            }
        } catch (error) {
            console.error("Error on handleUndockComponents: ", error);
        }
    };

    const getFocusedConnectionTypes = () => {
        const { firstComponentConnection } = dockComponentsInstance.getDockedComponents();

        if (!firstComponentConnection) {
            return currentCable.getFocusedTypes();
        }

        const [parentId, connectionType] = firstComponentConnection.split(":");
        if (parentId.startsWith("cable")) {
            return connectionType === CONNECTION_CABLE_SOURCE ? [CONNECTION_OUTPUT, CONNECTION_CABLE_DESTINY] : [CONNECTION_INPUT, CONNECTION_CABLE_SOURCE];
        }  

        return connectionType === CONNECTION_INPUT ? [CONNECTION_OUTPUT, CONNECTION_CABLE_DESTINY] : [CONNECTION_INPUT, CONNECTION_CABLE_SOURCE];
    };

        
    
    const selectPathEnd = (compoundKey, options = {}) => { //TODO: Add the posibility to select a cable as destinyConnectionId
        try {
            // CompoundKey could be used in the SourcePoint of a cable, so the compoundKey will have the format "cable_334:cable-source".
            const prevConnectionId = currentCable.selectedConnection;
            const nextConnectionId = compoundKey;

            let prevCableId = null;
            let nextCableId = null;

            if (isCableConnectionPoint(prevConnectionId)) { //No hay problema con el DestinyConnection de un cable, por lo que este if queda igual ante un cable al aire.
                prevCableId = parseCableConnectionId(prevConnectionId).cableId;
            }
            if (isCableConnectionPoint(nextConnectionId)) {
                nextCableId = parseCableConnectionId(nextConnectionId).cableId;
            }

            const sourceConnectionId = currentCable.selectedConnection;
            const destinyConnectionId = compoundKey; //destinyConnectionId could be a cable or a component. 

            const sourceIsCable = prevCableId !== null;
            const destinyIsCable = nextCableId !== null;

            const newPathParams = getNewPathParams({
                sourceIsCable, destinyIsCable,
                prevCableId, nextCableId,
                sourceConnectionId, destinyConnectionId
            });

            const cableId = addPath(newPathParams);

            connectInventoryComponents({
                sourceConnectionKey: sourceConnectionId,
                destinyConnectionKey: (destinyIsCable) ? getCompoundDestinyWithoutPosition(destinyConnectionId) : destinyConnectionId,
                cableId
            });

            currentCable.creatingPath.reset();
            currentCable.setSelectedConnection(null);
            currentCable.setSelectedCable(null);
        } catch (e) {
            console.error("Error on selectPathEnd: ", e);
            modal.setModal({
                title: "Error",
                message: `Error al seleccionar final del cable. Por favor contactar al equipo de soporte.`,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        }

    };

    const getCompoundDestinyWithoutPosition = (compoundId) => {
        const { cableId, connectionType, x, y } = getCableSourceConnectionPosition(compoundId);
        console.log("GET COMPOUND DESTINY WITHOUT POSITION: ", cableId + ":" + connectionType);
        return `${cableId}:${connectionType}`;
    };

    const getNewPathParams = ({
        sourceConnectionId, destinyConnectionId,
        sourceIsCable, prevCableId,
        destinyIsCable, nextCableId
    }) => {
        const newPathPoints = calculateNewPathPoints(destinyConnectionId, destinyIsCable); //If the destiny is a cable, replace the sourceConnectionPoint with destinyConnectionPoint
        const newPathParams = { id: currentCable.selectedCable, points: newPathPoints };

        if (sourceIsCable) {
            newPathParams.prevCableId = prevCableId;
        } else {
            newPathParams.sourceConnectionId = sourceConnectionId;
        }

        if (destinyIsCable) {
            newPathParams.nextCableId = nextCableId;
            //TODO: change the sourceConnectionId of the nextCable to the newCableId compoundKey.
        } else {
            newPathParams.destinyConnectionId = destinyConnectionId;
        }

        return newPathParams;
    };

    const calculateNewPathPoints = (destinyConnectionKey, destinyIsCableConnection = false) => {
        const lastPoint = currentCable.creatingPath.points.at(-1);
        const endPoint = getInputConnectionPoint(destinyConnectionKey, destinyIsCableConnection);
        const middleX = (lastPoint.x + endPoint.x) / 2;
        const connectionPoints = lastPoint.y !== endPoint.y
            ? [{ x: middleX, y: lastPoint.y }, { x: middleX, y: endPoint.y }]
            : [];

        return [...currentCable.creatingPath.points, ...connectionPoints, endPoint];
    };

    const getInputConnectionPoint = (destinyConnectionKey, destinyIsCableConnection) => {
        if (destinyIsCableConnection) {
            const { x, y } = getCableSourceConnectionPosition(destinyConnectionKey);
            return { x: parseInt(x), y: parseInt(y) };
        }
        return getComponentInputPoint(destinyConnectionKey);
    };

    const connectInventoryComponents = ({ sourceConnectionKey, destinyConnectionKey, cableId }) => {
        // This could be inside the useInventory2 custom hook
        console.log("CONNECT INVENTORY COMPONENTS: ", { sourceConnectionKey, destinyConnectionKey, cableId });
        consumeConnection({ compoundKey: sourceConnectionKey, cableId });
        consumeConnection({ compoundKey: destinyConnectionKey, cableId }); //destinyConnectionKey puede ser un cable ahora.

        const prevIsCable = isCableConnectionPoint(sourceConnectionKey);

        if (prevIsCable) { //update cable nextCableId
            const { cableId: prevCableId } = parseCableConnectionId(sourceConnectionKey);
            updatePathInfo({ key: prevCableId, info: { nextCableId: cableId } });
        }

        const destinyIsCable = isCableConnectionPoint(destinyConnectionKey);

        if (destinyIsCable) { //update the next cable prevCableId and the nextCable sourceConnectionId with a compoundKey of the newCableId with the cable-destiny connectionType.
            const { cableId: nextCableId } = parseCableConnectionId(destinyConnectionKey);
            const nextCableCompoundKey = `${cableId}:${CONNECTION_CABLE_DESTINY}`;

            updatePathInfo({ key: nextCableId, info: { prevCableId: cableId, sourceConnectionId: nextCableCompoundKey } });
            // console.log("UPDATE nextCable prevCableId: ", { destinyConnectionKey, nextCableId, connectionType });
        }

        const connects2Components = false;
        if (connects2Components) {
            propagateInventoryCubicle({
                sourceConnectionKey,
                destinyConnectionKey
            });
        }
    };

    const consumeConnectionInventory = ({ compoundKey, cableId }) => {
        const connectionIdReader = new ConnectionIdReader({ compoundId: compoundKey });
        if (connectionIdReader.isCableConnectionPoint()) {
            // console.log("CONSUME CONNECTION INVENTORY: CABLE CONNECTION POINT");
            const { cableId: selectedCableId } = parseCableConnectionId(compoundKey);
            const isSelectedCableAir = isCableSourceConnection(compoundKey);

            if (isSelectedCableAir) {
                updatePathInfo({ key: selectedCableId, info: { prevCableId: cableId } });
                updatePathInfo({ key: cableId, info: { nextCableId: selectedCableId } });
            }
            else {
                updatePathInfo({ key: selectedCableId, info: { nextCableId: cableId } });
                updatePathInfo({ key: cableId, info: { prevCableId: selectedCableId } });
            }
            try {
                setRetrievedEquipments(prev => {
                    const newState = deepCopy(prev);
                    if (isSelectedCableAir) {
                        newState[cableId].cable_siguiente = SLDComponentsManager.toInventoryId({ componentId: selectedCableId });
                    } else {
                        newState[selectedCableId].cable_siguiente = SLDComponentsManager.toInventoryId({ componentId: cableId });
                    }
                    return newState;
                });
            }
            catch (e) {
                console.warn("CATCH ERROR ON UPDATE CABLE NEXT CABLE ID");
            }
        }
        else {
            const { componentId, connectionType, connectionId } = connectionIdReader.getParsedComponentConnection();
            const componentType = connectionIdReader.getComponentTypeByConnection();

            const cableClientCode = cableId.split("_").at(-1);

            // console.log("CONSUME CONNECTION INVENTORY: CABLE CLIENT CODE: ", cableClientCode);
            // console.log("CONSUME CONNECTION INVENTORY: ", { componentId, connectionType, connectionId, componentType });

            if (connectionType === CONNECTION_OUTPUT) {
                inventoryInstance.consumeComponentOutput({
                    componentId, outputId: connectionId, cableId: cableClientCode, type: componentType
                });
            } else if (connectionType === CONNECTION_INPUT) {
                inventoryInstance.consumeComponentInput({
                    componentId, cableId: cableClientCode, type: componentType
                });
            }
        }
    }

    const consumeConnection = ({ compoundKey, cableId }) => {
        console.log("CONSUME CONNECTION: ", { compoundKey, cableId });
        if (compoundKey === CONNECTION_AIR) {
            return;
        }
        consumeConnectionInventory({ compoundKey, cableId });
        updateComponentConnection({ compoundKey, pathId: cableId });
    };

    const endPathOnAir = () => {
        try {
            if (!currentCable.selectedConnection) { //TODO: create a method to check if there's currentCable
                throw new Error("Trying to end a path on air, without starting one before.");
            }

            console.log("END PATH ON AIR: ", currentCable.selectedConnection, currentCable.selectedCable, currentCable.creatingPath.points);

            const prevConnectionKey = currentCable.selectedConnection;

            const cableId = addPath({
                id: currentCable.selectedCable,
                points: currentCable.creatingPath.points,
                sourceConnectionId: prevConnectionKey, //TODO: repeat this logic in selectPathEnd
            });

            console.log("END PATH ON AIR AFTER ADD PATH: ", { prevConnectionKey, cableId, currentCable });

            // const sourceConnectionReader = new ConnectionIdReader({ compoundId: prevConnectionKey });

            // console.log("DEBUG END PATH ON AIR: ", { prevConnectionKey, cableId, sourceConnectionReader });

            consumeConnection({ compoundKey: prevConnectionKey, cableId });

            currentCable.creatingPath.reset();
            currentCable.setSelectedConnection(null);
            currentCable.setSelectedCable(null);
        } catch (e) {
            console.error("ERROR ON endPathOnAir: ", e);
        }
    };

    const deleteCable = ({ cableId, singular = true, reasonFalla = false }) => {
        const pathInfo = paths?.[cableId] ?? {};
        // Get the inventory information of the cable
        const cableClientCode = inventoryEquipments[cableId]?.codigo_cliente ?? cableId;
        console.log("DELETE CABLE: ", { cableId, pathInfo, cableClientCode });
        const pathManager = new PathNode(pathInfo);
        const { sourceConnectionId, destinyConnectionId } = pathInfo;

        if (pathManager.nextIsCable()) {
            if (!singular) {
                deleteCable({ cableId: pathManager.nextCableId });
            }
            updatePathInfo({  //TODO: Inside this and the pathManager.prevIsCable() if we can create a const or function to decrease the amount of code.
                key: pathManager.nextCableId,
                info: {
                    prevCableId: null,
                    sourceConnectionId: CONNECTION_AIR,
                }
            });

            try {
                setRetrievedEquipments(prev => {
                    const newState = deepCopy(prev);
                    const state = newState[cableId]?.cable_siguiente ?? null;
                    if (state) {
                        newState[cableId].cable_siguiente = null;
                    }
                    return newState;
                });
            }
            catch (e) {
                console.warn("CATCH ERROR ON UPDATE CABLE's OWN 'cable_siguiente_id'");
            }

        } else if (pathManager.nextIsComponent()) {
            //TODO: release component in inventory
            const destinyConnectionIdReader = new ConnectionIdReader({
                compoundId: destinyConnectionId
            });
            const { componentId } = destinyConnectionIdReader.getParsedComponentConnection();
            const type = destinyConnectionIdReader.getComponentTypeByConnection();
            inventoryInstance.releaseComponentInput({ componentId, type });
        }

        if (pathManager.prevIsCable()) {
            updatePathInfo({
                key: pathManager.prevCableId,
                info: {
                    nextCableId: null,
                    destinyConnectionId: null //* This is not necessary, but if in the future we implement destinyConnectionId in cables, we need to update it.
                }
            });

            try {
                setRetrievedEquipments(prev => {
                    const newState = deepCopy(prev);
                    const state = newState[pathManager.prevCableId]?.cable_siguiente ?? null;
                    if (state) {
                        newState[pathManager.prevCableId].cable_siguiente = null;
                    }
                    return newState;
                });
            }
            catch (e) {
                console.warn("CATCH ERROR ON UPDATE PREV CABLE's 'cable_siguiente_id'");
            }
        } else if (pathManager.prevIsComponent()) {
            //TODO: release component in inventory
            const sourceConnectionIdReader = new ConnectionIdReader({ compoundId: sourceConnectionId });
            const { componentId, connectionId: outputId } = sourceConnectionIdReader.getParsedComponentConnection();
            const type = sourceConnectionIdReader.getComponentTypeByConnection();
            inventoryInstance.releaseComponentOutput({ componentId, type, outputId });
        }

        if (reasonFalla) {
            cableInventoryState.addCable(cableClientCode, "error");
        } else {
            cableInventoryState.addCable(cableClientCode, "available");
        }
        deletePath(cableId);
        if (pathManager.prevIsComponent() || pathManager.nextIsComponent()) {
            deleteCableFromComponents({
                sourceConnectionId: pathManager.prevIsComponent() ? sourceConnectionId : null,
                destinyConnectionId: pathManager.nextIsComponent() ? destinyConnectionId : null
            }); //TODO: separate and put inside ix XXXIsComponent
        }
        singleLineDiagramMode.setEditBaseMode();
        hoveredComponent.resetHoveredComponent();
    };

    const replaceCable = ({ cableId, newCableId }) => {
        try {
            const pathInfo = paths?.[cableId];
            if (!pathInfo) {
                throw new Error(`Path with id ${cableId} does not exist.`);
            }
            const pathManager = new PathNode(pathInfo);
            replaceCableFromComponents({ cableId, newCableId, pathManager });

            const replaceCableInInventory = ({ compoundKey, cableId }) => {
                consumeConnectionInventory({ compoundKey, cableId: newCableId });
            };
            if (pathManager.prevNodeType() === NodeTypes.COMPONENT) {
                replaceCableInInventory({ compoundKey: pathManager.sourceConnectionId, cableId });
            }
            if (pathManager.nextNodeType() === NodeTypes.COMPONENT) {
                replaceCableInInventory({ compoundKey: pathManager.destinyConnectionId, cableId });
            }

            const { prevCableId, nextCableId } = pathManager;
            if (pathManager.nextIsCable()) {
                updatePathInfo({
                    key: nextCableId,
                    info: { prevCableId: newCableId }
                });
            }
            if (pathManager.prevIsCable()) {
                updatePathInfo({
                    key: prevCableId,
                    info: { nextCableId: newCableId }
                });
            }
            updatePathId({ oldId: cableId, newId: newCableId });
        } catch (e) {
            console.error("Error while replacing cable: ", e);
            modal.setModal({
                title: "Error",
                message: `Error durante el remplazo de cable. Por favor contactar al equipo de soporte.`,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        }
    };

    const getSegmentInfoFromCable = (event, { id, points }) => {
        const stageRef = stageInteractions.stageRef.current;
        const clickX = stageRef.getPointerPosition().x;
        const clickY = stageRef.getPointerPosition().y;
        const strokeWidth = event.target.strokeWidth();

        const stageOffset = stageInteractions.screenOffset; //TODO: Use the stageOffet to fix the problem with the click position when we use the panning and zoom.
        // console.log("DEBUG STAGE WITH OFFSET:", { stageOffset, clickX, clickY });

        let segmentIndex = -1;
        let segmentMidpoint = { x: 0, y: 0 };
        let distances = [];

        for (let index = 0; index < points.length - 1; index++) {
            const segmentStart = points[index];
            const segmentEnd = points[index + 1];

            const isBetweenX = (clickX >= Math.min(segmentStart.x, segmentEnd.x) - strokeWidth && clickX <= Math.max(segmentStart.x, segmentEnd.x) + strokeWidth);
            const isBetweenY = (clickY >= Math.min(segmentStart.y, segmentEnd.y) - strokeWidth && clickY <= Math.max(segmentStart.y, segmentEnd.y) + strokeWidth);

            if (isBetweenX && isBetweenY) {
                segmentIndex = index;

                // Calculate midpoint
                segmentMidpoint = {
                    x: (segmentStart.x + segmentEnd.x) / 2,
                    y: (segmentStart.y + segmentEnd.y) / 2,
                };

                // Calculate distances
                for (let i = 0; i < points.length - 1; i++) {
                    const startPoint = points[i];
                    const endPoint = points[i + 1];
                    const distance = Math.sqrt((endPoint.x - startPoint.x) ** 2 + (endPoint.y - startPoint.y) ** 2);
                    distances.push(distance);
                }

                break;
            }
        }

        return { segmentIndex, segmentMidpoint, distances };
    };

    useEffect(() => {
        if (dockComponentsInstance.flagBothComponentsDocked) {
            handleDockComponents();
        }
    }, [dockComponentsInstance.flagBothComponentsDocked]);

    useEffect(() => {
        if (voltGraphInstance.flagBothPlugsSelected) {
            handleSelectPlugs();
        }
    }, [voltGraphInstance.flagBothPlugsSelected]);

    /**************************************************** STAGE INTERACTIONS  ****************************************************/

    const stageInteractions = useStageInteractions();
    const DEFAULT_DIAGRAM_ZONE = {
        x: 0,
        y: 0,
        width: 1000 * CANVAS_WIDTH_HEIGHT_RATIO,
        height: 1000
    };
    const [diagramZone, setDiagramZone] = useState(DEFAULT_DIAGRAM_ZONE);
    const [printInfoPosition, setPrintInfoPosition] = useState(SLDPrintInfoPositions.TOP);
    const [downloadingDiagram, setDownloadingDiagram] = useState(false);
    const downloadDiagramScreenshot = async ({ ultima, user, versionUser }) => {
        try {
            setIsLoading(true);
            setDownloadingDiagram(true);
            const diagramRange = {
                xMin: diagramZone.x,
                xMax: diagramZone.x + diagramZone.width,
                yMin: diagramZone.y,
                yMax: diagramZone.y + diagramZone.height,
            };
            const xRange = diagramRange.xMax - diagramRange.xMin;
            const yRange = diagramRange.yMax - diagramRange.yMin;

            stageInteractions.setScreenshotStageSize({
                width: xRange,
                height: yRange
            });
            await new Promise(resolve => setTimeout(resolve, 250));

            await fitDiagram({ range: diagramRange });

            await new Promise(resolve => setTimeout(resolve, 100)); //to wait state changes
            const stageRef = stageInteractions.stageRef.current;
            const screenshotURL = await stageRef.toDataURL();

            let currentDate = new Date();

            let image = new Image();
            image.src = screenshotURL;
            image.onload = function () {

                let canvas = document.createElement('canvas');
                let ctx = canvas.getContext('2d');

                canvas.width = image.width;
                canvas.height = image.height;

                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0);

                let date = new Date(ultima * 1000);
                if (isNaN(date)) {
                    date = new Date();
                }

                const FONT_SIZE = Math.round(0.012*canvas.width);
                const addTextWithLabel = ({text="", label="", x, y}) => {
                    ctx.font = `bold ${FONT_SIZE}px Arial`;
                    ctx.fillStyle = '#2c3a55ff';
                    ctx.fillText(label, x, y);
                    const labelWidth = ctx.measureText(label).width;

                    ctx.font = `${FONT_SIZE}px Arial`;
                    ctx.fillStyle = '#444';
                    ctx.fillText(text, x+labelWidth, y);
                };

                const infoPositionYOffset = printInfoPosition === SLDPrintInfoPositions.BOTTOM
                    ? canvas.height * (1 - PRINT_DIAGRAM_INFO_HEIGHT_RATIO)
                    : 0;
                addTextWithLabel({
                    x: 3 * PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height,
                    y: PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height / 4 + FONT_SIZE/2 + infoPositionYOffset,
                    label: "Fecha Versión: ",
                    text: `${formatDate(date)}`
                })
                addTextWithLabel({
                    x: 0.45 * canvas.width,
                    y: PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height / 4 + FONT_SIZE/2 + infoPositionYOffset,
                    label: "Usuario Versión: ",
                    text: `${versionUser}`
                })

                addTextWithLabel({
                    x: 3 * PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height,
                    y: PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height * 3/4 + infoPositionYOffset,
                    label: "Fecha Impresión: ",
                    text: `${formatDate(currentDate)}`
                })
                addTextWithLabel({
                    x: 0.45 * canvas.width,
                    y: PRINT_DIAGRAM_INFO_HEIGHT_RATIO * canvas.height * 3/4 + infoPositionYOffset,
                    label: "Usuario Impresión: ",
                    text: `${user}`
                })

                let link = document.createElement('a');
                link.download = `Diagrama Unilineal ${formatDate(date)}.png`;
                link.href = canvas.toDataURL();
                link.click();
                stageInteractions.setScreenshotStageSize(null);
            };
            setIsLoading(false);
            setDownloadingDiagram(false);
        } catch (e) {
            setIsLoading(false);
            setDownloadingDiagram(false);
            modal.setModal({
                title: "Error",
                message: `Error durante la descarga del diagrama. Por favor contactar al equipo de soporte.`,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
            console.error(`Error durante la descarga del diagrama.\n${e}`);
        }
    };

    function formatDate(date) {
        const pad = (number) => {
            if (number < 10) {
                return '0' + number;
            }
            return number;
        };

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1); // Months are 0-indexed
        const day = pad(date.getDate());
        const hour = pad(date.getHours());
        const minute = pad(date.getMinutes());

        return `${year}-${month}-${day} ${hour}:${minute}`;
    }

    const fitDiagram = async ({ range } = {}) => {
        if(!range) {
            range = getDiagramRange();
        }
        stageInteractions.fitStageToRange(range);
    };

    const fitCanvas = () => {
        const { xMin, xMax, yMin, yMax } = getDiagramRange();
        const xRange = xMax - xMin;
        const yRange = yMax - yMin;

        const width = Math.max(xRange, yRange * CANVAS_WIDTH_HEIGHT_RATIO);
        const height = Math.max(yRange, xRange / CANVAS_WIDTH_HEIGHT_RATIO) / (1 - PRINT_DIAGRAM_INFO_HEIGHT_RATIO);
        const yOffset = printInfoPosition === SLDPrintInfoPositions.TOP
            ? -(height * PRINT_DIAGRAM_INFO_HEIGHT_RATIO)
            : 0;
        setDiagramZone({
            width,
            height,
            x: xMin,
            y: yMin + yOffset
        });
    };

    const getDiagramRange = () => {
        const ceilTo100Multiple = v => Math.ceil(v/100) * 100;
        const floorTo100Multiple = v => Math.floor(v/100) * 100;
        const margin = 100;

        const defaultRanges = {xMin: Infinity, xMax: -Infinity, yMin: Infinity, yMax: -Infinity};

        const componentsRange = Object.values(components).reduce((prev, component) => {
            const { x = 0, y = 0, type } = component;
            const { width = 0, height = 0 } = SLDComponentSizes?.[type] ?? {};
            const leftPadding = type === SLDComponentEnum.SUBSTATION ? 200 : 0; // input label
            const rightPadding = type === SLDComponentEnum.LOAD ? 300 : 0; // load label and symbols.

            return {
                xMin: Math.min(prev.xMin, floorTo100Multiple(x) - margin - leftPadding),
                xMax: Math.max(prev.xMax, ceilTo100Multiple(x+width) + margin + rightPadding),
                yMin: Math.min(prev.yMin, floorTo100Multiple(y) - margin),
                yMax: Math.max(prev.yMax, ceilTo100Multiple(y+height) + margin)
            };
        }, defaultRanges);

        const labelsRange = Object.values(textLabels.state).reduce((prev, label) => {
            const { position, textStyle, value } = label;
            const { x, y } = position;
            const width = value.length * textStyle.fontSize * 0.5 ?? 0;
            const height = textStyle.fontSize * 1.5;
            // console.log("LABELS RANGE: LABEL: ", label, { width, height });

            return {
                xMin: Math.min(prev.xMin, floorTo100Multiple(x) - margin),
                xMax: Math.max(prev.xMax, ceilTo100Multiple(x + width) + margin),
                yMin: Math.min(prev.yMin, floorTo100Multiple(y) - margin),
                yMax: Math.max(prev.yMax, ceilTo100Multiple(y + height) + margin)
            };
        }, defaultRanges);

        const mergedRange = [componentsRange, labelsRange].reduce((prev, currentRange) => {
            const { xMin, xMax, yMin, yMax } = currentRange;
            return {
                xMin: Math.min(prev.xMin, xMin),
                xMax: Math.max(prev.xMax, xMax),
                yMin: Math.min(prev.yMin, yMin),
                yMax: Math.max(prev.yMax, yMax)
            };
        }, defaultRanges);

        return mergedRange;
    };

    const diagramHighlight = useSLDHightlight();

    /***************************************************** POP UPS  *****************************************************/

    const modal = useModal();
    const contextualMenu = useContextualMenu();
    const cableSelector = useCableSelector();

    /*************************************************** TEXT LABELS ***************************************************/
    const textLabels = useSLDTextLabels();

    /***************************************************** HELPERS *****************************************************/
    const isSLDComponentAvailable = ({ key, type }) => {
        if (type !== SLDComponentEnum.CABLE) {
            return !Object.keys(components).includes(key);
        }
        return !Object.keys(paths).includes(key);
    };
    const getAvailableComponents = (type) => {
        const availableTypeComponents = {};
        Object.entries(inventoryEquipments || {})
            .filter(([key, value]) => value.type === type)
            .filter(([key, value]) => isSLDComponentAvailable({ key, type }))
            .forEach(([key, value]) => {
                availableTypeComponents[key] = value
            });
        return availableTypeComponents;
    };

    const getFirstAvailableComponent = (type) => {
        // TODO: check if available in inventory instead.
        const entry = Object.entries(
            getAvailableComponents(type)
        )[0];
        return { key: entry[0], ...entry[1] };
    };

    const getComponentInfoById = (id) => {
        return inventoryEquipments[id];
    };

    const getComponentDisplayName = (componentId) => {
        const componentInfo = getComponentInfoById(componentId); //Los componentes tienen o codigo_cliente o nombre para desplegar. Añadir además el tipo del componente.
        return componentInfo?.codigo_cliente || componentInfo?.nombre || componentInfo?.type;
    }

    const isConnectionAvailable = (compoundId) => {
        // console.log("IS CONNECTION AVAILABLE"); //TODO: use useCallback to prevent run with every change in the front including moving the mouse
        try {
            // console.log("IS CONNECTION AVAILABLE 1: ", compoundId);
            const { componentId, connectionType, connectionId } = parseConnectionCompoundId(compoundId);
            const connectionTypeKey = connectionType === CONNECTION_OUTPUT
                ? "outputs" : "inputs";

            // console.log("IS CONNECTION AVAILABLE 2: ", { componentId, connectionType, connectionId, connectionTypeKey });
            const connectionInfo = components?.[componentId]?.[connectionTypeKey]?.[connectionId];
            // console.log("IS CONNECTION AVAILABLE 3: ", { connectionInfo });
            // console.log("IS CONNECTION AVAILABLE 4: ", {
            //   returnValue: (connectionInfo.connection.pathId === null)
            // });
            return connectionInfo.connection.pathId === null;
        } catch (e) {
            // console.warn("isConnectionAvailable failed: ", compoundId, e);
            return false;
        }
    };

    //in deleteComponent, after deleteComponentFromDiagram we need to delete the reference in the paths.

    const deleteComponent = (componentId) => {
        console.log("TO DELETE COMPONENT ID: ", componentId);
        updatePathConnectionsOnComponentDelete({ componentId });
        deleteComponentFromDiagram(componentId);
        deleteComponentInOutsInInventory(componentId);
        hoveredComponent.resetHoveredComponent();

        //TODO: Add back to inventory
        // console.log(inventoryEquipments);
        // console.log(inventoryEquipments[componentId]);
        //TODO:  Propagate cubicleId = null
    };

    const updateInUseInInventory = () => {
        const inDiagramComponentsIds = []
        Object.entries(components).forEach(([componentId, component]) => {
            const obj = { keys: componentId, type: component.type, activeOutputs: [] };

            if (component.type === SLDComponentEnum.SUBSTATION) {
                obj.activeOutputs = Object.keys(component?.outputs || {}).filter((cubicleId) => {
                    const substation = components[componentId];
                    const cubicle = substation.outputs[cubicleId];

                    return cubicle.connection.pathId !== null;
                });
            }

            inDiagramComponentsIds.push(obj);
        })

        Object.entries(paths).forEach(([pathId, path]) => {
            const obj = { keys: pathId, type: SLDComponentEnum.CABLE, path: path, activeOutputs: [] };

            inDiagramComponentsIds.push(obj);
        });

        // console.log("IN DIAGRAM COMPONENT IDS: ", { inDiagramComponentsIds, components });
        updateInUseComponentsInInventory(inDiagramComponentsIds)
    };

    const formatDateToDisplay = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}; //timeZone: 'UTC'
        const formattedDateTime = date.toLocaleString('es-CL', options);
        return formattedDateTime;
    }

    const goToPlugsGraph = (plugId) => {
        try {
            if (typeof plugId === "number") {
                plugId = plugId.toString();
                plugId = `plug_${plugId}`;
            }

            const plugParsedId = plugId.split("_")[1]
            const newPath = `/enchufes/${plugParsedId}`;

            // Create a full URL based on the new path
            const newTabUrl = `${window.location.origin}${newPath}`;

            // Open the new path in a new tab
            window.open(newTabUrl, '_blank');
            
        } catch (error) {
            console.error("Error on goToPlugsGraph: ", error);
        }

    };

    useEffect(() => {
        updateInUseInInventory();
        updateCubiclesToInventory({ components, paths });
    }, [components, paths]);

    return (
        <SingleLineDiagramContext.Provider
            value={{
                components,
                diagramHasLocalChanges,
                resetDiagramLocalChanges,
                updateRemoteDiagramToLocal,
                SLDComponents: SLDComponentsInstance,
                addComponent,
                updateComponent,
                updateComponentAndPaths,
                getComponentOutputPoints,
                getConnectionPathId,
                isLoading, setIsLoading,
                failedLoading,
                singleLineDiagramMode,
                ...currentCable, //This custom hook exports every method of the currentCable custom hook. 
                // REPLACED BY ...currentCable: DELETE WHEN READY --------------------------------
                // selectedCable, setSelectedCable,
                // selectedConnection, setSelectedConnection,
                // activateCable, deactivateCable,
                focusedConnectionTypes: getFocusedConnectionTypes(),
                // editingPath,
                updateEditedPath,
                currentPath: currentCable.creatingPath, // TODO: change name in the rest of the project1
                selectPathEnd,
                endPathOnAir,
                deleteCable,
                replaceCable,
                updatePathInfo,
                setIsBeingReplace,
                isBeingReplace,
                mouseCoordinates,
                setMouseCoordinates,
                paths, pathsHaveLocalChanges, resetPathsLocalChanges, updateRemotePathsToLocal,
                inventory, setInventory, consumeInventoryItem,
                diagramVersion,
                setDiagramVersion,
                inventoryEquipments,
                inventoryHasLocalChanges,
                resetInventoryLocalChanges,
                updateRemoteInventoryToLocal,
                getAvailableComponents,
                getFirstAvailableComponent,
                getComponentInfoById,
                isConnectionAvailable,
                deleteComponent,
                hoveredComponent,
                updateCubiclesToInventory,
                modal,
                contextualMenu,
                cableSelector,
                stageInteractions,
                diagramZone,
                setDiagramZone,
                formatDateToDisplay,
                diagramVersionHistoric,
                getSegmentInfoFromCable,
                updatePathPoints,
                updatePathInput,
                insertCable,
                moveDestinyConnectionPoint,
                moveSourceConnectionPoint,
                textLabels,
                toggleSymbolFromComponents,
                downloadDiagramScreenshot,
                downloadingDiagram,
                setPrintInfoPosition,
                fitDiagram,
                componentSelector,
                dockComponentsInstance,
                voltGraphInstance,
                handleDockComponents,
                handleUndockComponents,
                fitCanvas,
                aerialCables,
                getComponentDisplayName,
                diagramHighlight,
                diagramZonePanel,
                formatDate,
                goToPlugsGraph,
                cableInventoryState
            }}
        >
            {props.children}
        </SingleLineDiagramContext.Provider>
    );
};
