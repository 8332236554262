import { useEffect, useRef } from "react";

export function useKonvaShapeCache ({ deps = [], id = null, verbose = false, defaultCache = true }) {
    if (!Array.isArray(deps)) {
        throw new Error(`Error: useKonvaShapeCache expects deps to be an arrays of dependencies and received: ${deps}`);
    }
    const ref = useRef(null);

    useEffect(() => {
        if(verbose) {
            console.log(`change in useKonvaShapeCache with id: ${id}`);
            console.log("Deps: ", deps);
        }
        if(defaultCache) {
            console.log("ACTIVATE CACHE");
            enableCache();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    const redraw = () => {
        if (ref.current && ref.current.width() > 0 && ref.current.height() > 0) {
            ref.current.clearCache();
            ref.current.cache();
        } else {
            console.warn('Attempted to cache a node with zero width or height.');
        }
    };

    const enableCache = () => {
        try {
            const hasDimensions = ref.current.width() > 0 && ref.current.height() > 0;
            const hasChildren = ref.current.hasChildren && ref.current.getChildren().length > 0;
            // ref.current.getChildren().forEach((child, index) => {
            //     console.log(`${id} - child:${index}: `, child);
            //     child.cache();
            // });
            if (ref.current && id!==null && (hasDimensions || hasChildren)) {
                if (verbose) {
                    console.log(`useKonvaShapeCache cache enabled with id: ${id}`);
                }
                // console.log("BEFORE CACHE", ref.current.height(), ref.current.width(), ref.current.children.length, ref.current);
                ref.current.cache();
                // console.log("AFTER CACHE");
            }
        } catch (e) {
            console.warn("Could not cache a node with either height or width = 0");
        }

    };

    const disableCache = () => {
        try {
            if (ref.current) {
                if (verbose) {
                    console.log(`useKonvaShapeCache cache disabled with id: ${id}`);
                }
                ref.current.clearCache();  // Clearing cache effectively disables it
            }
        } catch (e) {
            console.warn("Could not clear cache of a node with either height or width = 0");
        }
    };

    return {
        ref,
        redraw,
        enableCache,
        disableCache
    };
}