import React from 'react';
import {Grid} from '@mui/material';
import CardContadorPala from "./CardContadorPala";


function WidgetContadorPalas({setCurrentEstadoPala, palas}) {

    return (
        <Grid container style={{
            direction: "row",
            alignContent: "flex-start",
            alignItems: "flex-start"
        }} spacing={2} justifyContent='space-evenly'>
            <CardContadorPala texto='Operativas Totales' color_icono="#0E537B"
                              palas={palas.filter(pala => pala.activo)}
                              setCurrentEstadoPala={() => setCurrentEstadoPala('')}/>

            <CardContadorPala texto='Funcionamiento Óptimo' color_icono="#529454"
                              palas={palas.filter(pala => pala.estado === 'operativo' && pala.activo)}
                              setCurrentEstadoPala={() => setCurrentEstadoPala('operativo')}/>

            <CardContadorPala texto='Zona Peligrosa' color_icono='#f8991e'
                              palas={palas.filter(pala => pala.estado === 'peligro' && pala.activo)}
                              setCurrentEstadoPala={() => setCurrentEstadoPala('peligro')}/>

            <CardContadorPala texto='Peligro Máximo' color_icono='#B51111'
                              palas={palas.filter(pala => pala.estado === 'peligro_maximo' && pala.activo)}
                              setCurrentEstadoPala={() => setCurrentEstadoPala('peligro_maximo')}/>

            <CardContadorPala texto='Desactivadas' color_icono='#565d60'
                              palas={palas.filter(pala => !pala.activo)}
                              setCurrentEstadoPala={() => setCurrentEstadoPala('desactivadas')}/>
        </Grid>
    );
}

export default WidgetContadorPalas;