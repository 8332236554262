import React from 'react';
import {Box, ButtonBase, Container, Divider, Paper, Typography} from "@mui/material";
import IconSubestacion from "../Icons/IconSubestacion";


const CardSubestacion = ({subestacion, setTopBarName}) => {
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'}

    return (
        <ButtonBase onClick={setTopBarName}>
            <Paper sx={{
                padding: 0,
                margin: 'auto',
                square: false,
                borderRadius: '8px 8px 8px 8px',
                alignItems: "flex-start",
                width: '225px',
                height: '191px',
                textDecorationLine: '',
            }}>
                <Container sx={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    borderRadius: '8px 8px 8px 8px',
                    paddingTop: '8px',
                    marginTop: '3px',
                    height: '45px',
                    display: 'flex',
                    alignItems: 'flex-end'

                }}>
                    <IconSubestacion fill_color={color["operativo"]}
                                     style={{
                                         width: '3em',
                                         height: '3em',
                                         margin: '0'
                                     }}/>
                </Container>
                <Container
                    style={{height: '32px', backgroundColor: color["operativo"]}}>
                    <Typography gutterBottom variant="subtitle1" sx={{
                        color: 'white',
                    }}>
                        {subestacion.nombre}
                    </Typography>
                </Container>
                <Container style={{
                    height: '6em',
                    backgroundColor: 'white',
                    borderRadius: '0px 0px 8px 8px',
                    padding: 0
                }}>
                    <Box display='flex' flex={1}>
                        <Box sx={{
                            width: '108px',
                            height: '111px',
                            margin: 0,
                            backgroundColor: '#a2b8c5',
                        }}
                             style={{borderRadius: '0px 0px 0px 8px'}}>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2" align="center">
                                    Cubículos:
                                </Typography>
                            </Container>
                            <Divider/>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2">
                                    Estado:
                                </Typography>
                            </Container>
                            <Divider/>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2">
                                    Última Conexión:
                                </Typography>
                            </Container>
                        </Box>
                        <Divider/>
                        <Box sx={{
                            width: '116px',
                            height: '111px',
                            margin: 0,
                            backgroundColor: 'white',
                        }}
                             style={{borderRadius: '0px 0px 8px 0px'}}>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2">
                                    {subestacion.cubiculos ? subestacion.cubiculos.length : '-'}
                                </Typography>
                            </Container>
                            <Divider/>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2">
                                    {'Operativo'}
                                </Typography>
                            </Container>
                            <Divider/>
                            <Container style={{
                                height: '37px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Typography variant="subtitle2">
                                    {'Hace 20[s]'}
                                </Typography>
                            </Container>
                        </Box>
                    </Box>
                </Container>

            </Paper>
        </ButtonBase>
    );
};

export default CardSubestacion;