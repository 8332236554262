import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";

export function ReplaceSelector({
  cableComponents,
}) {
  const [newCableId, setNewCableId] = useState("");

  const { replaceCable, setIsBeingReplace, isBeingReplace, singleLineDiagramMode } = React.useContext(SingleLineDiagramContext);
  const idToReplace = singleLineDiagramMode.getSelectedCableToEdit();

  const handleCloseReplaceModal = () => setIsBeingReplace(false);

  const handleChangeReplaceModal = (event) => {
      setNewCableId(event.target.value);
  };

  const handleReplaceCable = () => {
    replaceCable({ cableId: idToReplace, newCableId });
    setNewCableId("");
    handleCloseReplaceModal();
  };

  return (
    <>
        <Dialog open={isBeingReplace} onClose={handleCloseReplaceModal}>
            <DialogTitle>Selecciona un cable</DialogTitle>
            <DialogContent>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">Cables</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newCableId}
                label="Path"
                onChange={handleChangeReplaceModal}
                >
                {cableComponents.map(([key, value]) => {
                    return (
                    <MenuItem key={key} value={key}>
                        {key}
                    </MenuItem>
                    );
                })}
                </Select>
            </FormControl>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseReplaceModal}>Cancelar</Button>
            <Button onClick={handleReplaceCable} disabled={!newCableId}>
                Reemplazar
            </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
