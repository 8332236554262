import { useState } from "react";
import { SLDComponentEnum } from "../constants/single-line-diagram";

const DEFAULT_POSITION = { x: 0, y: 0 };

export function useSLDComponentSelector() {

    const [type, setType] = useState(null);
    const [position, setPosition] = useState(DEFAULT_POSITION);
    const [isAirCable, setIsAirCable] = useState(false);
    const [connectionPoint, setConnectionPoint] = useState(null);
    const [onDrawingPath, setOnDrawingPath] = useState(false); //TODO: usar para poder usar el boton de agregar cable tanto para el cable normal cómo el que parte en el aire.

    const resetPosition = () => setPosition(DEFAULT_POSITION);
    const resetConnectionPoint = () => setConnectionPoint(null);

    const setSafeType = (newType) => {
        if (Object.values(SLDComponentEnum).includes(newType) || newType === null) {
            setType(newType);
        }
        else {
            console.error("Invalid type for SLDComponentSelector: ", newType);
        }
    };

    return { type, setType: setSafeType, 
        position, setPosition, 
        resetPosition, 
        isAirCable, setIsAirCable,
        onDrawingPath, setOnDrawingPath,
        connectionPoint, setConnectionPoint,
        resetConnectionPoint
     };
}
