import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Container, Box, CircularProgress } from "@mui/material";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import LoadingGif from "../../assets/LoadingGif";
import {
    BarController,
    BarElement,
    Chart, Legend,
    LinearScale,
    LineController,
    LineElement, PointElement,
    TimeScale,
    Title,
    Tooltip
} from "chart.js";
import { set } from "date-fns";
import { pad } from "../../Utilities";

Chart.register(BarController, BarElement, LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip, Legend);

function transformDataDate(data) { //The data.fecha is a Unix timestamp, so we need to convert it to a Date object in format "yyyy-MM-ddTHH:mm:ss"
    if (!data) {
        return [];
    }
    return data.map((lectura) => ({
        ...lectura,
        fecha: new Date(lectura.fecha * 1000)
    }));
}

export function SLDVoltGraph({
    isOpen = false,
    handleClose = () => {},
    onSelect = () => {},
    data={},
}) {
    const chartContainerAB = useRef(null);
    const chartContainerBC = useRef(null);
    const chartContainerCA = useRef(null);
    const [chartInstanceAB, setChartInstanceAB] = useState(null);
    const [chartInstanceBC, setChartInstanceBC] = useState(null);
    const [chartInstanceCA, setChartInstanceCA] = useState(null);
    const [isLoadingAB, setIsLoadingAB] = useState(true);
    const [isLoadingBC, setIsLoadingBC] = useState(true);
    const [isLoadingCA, setIsLoadingCA] = useState(true);

    const abData = transformDataDate(data.ab);
    const bcData = transformDataDate(data.bc);
    const caData = transformDataDate(data.ca);

    const [initAb, setInitAb] = useState(false);
    const [initBc, setInitBc] = useState(false);
    const [initCa, setInitCa] = useState(false);

    useEffect(() => {
        if (chartContainerAB?.current) {
            const newChartInstanceAB = new Chart(chartContainerAB.current, {
                type: 'line',
                data: {
                    datasets: [{
                        label: 'Delta Voltaje AB',
                        abData: abData.map((lectura) => ({
                            x: lectura.fecha,
                            y: lectura.valor
                        })),
                        fill: 'none',
                        backgroundColor: '#0e537b',
                        pointRadius: 2,
                        borderColor: '#0e537b',
                        borderWidth: 1,
                        lineTension: 0,
                    }],
                },
                options: {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'hour',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12,
                            },

                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Diferencia Tensión [V]'
                            },
                        }
                    }
                }
            });
            setChartInstanceAB(newChartInstanceAB);
        }

        if (chartContainerBC && chartContainerBC.current) {
            const newChartInstanceBC = new Chart(chartContainerBC.current, {
                type: 'line',
                data: {
                    datasets: [{
                        label: 'Delta Voltaje BC',
                        data: bcData.map((lectura) => ({
                            x: lectura.fecha,
                            y: lectura.valor
                        })),
                        fill: 'none',
                        backgroundColor: '#d2ae1f',
                        pointRadius: 2,
                        borderColor: '#d2ae1f',
                        borderWidth: 1,
                        lineTension: 0,
                    }],
                },
                options: {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'hour'
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 12,
                            },
                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Diferencia Tensión [V]'
                            },
                        }
                    }
                }
            });
            setChartInstanceBC(newChartInstanceBC);
        }

        if (chartContainerCA && chartContainerCA.current) {
            const newChartInstanceCA = new Chart(chartContainerCA.current, {
                type: 'line',
                data: {
                    datasets: [{
                        label: 'Delta Voltaje CA',
                        data: caData.map((lectura) => ({
                            x: lectura.fecha,
                            y: lectura.valor
                        })),
                        fill: 'none',
                        backgroundColor: '#64ac35',
                        pointRadius: 2,
                        borderColor: '#64ac35',
                        borderWidth: 1,
                        lineTension: 0,
                    }],
                },
                options: {
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                unit: 'hour'
                            },

                        },
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Diferencia Tensión [V]'
                            },
                        }
                    }
                }
            });
            setChartInstanceCA(newChartInstanceCA);
        }

        return () => {
            if (chartInstanceAB) {
                chartInstanceAB.destroy();
            }
            if (chartInstanceBC) {
                chartInstanceBC.destroy();
            }
            if (chartInstanceCA) {
                chartInstanceCA.destroy();
            }
        };
    }, [initAb, initBc, initCa]);

    useEffect(() => {
        if (chartInstanceAB) {
            chartInstanceAB.data.datasets[0].data = abData.map((lectura) => ({
                x: lectura.fecha,
                y: lectura.valor
            }));
            chartInstanceAB.update();
        }
    }, [abData]);

    const interval = useRef(null);

    useEffect(() => {
        interval.current = setInterval(() => {
            if (chartContainerAB?.current) {
                setInitAb(true);
                setIsLoadingAB(false);
            }

            if (chartContainerBC?.current) {
                setInitBc(true);
                setIsLoadingBC(false);
            }

            if (chartContainerCA?.current) {
                setInitCa(true);
                setIsLoadingCA(false);
            }

            if (initAb && initBc && initCa) {
                clearInterval(interval.current);
            }
        }, 1000);
    }, []);
    
    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Gráfico Voltajes</DialogTitle>
            <DialogContent>
                <Container style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "stretch",
					height: "100%",
				}}>
					{(isLoadingAB || isLoadingBC || isLoadingCA ) ?
						(
							<Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
								<CircularProgress sx={{ color: "#55a1dd" }} size={100} />
							</Box>
						) : null
					}
					<Box style={{
						position: "relative",
						flex: 1,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<canvas ref={chartContainerAB} style={{ width: "100%", height: "100%" }} />
					</Box>
					<Box style={{
						position: "relative",
						flex: 1,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<canvas ref={chartContainerBC} style={{ width: "100%", height: "100%" }} />
					</Box>
					<Box style={{
						position: "relative",
						flex: 1,
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}>
						<canvas ref={chartContainerCA} style={{ width: "100%", height: "100%" }} />
					</Box>
                </Container>    
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
}