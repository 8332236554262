import React, { useContext } from "react";
import Toolbar from '@mui/material/Toolbar';
import {Badge, Box, Button, CssBaseline, IconButton, Typography} from "@mui/material";
import MuiAppBar from '@mui/material/AppBar';
import IconDashboard from "./Icons/IconDashboard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {theme as temaPrincipal} from "../App";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ButtonBase from "@mui/material/ButtonBase";
import {Link} from 'react-router-dom';
import {useTheme} from "@emotion/react";
import {styled} from "@mui/material/styles";
import { AuthenticationContext } from "../contexts/AuthenticationContext";


const drawerWidth = 311;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing(9)})`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const TopBar = ({open, handleDrawerOpen, user, handleDrawerClose, topBarName, setTopBarName}) => {
    const theme = useTheme();

    const authContext = useContext(AuthenticationContext);

    const classes = {
        root: {
            display: 'flex',
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
            backgroundColor: temaPrincipal.palette.primary.main,
            marginLeft: 0,
            paddingLeft: 0,
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
        },
        appBar: {
            marginLeft: theme.spacing(8),
            width: `calc(100% - ${theme.spacing(8)})`,
            paddingLeft: 0
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            paddingLeft: 0
        },
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            marginLeft: "1em",
            flexGrow: 1
        },
        closeDrawerButton: {
            backgroundColor: '#81d1ff',
            height: '51px',
            width: '22px',
            borderRadius: '0px 10px 10px 0px',
            marginRight: '16px',
            padding: 0,
            marginLeft: '5px',

        },
    };

    const getDisplayName = () => {
        if (!user.first_name) {
            return user.username
        }
        if (!user.last_name) {
            return user.first_name;
        }
        return `${user.first_name} ${user.last_name}`;
    }

    return (
        <Box sx={{ display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" sx={open ? classes.appBarShift : classes.appBar}>
                <Toolbar disableGutters>
                    <ButtonBase sx={classes.closeDrawerButton}
                                onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {open ? (
                            <ChevronLeftIcon sx={classes.closeDrawerButton}/>
                        ) : (
                            <ChevronRightIcon sx={classes.closeDrawerButton}/>
                        )}

                    </ButtonBase>

                    {/*<IconDashboard marginRightValue="2em"/>*/}

                    <Typography component="h1" variant="h6" color="inherit" noWrap sx={classes.title}>
                        {topBarName}
                    </Typography>


                    {user !== {} &&
                        <ButtonBase onClick={() => setTopBarName('Usuario')}>
                            <Link to="/usuario" sx={{textDecoration: 'none', color: "white"}}>
                                <Typography sx={{color: 'white', '&:hover': { textDecoration: 'underline' }}}>
                                    {getDisplayName()}
                                </Typography>
                            </Link>
                        </ButtonBase>
                    }
                    <Link to="/usuario" sx={{textDecoration: 'none', color: "white"}}>
                        <IconButton color="inherit" onClick={() => setTopBarName('Usuario')} size="large">
                            <AccountCircleRoundedIcon sx={{color: 'white', '&:hover': { color: '#ddd' } }} />
                        </IconButton>
                    </Link>
                    <IconButton color="inherit" style={{marginRight: '10px'}} size="large">
                        <Badge badgeContent={4} color="secondary">
                            <NotificationsIcon sx={{color: 'white', '&:hover': { color: '#ddd' } }} />
                        </Badge>
                    </IconButton>

                    <Button color="inherit" onClick={authContext.logout} sx={{ '&:hover': { textDecoration: 'underline' } }}>
                        Cerrar sesión
                    </Button>
                </Toolbar>
            </AppBar>
        </Box>

    );
};

export default TopBar;
