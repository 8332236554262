import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import IconPala from "./Icons/IconPala";
import {Container, Divider, Box} from "@mui/material";

const styles = {
    root: {},
    paper: {
        padding: 0,
        margin: 'auto',
        square: false,
        borderRadius: '8px 8px 8px 8px',
        alignItems: "flex-start",
        width: '225px',
        height: '191px',
        textDecorationLine: '',
    },
    palaImage: {
        width: 'auto',
        height: '41px',
        marginTop: '8px',
        marginLeft: '0px',
        marginBottom: '0px',
    },
    dataRow: {
        padding: '0',
    },
    cardImg: {
        width: '3em',
        height: '3em',
        margin: '0'
    },
    cardText: {
        color: 'white',
    },
    paramsColumn: {
        width: '34px',
        height: '111px',
        margin: 0,
        backgroundColor: '#a2b8c5',
    },
    valuesColumn: {
        width: '78px',
        height: '111px',
        margin: 0,
        backgroundColor: 'white',
    },
    valuesColumn2: {
        width: '79px',
        height: '111px',
        margin: 0,
        backgroundColor: 'white',
    },
    containerImage: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: '8px 8px 8px 8px',
        marginTop: '3px',
        height: '45px',
        display: 'flex',
        alignItems: 'flex-end'

    },
    tituloFila: {
        fontSize: 12,
    },
    valuesCell: {
        height: '37px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    }
}

export default function CardPala({pala, setTopBarName}) {
    // const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'}
    const colorEvento = {'': '#529454', 'Baja': 'rgba(30,161,248,0.53)', 'Media': '#F8991E', 'Alta': '#B51111'};
    const color_desactivado = '#565D60'

    return (
        <ButtonBase onClick={setTopBarName}>
            <Paper sx={styles.paper}>
                <Container sx={styles.containerImage}>
                    <IconPala fill_color={pala.activo ? colorEvento[pala.evento_mayor_urgencia] : color_desactivado}
                              style={styles.cardImg}/>
                </Container>

                <Container
                    style={{
                        height: '32px',
                        backgroundColor: pala.activo ? colorEvento[pala.evento_mayor_urgencia] : color_desactivado
                    }}>
                    <Typography gutterBottom variant="subtitle1" style={styles.cardText}>
                        {pala.nombre}
                    </Typography>
                </Container>
                <Container style={{
                    height: '6em',
                    backgroundColor: 'white',
                    borderRadius: '0px 0px 8px 8px',
                    padding: 0
                }}>
                    <Box display='flex' flex={1}>
                        <Box sx={styles.paramsColumn} style={{borderRadius: '0px 0px 0px 8px'}}>
                            <Box height={'37px'} pt={1}>
                                <Typography sx={styles.tituloFila}>
                                    Vab:
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box height={'37px'} pt={1}>
                                <Typography sx={styles.tituloFila}>
                                    Vbc:
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box height={'37px'} pt={1}>
                                <Typography sx={styles.tituloFila}>
                                    Vca:
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={styles.valuesColumn}>
                            <Box sx={styles.valuesCell}>
                                <Typography>
                                    {pala.enchufe_fuente ? pala.enchufe_fuente.voltaje_ab ? pala.enchufe_fuente.voltaje_ab.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.valuesCell}>
                                <Typography>
                                    {pala.enchufe_fuente ? pala.enchufe_fuente.voltaje_bc ? pala.enchufe_fuente.voltaje_bc.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.valuesCell}>
                                <Typography>
                                    {pala.enchufe_fuente ? pala.enchufe_fuente.voltaje_ca ? pala.enchufe_fuente.voltaje_ca.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={styles.paramsColumn}>
                            <Box height={'37px'} pt={1}>
                                <Typography sx={styles.tituloFila}>
                                    Vab:
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box height={'37px'} pt={1}>
                                <Typography sx={styles.tituloFila}>
                                    Vbc:
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box pt={1} height={'37px'} >
                                <Typography sx={styles.tituloFila}>
                                    Vca:
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={styles.valuesColumn2} style={{borderRadius: '0px 0px 8px 0px'}}>
                            <Box sx={styles.valuesCell}>
                                <Typography>
                                    {pala.enchufe_equipo ? pala.enchufe_equipo.voltaje_ab ? pala.enchufe_equipo.voltaje_ab.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.valuesCell}>
                                <Typography>
                                    {pala.enchufe_equipo ? pala.enchufe_equipo.voltaje_bc ? pala.enchufe_equipo.voltaje_bc.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.valuesCell}>
                                <Typography display="block">
                                    {pala.enchufe_equipo ? pala.enchufe_equipo.voltaje_ca ? pala.enchufe_equipo.voltaje_ca.toFixed(1) : '-' : '-'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>


            </Paper>
        </ButtonBase>
    );
}
