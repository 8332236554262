import React from "react";
import "./Diagrama.css";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import { Box, Typography } from "@mui/material";


const SLDInfo = ({ token }) => {
    const { hoveredComponent } = React.useContext(SingleLineDiagramContext);

    const infoDisplay = (componentInfo) => {
        return JSON.stringify(componentInfo, null, 2)
            .replace(/[\{\},"]/g, "")
            .replace(/:/g, " : ")
            .replace(/_/g, " ")
    };

    if (!hoveredComponent.getHoveredComponentInfo()) {
        return null;
    }

    const deployInfo = () => {
        const info = hoveredComponent.getHoveredComponentInfo()
        if (!Array.isArray(info)) {
            return (
                <Typography sx={{ whiteSpace: "pre-line", color: "white", marginLeft: "2em"}}>
                    {"Info: " + infoDisplay(info)}
                </Typography>
            );
        }
        return info.map((componentInfo, index) => {
            index = (componentInfo.display_name.includes("H") ? 0 : 1);
            const plugTypeLabel = index === 0 ? "hembra: " : "macho: ";
            return (
                <Typography key={index} sx={{ whiteSpace: "pre-line", color: "white", marginLeft: "2em"}}>
                    {`Info ${plugTypeLabel}` + infoDisplay(componentInfo)}
                </Typography>
            );
        });
    };

    return (
        <Box sx={{margin: "2em 2em 2em 0em", flexDirection: "row", display: "flex"}}>
            {deployInfo()}
        </Box>
    );
};

export default SLDInfo;
