import React from 'react';
import {Grid} from "@mui/material";
import CardPala from "./CardPala";
import {Link} from "react-router-dom";

function WidgetPalas({palas, currentEstadoPala, setTopBarName}) {
    return (
        <div>
            <Grid container spacing={2} direction="row">
                {palas.filter(pala => {
                    if (currentEstadoPala === '') {
                        return true
                    } else {
                        if (currentEstadoPala === 'desactivadas') {
                            return pala.activo === false;
                        } else {
                            return pala.estado === currentEstadoPala && pala.activo === true;
                        }

                    }
                }).map((pala) => (
                    <Grid item key={pala.id}>
                        <Link to={`palas/${pala.cubiculo}`} style={{textDecoration: 'none'}}>
                            <CardPala pala={pala} setTopBarName={() => setTopBarName(`${pala.nombre}`)}/>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </div>


    );
}

export default WidgetPalas;