import {ReactComponent as IconSVG} from '../svg/logo_collahuasi_vertical.svg';
// import {ReactComponent as IconSVG} from '../svg/logo_codelco.svg';
import React from 'react';

function LogoCollahuasiVertical() {
    return (
        <IconSVG alt="icon_logo_vertical" style={{
            background: "white",
            borderRadius: "0%",
            margin: '10',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        }}/>
    );
}

export default LogoCollahuasiVertical;
