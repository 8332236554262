import {ReactComponent as IconSVG} from '../../svg/splitter_2.svg';
import React from 'react';
import PropTypes from "prop-types";

function IconSplitter2({fill_color}) {
        return (
            <IconSVG alt="icon_splitter_2" style={{
                background: "rgba(0,0,0,0)",
                margin: '0px',
                marginTop: '20px',
                padding: '0px',
                display: 'block',
                width: '40px',
                maxHeight: '120px',
                fill: fill_color,
            }}/>
        );
}

IconSplitter2.propTypes = {
    fill_color: PropTypes.string,
};


IconSplitter2.defaultProps = {
    fill_color: 'black',
};


export default IconSplitter2;