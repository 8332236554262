import React, { useEffect } from "react";
import { useLocalLogin } from "../hooks/useLogin";
import { loginByUsernameAndPassword } from "../services/api/auth";
import axios from "axios";

export const AuthenticationContext = React.createContext(null);

export const AuthenticationContextProvider = (props) => {

    const localLogin = useLocalLogin();

    const login = async ({ username, password }) => {
        try {
            const { token, user_id: userId } = await loginByUsernameAndPassword({
                username, password
            });
            localLogin.saveLoginInfo({ username, userId, token });
        } catch (e) {
            throw e;
        }
    };

    const logout = () => {
        localLogin.deleteLoginInfo();
    }

    const axiosInstance = axios.create();

    useEffect(() => {
        if(localLogin.loginInfo.token) {
            axiosInstance.interceptors.request.use((config) => {
                config.headers['Authorization'] = "Token " + loginInfo.token;
                return config;
            });
        }
    }, [localLogin])

    const { loginInfo, isLogged } = localLogin;
    return (
        <AuthenticationContext.Provider
            value={{
                loginInfo,
                isLogged,
                login,
                logout,
                axiosInstance,
            }}
        >
            {props.children}
        </AuthenticationContext.Provider>
    );
};
