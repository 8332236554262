import { roundToStep } from "../../../utils/round-to-step";

export class SLDInfoParser {
    static parseCable (cable_id) {
        return cable_id ? "cable_" + cable_id : "-";
    }

    static getSLDComponentTypeName(info) {
        return {
            substation: "Subestación",
            splitter: "Splitter",
            load: "Carga"
        }[info.type] ?? "";
    }

    static parseSLDComponentInfo(info) {
        return {
            substation: SLDInfoParser.parseSubstationInfo(info),
            splitter: SLDInfoParser.parseSplitterInfo(info),
            load: SLDInfoParser.parseLoadInfo(info)
        }[info.type] ?? info;
    }

    static parseSubstationInfo(info) {
        const cubiculos = {};
        Object.entries(info?.cubiculos ?? {}).forEach(([key, value]) => {
            const { cable_salida_id } = value;
            cubiculos[key] = SLDInfoParser.parseCable(cable_salida_id);
        });
        return { cubiculos };
    }

    static parseSplitterInfo(info) {
        const parsedInfo = {};
        const { cable_entrada_id, cubiculo_id, splitter_salidas = [], input_voltages = {} } = info;
        parsedInfo["cable entrada"] = SLDInfoParser.parseCable(cable_entrada_id);
        parsedInfo["cubiculo"] = cubiculo_id ?? "-";
        splitter_salidas.forEach((salida, index) => {
            parsedInfo["salida " + index] = SLDInfoParser.parseCable(salida.cable_salida_id);
        });

        const voltajes = {};
        voltajes["voltaje_r"] = addUnit({ value: (input_voltages?.voltaje_ab ?? "-"), unit: "V" });
        voltajes["voltaje_s"] = addUnit({ value: (input_voltages?.voltaje_bc ?? "-"), unit: "V" });
        voltajes["voltaje_t"] = addUnit({ value: (input_voltages?.voltaje_ca ?? "-"), unit: "V" });
        parsedInfo["voltajes_entrada"] = voltajes;

        return parsedInfo;
    }

    static parseLoadInfo(info) {
        const { tipo_equipo, cubiculo_id, cable_entrada_id, input_voltages = {} } = info;
        const parsedInfo = {};
        parsedInfo["tipo"] = tipo_equipo;
        parsedInfo["cubiculo"] = cubiculo_id;
        parsedInfo["cable_entrada"] = cable_entrada_id;

        const voltajes = {};
        voltajes["voltaje_r"] = addUnit({ value: (input_voltages?.voltaje_ab ?? "-"), unit: "V" });
        voltajes["voltaje_s"] = addUnit({ value: (input_voltages?.voltaje_bc ?? "-"), unit: "V" });
        voltajes["voltaje_t"] = addUnit({ value: (input_voltages?.voltaje_ca ?? "-"), unit: "V" });
        parsedInfo['voltajes'] = voltajes;
        // parsedInfo["voltaje_r"] = "-[V]";
        // parsedInfo["voltaje_s"] = "-[V]";
        // parsedInfo["voltaje_t"] = "-[V]";

        return parsedInfo;
    }

    static parseCableInfo(info) {
        const parsedInfo = {};
        const {
            cable_anterior,
            cable_siguiente,
            cubiculo,
            estado_cable,
            fecha_cambio_estado,
            locacion,
            fecha_cambio_locacion,
            largo,
            voltage_alarms = {},
            inventory_state,
        } = info;

        parsedInfo["anterior"] = SLDInfoParser.parseCable(cable_anterior);
        parsedInfo["siguiente"] = SLDInfoParser.parseCable(cable_siguiente);
        parsedInfo["cubiculo"] = cubiculo ?? "-";
        parsedInfo["estado"] = estado_cable ?? "-";
        parsedInfo["cambio_estado"] = fecha_cambio_estado ?? "-";
        // parsedInfo["ubicacion"] = locacion ?? "-";
        // parsedInfo["cambio_ubicacion"] = fecha_cambio_locacion ?? "-";
        parsedInfo["largo"] = largo ?? "-";
        parsedInfo["voltage_alarms"] = voltage_alarms;
        parsedInfo["inventory_state"] = inventory_state;

        return parsedInfo;
    }

    static parsePlugInfo(info, alarms = {}) {
        if(!info) {
            return {};
        }

        //TODO: Add alarmas de voltaje cuando estén listas.
        let {
            voltaje_ab: voltaje_r,
            voltaje_bc: voltaje_s,
            voltaje_ca: voltaje_t,
            temp_interior_ab: temperatura_r,
            temp_interior_bc: temperatura_s,
            temp_interior_ca: temperatura_t,
            temp_exterior: temperatura_exterior,
            medicion_ozono_r,
            medicion_ozono_s,
            medicion_ozono_t,
            medicion_voltaje_microfono: nivel_chispa,
            medicion_humedad: nivel_humedad,
            latitud,
            longitud
        } = info;

        const parsedInfo = {};

         const voltajes = {};
        voltajes["voltaje_r"] = alarms != {} ? [addUnit({ value: (voltaje_r ?? "-"), unit: "V" }), alarms?.["voltages"]?.["voltaje_ab"]] :
            addUnit({ value: (voltaje_r ?? "-"), unit: "V" });
        voltajes["voltaje_s"] = alarms != {} ? [addUnit({ value: (voltaje_s ?? "-"), unit: "V" }), alarms?.["voltages"]?.["voltaje_bc"]] :
            addUnit({ value: (voltaje_s ?? "-"), unit: "V" });
        voltajes["voltaje_t"] = alarms != {} ? [addUnit({ value: (voltaje_t ?? "-"), unit: "V" }), alarms?.["voltages"]?.["voltaje_ca"]] :
            addUnit({ value: (voltaje_t ?? "-"), unit: "V" });

        const corrientes = {};
        corrientes["corriente_r"] = addUnit({ value: "-", unit: "A" });
        corrientes["corriente_s"] = addUnit({ value: "-", unit: "A" });
        corrientes["corriente_t"] = addUnit({ value: "-", unit: "A" });

        const tryRound = (value, fractionDigits=1) => {
            try {
                return value.toFixed(fractionDigits);
            } catch (e) {
                return value;
            }
        }

        const temperaturas = {};
        console.log("Alarms in parsePlugInfo: ", alarms);
        temperaturas["temperatura_r"] = alarms != {} ? [addUnit({ value: (tryRound(temperatura_r) ?? "-"), unit: "°C" }), alarms?.["inner_temperature"]?.["temp_interior_ab"]] :
            addUnit({ value: (tryRound(temperatura_r) ?? "-"), unit: "°C" });
        temperaturas["temperatura_s"] = alarms != {} ? [addUnit({ value: (tryRound(temperatura_s) ?? "-"), unit: "°C" }), alarms?.["inner_temperature"]?.["temp_interior_bc"]] :
            addUnit({ value: (tryRound(temperatura_s) ?? "-"), unit: "°C" });
        temperaturas["temperatura_t"] = alarms != {} ? [addUnit({ value: (tryRound(temperatura_t) ?? "-"), unit: "°C" }), alarms?.["inner_temperature"]?.["temp_interior_ca"]] :
            addUnit({ value: (tryRound(temperatura_t) ?? "-"), unit: "°C" });
        temperaturas["temperatura_exterior"] = addUnit({ value: (tryRound(temperatura_exterior) ?? "-"), unit: "°C" });

        const gps = {};
        const roundCoordinate = (value) => {
            try {
                return value.toFixed(6);
            } catch (e) {
                return value;
            }
        }
        latitud = roundCoordinate(latitud);
        longitud = roundCoordinate(longitud);
        gps["latitud"] = addUnit({ value: (latitud ?? "-"), unit: "°" });
        gps["longitud"] = addUnit({ value: (longitud ?? "-"), unit: "°" });

        parsedInfo["voltajes"] = voltajes;
        parsedInfo["corriente"] = corrientes;
        parsedInfo["temperatura"] = temperaturas;
        parsedInfo["gps"] = gps;

        const nivel_ozono = Math.max(medicion_ozono_r, medicion_ozono_s, medicion_ozono_t);
        parsedInfo["nivel_ozono"] =  addUnit({ value: (nivel_ozono ?? "-"), unit: "ppb" });
        parsedInfo["nivel_chispa"] = addUnit({ value: (nivel_chispa ?? "-"), unit: "V" });

        const humedad = {};
        humedad["nivel_humedad"] = alarms != {} ? [addUnit({ value: (Math.round(Number(nivel_humedad)/10) ?? "-"), unit: "%" }), alarms?.["humidity"]] :
            addUnit({ value: (Math.round(Number(nivel_humedad)/10) ?? "-"), unit: "%" });
        parsedInfo["humedad"] = humedad;


        return parsedInfo;
    }
}

const addUnit = ({value, unit}) => `${value} [${unit}]`
