import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@mui/material";

const ConfirmModal = ({
    isOpen = false,
    onConfirm = () => {},
    onClose = () => {},
    title,
    message,
    component,
    anotherMessage,
    cancelLabel =  "Cancelar",
    okLabel = "OK"
}) => {

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
                <Typography>{anotherMessage}</Typography>
            </DialogContent>
            <Box sx={{ marginLeft: '1em', marginRight: '1em'}}>
                { component }
            </Box>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {cancelLabel}
                </Button>
                <Button color="primary" onClick={onConfirm}>
                    {okLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmModal;
