import { GRID_SIZE, InsertCableSides, NodeTypes, CONNECTION_POINT_HIGHLIGHTED_RADIUS } from "../../../constants/single-line-diagram";
import { ConnectionIdReader } from "../ConnectionIdReader";
import { deepCopy } from "../../../utils/deep-copy";
// import {
//     CONNECTION_POINT_HIGHLIGHTED_RADIUS,
// } from "../constants/single-line-diagram";

export class InvalidNodeType extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_NODE_TYPE'
        this.message = message
    }
}

export class InvalidArguments extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_ARGUMENTS'
        this.message = message
    }
}

export class PathNode {
    prevCableId = null;
    nextCableId = null;
    sourceConnectionId = null;  // either component output or prev cable destiny. Ex: 1. substation_100:out:cubicle_101 | 2. prev_cable:cable-destiny
    destinyConnectionId = null; // either component input or this cable destiny.  Ex: 1. load_000:in:0                  | 2. this_cable:cable-destiny
    sourceCubicle = null;

    constructor({id = null, prevCableId = null, nextCableId = null, sourceConnectionId = null, destinyConnectionId = null, sourceCubicle=null}) {
        this.id = id;
        this.prevCableId = prevCableId;
        this.nextCableId = nextCableId;
        this.sourceConnectionId = sourceConnectionId;
        this.destinyConnectionId = destinyConnectionId;
        this.sourceCubicle = sourceCubicle;
    }

    getPrevCable() {
        return this.prevCableId;
    }

    getNextCable() {
        return this.nextCableId;
    }

    prevIsCable() {
        return this.prevCableId !== null;
    }

    nextIsCable() {
        return this.nextCableId !== null;
    }

    prevNodeType() {
        try {
            const connectionIdReader = new ConnectionIdReader({compoundId: this.sourceConnectionId});
            return connectionIdReader.getParentNodeType();
        } catch (e) {
            return undefined;
        }
    }

    nextNodeType() {
        try {
            const connectionIdReader = new ConnectionIdReader({compoundId: this.destinyConnectionId});
            return connectionIdReader.getParentNodeType();
        } catch (e) {
            return undefined;
        }

    }

    prevIsComponent() { //TO DO: add unit test
        return this.prevNodeType() === NodeTypes.COMPONENT;
    }

    nextIsComponent() { //TO DO: add unit test
        return this.nextNodeType() === NodeTypes.COMPONENT;
    }

    getSourceComponentId() {
        if (this.prevNodeType() !== NodeTypes.COMPONENT) {
            throw new InvalidNodeType(`Source connection is not COMPONENT.`);
        }
        const connectionIdReader = new ConnectionIdReader({compoundId: this.sourceConnectionId});

        return connectionIdReader.getParsedComponentConnection().componentId;
    }

    getDestinyComponentId() {
        if (this.nextNodeType() !== NodeTypes.COMPONENT) {
            throw new InvalidNodeType(`Source connection is not COMPONENT.`);
        }
        const connectionIdReader = new ConnectionIdReader({compoundId: this.destinyConnectionId});

        return connectionIdReader.getParsedComponentConnection().componentId;
    }

    static insertCableToSide({ pathsInfo, originalCableId = null, newCableId = null, side = InsertCableSides.BEFORE }) {
        if (originalCableId === null) {
            throw new InvalidArguments("Invalid originalCableId (null).");
        }
        if (newCableId === null) {
            throw new InvalidArguments("Invalid newCableId (null).");
        }
        if (!Object.values(InsertCableSides).includes(side)) {
            throw new InvalidArguments(`${side} is not a side from InsertCableSides values (${Object.values(InsertCableSides)})`);
        }

        const originalCableNode = new PathNode({
            ...pathsInfo[originalCableId]
        });

        const updatedPathsInfo = deepCopy(pathsInfo);
        updatedPathsInfo[newCableId] = {
            sourceConnectionId: null,
            destinyConnectionId: null,
            prevCableId: null,
            nextCableId: null,
        };

        if (side === InsertCableSides.BEFORE) {
          updatedPathsInfo[newCableId].sourceConnectionId = originalCableNode.sourceConnectionId;
          updatedPathsInfo[newCableId].prevCableId = originalCableNode.prevCableId;
          updatedPathsInfo[newCableId].nextCableId = originalCableId;
        }
        if (side === InsertCableSides.AFTER) {
          updatedPathsInfo[newCableId].prevCableId = originalCableId;
          updatedPathsInfo[newCableId].nextCableId = originalCableNode.nextCableId;
          updatedPathsInfo[newCableId].destinyConnectionId = originalCableNode.destinyConnectionId;
        }

        if (side === InsertCableSides.BEFORE) {
          updatedPathsInfo[originalCableId].sourceConnectionId = null;
          updatedPathsInfo[originalCableId].prevCableId = newCableId;
        }
        if (side === InsertCableSides.AFTER) {
          updatedPathsInfo[originalCableId].destinyConnectionId = null;
          updatedPathsInfo[originalCableId].nextCableId = newCableId;
        }

        if (side === InsertCableSides.BEFORE && originalCableNode.prevIsCable()) {
            const prevCableId = originalCableNode.getPrevCable();
            updatedPathsInfo[prevCableId] = {
                ...updatedPathsInfo[prevCableId],
                nextCableId: newCableId
            };
        }
        if (side === InsertCableSides.AFTER && originalCableNode.nextIsCable()) {
            const nextCableId = originalCableNode.getNextCable();
            updatedPathsInfo[nextCableId] = {
                ...updatedPathsInfo[nextCableId],
                prevCableId: newCableId
            };
        }

        return updatedPathsInfo;
    }

    static getPathsChain({ pathsInfo, firstCableId }) {
        const chain = [];
        let nextCableId = firstCableId;
        while(nextCableId) {
            const nextPath = deepCopy(pathsInfo[nextCableId]);
            if(!nextPath) {
                break;
            }
            chain.push(deepCopy({ id: nextCableId, ...nextPath }));
            nextCableId = nextPath.nextCableId;
        }
        return chain;
    }

    static getReversedPathChain({ pathsInfo, lastCableId }) {
        const chain = [];
        let prevCableId = lastCableId;
        while(prevCableId) {
            const path = deepCopy(pathsInfo[prevCableId]);
            if(!path) {
                break;
            }
            chain.push(deepCopy({
                id: prevCableId,
                prevCableId: path.nextCableId,
                nextCableId: path.prevCableId,
                sourceConnectionId: path.destinyConnectionId,
                destinyConnectionId: path.sourceConnectionId,
                points: path.points.reverse()
            }));
            prevCableId = path.prevCableId;
        }
        return chain;
    }

    static reversePathChain(pathChain) {
        pathChain.forEach((path) => {
            path.prevCableId = path.nextCableId;
            path.nextCableId = path.prevCableId;
            path.sourceConnectionId = path.destinyConnectionId;
            path.destinyConnectionId = path.sourceConnectionId;
            path.points = path.points.reverse();
        });
        return pathChain.reverse()
    }


    static updatePathsChain({ pathsInfo, firstCableId = null, lastCableId = null, newFirstPoint, }) {
        const StartPoints = {
            SOURCE: 'source',
            DESTINY: 'destiny'
        };
        const startPoint = firstCableId
            ? StartPoints.SOURCE
            : lastCableId ? StartPoints.DESTINY : null
        if (!startPoint) {
            throw Error('Need to set either firstCableId or lastCableId');
        }
        const pathsChain = startPoint === StartPoints.SOURCE
            ? PathNode.getPathsChain({ pathsInfo, firstCableId})
            : PathNode.getReversedPathChain({ pathsInfo, lastCableId });
        const firstPoint = pathsChain.at(0).points.at(0);
        const firstX = firstPoint.x;
        const firstY = firstPoint.y;
        const lastPoint = pathsChain.at(-1).points.at(-1);
        const lastX = lastPoint.x;

        const getNewX = (x) => newFirstPoint.x + (x - firstX) * (lastX - newFirstPoint.x)/(lastX - firstX); // trust me, I'm an engineer

        let horizontallyAligned = true;
        let nextIsComponent = false;
        pathsChain.forEach((path, chainIndex) => {
            path.points.forEach((point, pointIndex) => {
                const isFirst = chainIndex === 0 && pointIndex === 0;
                if (isFirst) {
                    point.x = newFirstPoint.x;
                    point.y = newFirstPoint.y;
                    return;
                }
                const isLastChainPoint = chainIndex === pathsChain.length - 1 && pointIndex === path.points.length - 1;
                nextIsComponent = Boolean(path.destinyConnectionId);
                if (isLastChainPoint && nextIsComponent) {
                    return;
                }
                point.x = getNewX(point.x);
                if (horizontallyAligned && point.y === firstY) {
                    point.y = newFirstPoint.y;
                } else {
                    horizontallyAligned = false;
                }
            });
        });
        {
            const lastPathPoints = pathsChain.at(-1).points;
            const lastPoint = lastPathPoints.at(-1);
            const prevToLastPoint = lastPathPoints.at(-2);

            if (nextIsComponent && lastPoint.y !== prevToLastPoint.y) {
                const middleX = (lastPoint.x + prevToLastPoint.x) / 2;
                const correctionPoint1 = { x: middleX, y: prevToLastPoint.y };
                const correctionPoint2 = { x: middleX, y: lastPoint.y };
                lastPathPoints.splice(-1, 0, correctionPoint1, correctionPoint2);
            }
        }
        if (startPoint === StartPoints.DESTINY) {
            PathNode.reversePathChain(pathsChain);
        }
        return pathsChain;
    }

    static toUpdateSourceConnectionPoint({ pathsInfo, cableId, dropPoint }) {
        try {
            const updatedPathsInfo = deepCopy(pathsInfo);

            const currentCable = updatedPathsInfo[cableId]; //Los cables al aire no tienen nextCableId, destinyConnectionId ni prevCableId.

            const nextPoint = currentCable.points[1];
            const currentPoint = currentCable.points[0];

            // if (currentPoint.y !== nextPoint.y) {
            //     return updatedPathsInfo;
            // }

            const setX = (x) => {
                //flat x and y to avoid floating point errors
                currentCable.points[0].x = parseInt(x);
            }
            const limitToRange = ({value, range}) => Math.min(Math.max(range[0], value), range[1]);
            const margin = 2 * CONNECTION_POINT_HIGHLIGHTED_RADIUS + Math.random(); //El cable tiene entrada al aire, por lo que no tiene MINIMO.
            const minX = dropPoint.x
            const maxX = nextPoint.x - margin;

            if ((maxX - minX) < margin) {
                setX((minX + maxX + Math.random() - 0.5) / 2);
                console.log("Setting X: ", (minX + maxX + Math.random() - 0.5) / 2);
            } else if (dropPoint.x < minX) {
                setX(minX);
                console.log("Setting X: ", minX);
            } else if (dropPoint.x > maxX) {
                setX(maxX);
                console.log("Setting X: ", maxX);
            } else {
                setX(
                    limitToRange({ value: dropPoint.x, range: [minX, maxX]})
                );
                console.log("Setting X: ", limitToRange({ value: dropPoint.x, range: [minX, maxX]}));
            }

            console.log("CurrentCable points: ", currentCable.points[0]);

            if (Math.abs(dropPoint.y - nextPoint.y) < GRID_SIZE) {
                dropPoint.y = nextPoint.y + Math.random() - 0.5;
            }

            const flattedY = parseInt(dropPoint.y);

            if (currentCable.points.length < 3 && dropPoint.y !== nextPoint.y) {
                const middleX = (currentPoint.x + nextPoint.x)/2;
                const flattedMiddleX = parseInt(middleX);
                const flattedNextY = parseInt(nextPoint.y);

                console.log("middleX, currentPoint.x, nextPoint.x", middleX, currentPoint.x, nextPoint.x);

                const newPoint1 = {
                    x: flattedMiddleX,
                    y: flattedNextY
                };
                const newPoint2 = {
                    x: flattedMiddleX,
                    y: flattedY
                };

                currentCable.points.splice(1, 0, newPoint1); //Por algún motivo hacer "currentCable.points.splice(1, 0, newPoint1, newPoint2);" desordena todo.
                currentCable.points.splice(1, 0, newPoint2); //TODO: Si se borra esta linea, el mover el vertice permite mover el cable COMPLETO recto, puede ser usado eventualmente para arrastrar cables.

            }

            currentCable.points[0].y = flattedY;
            currentCable.points[1].y = flattedY;

            return updatedPathsInfo;
        } catch (error) {
            console.log('Error in PathNode.toUpdateSourceConnectionPoint', error);
            return pathsInfo;
        }
    }

    static toUpdatedConnectionPoint({ pathsInfo, prevCableId, dropPoint }) {
        try {
            const updatedPathsInfo = deepCopy(pathsInfo);

            const prevCable = updatedPathsInfo[prevCableId];
            const destinyCableId = prevCable.nextCableId; //Si está al aire, no tiene nextCableId.
            const nextCable = updatedPathsInfo[destinyCableId];

            const prevPoint = prevCable.points[prevCable.points.length - 2];
            const currentPoint = (nextCable) ? nextCable.points[0] : prevCable.points[prevCable.points.length - 1];
            const nextPoint = (nextCable) ? nextCable.points[1] : null;

            // if (nextPoint) { //TODO: Revisar si es necesario, ya que al borrarlo se evita el problema de que al estar levemente desalineados los puntos, no se mueva el punto.
            //     if (prevPoint.y !== currentPoint.y || currentPoint.y !== nextPoint.y) {
            //         console.log("No se puede mover el punto, no están alineados.");
            //         return updatedPathsInfo;
            //     }
            // } else {
            //     if (prevPoint.y !== currentPoint.y) {
            //         return updatedPathsInfo;
            //     }
            // }

            const setX = (x) => {
                prevCable.points[prevCable.points.length - 1].x = x;
                if (nextCable){
                    nextCable.points[0].x = x;
                    console.log("Setting nextCable: ", nextCable);
                }
            };
            const limitToRange = ({value, range}) => Math.min(Math.max(range[0], value), range[1]);
            const margin = 2 * CONNECTION_POINT_HIGHLIGHTED_RADIUS + Math.random();
            const minX = (nextCable) ? Math.min(prevPoint.x, nextPoint.x) + margin : prevPoint.x + margin;
            const maxX = (nextCable) ? Math.max(prevPoint.x, nextPoint.x) - margin : dropPoint.x; //Si es cable al aire, no hay un maximo.

            if ((maxX - minX) < margin) { //TODO: En cable al aire es posible que el punto se mueva atrás del eje X del penultimo punto, esto si bien no es un problema per se, puede ser confuso para el usuario.
                setX((minX + maxX + Math.random() - 0.5) / 2);
            } else if (dropPoint.x < minX) {
                setX(minX);
            } else if (dropPoint.x > maxX) {
                setX(maxX);
            } else {
                setX(
                    limitToRange({ value: dropPoint.x, range: [minX, maxX]})
                );
            }

            if (Math.abs(dropPoint.y - currentPoint.y) < GRID_SIZE) {
                dropPoint.y = currentPoint.y + Math.random() - 0.5;
            }
            if (prevCable.points.length < 3 && dropPoint.y !== currentPoint.y) {
                const middleX = (prevPoint.x + currentPoint.x)/2;
                const newPoint1 = {
                    x: middleX,
                    y: prevPoint.y
                };
                const newPoint2 = {
                    x: middleX,
                    y: dropPoint.y
                };

                prevCable.points.splice(prevCable.points.length-1, 0, newPoint1, newPoint2);
            }
            prevCable.points[prevCable.points.length - 1].y = dropPoint.y;
            prevCable.points[prevCable.points.length - 2].y = dropPoint.y;

            if (!nextCable) {
                return updatedPathsInfo;
            }
            if (nextCable.points.length < 3 && dropPoint.y !== currentPoint.y) {
                const middleX = (currentPoint.x + nextPoint.x)/2;
                const newPoint1 = {
                    x: middleX,
                    y: dropPoint.y
                };
                const newPoint2 = {
                    x: middleX,
                    y: nextPoint.y
                };
                nextCable.points.splice(1, 0, newPoint1, newPoint2);
            }
            nextCable.points[0].y = dropPoint.y;
            nextCable.points[1].y = dropPoint.y;



            return updatedPathsInfo;
        } catch (error) {
            console.error('Error in PathNode.toUpdatedConnectionPoint', error);
            return pathsInfo;
        }
        
    }
}