import axios from "axios";

export async function logDiagramRegister(token, data = {}) {
    try {
        console.warn("logDiagramRegister: ", data);
        const headers = { Authorization: `Token ${token}`};
        const config = { headers };
        const response = await axios.post('/diagrama/instancia/log_diagram_register/', data, config);

        return response.data;
    } catch (e) {
        console.error("Error in logDiagramRegister: ", e);
    }
}