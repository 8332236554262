import { useState, useEffect } from 'react';

const useSLDCableInventoryState = () => {
    const [cableChanges, setCableChanges] = useState({});

    const addCable = (codigoCliente, estado) => {
        setCableChanges(prevState => ({
            ...prevState,
            [codigoCliente]: estado,
        }));
    };

    const deleteCable = (codigoCliente) => {
        setCableChanges(prevState => {
            const newState = { ...prevState };
            delete newState[codigoCliente];
            return newState;
        });
    };

    useEffect(() => {
        console.log("DEBUG CABLE CHANGES: ", cableChanges);
    }, [cableChanges]);

    return { cableChanges, addCable, deleteCable };
};

export default useSLDCableInventoryState;