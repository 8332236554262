import { useEffect, useState } from "react";
import axios from "axios";
import useModal from "./useModal";
import { deepCopy } from "../utils/deep-copy";
import { getCablesWithPosition } from "../services/api/inventory";

export const CABLES_INVENTORY_MODALS = {
    "CREATE_CABLE": "create-cable",
    "EDIT_CABLE": "edit-cable",
    "LINK_ECLAMP": "link-eclamp"
};

const useOpenModal = (initState = null) => {
    const [openModal, setOpenModal] = useState(initState);

    const getCurrent = () => openModal;
    const isOpen = state => state === openModal;
    const open = (state) => setOpenModal(state);
    const close = () => setOpenModal(null);

    return { getCurrent, isOpen, open, close };
};

export const useCablesInventory = ({ config }) => {
    const modalState = useOpenModal();

    const confirmModal = useModal();

    const [cables, setCables] = useState([]);
    const [cablesWithPositionInfo, setCablesWithPositionInfo] = useState([]);
    const [enchufes, setEnchufes] = useState([]);
    const [eclamps, setEclamps] = useState([]);
    const [loading, setLoading] = useState(false);

    const [selectedRows, setSelectedRows] = useState([]);

    const [snackbarText, setSnackBarText] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    const [ cubiculos, setCubiculos ] = useState([]);
    const [ splitters, setSplitters ] = useState([]);

    useEffect(() => {
        onMount();
    }, []);

    const onMount = () => {
        Promise.all([loadCablesWithPositionInfo(), loadEnchufes(), loadEclamps(), loadCubiculos(), loadSplitters()])
            .then(()=>{
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error al obtener los datos:', error);
            });
    };

    const loadCablesWithPositionInfo = async () => {
        setLoading(true);
        try {
            const newCables = await getCablesWithPosition({ config });
            setCablesWithPositionInfo(newCables)
        } catch (e) {
            console.error("Failed fetching cables data");
        } finally {
            setLoading(false);
        }

    };

    const setCablesStateFromResponse = (cablesResponse) => {
        const parseCable = (cable) => {
            return {
                ...cable,
                inventario: cable.inventario?.id || '',
                enchufe_hembra: cable.enchufe_hembra?.id || '',
                enchufe_macho: cable.enchufe_macho?.id || '',
                serial_macho: cable.enchufe_macho?.eclamp?.["serial_number"] || '',
                serial_hembra: cable.enchufe_hembra?.eclamp?.["serial_number"] || '',
            };
        };

        const cablesWithAvailability = cablesResponse.data.map(parseCable);
        setCables(cablesWithAvailability.reverse());

        console.log("CABLES:", cablesWithAvailability);
    };

    const loadEnchufes = async () => {
        axios.get(`/enchufes/`, config)
            .then(res => {
                if (res.status === 200) {
                    setEnchufes(res.data);
                } else {
                    snackBarError("Error al obtener enchufes")
                }
            })
            .catch(err => {
                snackBarError("Error al obtener enchufes")
            })
    }

    const loadEclamps = async () => {
        axios.get(`/eclamp/`, config)
            .then(res => {
                if (res.status === 200) {
                    setEclamps(res.data);
                } else {
                    snackBarError("Error al obtener eclamps")
                }
            })
            .catch(err => {
                snackBarError("Error al obtener eclamps")
            })
    }

    const loadCubiculos = async () => {
        axios.get(`/cubiculos/?detalle=true`, config)
            .then(res => {
                if (res.status === 200) {
                    setCubiculos(res.data);
                } else {
                    snackBarError("Error al obtener cubiculos")
                }
            })
            .catch(err => {
                snackBarError("Error al obtener cubiculos")
            })
    }

    const loadSplitters = async () => {
        axios.get(`/splitters/`, config)
            .then(res => {
                if (res.status === 200) {
                    setSplitters(res.data);
                } else {
                    snackBarError("Error al obtener splitters")
                }
            })
            .catch(err => {
                snackBarError("Error al obtener splitters")
            })
    }

    // Función para calcular la ubicación correlativa de los cables
    const calcularUbicacionCorrelativa = (cubiculo, cables) => {
        const ubicacionCorrelativa = {};
        const splitters = {};
      
        let posicion = 1;
      
        // Función para agregar los cables y sus posiciones
        const agregarCables = (cables) => {
          for (const cable of cables) {
            ubicacionCorrelativa[cable.codigo_cliente] = posicion;
            posicion++;
          }
        };
      
        // Verificar si el cubiculo tiene un splitter en su cadena de cables
        if (cubiculo.has_splitter_in_cable_chain) {
          // Obtener el nombre del splitter
          const splitterNombre = cubiculo.has_splitter_in_cable_chain;
      
          // Buscar el splitter en los splitters cargados
          const splitter = splitters[splitterNombre];
      
          // Verificar si se encontró el splitter y si tiene salidas
          if (splitter && splitter.salidas.length > 0) {
            // Calcular la ubicación correlativa de los cables que salen del splitter
            agregarCables(splitter.salidas);
          }
        }
      
        // Calcular la ubicación correlativa de los cables del cubiculo
        agregarCables(cubiculo.cadena_cables_sin_splitters);
      
        return ubicacionCorrelativa;
    };
    
    // Dentro del componente donde tienes los cubiculos y splitters cargados
    // Calcular la ubicación correlativa para cada cubiculo
    const cubiculosConUbicacion = cubiculos.map((c) => ({
        ...c,
        ubicacion_correlativa: calcularUbicacionCorrelativa(c, cables),
    }));
  
    const handleLinkEclampPlug = async ({ eclamp, enchufeAsociar, onDone }) => {
        //verify if eclamp is being used by another cable
        const eclampIsUsed = enchufes.some(plug => plug["eclamp_id"] === eclamp);

        if (eclampIsUsed) {
            throw new Error("Either eclamp is being used by another cable or cable already has eclamp");
        }
        const data = {
            "eclamp_id": eclamp,
            "enchufe_id": enchufeAsociar
        };
        try {
            const linkPlugToEclampResponse = await axios.post(
                `/enchufeeclamp/set_eclamp/`, data, config
            );
            if (linkPlugToEclampResponse.status === 200) { //Devolver el id de los enchufes, tmb el id del eclamp
                onDone();
                snackBarSuccess('Enchufe e Eclamp asociados')
                updatePlugEclampLocally({
                    plugId: enchufeAsociar, eclampId: eclamp
                });
                // loadCables(); //TODO: load plugs (enchufes) to reload state
            } else {
                snackBarError("Error al asociar enchufe (request)");
            }
        } catch (e) {
            snackBarError("Error al asociar enchufe (local)");
        }
    };

    const updatePlugEclampLocally = ({ plugId, eclampId }) => {
        setEnchufes((prev) => {
            const linkedPlugIndex = prev.findIndex(plug => plug.id === plugId);
            const plugDoesNotExist = (linkedPlugIndex === -1);
            if (plugDoesNotExist){
                return prev;
            }
            const newValue = deepCopy(prev);
            newValue[linkedPlugIndex] = {
                ...newValue[linkedPlugIndex],
                ["eclamp_id"]: eclampId
            };

            return newValue;
        });
    };

    const handleDelete = () => {
        const userConfirm = window.confirm("¿Estás seguro de que quieres eliminar los cables seleccionados?");
        if (!userConfirm) {
            return;
        }
        const rowIsDeletable = (row) => {
            const cable = cables.find(cable => cable["codigo_cliente"] === row);
            return cable["disp"] === false;
        };
        const deletableSelectedRows = selectedRows.filter(rowIsDeletable);
        axios.post('/cablesinventory/delete_selected/', { selected_rows: deletableSelectedRows }, config)
            .then(res => {
                if (res.status === 200) {
                    snackBarSuccess('Cables eliminados');
                    deleteCablesLocally(deletableSelectedRows);

                    const enchufeHIdToDelete = cables
                        .filter(cable => deletableSelectedRows.includes(cable.codigo_cliente))
                        .map(cable => cable.enchufe_hembra);
                    const enchufeMIdToDelete = cables
                        .filter(cable => deletableSelectedRows.includes(cable.codigo_cliente))
                        .map(cable => cable.enchufe_macho);

                    setEnchufes(prevEnchufes => prevEnchufes.filter(enchufe => (
                        !enchufeHIdToDelete.includes(enchufe.id) && !enchufeMIdToDelete.includes(enchufe.id)
                    )));

                    // loadCables().then(); TODO: reload from backend
                } else {
                    snackBarError('Error al eliminar')
                }
            })
            .catch(err => {
                snackBarError('Error al eliminar')
            })
    };

    const deleteCablesLocally = (rows) => {
        const cableWasNotDeleted = (cable) => !rows.includes(cable["codigo_cliente"]);
        setCables(prev => prev.filter(cableWasNotDeleted));
    };

    const createCable = ({ codigoCliente, largo, onDone, eclampM, eclampH}) => {
        const eclampMIsUsed = enchufes.some(plug => plug["eclamp_id"] === eclampM); //Eclamp Macho is used
        const eclampHIsUsed = enchufes.some(plug => plug["eclamp_id"] === eclampH); //Eclamp Hembra is used

        if (eclampMIsUsed || eclampHIsUsed) {
            throw new Error("Either eclamp is being used by another cable or cable already has eclamp");
        }

        const newCable = {
            "codigo_cliente": codigoCliente,
            "largo": largo,
        };

        if (eclampH) {
            newCable["serial_hembra"] = eclampH;
        }

        if (eclampM) {
            newCable["serial_macho"] = eclampM;
        }
        axios.post('/cableupdate/', newCable, config)
            .then(res => {
                if (res.status === 201) {
                    const enchufeHId = res.data.enchufe_hembra;
                    const enchufeMId = res.data.enchufe_macho;
                    const newCable = res.data;

                    if (eclampH) {
                        newCable["serial_hembra"] = eclamps.find(eclamp => eclamp.id === eclampH).serial_number;
                    }
                    if (eclampM) {
                        newCable["serial_macho"] = eclamps.find(eclamp => eclamp.id === eclampM).serial_number;
                    }
                    newCable["disp"] = false;


                    snackBarSuccess('Cable creado');
                    onDone();
                    setCables(prevCables => [newCable, ...prevCables]);
                    setEnchufes((prevEnchufes) => [
                        ...prevEnchufes,
                        { id: enchufeHId, eclamp_id: eclampH },
                        { id: enchufeMId, eclamp_id: eclampM },
                      ]);
                    // loadCables().then();
                } else {
                    snackBarError("Error al crear cables");
                }
            })
            .catch(err => {
                console.log(err);
                snackBarError("Error al crear cables");
            })
    };

    const editCable = async ({ onDone, codigoCliente, largo, eclampHembra, eclampMacho }) => { //TODO: Hacer que todo se haga en una sola solicitud y que el backend maneje todo.
        const data = {
            "codigo_cliente": codigoCliente,
            "largo": largo,
        };
        try {
            const updateCableResponse = await axios.put(`/cableupdate/${codigoCliente}/update_cable/`, data, config);
            if (updateCableResponse.status !== 200) {
                snackBarError("Error al editar cables");
                return;
            }
    
            const getCableResponse = await axios.get(`/cableupdate/${codigoCliente}`, config);
            if (getCableResponse.status !== 200) {
                snackBarError("Error al obtener cable");
                return;
            }

            const enchufeHembraId = getCableResponse.data.enchufe_hembra;
            const enchufeMachoId = getCableResponse.data.enchufe_macho;

            if (eclampHembra !== null && eclampHembra !== enchufes.find(plug => plug.id === enchufeHembraId)?.["eclamp_id"]) {
                await handleLinkEclampPlug({ eclamp: eclampHembra, enchufeAsociar: enchufeHembraId, onDone });
            }
            if (eclampMacho !== null && eclampMacho !== enchufes.find(plug => plug.id === enchufeMachoId)?.["eclamp_id"]) {
                await handleLinkEclampPlug({ eclamp: eclampMacho, enchufeAsociar: enchufeMachoId, onDone });
            }
            data["serial_hembra"] = eclampHembra ? eclamps.find(eclamp => eclamp.id === eclampHembra).serial_number : null;
            data["serial_macho"] = eclampMacho ? eclamps.find(eclamp => eclamp.id === eclampMacho).serial_number : null;
    
            snackBarSuccess('Cable editado');
            onDone();
            updateCableLocally(data);
            // loadCables().then(); TODO: add remote reload
        } catch (err) {
            console.log(err);
            snackBarError("Error al editar cables");
        }
    };
    

    const updateCableLocally = (updatedCable) => {
        setCablesWithPositionInfo(prev => {
            const updatedIndex = prev.findIndex(
                (cable) => cable["codigo_cliente"] === updatedCable["codigo_cliente"]
            );
            if (updatedIndex === -1) {
                return prev;
            }
            const newCables = deepCopy(prev);
            newCables[updatedIndex] = {
                ...newCables[updatedIndex],
                ...updatedCable
            };
            return newCables;
        });
    };

    /******************************** SNACKBAR ********************************/

    const snackBarError = (text) => {
        setSnackBarText(text);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    }

    const snackBarSuccess = (text) => {
        setSnackBarText(text);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    /********************************* GETTERS *********************************/

    const getEnchufesWithoutCable = () => {
        const selectedCable = modalState.isOpen(CABLES_INVENTORY_MODALS.EDIT_CABLE) ?
            cables.find(cable => cable.codigo_cliente === selectedRows[0]) : null;

        const hasCable = (enchufe) => {
            return cables.every((cable) => {
                if (selectedCable && (enchufe.id === selectedCable.enchufe_hembra || enchufe.id === selectedCable.enchufe_macho)) {
                    // Si estamos editando un cable y este enchufe está asociado con el cable seleccionado,
                    // entonces consideramos que este enchufe no tiene cable
                    return true;
                }
                // En otros casos, verificamos que este enchufe no esté asociado con ningún cable
                return cable.enchufe_hembra !== enchufe.id && cable.enchufe_macho !== enchufe.id;
            });
        };

        return enchufes.filter(hasCable);
    };

    const getEnchufesWithoutEclamp = () => {
        return enchufes.filter(plug => plug["eclamp_id"] === null);
    };

    const getEclampsWithoutEnchufe = () => {
        const usedEclampIds = enchufes
            .filter(plug => plug["eclamp_id"] !== null)
            .map(plug => plug["eclamp_id"]);
        return eclamps.filter(({ id }) => !usedEclampIds.includes(id));
    };

    const getPlugLinkedEclamp = (plugId) => {
        const eclampId = enchufes.find(({id}) => id === plugId)?.["eclamp_id"] ?? null;
        return eclamps.find(({ id }) => id === eclampId);
    }

    return {
        cablesWithPositionInfo, enchufes, eclamps, cubiculos, cubiculosConUbicacion, splitters, selectedRows, setSelectedRows,
        getEnchufesWithoutCable, getEnchufesWithoutEclamp, getEclampsWithoutEnchufe,
        getPlugLinkedEclamp,
        handleLinkEclampPlug, handleDelete, handleCreate: createCable, handleEditDB: editCable, handleSnackbarClose,
        snackbarText, snackbarOpen, snackbarSeverity, loading, modalState, confirmModal
    };
};