import PropTypes from "prop-types";

function IconNoRotado({text, fill_color, style}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 82.247 43.315" width={style?.width ?? {}}>
            <path id="Trazado_19" data-name="Trazado 19"
                  d="M171.96,151.942H155.984v-2.507l-66.271-7.148V185.6l66.271-7.148v-2.507H171.96Z"
                  transform="translate(-89.713 -142.287)" fill={fill_color}/>
            <text x="8" y="26" fontFamily="Roboto" fontSize="13" fill="white">{text}</text>
        </svg>

    )
}

function IconRotado({text, fill_color, style}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.247 43.315" width={style?.width ?? {}}>
            <g>
                <path id="Trazado_20" data-name="Trazado 20"
                      d="M82.247,9.655H66.271V7.148L0,0V43.315l66.271-7.148V33.66H82.247Z"
                      transform="translate(82.247 43.315) rotate(180)" fill={fill_color}/>
                <text x="23" y="26" fontFamily="Roboto" fontSize="13" fill="white">{text}</text>
            </g>
        </svg>
    )
}

function IconConexion({fill_color, rotado, text, width = 96}) {
    if (rotado) {
        return (
            <IconRotado text={text} fill_color={fill_color} alt="icon_conexion" style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: `${width}px`
            }}/>
        );
    } else {
        return (
            <IconNoRotado text={text} fill_color={fill_color} alt="icon_conexion" style={{
                backgroundColor: "rgba(0,0,0,0)",
                width: `${width}px`
            }}/>
        );
    }

}

IconConexion.propTypes = {
    fill_color: PropTypes.string,
    rotado: PropTypes.bool,
    text: PropTypes.string,
    width: PropTypes.number
};


IconConexion.defaultProps = {
    fill_color: 'black',
    rotado: false,
    text: '',
}

export default IconConexion;