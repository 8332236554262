import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";

export function CableSelectorPopUp({
    isOpen = false,
    title,
    onSelect,
    close,
    confirmLabel = 'Confirmar',
    cancelLabel = 'Cancelar',
}) {
    const { inventoryEquipments } = React.useContext(SingleLineDiagramContext);
    const cableEntries = Object.entries(inventoryEquipments)
        .filter(([key, value]) => (key && value.type === "cable" && value.disponible));
    const [currentCableId, setCurrentCableId] = useState("");

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-simple-select-label">
                    Cables
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentCableId}
                    label="Path"
                    onChange={(event) => {setCurrentCableId(event.target.value)}}
                >
                    {cableEntries.map(([key, value]) => {
                        return (
                        <MenuItem key={key} value={key}>
                            {key}
                        </MenuItem>
                        );
                    })}
                </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>{cancelLabel}</Button>
                <Button
                    onClick={() => {
                        onSelect(currentCableId);
                    }}
                    disabled={!currentCableId}
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
