import {ReactComponent as DashboardSvg} from '../../svg/dashboard.svg';
import React from 'react';

function ButtonDashboard() {
    return (
        <DashboardSvg alt="dashboard"style={{
            width: "3em",
            height: "auto",
            borderRadius: "0%",
            textAlign: "center",
            lineHeight: "3em",
            verticalAlign: "middle",
        }}></DashboardSvg>
    );
}

export default ButtonDashboard;