import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import {
    Box,
    TableContainer,
    TableBody,
    TableCell,
    Table,
    TableRow, Typography
} from "@mui/material";
import IconConexion from "../Icons/IconConexion";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import {useTheme} from "@emotion/react";

const styles = {
    root: {},
    paper: {
        padding: 0,
        margin: 'auto',
        square: "false",
        borderRadius: '0px 0px 0px 0px',
        alignItems: "flex-start",
        height: '50px',
        textDecorationLine: '',
        width: '100px',
        backgroundColor: '#E4EBEF',
    },
    dataRow: {
        padding: '0',
    },
    cardImg: {
        width: "82px",
        height: "43px",
        margin: '0',
        padding: 0,
    },
    container: {
        height: '147px',
        widht: '100px',
        flexDirection: 'column',
    },
    cardText: {
        color: 'white',
    },
    paramsText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    valuesText: {
        fontSize: 12,
        fontWeight: 'regular',
    },
    paramsRow: {
        height: '22px',
        width: '21px',
    },
    valuesRow: {
        height: '22px',
        width: '63px',
    },
    table: {
        minWidth: '100px',
        maxWidth: '100px',
        padding: '0px',
        margin: '0px',
    },
    tableCell: {
        padding: 'none',
        fontSize: '12px'
    }
}

export default function EnchufeToolTip({enchufe, rotado, setTopBarName, configuraciones, nombreEnchufe}) {
    const theme = useTheme();
    const color = {
        'operativo': '#529454',
        'peligro': '#F8991E',
        'peligro_maximo': '#B51111',
        'desactivado': 'rgba(86,93,96,0.85)',
        'antiguo': '#bfa982'
    }
    const colorClaro = {
        'operativo': '#E4FCE5',
        'peligro': '#FFEED8',
        'peligro_maximo': '#FFDFDF',
        'desactivado': '#c9cccd',
        'antiguo': '#bfa982'
    }
    const [colorHumedad, setColorHumedad] = useState('desactivado')
    const [colorAB, setColorAB] = useState('desactivado')
    const [colorBC, setColorBC] = useState('desactivado')
    const [colorCA, setColorCA] = useState('desactivado')
    const [colorVR, setColorVR] = useState('desactivado')
    const [colorVS, setColorVS] = useState('desactivado')
    const [colorVT, setColorVT] = useState('desactivado')
    const [colortemp_exterior, setColortemp_exterior] = useState('desactivado')
    const configureColorHumedad = function () {
        if (configuraciones !== null && enchufe.medicionHumedad !== null) {
            if (enchufe.medicionHumedad >= configuraciones.umbral_diferencia_humedad_critico) {
                setColorHumedad('peligro_maximo')
            } else if (enchufe.medicionHumedad >= configuraciones.umbral_diferencia_humedad_peligro) {
                setColorHumedad('peligro')
            } else {
                setColorHumedad('operativo')
            }
        } else {
            setColorHumedad('desactivado')
        }
    }
    const configureColorT = function () {
        if (configuraciones !== null && enchufe.temp_interior_ab !== null) {
            if (enchufe.temperaturaAntigua) {
                return 'antiguo'
            }
            if (enchufe.temp_interior_ab >= configuraciones.umbral_diferencia_tinterior_critico) {
                setColorAB('peligro_maximo')
            } else if (enchufe.temp_interior_ab >= configuraciones.umbral_diferencia_tinterior_peligro) {
                setColorAB('peligro')
            } else {
                setColorAB('operativo')
            }
        } else {
            setColorAB('desactivado')
        }
        if (configuraciones !== null && enchufe.temp_interior_bc !== null) {
            if (enchufe.temperaturaAntigua) {
                return 'antiguo'
            }
            if (enchufe.temp_interior_bc >= configuraciones.umbral_diferencia_tinterior_critico) {
                setColorBC('peligro_maximo')
            } else if (enchufe.temp_interior_bc >= configuraciones.umbral_diferencia_tinterior_peligro) {
                setColorBC('peligro')
            } else {
                setColorBC('operativo')
            }
        } else {
            setColorBC('desactivado')
        }
        if (configuraciones !== null && enchufe.temp_interior_ca !== null) {
            if (enchufe.temperaturaAntigua) {
                return 'antiguo'
            }
            if (enchufe.temp_interior_ca >= configuraciones.umbral_diferencia_tinterior_critico) {
                setColorCA('peligro_maximo')
            } else if (enchufe.temp_interior_ca >= configuraciones.umbral_diferencia_tinterior_peligro) {
                setColorCA('peligro')
            } else {
                setColorCA('operativo')
            }
        } else {
            setColorCA('desactivado')
        }

        if (configuraciones !== null && enchufe.temp_exterior !== null) {
            if (enchufe.temperaturaAntigua) {
                return 'antiguo'
            }
            setColortemp_exterior('operativo')
        } else {
            setColortemp_exterior('desactivado')
        }

    }
    const configureColorVR = function () {
        if (configuraciones !== null && enchufe.voltaje_ab !== null) {
            if (enchufe.voltajeAntiguo) {
                return 'antiguo'
            }
            if (enchufe.voltaje_ab <= configuraciones.umbral_diferencia_voltaje_critico) {
                setColorVR('peligro_maximo')
            } else if (enchufe.voltaje_ab <= configuraciones.umbral_diferencia_voltaje_peligro) {
                setColorVR('peligro')
            } else {
                setColorVR('operativo')
            }
        } else {
            setColorVR('desactivado')
        }
    }
    const configureColorVS = function () {
        if (configuraciones !== null && enchufe.voltaje_bc !== null) {
            if (enchufe.voltajeAntiguo) {
                return 'antiguo'
            }
            if (enchufe.voltaje_bc <= configuraciones.umbral_diferencia_voltaje_critico) {
                setColorVS('peligro_maximo')
            } else if (enchufe.voltaje_bc <= configuraciones.umbral_diferencia_voltaje_peligro) {
                setColorVS('peligro')
            } else {
                setColorVS('operativo')
            }
        } else {
            setColorVS('desactivado')
        }
    }
    const configureColorVT = function () {
        if (configuraciones !== null && enchufe.voltaje_ca !== null) {
            if (enchufe.voltajeAntiguo) {
                return 'antiguo'
            }
            if (enchufe.voltaje_ca <= configuraciones.umbral_diferencia_voltaje_critico) {
                setColorVT('peligro_maximo')
            } else if (enchufe.voltaje_ca <= configuraciones.umbral_diferencia_voltaje_peligro) {
                setColorVT('peligro')
            } else {
                setColorVT('operativo')
            }
        } else {
            setColorVT('desactivado')
        }
    }
    const ColorEnchufe = function (colorAB, colorBC, colorCA, colortemp_exterior, colorVR, colorVS, colorVT) {
        if (colorAB === 'peligro_maximo' || colorBC === 'peligro_maximo' || colorCA === 'peligro_maximo' || colorVR === 'peligro_maximo' || colorVS === 'peligro_maximo' || colorVT === 'peligro_maximo') {
            return ('peligro_maximo');
        }
        if (colorAB === 'peligro' || colorBC === 'peligro' || colorCA === 'peligro' || colorVR === 'peligro' || colorVS === 'peligro' || colorVT === 'peligro') {
            return ('peligro')
        }
        if (colorAB === 'desactivado' && colorBC === 'desactivado' && colorCA === 'desactivado' && colorVR === 'desactivado' && colorVS === 'desactivado' && colorVT === 'desactivado') {
            return ('desactivado')
        }
        if (colorAB === 'antiguo' && colorBC === 'antiguo' && colorCA === 'antiguo' && colorVR === 'antiguo' && colorVS === 'antiguo' && colorVT === 'antiguo') {
            return ('desactivado')
        }
        return 'operativo'
    }

    useEffect(
        () => {
            configureColorHumedad();
            configureColorT();
            configureColorVR();
            configureColorVS();
            configureColorVT();

        }
        , [configuraciones, enchufe])
    return (
        <Tooltip sx={{
            backgroundColor: '#E4EBEF',
            width: '130px',
            fontSize: theme.typography.pxToRem(12),
            height: '160px',
        }} title={
            <React.Fragment>
                <Box sx={styles.container} display='flex' flex={1}>
                    <TableContainer>
                        <Table sx={styles.table} size="small" aria-label="a dense table">
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorVR]}}>
                                        Vab:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorVR]}}
                                               align="left">{enchufe.voltaje_ab ? enchufe.voltaje_ab.toFixed(1) : '-'} kV</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorVS]}}>
                                        Vbc:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorVS]}}
                                               align="left">{enchufe.voltaje_bc ? enchufe.voltaje_bc.toFixed(1) : '-'} kV</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorVT]}}>
                                        Vca:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorVT]}}
                                               align="left">{enchufe.voltaje_ca ? enchufe.voltaje_ca.toFixed(1) : '-'} kV</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorAB]}}>
                                        Tab°.:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorAB]}}
                                               align="left">{enchufe.temp_interior_ab !== null ? Math.round(enchufe.temp_interior_ab) : '- '}ºC</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorBC]}}>
                                        Tbc°:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorBC]}}
                                               align="left">{enchufe.temp_interior_bc !== null ? Math.round(enchufe.temp_interior_bc) : '- '}ºC</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colorCA]}}>
                                        Tca°:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colorCA]}}
                                               align="left">{enchufe.temp_interior_ca !== null ? Math.round(enchufe.temp_interior_ca) : '- '}ºC</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row" padding='none' align="center"
                                               style={{backgroundColor: colorClaro[colortemp_exterior]}}>
                                        TExt°:
                                    </TableCell>
                                    <TableCell sx={styles.tableCell} padding='none'
                                               style={{backgroundColor: colorClaro[colortemp_exterior]}}
                                               align="left">{enchufe.temp_exterior !== null ? Math.round(enchufe.temp_exterior) : '- '}°C</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </React.Fragment>
        }>
            <ButtonBase onClick={setTopBarName}>
                <Paper sx={styles.paper} elevation={0}>
                    <IconConexion
                        fill_color={color[ColorEnchufe(colorAB, colorBC, colorCA, colortemp_exterior, colorVR, colorVS, colorVT)]}
                        style={styles.cardImg} rotado={rotado} text={nombreEnchufe}/>
                </Paper>
            </ButtonBase>
        </Tooltip>

    );
}

EnchufeToolTip.propTypes = {
    enchufe: PropTypes.object.isRequired,
    rotado: PropTypes.bool,
}
