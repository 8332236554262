import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField, FormControl, InputLabel, Menu } from "@mui/material";

const EditCableModal = ({
    dataEdit,
    setDataEdit,
    isOpen,
    onClose,
    eclamps,
    handleEdit,
    allEclamps
}) => {

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Editar Cable</DialogTitle>
            <DialogContent>
                <TextField
                    label="Código Cliente"
                    value={dataEdit.codigoClienteEdit}
                    onChange={(e) => setDataEdit.setCodigoClienteEdit(e.target.value)}
                    fullWidth
                    required
                    margin="dense"
                    disabled={true}
                />
                <TextField
                    type="number"
                    label="Largo"
                    value={dataEdit.largoEdit}
                    onChange={(e) => setDataEdit.setLargoEdit(Number(e.target.value))}
                    fullWidth
                    margin="dense"
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="enchufeh-label">Eclamp Hembra</InputLabel>
                    <Select
                        labelId="enchufeh-label"
                        value={dataEdit.eclampHembraEdit}
                        onChange={(e) => setDataEdit.setEclampHembraEdit(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {dataEdit.eclampHembraEdit ? <MenuItem value={dataEdit.eclampHembraEdit}>{allEclamps.find(eclamp => eclamp.id === dataEdit.eclampHembraEdit).serial_number}</MenuItem> : null}
                        {eclamps.filter(eclamp => eclamp.id !== dataEdit.eclampMachoEdit).map((eclamp, index) => (
                            <MenuItem value={eclamp.id} key={index}>{eclamp.serial_number}</MenuItem>
                        ))}
                        <MenuItem value={''}>Ninguno</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="enchufem-label">Eclamp Macho</InputLabel>
                    <Select
                        labelId="enchufem-label"
                        value={dataEdit.eclampMachoEdit}
                        onChange={(e) => setDataEdit.setEclampMachoEdit(e.target.value)}
                        fullWidth
                        margin="dense"
                    >
                        {dataEdit.eclampMachoEdit ? <MenuItem value={dataEdit.eclampMachoEdit}>{allEclamps.find(eclamp => eclamp.id === dataEdit.eclampMachoEdit).serial_number}</MenuItem> : null}
                        {eclamps.filter(eclamp => eclamp.id !== dataEdit.eclampHembraEdit).map((eclamp, index) => (
                            <MenuItem value={eclamp.id} key={index}>{eclamp.serial_number}</MenuItem>
                        ))}
                        <MenuItem value={''}>Ninguno</MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleEdit} color="primary">
                    Editar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditCableModal;