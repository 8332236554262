export const singleLineDiagramInventoryMock = {
    "store": {
        "substations": 9,
        "splitters": 20,
        "cables": 268,
        "loads": 20
    },
    "version": {
        "id": 69,
        "user_id": 15,
        "created_at": "2023-06-07T17:12:16.292265-04:00",
        "url_archivo": "/media/versiones_diagrama_unilineal/1686172336.json"
    },
    "substations": {
        "ROLEC N1": {
            "nombre": "ROLEC N1",
            "zona_id": "Rosario",
            "inventario_id": 289,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 01 CB-A": {
                    "nombre": "SE 01 CB-A",
                    "subestacion_id": "ROLEC N1",
                    "cable_salida_id": "672",
                    "inventario_id": 298
                },
                "SE 01 CB-B": {
                    "nombre": "SE 01 CB-B",
                    "subestacion_id": "ROLEC N1",
                    "cable_salida_id": "525",
                    "inventario_id": 299
                },
                "SE 01 CB-C": {
                    "nombre": "SE 01 CB-C",
                    "subestacion_id": "ROLEC N1",
                    "cable_salida_id": "246",
                    "inventario_id": 300
                },
                "SE 01 CB-D": {
                    "nombre": "SE 01 CB-D",
                    "subestacion_id": "ROLEC N1",
                    "cable_salida_id": "215",
                    "inventario_id": 301
                }
            },
            "id": "substation_ROLEC N1"
        },
        "ROLEC N2": {
            "nombre": "ROLEC N2",
            "zona_id": "Rosario",
            "inventario_id": 290,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 02 CB-A": {
                    "nombre": "SE 02 CB-A",
                    "subestacion_id": "ROLEC N2",
                    "cable_salida_id": "297",
                    "inventario_id": 302
                },
                "SE 02 CB-B": {
                    "nombre": "SE 02 CB-B",
                    "subestacion_id": "ROLEC N2",
                    "cable_salida_id": "289",
                    "inventario_id": 303
                },
                "SE 02 CB-C": {
                    "nombre": "SE 02 CB-C",
                    "subestacion_id": "ROLEC N2",
                    "cable_salida_id": "678",
                    "inventario_id": 304
                },
                "SE 02 CB-D": {
                    "nombre": "SE 02 CB-D",
                    "subestacion_id": "ROLEC N2",
                    "cable_salida_id": "CH-79",
                    "inventario_id": 305
                }
            },
            "id": "substation_ROLEC N2"
        },
        "ROLEC N3": {
            "nombre": "ROLEC N3",
            "zona_id": "Rosario",
            "inventario_id": 291,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 03 CB-A": {
                    "nombre": "SE 03 CB-A",
                    "subestacion_id": "ROLEC N3",
                    "cable_salida_id": "712",
                    "inventario_id": 306
                },
                "SE 03 CB-B": {
                    "nombre": "SE 03 CB-B",
                    "subestacion_id": "ROLEC N3",
                    "cable_salida_id": "558",
                    "inventario_id": 307
                }
            },
            "id": "substation_ROLEC N3"
        },
        "ROLEC N4": {
            "nombre": "ROLEC N4",
            "zona_id": "Rosario",
            "inventario_id": 292,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 04 CB-A": {
                    "nombre": "SE 04 CB-A",
                    "subestacion_id": "ROLEC N4",
                    "cable_salida_id": null,
                    "inventario_id": 308
                },
                "SE 04 CB-B": {
                    "nombre": "SE 04 CB-B",
                    "subestacion_id": "ROLEC N4",
                    "cable_salida_id": null,
                    "inventario_id": 309
                }
            },
            "id": "substation_ROLEC N4"
        },
        "ROLEC N5": {
            "nombre": "ROLEC N5",
            "zona_id": "Rosario",
            "inventario_id": 293,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 05 CB-A": {
                    "nombre": "SE 05 CB-A",
                    "subestacion_id": "ROLEC N5",
                    "cable_salida_id": "CH-31",
                    "inventario_id": 310
                },
                "SE 05 CB-B": {
                    "nombre": "SE 05 CB-B",
                    "subestacion_id": "ROLEC N5",
                    "cable_salida_id": "CH-45",
                    "inventario_id": 311
                }
            },
            "id": "substation_ROLEC N5"
        },
        "ROLEC N8": {
            "nombre": "ROLEC N8",
            "zona_id": "Rosario",
            "inventario_id": 294,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 08 CB-A": {
                    "nombre": "SE 08 CB-A",
                    "subestacion_id": "ROLEC N8",
                    "cable_salida_id": "645",
                    "inventario_id": 318
                },
                "SE 08 CB-B": {
                    "nombre": "SE 08 CB-B",
                    "subestacion_id": "ROLEC N8",
                    "cable_salida_id": null,
                    "inventario_id": 319
                },
                "SE 08CB-C": {
                    "nombre": "SE 08CB-C",
                    "subestacion_id": "ROLEC N8",
                    "cable_salida_id": null,
                    "inventario_id": 320
                }
            },
            "id": "substation_ROLEC N8"
        },
        "RONA N6": {
            "nombre": "RONA N6",
            "zona_id": "Rosario",
            "inventario_id": 295,
            "type": "substation",
            "disponible": false,
            "cubiculos": {
                "SE 06 CB-A": {
                    "nombre": "SE 06 CB-A",
                    "subestacion_id": "RONA N6",
                    "cable_salida_id": "402",
                    "inventario_id": 312
                },
                "SE 06 CB-B": {
                    "nombre": "SE 06 CB-B",
                    "subestacion_id": "RONA N6",
                    "cable_salida_id": "611",
                    "inventario_id": 313
                },
                "SE 06 CB-C": {
                    "nombre": "SE 06 CB-C",
                    "subestacion_id": "RONA N6",
                    "cable_salida_id": null,
                    "inventario_id": 314
                }
            },
            "id": "substation_RONA N6"
        },
        "RONA N7": {
            "nombre": "RONA N7",
            "zona_id": "Rosario",
            "inventario_id": 296,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 07 CB-B": {
                    "nombre": "SE 07 CB-B",
                    "subestacion_id": "RONA N7",
                    "cable_salida_id": "482",
                    "inventario_id": 315
                },
                "SE 07 CB-C": {
                    "nombre": "SE 07 CB-C",
                    "subestacion_id": "RONA N7",
                    "cable_salida_id": null,
                    "inventario_id": 316
                },
                "SE 07CB-A": {
                    "nombre": "SE 07CB-A",
                    "subestacion_id": "RONA N7",
                    "cable_salida_id": "522",
                    "inventario_id": 317
                }
            },
            "id": "substation_RONA N7"
        },
        "RONA N9": {
            "nombre": "RONA N9",
            "zona_id": "Rosario",
            "inventario_id": 297,
            "type": "substation",
            "disponible": true,
            "cubiculos": {
                "SE 09 CB-A": {
                    "nombre": "SE 09 CB-A",
                    "subestacion_id": "RONA N9",
                    "cable_salida_id": "411",
                    "inventario_id": 321
                },
                "SE 09 CB-B": {
                    "nombre": "SE 09 CB-B",
                    "subestacion_id": "RONA N9",
                    "cable_salida_id": "499",
                    "inventario_id": 322
                },
                "SE 09 CB-C": {
                    "nombre": "SE 09 CB-C",
                    "subestacion_id": "RONA N9",
                    "cable_salida_id": "480",
                    "inventario_id": 323
                }
            },
            "id": "substation_RONA N9"
        }
    },
    "splitters": {
        "SPL 08": {
            "codigo_cliente": "SPL 08",
            "cable_entrada_id": "579",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 324,
            "nombre": "SPL 08",
            "type": "splitter",
            "disponible": false,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL 08"
        },
        "SPL-02": {
            "codigo_cliente": "SPL-02",
            "cable_entrada_id": "403",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 325,
            "nombre": "SPL-02",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-02"
        },
        "SPL-03": {
            "codigo_cliente": "SPL-03",
            "cable_entrada_id": "628",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 326,
            "nombre": "SPL-03",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-03"
        },
        "SPL-05": {
            "codigo_cliente": "SPL-05",
            "cable_entrada_id": "641",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 327,
            "nombre": "SPL-05",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-05"
        },
        "SPL-10": {
            "codigo_cliente": "SPL-10",
            "cable_entrada_id": "468",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 328,
            "nombre": "SPL-10",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-10"
        },
        "SPL-11": {
            "codigo_cliente": "SPL-11",
            "cable_entrada_id": "473",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 329,
            "nombre": "SPL-11",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-11"
        },
        "SPL-14": {
            "codigo_cliente": "SPL-14",
            "cable_entrada_id": "551",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 330,
            "nombre": "SPL-14",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-14"
        },
        "SPL-15": {
            "codigo_cliente": "SPL-15",
            "cable_entrada_id": "563",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 331,
            "nombre": "SPL-15",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-15"
        },
        "SPL-16": {
            "codigo_cliente": "SPL-16",
            "cable_entrada_id": "401",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 332,
            "nombre": "SPL-16",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-16"
        },
        "SPL-17": {
            "codigo_cliente": "SPL-17",
            "cable_entrada_id": "506",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 333,
            "nombre": "SPL-17",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-17"
        },
        "SPL-19": {
            "codigo_cliente": "SPL-19",
            "cable_entrada_id": "594",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 334,
            "nombre": "SPL-19",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-19"
        },
        "SPL-21": {
            "codigo_cliente": "SPL-21",
            "cable_entrada_id": "455",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 335,
            "nombre": "SPL-21",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-21"
        },
        "SPL-22": {
            "codigo_cliente": "SPL-22",
            "cable_entrada_id": "385",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 336,
            "nombre": "SPL-22",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-22"
        },
        "SPL-23": {
            "codigo_cliente": "SPL-23",
            "cable_entrada_id": "458",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 337,
            "nombre": "SPL-23",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-23"
        },
        "SPL-26": {
            "codigo_cliente": "SPL-26",
            "cable_entrada_id": "574",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 338,
            "nombre": "SPL-26",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-26"
        },
        "SPL-27": {
            "codigo_cliente": "SPL-27",
            "cable_entrada_id": "598",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 339,
            "nombre": "SPL-27",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-27"
        },
        "SPL-28": {
            "codigo_cliente": "SPL-28",
            "cable_entrada_id": "615",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 340,
            "nombre": "SPL-28",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-28"
        },
        "SPL-30": {
            "codigo_cliente": "SPL-30",
            "cable_entrada_id": "CH-45",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 341,
            "nombre": "SPL-30",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-30"
        },
        "SPL-33": {
            "codigo_cliente": "SPL-33",
            "cable_entrada_id": "633",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 342,
            "nombre": "SPL-33",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-33"
        },
        "SPL-34": {
            "codigo_cliente": "SPL-34",
            "cable_entrada_id": "651",
            "modelo_id": null,
            "cubiculo_id": null,
            "en_uso": false,
            "inventario_id": 343,
            "nombre": "SPL-34",
            "type": "splitter",
            "disponible": true,
            "splitter_salidas": [
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                },
                {
                    "cable_salida_id": null
                }
            ],
            "is_energized": false,
            "id": "splitter_SPL-34"
        }
    },
    "loads": {
        "1": {
            "id": "load_1",
            "nombre": "PA-13",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "671",
            "inventario_id": 1,
            "type": "load",
            "disponible": false,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "2": {
            "id": "load_2",
            "nombre": "PO-24",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "576",
            "inventario_id": 2,
            "type": "load",
            "disponible": false,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "3": {
            "id": "load_3",
            "nombre": "MDS-08-08",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "694",
            "inventario_id": 3,
            "type": "load",
            "disponible": false,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "4": {
            "id": "load_4",
            "nombre": "PA-11",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": null,
            "inventario_id": 4,
            "type": "load",
            "disponible": false,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "5": {
            "id": "load_5",
            "nombre": "PA-06",
            "activo": true,
            "zona_id": "Ujina",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "676",
            "inventario_id": 5,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "6": {
            "id": "load_6",
            "nombre": "PA-11",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "696",
            "inventario_id": 6,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "7": {
            "id": "load_7",
            "nombre": "PO-23",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "575",
            "inventario_id": 7,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "8": {
            "id": "load_8",
            "nombre": "PA-12",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "648",
            "inventario_id": 8,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "9": {
            "id": "load_9",
            "nombre": "PA-10",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "670",
            "inventario_id": 9,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "10": {
            "id": "load_10",
            "nombre": "PO-12",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "450",
            "inventario_id": 10,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "11": {
            "id": "load_11",
            "nombre": "PA-22",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "654",
            "inventario_id": 11,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "12": {
            "id": "load_12",
            "nombre": "PO-15",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "538",
            "inventario_id": 12,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "13": {
            "id": "load_13",
            "nombre": "PA-04",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "599",
            "inventario_id": 13,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "14": {
            "id": "load_14",
            "nombre": "PO-07",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "445",
            "inventario_id": 14,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "15": {
            "id": "load_15",
            "nombre": "PA-09",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "680",
            "inventario_id": 15,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "16": {
            "id": "load_16",
            "nombre": "PA-14",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "673",
            "inventario_id": 16,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "17": {
            "id": "load_17",
            "nombre": "PO-19",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "568",
            "inventario_id": 17,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "18": {
            "id": "load_18",
            "nombre": "PO-01",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "480",
            "inventario_id": 18,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "19": {
            "id": "load_19",
            "nombre": "PA-21",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "669",
            "inventario_id": 19,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        },
        "20": {
            "id": "load_20",
            "nombre": "PA-25",
            "activo": true,
            "zona_id": "Rosario",
            "tipo_equipo": "Pala",
            "estado": "operativo",
            "cubiculo_id": null,
            "cable_entrada_id": "647",
            "inventario_id": 20,
            "type": "load",
            "disponible": true,
            "voltage_difference_alarm": {},
            "is_energized": false
        }
    },
    "cables": {
        "127": {
            "cable_siguiente": null,
            "codigo_cliente": "127",
            "cubiculo": null,
            "enchufe_hembra": 159,
            "enchufe_macho": 160,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 23,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": false,
            "voltage_alarms": {
                "voltaje_ab": "ok",
                "voltaje_bc": "ok",
                "voltaje_ca": "ok"
            },
            "type": "cable",
            "id": "cable_127"
        },
        "133": {
            "cable_siguiente": null,
            "codigo_cliente": "133",
            "cubiculo": null,
            "enchufe_hembra": 277,
            "enchufe_macho": 278,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 24,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_133"
        },
        "135": {
            "cable_siguiente": null,
            "codigo_cliente": "135",
            "cubiculo": null,
            "enchufe_hembra": 153,
            "enchufe_macho": 154,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 25,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_135"
        },
        "210": {
            "cable_siguiente": null,
            "codigo_cliente": "210",
            "cubiculo": null,
            "enchufe_hembra": 497,
            "enchufe_macho": 498,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 26,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_210"
        },
        "213": {
            "cable_siguiente": null,
            "codigo_cliente": "213",
            "cubiculo": null,
            "enchufe_hembra": 229,
            "enchufe_macho": 230,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 27,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_213"
        },
        "215": {
            "cable_siguiente": null,
            "codigo_cliente": "215",
            "cubiculo": null,
            "enchufe_hembra": 25,
            "enchufe_macho": 26,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 28,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_215"
        },
        "216": {
            "cable_siguiente": null,
            "codigo_cliente": "216",
            "cubiculo": null,
            "enchufe_hembra": 83,
            "enchufe_macho": 84,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 29,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_216"
        },
        "219": {
            "cable_siguiente": null,
            "codigo_cliente": "219",
            "cubiculo": null,
            "enchufe_hembra": 201,
            "enchufe_macho": 202,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 30,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": "ok",
                "voltaje_bc": "yellow-alarm",
                "voltaje_ca": "red-alarm"
            },
            "type": "cable",
            "id": "cable_219"
        },
        "220": {
            "cable_siguiente": null,
            "codigo_cliente": "220",
            "cubiculo": null,
            "enchufe_hembra": 345,
            "enchufe_macho": 346,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 31,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_220"
        },
        "226": {
            "cable_siguiente": null,
            "codigo_cliente": "226",
            "cubiculo": null,
            "enchufe_hembra": 443,
            "enchufe_macho": 444,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 32,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_226"
        },
        "228": {
            "cable_siguiente": null,
            "codigo_cliente": "228",
            "cubiculo": null,
            "enchufe_hembra": 351,
            "enchufe_macho": 352,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 33,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_228"
        },
        "234": {
            "cable_siguiente": null,
            "codigo_cliente": "234",
            "cubiculo": null,
            "enchufe_hembra": 231,
            "enchufe_macho": 232,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 34,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_234"
        },
        "239": {
            "cable_siguiente": null,
            "codigo_cliente": "239",
            "cubiculo": null,
            "enchufe_hembra": 261,
            "enchufe_macho": 262,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 35,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_239"
        },
        "246": {
            "cable_siguiente": null,
            "codigo_cliente": "246",
            "cubiculo": null,
            "enchufe_hembra": 23,
            "enchufe_macho": 24,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 36,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_246"
        },
        "253": {
            "cable_siguiente": null,
            "codigo_cliente": "253",
            "cubiculo": null,
            "enchufe_hembra": 467,
            "enchufe_macho": 468,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 37,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_253"
        },
        "263": {
            "cable_siguiente": null,
            "codigo_cliente": "263",
            "cubiculo": null,
            "enchufe_hembra": 127,
            "enchufe_macho": 128,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 38,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_263"
        },
        "265": {
            "cable_siguiente": null,
            "codigo_cliente": "265",
            "cubiculo": null,
            "enchufe_hembra": 111,
            "enchufe_macho": 112,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 39,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_265"
        },
        "270": {
            "cable_siguiente": null,
            "codigo_cliente": "270",
            "cubiculo": null,
            "enchufe_hembra": 99,
            "enchufe_macho": 100,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 40,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_270"
        },
        "284": {
            "cable_siguiente": null,
            "codigo_cliente": "284",
            "cubiculo": null,
            "enchufe_hembra": 63,
            "enchufe_macho": 64,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 41,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_284"
        },
        "285": {
            "cable_siguiente": null,
            "codigo_cliente": "285",
            "cubiculo": null,
            "enchufe_hembra": 215,
            "enchufe_macho": 216,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 42,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_285"
        },
        "286": {
            "cable_siguiente": null,
            "codigo_cliente": "286",
            "cubiculo": null,
            "enchufe_hembra": 271,
            "enchufe_macho": 272,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 43,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_286"
        },
        "288": {
            "cable_siguiente": null,
            "codigo_cliente": "288",
            "cubiculo": null,
            "enchufe_hembra": 425,
            "enchufe_macho": 426,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 44,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_288"
        },
        "289": {
            "cable_siguiente": null,
            "codigo_cliente": "289",
            "cubiculo": null,
            "enchufe_hembra": 165,
            "enchufe_macho": 166,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 45,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_289"
        },
        "296": {
            "cable_siguiente": null,
            "codigo_cliente": "296",
            "cubiculo": null,
            "enchufe_hembra": 353,
            "enchufe_macho": 354,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 46,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_296"
        },
        "297": {
            "cable_siguiente": null,
            "codigo_cliente": "297",
            "cubiculo": null,
            "enchufe_hembra": 57,
            "enchufe_macho": 58,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 47,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_297"
        },
        "298": {
            "cable_siguiente": null,
            "codigo_cliente": "298",
            "cubiculo": null,
            "enchufe_hembra": 161,
            "enchufe_macho": 162,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 48,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_298"
        },
        "309": {
            "cable_siguiente": null,
            "codigo_cliente": "309",
            "cubiculo": null,
            "enchufe_hembra": 69,
            "enchufe_macho": 70,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 49,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_309"
        },
        "313": {
            "cable_siguiente": null,
            "codigo_cliente": "313",
            "cubiculo": null,
            "enchufe_hembra": 343,
            "enchufe_macho": 344,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 50,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_313"
        },
        "319": {
            "cable_siguiente": null,
            "codigo_cliente": "319",
            "cubiculo": null,
            "enchufe_hembra": 419,
            "enchufe_macho": 420,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 51,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_319"
        },
        "325": {
            "cable_siguiente": null,
            "codigo_cliente": "325",
            "cubiculo": null,
            "enchufe_hembra": 131,
            "enchufe_macho": 132,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 52,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_325"
        },
        "329": {
            "cable_siguiente": null,
            "codigo_cliente": "329",
            "cubiculo": null,
            "enchufe_hembra": 7,
            "enchufe_macho": 8,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 53,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_329"
        },
        "330": {
            "cable_siguiente": null,
            "codigo_cliente": "330",
            "cubiculo": null,
            "enchufe_hembra": 285,
            "enchufe_macho": 286,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 54,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_330"
        },
        "333": {
            "cable_siguiente": null,
            "codigo_cliente": "333",
            "cubiculo": null,
            "enchufe_hembra": 147,
            "enchufe_macho": 148,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 55,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_333"
        },
        "334": {
            "cable_siguiente": null,
            "codigo_cliente": "334",
            "cubiculo": null,
            "enchufe_hembra": 195,
            "enchufe_macho": 196,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 56,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_334"
        },
        "341": {
            "cable_siguiente": null,
            "codigo_cliente": "341",
            "cubiculo": null,
            "enchufe_hembra": 129,
            "enchufe_macho": 130,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 57,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_341"
        },
        "344": {
            "cable_siguiente": null,
            "codigo_cliente": "344",
            "cubiculo": null,
            "enchufe_hembra": 169,
            "enchufe_macho": 170,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 58,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_344"
        },
        "350": {
            "cable_siguiente": null,
            "codigo_cliente": "350",
            "cubiculo": null,
            "enchufe_hembra": 123,
            "enchufe_macho": 124,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 59,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_350"
        },
        "353": {
            "cable_siguiente": null,
            "codigo_cliente": "353",
            "cubiculo": null,
            "enchufe_hembra": 415,
            "enchufe_macho": 416,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 60,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_353"
        },
        "354": {
            "cable_siguiente": null,
            "codigo_cliente": "354",
            "cubiculo": null,
            "enchufe_hembra": 387,
            "enchufe_macho": 388,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 61,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_354"
        },
        "355": {
            "cable_siguiente": null,
            "codigo_cliente": "355",
            "cubiculo": null,
            "enchufe_hembra": 509,
            "enchufe_macho": 510,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 62,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_355"
        },
        "358": {
            "cable_siguiente": null,
            "codigo_cliente": "358",
            "cubiculo": null,
            "enchufe_hembra": 349,
            "enchufe_macho": 350,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 63,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_358"
        },
        "360": {
            "cable_siguiente": null,
            "codigo_cliente": "360",
            "cubiculo": null,
            "enchufe_hembra": 403,
            "enchufe_macho": 404,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 64,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_360"
        },
        "366": {
            "cable_siguiente": null,
            "codigo_cliente": "366",
            "cubiculo": null,
            "enchufe_hembra": 319,
            "enchufe_macho": 320,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 65,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_366"
        },
        "367": {
            "cable_siguiente": null,
            "codigo_cliente": "367",
            "cubiculo": null,
            "enchufe_hembra": 149,
            "enchufe_macho": 150,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 66,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_367"
        },
        "368": {
            "cable_siguiente": null,
            "codigo_cliente": "368",
            "cubiculo": null,
            "enchufe_hembra": 145,
            "enchufe_macho": 146,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 67,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_368"
        },
        "369": {
            "cable_siguiente": null,
            "codigo_cliente": "369",
            "cubiculo": null,
            "enchufe_hembra": 219,
            "enchufe_macho": 220,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 68,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_369"
        },
        "371": {
            "cable_siguiente": null,
            "codigo_cliente": "371",
            "cubiculo": null,
            "enchufe_hembra": 45,
            "enchufe_macho": 46,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 69,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_371"
        },
        "375": {
            "cable_siguiente": null,
            "codigo_cliente": "375",
            "cubiculo": null,
            "enchufe_hembra": 303,
            "enchufe_macho": 304,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 70,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_375"
        },
        "377": {
            "cable_siguiente": null,
            "codigo_cliente": "377",
            "cubiculo": null,
            "enchufe_hembra": 399,
            "enchufe_macho": 400,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 71,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_377"
        },
        "380": {
            "cable_siguiente": null,
            "codigo_cliente": "380",
            "cubiculo": null,
            "enchufe_hembra": 477,
            "enchufe_macho": 478,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 72,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_380"
        },
        "382": {
            "cable_siguiente": null,
            "codigo_cliente": "382",
            "cubiculo": null,
            "enchufe_hembra": 47,
            "enchufe_macho": 48,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 73,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_382"
        },
        "385": {
            "cable_siguiente": null,
            "codigo_cliente": "385",
            "cubiculo": null,
            "enchufe_hembra": 125,
            "enchufe_macho": 126,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 74,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_385"
        },
        "387": {
            "cable_siguiente": null,
            "codigo_cliente": "387",
            "cubiculo": null,
            "enchufe_hembra": 5,
            "enchufe_macho": 6,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 75,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_387"
        },
        "389": {
            "cable_siguiente": null,
            "codigo_cliente": "389",
            "cubiculo": null,
            "enchufe_hembra": 519,
            "enchufe_macho": 520,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 76,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_389"
        },
        "390": {
            "cable_siguiente": null,
            "codigo_cliente": "390",
            "cubiculo": null,
            "enchufe_hembra": 357,
            "enchufe_macho": 358,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 77,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_390"
        },
        "396": {
            "cable_siguiente": null,
            "codigo_cliente": "396",
            "cubiculo": null,
            "enchufe_hembra": 367,
            "enchufe_macho": 368,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 78,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_396"
        },
        "401": {
            "cable_siguiente": null,
            "codigo_cliente": "401",
            "cubiculo": null,
            "enchufe_hembra": 237,
            "enchufe_macho": 238,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 79,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_401"
        },
        "402": {
            "cable_siguiente": null,
            "codigo_cliente": "402",
            "cubiculo": null,
            "enchufe_hembra": 273,
            "enchufe_macho": 274,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 80,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_402"
        },
        "403": {
            "cable_siguiente": null,
            "codigo_cliente": "403",
            "cubiculo": null,
            "enchufe_hembra": 135,
            "enchufe_macho": 136,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 81,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_403"
        },
        "404": {
            "cable_siguiente": null,
            "codigo_cliente": "404",
            "cubiculo": null,
            "enchufe_hembra": 133,
            "enchufe_macho": 134,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 82,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_404"
        },
        "405": {
            "cable_siguiente": null,
            "codigo_cliente": "405",
            "cubiculo": null,
            "enchufe_hembra": 233,
            "enchufe_macho": 234,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 83,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_405"
        },
        "406": {
            "cable_siguiente": null,
            "codigo_cliente": "406",
            "cubiculo": null,
            "enchufe_hembra": 31,
            "enchufe_macho": 32,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 84,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_406"
        },
        "408": {
            "cable_siguiente": null,
            "codigo_cliente": "408",
            "cubiculo": null,
            "enchufe_hembra": 87,
            "enchufe_macho": 88,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 85,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_408"
        },
        "410": {
            "cable_siguiente": null,
            "codigo_cliente": "410",
            "cubiculo": null,
            "enchufe_hembra": 203,
            "enchufe_macho": 204,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 86,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_410"
        },
        "411": {
            "cable_siguiente": null,
            "codigo_cliente": "411",
            "cubiculo": null,
            "enchufe_hembra": 417,
            "enchufe_macho": 418,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 87,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_411"
        },
        "412": {
            "cable_siguiente": null,
            "codigo_cliente": "412",
            "cubiculo": null,
            "enchufe_hembra": 405,
            "enchufe_macho": 406,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 88,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_412"
        },
        "415": {
            "cable_siguiente": null,
            "codigo_cliente": "415",
            "cubiculo": null,
            "enchufe_hembra": 339,
            "enchufe_macho": 340,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 89,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_415"
        },
        "416": {
            "cable_siguiente": null,
            "codigo_cliente": "416",
            "cubiculo": null,
            "enchufe_hembra": 139,
            "enchufe_macho": 140,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 90,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_416"
        },
        "421": {
            "cable_siguiente": null,
            "codigo_cliente": "421",
            "cubiculo": null,
            "enchufe_hembra": 531,
            "enchufe_macho": 532,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 91,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_421"
        },
        "422": {
            "cable_siguiente": null,
            "codigo_cliente": "422",
            "cubiculo": null,
            "enchufe_hembra": 365,
            "enchufe_macho": 366,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 92,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_422"
        },
        "426": {
            "cable_siguiente": null,
            "codigo_cliente": "426",
            "cubiculo": null,
            "enchufe_hembra": 121,
            "enchufe_macho": 122,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 93,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_426"
        },
        "430": {
            "cable_siguiente": null,
            "codigo_cliente": "430",
            "cubiculo": null,
            "enchufe_hembra": 311,
            "enchufe_macho": 312,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 94,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_430"
        },
        "431": {
            "cable_siguiente": null,
            "codigo_cliente": "431",
            "cubiculo": null,
            "enchufe_hembra": 455,
            "enchufe_macho": 456,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 95,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_431"
        },
        "432": {
            "cable_siguiente": null,
            "codigo_cliente": "432",
            "cubiculo": null,
            "enchufe_hembra": 37,
            "enchufe_macho": 38,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 96,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_432"
        },
        "433": {
            "cable_siguiente": null,
            "codigo_cliente": "433",
            "cubiculo": null,
            "enchufe_hembra": 335,
            "enchufe_macho": 336,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 97,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_433"
        },
        "435": {
            "cable_siguiente": null,
            "codigo_cliente": "435",
            "cubiculo": null,
            "enchufe_hembra": 243,
            "enchufe_macho": 244,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 98,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_435"
        },
        "441": {
            "cable_siguiente": null,
            "codigo_cliente": "441",
            "cubiculo": null,
            "enchufe_hembra": 283,
            "enchufe_macho": 284,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 99,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_441"
        },
        "444": {
            "cable_siguiente": null,
            "codigo_cliente": "444",
            "cubiculo": null,
            "enchufe_hembra": 371,
            "enchufe_macho": 372,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 100,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_444"
        },
        "445": {
            "cable_siguiente": null,
            "codigo_cliente": "445",
            "cubiculo": null,
            "enchufe_hembra": 391,
            "enchufe_macho": 392,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 101,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_445"
        },
        "448": {
            "cable_siguiente": null,
            "codigo_cliente": "448",
            "cubiculo": null,
            "enchufe_hembra": 527,
            "enchufe_macho": 528,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 102,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_448"
        },
        "449": {
            "cable_siguiente": null,
            "codigo_cliente": "449",
            "cubiculo": null,
            "enchufe_hembra": 103,
            "enchufe_macho": 104,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 103,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_449"
        },
        "450": {
            "cable_siguiente": null,
            "codigo_cliente": "450",
            "cubiculo": null,
            "enchufe_hembra": 205,
            "enchufe_macho": 206,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 104,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_450"
        },
        "453": {
            "cable_siguiente": null,
            "codigo_cliente": "453",
            "cubiculo": null,
            "enchufe_hembra": 143,
            "enchufe_macho": 144,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 105,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_453"
        },
        "454": {
            "cable_siguiente": null,
            "codigo_cliente": "454",
            "cubiculo": null,
            "enchufe_hembra": 27,
            "enchufe_macho": 28,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 106,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_454"
        },
        "455": {
            "cable_siguiente": null,
            "codigo_cliente": "455",
            "cubiculo": null,
            "enchufe_hembra": 447,
            "enchufe_macho": 448,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 107,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_455"
        },
        "458": {
            "cable_siguiente": null,
            "codigo_cliente": "458",
            "cubiculo": null,
            "enchufe_hembra": 141,
            "enchufe_macho": 142,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 108,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_458"
        },
        "462": {
            "cable_siguiente": null,
            "codigo_cliente": "462",
            "cubiculo": null,
            "enchufe_hembra": 253,
            "enchufe_macho": 254,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 109,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_462"
        },
        "465": {
            "cable_siguiente": null,
            "codigo_cliente": "465",
            "cubiculo": null,
            "enchufe_hembra": 411,
            "enchufe_macho": 412,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 110,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_465"
        },
        "467": {
            "cable_siguiente": null,
            "codigo_cliente": "467",
            "cubiculo": null,
            "enchufe_hembra": 309,
            "enchufe_macho": 310,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 111,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_467"
        },
        "468": {
            "cable_siguiente": null,
            "codigo_cliente": "468",
            "cubiculo": null,
            "enchufe_hembra": 505,
            "enchufe_macho": 506,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 112,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_468"
        },
        "470": {
            "cable_siguiente": null,
            "codigo_cliente": "470",
            "cubiculo": null,
            "enchufe_hembra": 209,
            "enchufe_macho": 210,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 113,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_470"
        },
        "473": {
            "cable_siguiente": null,
            "codigo_cliente": "473",
            "cubiculo": null,
            "enchufe_hembra": 445,
            "enchufe_macho": 446,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 114,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_473"
        },
        "477": {
            "cable_siguiente": null,
            "codigo_cliente": "477",
            "cubiculo": null,
            "enchufe_hembra": 423,
            "enchufe_macho": 424,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 115,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_477"
        },
        "480": {
            "cable_siguiente": null,
            "codigo_cliente": "480",
            "cubiculo": null,
            "enchufe_hembra": 491,
            "enchufe_macho": 492,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 116,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_480"
        },
        "482": {
            "cable_siguiente": null,
            "codigo_cliente": "482",
            "cubiculo": null,
            "enchufe_hembra": 393,
            "enchufe_macho": 394,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 117,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_482"
        },
        "487": {
            "cable_siguiente": null,
            "codigo_cliente": "487",
            "cubiculo": null,
            "enchufe_hembra": 113,
            "enchufe_macho": 114,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 118,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_487"
        },
        "492": {
            "cable_siguiente": null,
            "codigo_cliente": "492",
            "cubiculo": null,
            "enchufe_hembra": 431,
            "enchufe_macho": 432,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 119,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_492"
        },
        "495": {
            "cable_siguiente": null,
            "codigo_cliente": "495",
            "cubiculo": null,
            "enchufe_hembra": 401,
            "enchufe_macho": 402,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 120,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_495"
        },
        "496": {
            "cable_siguiente": null,
            "codigo_cliente": "496",
            "cubiculo": null,
            "enchufe_hembra": 451,
            "enchufe_macho": 452,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 121,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_496"
        },
        "498": {
            "cable_siguiente": null,
            "codigo_cliente": "498",
            "cubiculo": null,
            "enchufe_hembra": 33,
            "enchufe_macho": 34,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 122,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_498"
        },
        "499": {
            "cable_siguiente": null,
            "codigo_cliente": "499",
            "cubiculo": null,
            "enchufe_hembra": 427,
            "enchufe_macho": 428,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 123,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_499"
        },
        "500": {
            "cable_siguiente": null,
            "codigo_cliente": "500",
            "cubiculo": null,
            "enchufe_hembra": 101,
            "enchufe_macho": 102,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 124,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_500"
        },
        "502": {
            "cable_siguiente": null,
            "codigo_cliente": "502",
            "cubiculo": null,
            "enchufe_hembra": 297,
            "enchufe_macho": 298,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 125,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_502"
        },
        "503": {
            "cable_siguiente": null,
            "codigo_cliente": "503",
            "cubiculo": null,
            "enchufe_hembra": 9,
            "enchufe_macho": 10,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 126,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_503"
        },
        "505": {
            "cable_siguiente": null,
            "codigo_cliente": "505",
            "cubiculo": null,
            "enchufe_hembra": 369,
            "enchufe_macho": 370,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 127,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_505"
        },
        "506": {
            "cable_siguiente": null,
            "codigo_cliente": "506",
            "cubiculo": null,
            "enchufe_hembra": 361,
            "enchufe_macho": 362,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 128,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_506"
        },
        "509": {
            "cable_siguiente": null,
            "codigo_cliente": "509",
            "cubiculo": null,
            "enchufe_hembra": 211,
            "enchufe_macho": 212,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 129,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_509"
        },
        "511": {
            "cable_siguiente": null,
            "codigo_cliente": "511",
            "cubiculo": null,
            "enchufe_hembra": 241,
            "enchufe_macho": 242,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 130,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_511"
        },
        "512": {
            "cable_siguiente": null,
            "codigo_cliente": "512",
            "cubiculo": null,
            "enchufe_hembra": 213,
            "enchufe_macho": 214,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 131,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_512"
        },
        "513": {
            "cable_siguiente": null,
            "codigo_cliente": "513",
            "cubiculo": null,
            "enchufe_hembra": 459,
            "enchufe_macho": 460,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 132,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_513"
        },
        "519": {
            "cable_siguiente": null,
            "codigo_cliente": "519",
            "cubiculo": null,
            "enchufe_hembra": 117,
            "enchufe_macho": 118,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 133,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_519"
        },
        "521": {
            "cable_siguiente": null,
            "codigo_cliente": "521",
            "cubiculo": null,
            "enchufe_hembra": 259,
            "enchufe_macho": 260,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 134,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_521"
        },
        "522": {
            "cable_siguiente": null,
            "codigo_cliente": "522",
            "cubiculo": null,
            "enchufe_hembra": 329,
            "enchufe_macho": 330,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 135,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_522"
        },
        "523": {
            "cable_siguiente": null,
            "codigo_cliente": "523",
            "cubiculo": null,
            "enchufe_hembra": 19,
            "enchufe_macho": 20,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 136,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_523"
        },
        "524": {
            "cable_siguiente": null,
            "codigo_cliente": "524",
            "cubiculo": null,
            "enchufe_hembra": 521,
            "enchufe_macho": 522,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 137,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_524"
        },
        "525": {
            "cable_siguiente": null,
            "codigo_cliente": "525",
            "cubiculo": null,
            "enchufe_hembra": 15,
            "enchufe_macho": 16,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 138,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_525"
        },
        "527": {
            "cable_siguiente": null,
            "codigo_cliente": "527",
            "cubiculo": null,
            "enchufe_hembra": 377,
            "enchufe_macho": 378,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 139,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_527"
        },
        "529": {
            "cable_siguiente": null,
            "codigo_cliente": "529",
            "cubiculo": null,
            "enchufe_hembra": 255,
            "enchufe_macho": 256,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 140,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_529"
        },
        "530": {
            "cable_siguiente": null,
            "codigo_cliente": "530",
            "cubiculo": null,
            "enchufe_hembra": 21,
            "enchufe_macho": 22,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 141,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_530"
        },
        "531": {
            "cable_siguiente": null,
            "codigo_cliente": "531",
            "cubiculo": null,
            "enchufe_hembra": 67,
            "enchufe_macho": 68,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 142,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_531"
        },
        "532": {
            "cable_siguiente": null,
            "codigo_cliente": "532",
            "cubiculo": null,
            "enchufe_hembra": 265,
            "enchufe_macho": 266,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 143,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_532"
        },
        "533": {
            "cable_siguiente": null,
            "codigo_cliente": "533",
            "cubiculo": null,
            "enchufe_hembra": 333,
            "enchufe_macho": 334,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 144,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_533"
        },
        "536": {
            "cable_siguiente": null,
            "codigo_cliente": "536",
            "cubiculo": null,
            "enchufe_hembra": 251,
            "enchufe_macho": 252,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 145,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_536"
        },
        "538": {
            "cable_siguiente": null,
            "codigo_cliente": "538",
            "cubiculo": null,
            "enchufe_hembra": 317,
            "enchufe_macho": 318,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 146,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_538"
        },
        "539": {
            "cable_siguiente": null,
            "codigo_cliente": "539",
            "cubiculo": null,
            "enchufe_hembra": 463,
            "enchufe_macho": 464,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 147,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_539"
        },
        "543": {
            "cable_siguiente": null,
            "codigo_cliente": "543",
            "cubiculo": null,
            "enchufe_hembra": 217,
            "enchufe_macho": 218,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 148,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_543"
        },
        "545": {
            "cable_siguiente": null,
            "codigo_cliente": "545",
            "cubiculo": null,
            "enchufe_hembra": 109,
            "enchufe_macho": 110,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 149,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_545"
        },
        "546": {
            "cable_siguiente": null,
            "codigo_cliente": "546",
            "cubiculo": null,
            "enchufe_hembra": 281,
            "enchufe_macho": 282,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 150,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_546"
        },
        "547": {
            "cable_siguiente": null,
            "codigo_cliente": "547",
            "cubiculo": null,
            "enchufe_hembra": 483,
            "enchufe_macho": 484,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 151,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_547"
        },
        "549": {
            "cable_siguiente": null,
            "codigo_cliente": "549",
            "cubiculo": null,
            "enchufe_hembra": 295,
            "enchufe_macho": 296,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 152,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_549"
        },
        "551": {
            "cable_siguiente": null,
            "codigo_cliente": "551",
            "cubiculo": null,
            "enchufe_hembra": 397,
            "enchufe_macho": 398,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 153,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_551"
        },
        "552": {
            "cable_siguiente": null,
            "codigo_cliente": "552",
            "cubiculo": null,
            "enchufe_hembra": 511,
            "enchufe_macho": 512,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 154,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_552"
        },
        "553": {
            "cable_siguiente": null,
            "codigo_cliente": "553",
            "cubiculo": null,
            "enchufe_hembra": 291,
            "enchufe_macho": 292,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 155,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_553"
        },
        "554": {
            "cable_siguiente": null,
            "codigo_cliente": "554",
            "cubiculo": null,
            "enchufe_hembra": 181,
            "enchufe_macho": 182,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 156,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_554"
        },
        "555": {
            "cable_siguiente": null,
            "codigo_cliente": "555",
            "cubiculo": null,
            "enchufe_hembra": 105,
            "enchufe_macho": 106,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 157,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_555"
        },
        "557": {
            "cable_siguiente": null,
            "codigo_cliente": "557",
            "cubiculo": null,
            "enchufe_hembra": 305,
            "enchufe_macho": 306,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 158,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_557"
        },
        "558": {
            "cable_siguiente": null,
            "codigo_cliente": "558",
            "cubiculo": null,
            "enchufe_hembra": 207,
            "enchufe_macho": 208,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 159,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_558"
        },
        "559": {
            "cable_siguiente": null,
            "codigo_cliente": "559",
            "cubiculo": null,
            "enchufe_hembra": 395,
            "enchufe_macho": 396,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 160,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_559"
        },
        "560": {
            "cable_siguiente": null,
            "codigo_cliente": "560",
            "cubiculo": null,
            "enchufe_hembra": 485,
            "enchufe_macho": 486,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 161,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_560"
        },
        "561": {
            "cable_siguiente": null,
            "codigo_cliente": "561",
            "cubiculo": null,
            "enchufe_hembra": 383,
            "enchufe_macho": 384,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 162,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_561"
        },
        "563": {
            "cable_siguiente": null,
            "codigo_cliente": "563",
            "cubiculo": null,
            "enchufe_hembra": 279,
            "enchufe_macho": 280,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 163,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_563"
        },
        "565": {
            "cable_siguiente": null,
            "codigo_cliente": "565",
            "cubiculo": null,
            "enchufe_hembra": 375,
            "enchufe_macho": 376,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 164,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_565"
        },
        "567": {
            "cable_siguiente": null,
            "codigo_cliente": "567",
            "cubiculo": null,
            "enchufe_hembra": 421,
            "enchufe_macho": 422,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 165,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_567"
        },
        "568": {
            "cable_siguiente": null,
            "codigo_cliente": "568",
            "cubiculo": null,
            "enchufe_hembra": 487,
            "enchufe_macho": 488,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 166,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_568"
        },
        "571": {
            "cable_siguiente": null,
            "codigo_cliente": "571",
            "cubiculo": null,
            "enchufe_hembra": 435,
            "enchufe_macho": 436,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 167,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_571"
        },
        "572": {
            "cable_siguiente": null,
            "codigo_cliente": "572",
            "cubiculo": null,
            "enchufe_hembra": 475,
            "enchufe_macho": 476,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 168,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_572"
        },
        "574": {
            "cable_siguiente": null,
            "codigo_cliente": "574",
            "cubiculo": null,
            "enchufe_hembra": 379,
            "enchufe_macho": 380,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 169,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_574"
        },
        "575": {
            "cable_siguiente": null,
            "codigo_cliente": "575",
            "cubiculo": null,
            "enchufe_hembra": 189,
            "enchufe_macho": 190,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 170,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_575"
        },
        "576": {
            "cable_siguiente": null,
            "codigo_cliente": "576",
            "cubiculo": null,
            "enchufe_hembra": 55,
            "enchufe_macho": 56,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 171,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_576"
        },
        "578": {
            "cable_siguiente": null,
            "codigo_cliente": "578",
            "cubiculo": null,
            "enchufe_hembra": 151,
            "enchufe_macho": 152,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 172,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_578"
        },
        "579": {
            "cable_siguiente": null,
            "codigo_cliente": "579",
            "cubiculo": null,
            "enchufe_hembra": 179,
            "enchufe_macho": 180,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 173,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_579"
        },
        "583": {
            "cable_siguiente": null,
            "codigo_cliente": "583",
            "cubiculo": null,
            "enchufe_hembra": 337,
            "enchufe_macho": 338,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 174,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_583"
        },
        "584": {
            "cable_siguiente": null,
            "codigo_cliente": "584",
            "cubiculo": null,
            "enchufe_hembra": 363,
            "enchufe_macho": 364,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 175,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_584"
        },
        "585": {
            "cable_siguiente": null,
            "codigo_cliente": "585",
            "cubiculo": null,
            "enchufe_hembra": 293,
            "enchufe_macho": 294,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 176,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_585"
        },
        "587": {
            "cable_siguiente": null,
            "codigo_cliente": "587",
            "cubiculo": null,
            "enchufe_hembra": 183,
            "enchufe_macho": 184,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 177,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_587"
        },
        "588": {
            "cable_siguiente": null,
            "codigo_cliente": "588",
            "cubiculo": null,
            "enchufe_hembra": 185,
            "enchufe_macho": 186,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 178,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_588"
        },
        "589": {
            "cable_siguiente": null,
            "codigo_cliente": "589",
            "cubiculo": null,
            "enchufe_hembra": 93,
            "enchufe_macho": 94,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 179,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_589"
        },
        "591": {
            "cable_siguiente": null,
            "codigo_cliente": "591",
            "cubiculo": null,
            "enchufe_hembra": 299,
            "enchufe_macho": 300,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 180,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_591"
        },
        "594": {
            "cable_siguiente": null,
            "codigo_cliente": "594",
            "cubiculo": null,
            "enchufe_hembra": 193,
            "enchufe_macho": 194,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 181,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_594"
        },
        "595": {
            "cable_siguiente": null,
            "codigo_cliente": "595",
            "cubiculo": null,
            "enchufe_hembra": 187,
            "enchufe_macho": 188,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 182,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_595"
        },
        "596": {
            "cable_siguiente": null,
            "codigo_cliente": "596",
            "cubiculo": null,
            "enchufe_hembra": 39,
            "enchufe_macho": 40,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 183,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_596"
        },
        "598": {
            "cable_siguiente": null,
            "codigo_cliente": "598",
            "cubiculo": null,
            "enchufe_hembra": 221,
            "enchufe_macho": 222,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 184,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_598"
        },
        "599": {
            "cable_siguiente": null,
            "codigo_cliente": "599",
            "cubiculo": null,
            "enchufe_hembra": 327,
            "enchufe_macho": 328,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 185,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_599"
        },
        "601": {
            "cable_siguiente": null,
            "codigo_cliente": "601",
            "cubiculo": null,
            "enchufe_hembra": 439,
            "enchufe_macho": 440,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 186,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_601"
        },
        "602": {
            "cable_siguiente": null,
            "codigo_cliente": "602",
            "cubiculo": null,
            "enchufe_hembra": 41,
            "enchufe_macho": 42,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 187,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_602"
        },
        "603": {
            "cable_siguiente": null,
            "codigo_cliente": "603",
            "cubiculo": null,
            "enchufe_hembra": 513,
            "enchufe_macho": 514,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 188,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_603"
        },
        "604": {
            "cable_siguiente": null,
            "codigo_cliente": "604",
            "cubiculo": null,
            "enchufe_hembra": 409,
            "enchufe_macho": 410,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 189,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_604"
        },
        "606": {
            "cable_siguiente": null,
            "codigo_cliente": "606",
            "cubiculo": null,
            "enchufe_hembra": 17,
            "enchufe_macho": 18,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 191,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_606"
        },
        "610": {
            "cable_siguiente": null,
            "codigo_cliente": "610",
            "cubiculo": null,
            "enchufe_hembra": 29,
            "enchufe_macho": 30,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 192,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_610"
        },
        "611": {
            "cable_siguiente": null,
            "codigo_cliente": "611",
            "cubiculo": null,
            "enchufe_hembra": 321,
            "enchufe_macho": 322,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 193,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_611"
        },
        "612": {
            "cable_siguiente": null,
            "codigo_cliente": "612",
            "cubiculo": null,
            "enchufe_hembra": 275,
            "enchufe_macho": 276,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 194,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_612"
        },
        "613": {
            "cable_siguiente": null,
            "codigo_cliente": "613",
            "cubiculo": null,
            "enchufe_hembra": 453,
            "enchufe_macho": 454,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 195,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_613"
        },
        "614": {
            "cable_siguiente": null,
            "codigo_cliente": "614",
            "cubiculo": null,
            "enchufe_hembra": 473,
            "enchufe_macho": 474,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 196,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_614"
        },
        "615": {
            "cable_siguiente": null,
            "codigo_cliente": "615",
            "cubiculo": null,
            "enchufe_hembra": 49,
            "enchufe_macho": 50,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 197,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_615"
        },
        "616": {
            "cable_siguiente": null,
            "codigo_cliente": "616",
            "cubiculo": null,
            "enchufe_hembra": 495,
            "enchufe_macho": 496,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 198,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_616"
        },
        "618": {
            "cable_siguiente": null,
            "codigo_cliente": "618",
            "cubiculo": null,
            "enchufe_hembra": 449,
            "enchufe_macho": 450,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 199,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_618"
        },
        "619": {
            "cable_siguiente": null,
            "codigo_cliente": "619",
            "cubiculo": null,
            "enchufe_hembra": 287,
            "enchufe_macho": 288,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 200,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_619"
        },
        "621": {
            "cable_siguiente": null,
            "codigo_cliente": "621",
            "cubiculo": null,
            "enchufe_hembra": 341,
            "enchufe_macho": 342,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 201,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_621"
        },
        "622": {
            "cable_siguiente": null,
            "codigo_cliente": "622",
            "cubiculo": null,
            "enchufe_hembra": 413,
            "enchufe_macho": 414,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 202,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_622"
        },
        "623": {
            "cable_siguiente": null,
            "codigo_cliente": "623",
            "cubiculo": null,
            "enchufe_hembra": 359,
            "enchufe_macho": 360,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 203,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_623"
        },
        "624": {
            "cable_siguiente": null,
            "codigo_cliente": "624",
            "cubiculo": null,
            "enchufe_hembra": 499,
            "enchufe_macho": 500,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 204,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_624"
        },
        "625": {
            "cable_siguiente": null,
            "codigo_cliente": "625",
            "cubiculo": null,
            "enchufe_hembra": 347,
            "enchufe_macho": 348,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 205,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_625"
        },
        "626": {
            "cable_siguiente": null,
            "codigo_cliente": "626",
            "cubiculo": null,
            "enchufe_hembra": 323,
            "enchufe_macho": 324,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 206,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_626"
        },
        "627": {
            "cable_siguiente": null,
            "codigo_cliente": "627",
            "cubiculo": null,
            "enchufe_hembra": 3,
            "enchufe_macho": 4,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 207,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_627"
        },
        "628": {
            "cable_siguiente": null,
            "codigo_cliente": "628",
            "cubiculo": null,
            "enchufe_hembra": 461,
            "enchufe_macho": 462,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 208,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_628"
        },
        "629": {
            "cable_siguiente": null,
            "codigo_cliente": "629",
            "cubiculo": null,
            "enchufe_hembra": 433,
            "enchufe_macho": 434,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 209,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_629"
        },
        "632": {
            "cable_siguiente": null,
            "codigo_cliente": "632",
            "cubiculo": null,
            "enchufe_hembra": 307,
            "enchufe_macho": 308,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 210,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_632"
        },
        "633": {
            "cable_siguiente": null,
            "codigo_cliente": "633",
            "cubiculo": null,
            "enchufe_hembra": 227,
            "enchufe_macho": 228,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 211,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_633"
        },
        "634": {
            "cable_siguiente": null,
            "codigo_cliente": "634",
            "cubiculo": null,
            "enchufe_hembra": 171,
            "enchufe_macho": 172,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 212,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_634"
        },
        "636": {
            "cable_siguiente": null,
            "codigo_cliente": "636",
            "cubiculo": null,
            "enchufe_hembra": 523,
            "enchufe_macho": 524,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 213,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_636"
        },
        "637": {
            "cable_siguiente": null,
            "codigo_cliente": "637",
            "cubiculo": null,
            "enchufe_hembra": 457,
            "enchufe_macho": 458,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 214,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_637"
        },
        "638": {
            "cable_siguiente": null,
            "codigo_cliente": "638",
            "cubiculo": null,
            "enchufe_hembra": 437,
            "enchufe_macho": 438,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 215,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_638"
        },
        "639": {
            "cable_siguiente": null,
            "codigo_cliente": "639",
            "cubiculo": null,
            "enchufe_hembra": 469,
            "enchufe_macho": 470,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 216,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_639"
        },
        "640": {
            "cable_siguiente": null,
            "codigo_cliente": "640",
            "cubiculo": null,
            "enchufe_hembra": 525,
            "enchufe_macho": 526,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 217,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_640"
        },
        "641": {
            "cable_siguiente": null,
            "codigo_cliente": "641",
            "cubiculo": null,
            "enchufe_hembra": 301,
            "enchufe_macho": 302,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 218,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_641"
        },
        "642": {
            "cable_siguiente": null,
            "codigo_cliente": "642",
            "cubiculo": null,
            "enchufe_hembra": 51,
            "enchufe_macho": 52,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 219,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_642"
        },
        "643": {
            "cable_siguiente": null,
            "codigo_cliente": "643",
            "cubiculo": null,
            "enchufe_hembra": 355,
            "enchufe_macho": 356,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 220,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_643"
        },
        "644": {
            "cable_siguiente": null,
            "codigo_cliente": "644",
            "cubiculo": null,
            "enchufe_hembra": 331,
            "enchufe_macho": 332,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 221,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_644"
        },
        "645": {
            "cable_siguiente": null,
            "codigo_cliente": "645",
            "cubiculo": null,
            "enchufe_hembra": 493,
            "enchufe_macho": 494,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 222,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_645"
        },
        "646": {
            "cable_siguiente": null,
            "codigo_cliente": "646",
            "cubiculo": null,
            "enchufe_hembra": 429,
            "enchufe_macho": 430,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 223,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_646"
        },
        "647": {
            "cable_siguiente": null,
            "codigo_cliente": "647",
            "cubiculo": null,
            "enchufe_hembra": 515,
            "enchufe_macho": 516,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 224,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_647"
        },
        "648": {
            "cable_siguiente": null,
            "codigo_cliente": "648",
            "cubiculo": null,
            "enchufe_hembra": 267,
            "enchufe_macho": 268,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 225,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_648"
        },
        "649": {
            "cable_siguiente": null,
            "codigo_cliente": "649",
            "cubiculo": null,
            "enchufe_hembra": 11,
            "enchufe_macho": 12,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 226,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_649"
        },
        "651": {
            "cable_siguiente": null,
            "codigo_cliente": "651",
            "cubiculo": null,
            "enchufe_hembra": 471,
            "enchufe_macho": 472,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 227,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_651"
        },
        "652": {
            "cable_siguiente": null,
            "codigo_cliente": "652",
            "cubiculo": null,
            "enchufe_hembra": 407,
            "enchufe_macho": 408,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 228,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_652"
        },
        "653": {
            "cable_siguiente": null,
            "codigo_cliente": "653",
            "cubiculo": null,
            "enchufe_hembra": 529,
            "enchufe_macho": 530,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 229,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_653"
        },
        "654": {
            "cable_siguiente": null,
            "codigo_cliente": "654",
            "cubiculo": null,
            "enchufe_hembra": 315,
            "enchufe_macho": 316,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 230,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_654"
        },
        "655": {
            "cable_siguiente": null,
            "codigo_cliente": "655",
            "cubiculo": null,
            "enchufe_hembra": 465,
            "enchufe_macho": 466,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 231,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_655"
        },
        "656": {
            "cable_siguiente": null,
            "codigo_cliente": "656",
            "cubiculo": null,
            "enchufe_hembra": 249,
            "enchufe_macho": 250,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 232,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_656"
        },
        "657": {
            "cable_siguiente": null,
            "codigo_cliente": "657",
            "cubiculo": null,
            "enchufe_hembra": 247,
            "enchufe_macho": 248,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 233,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_657"
        },
        "658": {
            "cable_siguiente": null,
            "codigo_cliente": "658",
            "cubiculo": null,
            "enchufe_hembra": 61,
            "enchufe_macho": 62,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 234,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_658"
        },
        "659": {
            "cable_siguiente": null,
            "codigo_cliente": "659",
            "cubiculo": null,
            "enchufe_hembra": 65,
            "enchufe_macho": 66,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 235,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_659"
        },
        "660": {
            "cable_siguiente": null,
            "codigo_cliente": "660",
            "cubiculo": null,
            "enchufe_hembra": 191,
            "enchufe_macho": 192,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 236,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_660"
        },
        "661": {
            "cable_siguiente": null,
            "codigo_cliente": "661",
            "cubiculo": null,
            "enchufe_hembra": 289,
            "enchufe_macho": 290,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 237,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_661"
        },
        "662": {
            "cable_siguiente": null,
            "codigo_cliente": "662",
            "cubiculo": null,
            "enchufe_hembra": 75,
            "enchufe_macho": 76,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 238,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_662"
        },
        "663": {
            "cable_siguiente": null,
            "codigo_cliente": "663",
            "cubiculo": null,
            "enchufe_hembra": 173,
            "enchufe_macho": 174,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 239,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_663"
        },
        "664": {
            "cable_siguiente": null,
            "codigo_cliente": "664",
            "cubiculo": null,
            "enchufe_hembra": 91,
            "enchufe_macho": 92,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 240,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_664"
        },
        "665": {
            "cable_siguiente": null,
            "codigo_cliente": "665",
            "cubiculo": null,
            "enchufe_hembra": 95,
            "enchufe_macho": 96,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 241,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_665"
        },
        "666": {
            "cable_siguiente": null,
            "codigo_cliente": "666",
            "cubiculo": null,
            "enchufe_hembra": 501,
            "enchufe_macho": 502,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 242,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_666"
        },
        "669": {
            "cable_siguiente": null,
            "codigo_cliente": "669",
            "cubiculo": null,
            "enchufe_hembra": 517,
            "enchufe_macho": 518,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 243,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_669"
        },
        "670": {
            "cable_siguiente": null,
            "codigo_cliente": "670",
            "cubiculo": null,
            "enchufe_hembra": 199,
            "enchufe_macho": 200,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 244,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_670"
        },
        "671": {
            "cable_siguiente": null,
            "codigo_cliente": "671",
            "cubiculo": null,
            "enchufe_hembra": 53,
            "enchufe_macho": 54,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 245,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_671"
        },
        "672": {
            "cable_siguiente": null,
            "codigo_cliente": "672",
            "cubiculo": null,
            "enchufe_hembra": 1,
            "enchufe_macho": 2,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 246,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_672"
        },
        "673": {
            "cable_siguiente": null,
            "codigo_cliente": "673",
            "cubiculo": null,
            "enchufe_hembra": 481,
            "enchufe_macho": 482,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 247,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_673"
        },
        "674": {
            "cable_siguiente": null,
            "codigo_cliente": "674",
            "cubiculo": null,
            "enchufe_hembra": 59,
            "enchufe_macho": 60,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 248,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_674"
        },
        "675": {
            "cable_siguiente": null,
            "codigo_cliente": "675",
            "cubiculo": null,
            "enchufe_hembra": 245,
            "enchufe_macho": 246,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 249,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_675"
        },
        "676": {
            "cable_siguiente": null,
            "codigo_cliente": "676",
            "cubiculo": null,
            "enchufe_hembra": 119,
            "enchufe_macho": 120,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 250,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_676"
        },
        "678": {
            "cable_siguiente": null,
            "codigo_cliente": "678",
            "cubiculo": null,
            "enchufe_hembra": 167,
            "enchufe_macho": 168,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 251,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_678"
        },
        "680": {
            "cable_siguiente": null,
            "codigo_cliente": "680",
            "cubiculo": null,
            "enchufe_hembra": 389,
            "enchufe_macho": 390,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 252,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_680"
        },
        "681": {
            "cable_siguiente": null,
            "codigo_cliente": "681",
            "cubiculo": null,
            "enchufe_hembra": 313,
            "enchufe_macho": 314,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 253,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_681"
        },
        "682": {
            "cable_siguiente": null,
            "codigo_cliente": "682",
            "cubiculo": null,
            "enchufe_hembra": 373,
            "enchufe_macho": 374,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 254,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_682"
        },
        "685": {
            "cable_siguiente": null,
            "codigo_cliente": "685",
            "cubiculo": null,
            "enchufe_hembra": 325,
            "enchufe_macho": 326,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 255,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_685"
        },
        "686": {
            "cable_siguiente": null,
            "codigo_cliente": "686",
            "cubiculo": null,
            "enchufe_hembra": 385,
            "enchufe_macho": 386,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 256,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_686"
        },
        "687": {
            "cable_siguiente": null,
            "codigo_cliente": "687",
            "cubiculo": null,
            "enchufe_hembra": 257,
            "enchufe_macho": 258,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 257,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_687"
        },
        "688": {
            "cable_siguiente": null,
            "codigo_cliente": "688",
            "cubiculo": null,
            "enchufe_hembra": 223,
            "enchufe_macho": 224,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 258,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_688"
        },
        "690": {
            "cable_siguiente": null,
            "codigo_cliente": "690",
            "cubiculo": null,
            "enchufe_hembra": 479,
            "enchufe_macho": 480,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 259,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_690"
        },
        "691": {
            "cable_siguiente": null,
            "codigo_cliente": "691",
            "cubiculo": null,
            "enchufe_hembra": 263,
            "enchufe_macho": 264,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 260,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_691"
        },
        "692": {
            "cable_siguiente": null,
            "codigo_cliente": "692",
            "cubiculo": null,
            "enchufe_hembra": 239,
            "enchufe_macho": 240,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 261,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_692"
        },
        "693": {
            "cable_siguiente": null,
            "codigo_cliente": "693",
            "cubiculo": null,
            "enchufe_hembra": 97,
            "enchufe_macho": 98,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 262,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_693"
        },
        "694": {
            "cable_siguiente": null,
            "codigo_cliente": "694",
            "cubiculo": null,
            "enchufe_hembra": 71,
            "enchufe_macho": 72,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 263,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_694"
        },
        "695": {
            "cable_siguiente": null,
            "codigo_cliente": "695",
            "cubiculo": null,
            "enchufe_hembra": 441,
            "enchufe_macho": 442,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 264,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_695"
        },
        "696": {
            "cable_siguiente": null,
            "codigo_cliente": "696",
            "cubiculo": null,
            "enchufe_hembra": 175,
            "enchufe_macho": 176,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 265,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_696"
        },
        "697": {
            "cable_siguiente": null,
            "codigo_cliente": "697",
            "cubiculo": null,
            "enchufe_hembra": 107,
            "enchufe_macho": 108,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 266,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_697"
        },
        "699": {
            "cable_siguiente": null,
            "codigo_cliente": "699",
            "cubiculo": null,
            "enchufe_hembra": 503,
            "enchufe_macho": 504,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 267,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_699"
        },
        "700": {
            "cable_siguiente": null,
            "codigo_cliente": "700",
            "cubiculo": null,
            "enchufe_hembra": 35,
            "enchufe_macho": 36,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 268,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_700"
        },
        "703": {
            "cable_siguiente": null,
            "codigo_cliente": "703",
            "cubiculo": null,
            "enchufe_hembra": 43,
            "enchufe_macho": 44,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 269,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_703"
        },
        "704": {
            "cable_siguiente": null,
            "codigo_cliente": "704",
            "cubiculo": null,
            "enchufe_hembra": 115,
            "enchufe_macho": 116,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 270,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_704"
        },
        "708": {
            "cable_siguiente": null,
            "codigo_cliente": "708",
            "cubiculo": null,
            "enchufe_hembra": 507,
            "enchufe_macho": 508,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 271,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_708"
        },
        "710": {
            "cable_siguiente": null,
            "codigo_cliente": "710",
            "cubiculo": null,
            "enchufe_hembra": 197,
            "enchufe_macho": 198,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 272,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_710"
        },
        "711": {
            "cable_siguiente": null,
            "codigo_cliente": "711",
            "cubiculo": null,
            "enchufe_hembra": 381,
            "enchufe_macho": 382,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 273,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_711"
        },
        "712": {
            "cable_siguiente": null,
            "codigo_cliente": "712",
            "cubiculo": null,
            "enchufe_hembra": 73,
            "enchufe_macho": 74,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 274,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_712"
        },
        "077": {
            "cable_siguiente": null,
            "codigo_cliente": "077",
            "cubiculo": null,
            "enchufe_hembra": 163,
            "enchufe_macho": 164,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 21,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_077"
        },
        "088": {
            "cable_siguiente": null,
            "codigo_cliente": "088",
            "cubiculo": null,
            "enchufe_hembra": 79,
            "enchufe_macho": 80,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 22,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_088"
        },
        "604-2": {
            "cable_siguiente": null,
            "codigo_cliente": "604-2",
            "cubiculo": null,
            "enchufe_hembra": 489,
            "enchufe_macho": 490,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 190,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_604-2"
        },
        "CH-02": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-02",
            "cubiculo": null,
            "enchufe_hembra": 137,
            "enchufe_macho": 138,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 275,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-02"
        },
        "CH-31": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-31",
            "cubiculo": null,
            "enchufe_hembra": 85,
            "enchufe_macho": 86,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 276,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-31"
        },
        "CH-38": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-38",
            "cubiculo": null,
            "enchufe_hembra": 533,
            "enchufe_macho": 534,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 277,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-38"
        },
        "CH-45": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-45",
            "cubiculo": null,
            "enchufe_hembra": 89,
            "enchufe_macho": 90,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 278,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-45"
        },
        "CH-63": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-63",
            "cubiculo": null,
            "enchufe_hembra": 539,
            "enchufe_macho": 540,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 279,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-63"
        },
        "CH-71": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-71",
            "cubiculo": null,
            "enchufe_hembra": 541,
            "enchufe_macho": 542,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 280,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-71"
        },
        "CH-74": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-74",
            "cubiculo": null,
            "enchufe_hembra": 545,
            "enchufe_macho": 546,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 281,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-74"
        },
        "CH-79": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-79",
            "cubiculo": null,
            "enchufe_hembra": 547,
            "enchufe_macho": 548,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 282,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": "ok",
                "voltaje_bc": "ok",
                "voltaje_ca": "ok"
            },
            "type": "cable",
            "id": "cable_CH-79"
        },
        "CH-81": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-81",
            "cubiculo": null,
            "enchufe_hembra": 77,
            "enchufe_macho": 78,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 283,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-81"
        },
        "CH-92": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-92",
            "cubiculo": null,
            "enchufe_hembra": 13,
            "enchufe_macho": 14,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 284,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-92"
        },
        "CH-94": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-94",
            "cubiculo": null,
            "enchufe_hembra": 535,
            "enchufe_macho": 536,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 285,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-94"
        },
        "CH-95": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-95",
            "cubiculo": null,
            "enchufe_hembra": 537,
            "enchufe_macho": 538,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 286,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-95"
        },
        "CH-97": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-97",
            "cubiculo": null,
            "enchufe_hembra": 157,
            "enchufe_macho": 158,
            "estado_cable": null,
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 287,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-97"
        },
        "CH-98": {
            "cable_siguiente": null,
            "codigo_cliente": "CH-98",
            "cubiculo": null,
            "enchufe_hembra": 543,
            "enchufe_macho": 544,
            "estado_cable": "Operativo",
            "fecha_cambio_estado": "2023-03-13",
            "fecha_cambio_locacion": "2023-04-28",
            "inventario_id": 288,
            "largo": 100,
            "locacion": null,
            "ubicacion": null,
            "disponible": true,
            "voltage_alarms": {
                "voltaje_ab": null,
                "voltaje_bc": null,
                "voltaje_ca": null
            },
            "type": "cable",
            "id": "cable_CH-98"
        }
    },
    "plugs": {
        "1": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_1"
        },
        "2": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_2"
        },
        "3": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_3"
        },
        "4": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_4"
        },
        "5": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_5"
        },
        "6": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_6"
        },
        "7": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_7"
        },
        "8": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_8"
        },
        "9": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_9"
        },
        "10": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_10"
        },
        "11": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_11"
        },
        "12": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_12"
        },
        "13": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_13"
        },
        "14": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_14"
        },
        "15": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_15"
        },
        "16": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_16"
        },
        "17": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_17"
        },
        "18": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_18"
        },
        "19": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_19"
        },
        "20": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_20"
        },
        "21": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_21"
        },
        "22": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_22"
        },
        "23": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_23"
        },
        "24": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_24"
        },
        "25": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_25"
        },
        "26": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_26"
        },
        "27": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_27"
        },
        "28": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_28"
        },
        "29": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_29"
        },
        "30": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_30"
        },
        "31": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_31"
        },
        "32": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_32"
        },
        "33": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_33"
        },
        "34": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_34"
        },
        "35": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_35"
        },
        "36": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_36"
        },
        "37": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_37"
        },
        "38": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_38"
        },
        "39": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_39"
        },
        "40": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_40"
        },
        "41": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_41"
        },
        "42": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_42"
        },
        "43": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_43"
        },
        "44": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_44"
        },
        "45": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_45"
        },
        "46": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_46"
        },
        "47": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_47"
        },
        "48": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_48"
        },
        "49": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_49"
        },
        "50": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_50"
        },
        "51": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_51"
        },
        "52": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_52"
        },
        "53": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_53"
        },
        "54": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_54"
        },
        "55": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_55"
        },
        "56": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_56"
        },
        "57": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_57"
        },
        "58": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_58"
        },
        "59": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_59"
        },
        "60": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_60"
        },
        "61": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_61"
        },
        "62": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_62"
        },
        "63": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": 50,
            "temp_interior_bc": 70,
            "temp_interior_ca": 200,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": 70,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": "ok",
                    "temp_interior_bc": "ok",
                    "temp_interior_ca": "red-alarm"
                },
                "humidity": "yellow-alarm"
            },
            "id": "plug_63"
        },
        "64": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_64"
        },
        "65": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_65"
        },
        "66": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_66"
        },
        "67": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_67"
        },
        "68": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_68"
        },
        "69": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_69"
        },
        "70": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_70"
        },
        "71": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_71"
        },
        "72": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_72"
        },
        "73": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_73"
        },
        "74": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_74"
        },
        "75": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_75"
        },
        "76": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_76"
        },
        "77": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_77"
        },
        "78": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_78"
        },
        "79": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_79"
        },
        "80": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_80"
        },
        "81": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_81"
        },
        "82": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_82"
        },
        "83": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_83"
        },
        "84": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_84"
        },
        "85": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_85"
        },
        "86": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_86"
        },
        "87": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_87"
        },
        "88": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_88"
        },
        "89": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_89"
        },
        "90": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_90"
        },
        "91": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_91"
        },
        "92": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_92"
        },
        "93": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_93"
        },
        "94": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_94"
        },
        "95": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_95"
        },
        "96": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_96"
        },
        "97": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_97"
        },
        "98": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_98"
        },
        "99": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_99"
        },
        "100": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_100"
        },
        "101": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_101"
        },
        "102": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_102"
        },
        "103": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_103"
        },
        "104": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_104"
        },
        "105": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_105"
        },
        "106": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_106"
        },
        "107": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_107"
        },
        "108": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_108"
        },
        "109": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_109"
        },
        "110": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_110"
        },
        "111": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_111"
        },
        "112": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_112"
        },
        "113": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_113"
        },
        "114": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_114"
        },
        "115": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_115"
        },
        "116": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_116"
        },
        "117": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_117"
        },
        "118": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_118"
        },
        "119": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_119"
        },
        "120": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_120"
        },
        "121": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_121"
        },
        "122": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_122"
        },
        "123": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_123"
        },
        "124": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_124"
        },
        "125": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_125"
        },
        "126": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_126"
        },
        "127": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": 100,
            "temp_interior_bc": 70,
            "temp_interior_ca": 100,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": "yellow-alarm",
                    "temp_interior_bc": "ok",
                    "temp_interior_ca": "yellow-alarm"
                },
                "humidity": null
            },
            "id": "plug_127"
        },
        "128": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_128"
        },
        "129": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_129"
        },
        "130": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_130"
        },
        "131": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_131"
        },
        "132": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_132"
        },
        "133": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_133"
        },
        "134": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_134"
        },
        "135": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_135"
        },
        "136": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_136"
        },
        "137": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_137"
        },
        "138": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_138"
        },
        "139": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_139"
        },
        "140": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_140"
        },
        "141": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_141"
        },
        "142": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_142"
        },
        "143": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_143"
        },
        "144": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_144"
        },
        "145": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_145"
        },
        "146": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_146"
        },
        "147": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_147"
        },
        "148": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_148"
        },
        "149": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_149"
        },
        "150": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_150"
        },
        "151": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_151"
        },
        "152": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_152"
        },
        "153": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_153"
        },
        "154": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_154"
        },
        "157": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_157"
        },
        "158": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_158"
        },
        "159": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": 7480,
            "voltaje_bc": 7483,
            "voltaje_ca": 7479,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_159"
        },
        "160": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": 7493,
            "voltaje_bc": 7493,
            "voltaje_ca": 7493,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_160"
        },
        "161": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_161"
        },
        "162": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_162"
        },
        "163": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_163"
        },
        "164": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_164"
        },
        "165": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_165"
        },
        "166": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_166"
        },
        "167": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_167"
        },
        "168": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_168"
        },
        "169": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_169"
        },
        "170": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_170"
        },
        "171": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_171"
        },
        "172": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_172"
        },
        "173": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_173"
        },
        "174": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_174"
        },
        "175": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_175"
        },
        "176": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_176"
        },
        "177": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_177"
        },
        "178": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_178"
        },
        "179": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_179"
        },
        "180": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_180"
        },
        "181": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_181"
        },
        "182": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_182"
        },
        "183": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_183"
        },
        "184": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_184"
        },
        "185": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_185"
        },
        "186": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_186"
        },
        "187": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_187"
        },
        "188": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_188"
        },
        "189": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_189"
        },
        "190": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_190"
        },
        "191": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_191"
        },
        "192": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_192"
        },
        "193": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_193"
        },
        "194": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_194"
        },
        "195": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_195"
        },
        "196": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_196"
        },
        "197": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_197"
        },
        "198": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_198"
        },
        "199": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_199"
        },
        "200": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_200"
        },
        "201": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": 7300,
            "voltaje_bc": 7250,
            "voltaje_ca": 7330,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_201"
        },
        "202": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": 7300,
            "voltaje_bc": 7340,
            "voltaje_ca": 7100,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_202"
        },
        "203": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_203"
        },
        "204": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_204"
        },
        "205": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_205"
        },
        "206": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_206"
        },
        "207": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_207"
        },
        "208": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_208"
        },
        "209": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_209"
        },
        "210": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_210"
        },
        "211": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_211"
        },
        "212": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_212"
        },
        "213": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_213"
        },
        "214": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_214"
        },
        "215": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_215"
        },
        "216": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_216"
        },
        "217": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_217"
        },
        "218": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_218"
        },
        "219": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_219"
        },
        "220": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_220"
        },
        "221": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_221"
        },
        "222": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_222"
        },
        "223": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_223"
        },
        "224": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_224"
        },
        "227": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_227"
        },
        "228": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_228"
        },
        "229": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_229"
        },
        "230": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_230"
        },
        "231": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_231"
        },
        "232": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_232"
        },
        "233": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_233"
        },
        "234": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_234"
        },
        "235": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_235"
        },
        "236": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_236"
        },
        "237": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_237"
        },
        "238": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_238"
        },
        "239": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_239"
        },
        "240": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_240"
        },
        "241": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_241"
        },
        "242": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_242"
        },
        "243": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_243"
        },
        "244": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_244"
        },
        "245": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_245"
        },
        "246": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_246"
        },
        "247": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_247"
        },
        "248": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_248"
        },
        "249": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_249"
        },
        "250": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_250"
        },
        "251": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_251"
        },
        "252": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_252"
        },
        "253": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_253"
        },
        "254": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_254"
        },
        "255": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_255"
        },
        "256": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_256"
        },
        "257": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_257"
        },
        "258": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_258"
        },
        "259": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_259"
        },
        "260": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_260"
        },
        "261": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_261"
        },
        "262": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_262"
        },
        "263": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_263"
        },
        "264": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_264"
        },
        "265": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_265"
        },
        "266": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_266"
        },
        "267": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_267"
        },
        "268": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_268"
        },
        "269": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_269"
        },
        "270": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_270"
        },
        "271": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_271"
        },
        "272": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_272"
        },
        "273": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_273"
        },
        "274": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_274"
        },
        "275": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_275"
        },
        "276": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_276"
        },
        "277": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_277"
        },
        "278": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_278"
        },
        "279": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_279"
        },
        "280": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_280"
        },
        "281": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_281"
        },
        "282": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_282"
        },
        "283": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_283"
        },
        "284": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_284"
        },
        "285": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_285"
        },
        "286": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_286"
        },
        "287": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_287"
        },
        "288": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_288"
        },
        "289": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_289"
        },
        "290": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_290"
        },
        "291": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_291"
        },
        "292": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_292"
        },
        "293": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_293"
        },
        "294": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_294"
        },
        "295": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_295"
        },
        "296": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_296"
        },
        "297": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_297"
        },
        "298": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_298"
        },
        "299": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_299"
        },
        "300": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_300"
        },
        "301": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_301"
        },
        "302": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_302"
        },
        "303": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_303"
        },
        "304": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_304"
        },
        "305": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_305"
        },
        "306": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_306"
        },
        "307": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_307"
        },
        "308": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_308"
        },
        "309": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_309"
        },
        "310": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_310"
        },
        "311": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_311"
        },
        "312": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_312"
        },
        "313": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_313"
        },
        "314": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_314"
        },
        "315": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_315"
        },
        "316": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_316"
        },
        "317": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_317"
        },
        "318": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_318"
        },
        "319": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_319"
        },
        "320": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_320"
        },
        "321": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_321"
        },
        "322": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_322"
        },
        "323": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_323"
        },
        "324": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_324"
        },
        "325": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_325"
        },
        "326": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_326"
        },
        "327": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_327"
        },
        "328": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_328"
        },
        "329": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_329"
        },
        "330": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_330"
        },
        "331": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_331"
        },
        "332": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_332"
        },
        "333": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_333"
        },
        "334": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_334"
        },
        "335": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_335"
        },
        "336": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_336"
        },
        "337": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_337"
        },
        "338": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_338"
        },
        "339": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_339"
        },
        "340": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_340"
        },
        "341": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_341"
        },
        "342": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_342"
        },
        "343": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_343"
        },
        "344": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_344"
        },
        "345": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_345"
        },
        "346": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_346"
        },
        "347": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_347"
        },
        "348": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_348"
        },
        "349": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_349"
        },
        "350": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_350"
        },
        "351": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_351"
        },
        "352": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_352"
        },
        "353": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_353"
        },
        "354": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_354"
        },
        "355": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_355"
        },
        "356": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_356"
        },
        "357": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_357"
        },
        "358": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_358"
        },
        "359": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_359"
        },
        "360": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_360"
        },
        "361": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_361"
        },
        "362": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_362"
        },
        "363": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_363"
        },
        "364": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_364"
        },
        "365": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_365"
        },
        "366": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_366"
        },
        "367": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_367"
        },
        "368": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_368"
        },
        "369": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_369"
        },
        "370": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_370"
        },
        "371": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_371"
        },
        "372": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_372"
        },
        "373": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_373"
        },
        "374": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_374"
        },
        "375": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_375"
        },
        "376": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_376"
        },
        "377": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_377"
        },
        "378": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_378"
        },
        "379": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_379"
        },
        "380": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_380"
        },
        "381": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_381"
        },
        "382": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_382"
        },
        "383": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_383"
        },
        "384": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_384"
        },
        "385": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_385"
        },
        "386": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_386"
        },
        "387": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_387"
        },
        "388": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_388"
        },
        "389": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_389"
        },
        "390": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_390"
        },
        "391": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_391"
        },
        "392": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_392"
        },
        "393": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_393"
        },
        "394": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_394"
        },
        "395": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_395"
        },
        "396": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_396"
        },
        "397": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_397"
        },
        "398": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_398"
        },
        "399": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_399"
        },
        "400": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_400"
        },
        "401": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_401"
        },
        "402": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_402"
        },
        "403": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_403"
        },
        "404": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_404"
        },
        "405": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_405"
        },
        "406": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_406"
        },
        "407": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_407"
        },
        "408": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_408"
        },
        "409": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_409"
        },
        "410": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_410"
        },
        "411": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_411"
        },
        "412": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_412"
        },
        "413": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_413"
        },
        "414": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_414"
        },
        "415": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_415"
        },
        "416": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_416"
        },
        "417": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_417"
        },
        "418": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_418"
        },
        "419": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_419"
        },
        "420": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_420"
        },
        "421": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_421"
        },
        "422": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_422"
        },
        "423": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_423"
        },
        "424": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_424"
        },
        "425": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_425"
        },
        "426": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_426"
        },
        "427": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_427"
        },
        "428": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_428"
        },
        "429": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_429"
        },
        "430": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_430"
        },
        "431": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_431"
        },
        "432": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_432"
        },
        "433": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_433"
        },
        "434": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_434"
        },
        "435": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_435"
        },
        "436": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_436"
        },
        "437": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_437"
        },
        "438": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_438"
        },
        "439": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_439"
        },
        "440": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_440"
        },
        "441": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_441"
        },
        "442": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_442"
        },
        "443": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_443"
        },
        "444": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_444"
        },
        "445": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_445"
        },
        "446": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_446"
        },
        "447": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_447"
        },
        "448": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_448"
        },
        "449": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_449"
        },
        "450": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_450"
        },
        "451": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_451"
        },
        "452": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_452"
        },
        "453": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_453"
        },
        "454": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_454"
        },
        "455": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_455"
        },
        "456": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_456"
        },
        "457": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_457"
        },
        "458": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_458"
        },
        "459": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_459"
        },
        "460": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_460"
        },
        "461": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_461"
        },
        "462": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_462"
        },
        "463": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_463"
        },
        "464": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_464"
        },
        "465": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_465"
        },
        "466": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_466"
        },
        "467": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_467"
        },
        "468": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_468"
        },
        "469": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_469"
        },
        "470": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_470"
        },
        "471": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_471"
        },
        "472": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_472"
        },
        "473": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_473"
        },
        "474": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_474"
        },
        "475": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_475"
        },
        "476": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_476"
        },
        "477": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_477"
        },
        "478": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_478"
        },
        "479": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_479"
        },
        "480": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_480"
        },
        "481": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_481"
        },
        "482": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_482"
        },
        "483": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_483"
        },
        "484": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_484"
        },
        "485": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_485"
        },
        "486": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_486"
        },
        "487": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_487"
        },
        "488": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_488"
        },
        "489": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_489"
        },
        "490": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_490"
        },
        "491": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_491"
        },
        "492": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_492"
        },
        "493": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_493"
        },
        "494": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_494"
        },
        "495": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_495"
        },
        "496": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_496"
        },
        "497": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_497"
        },
        "498": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_498"
        },
        "499": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_499"
        },
        "500": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_500"
        },
        "501": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_501"
        },
        "502": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_502"
        },
        "503": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_503"
        },
        "504": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_504"
        },
        "505": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_505"
        },
        "506": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_506"
        },
        "507": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_507"
        },
        "508": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_508"
        },
        "509": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_509"
        },
        "510": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_510"
        },
        "511": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_511"
        },
        "512": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_512"
        },
        "513": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_513"
        },
        "514": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_514"
        },
        "515": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_515"
        },
        "516": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_516"
        },
        "517": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_517"
        },
        "518": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_518"
        },
        "519": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_519"
        },
        "520": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_520"
        },
        "521": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_521"
        },
        "522": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_522"
        },
        "523": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_523"
        },
        "524": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_524"
        },
        "525": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_525"
        },
        "526": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_526"
        },
        "527": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_527"
        },
        "528": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_528"
        },
        "529": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_529"
        },
        "530": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_530"
        },
        "531": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_531"
        },
        "532": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_532"
        },
        "533": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_533"
        },
        "534": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_534"
        },
        "535": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_535"
        },
        "536": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_536"
        },
        "537": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_537"
        },
        "538": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_538"
        },
        "539": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_539"
        },
        "540": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_540"
        },
        "541": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_541"
        },
        "542": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_542"
        },
        "543": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_543"
        },
        "544": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_544"
        },
        "545": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_545"
        },
        "546": {
            "identificador_eclamp": null,
            "modelo_enchufe": null,
            "estado_eclamp": null,
            "fecha_voltaje": null,
            "voltaje_ab": null,
            "voltaje_bc": null,
            "voltaje_ca": null,
            "fecha_temperatura": null,
            "temp_interior_ab": null,
            "temp_interior_bc": null,
            "temp_interior_ca": null,
            "temp_exterior": null,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": null,
                    "temp_interior_bc": null,
                    "temp_interior_ca": null
                },
                "humidity": null
            },
            "id": "plug_546"
        },
        "547": {
            "identificador_eclamp": "CH-79H",
            "modelo_enchufe": "Modelo 1 - H",
            "estado_eclamp": "Operativo",
            "fecha_voltaje": 1634673068,
            "voltaje_ab": 0.2,
            "voltaje_bc": 0,
            "voltaje_ca": 0.2,
            "fecha_temperatura": 1634673067,
            "temp_interior_ab": 20.8,
            "temp_interior_bc": 19.7,
            "temp_interior_ca": 20.2,
            "temp_exterior": 13.7,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": "ok",
                    "temp_interior_bc": "ok",
                    "temp_interior_ca": "ok"
                },
                "humidity": null
            },
            "id": "plug_547"
        },
        "548": {
            "identificador_eclamp": "CH-79M",
            "modelo_enchufe": "Modelo 1",
            "estado_eclamp": "Operativo",
            "fecha_voltaje": 1634673035,
            "voltaje_ab": 0.2,
            "voltaje_bc": 0,
            "voltaje_ca": 0.2,
            "fecha_temperatura": 1634673034,
            "temp_interior_ab": 20.8,
            "temp_interior_bc": 19.7,
            "temp_interior_ca": 20.2,
            "temp_exterior": 13.8,
            "fecha_humedad": null,
            "medicion_humedad": null,
            "eclamp": null,
            "type": "plug",
            "alarms": {
                "inner_temperature": {
                    "temp_interior_ab": "ok",
                    "temp_interior_bc": "ok",
                    "temp_interior_ca": "ok"
                },
                "humidity": null
            },
            "id": "plug_548"
        }
    }
}