import React, { useContext, useState } from "react";
import {AppBar, Box, Button, Container, Paper, TextField, Toolbar, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import axios from "axios";
import {useTheme, css} from '@emotion/react';
import LogoAikoHorizontal from "../LogoAikoHorizontal";
import LogoCollahuasiHorizontal from "../LogoCollahuasiHorizontal";
import { AuthenticationContext } from "../../contexts/AuthenticationContext";

async function loginUser(credentials) {
    return axios.post('/login/', credentials)
        .then(function (res) {
                if (res.status === 200) {
                    return res.data
                }
            }
        )
        .catch(function (err) {
            console.log(err);
        })
}

/*const Content = styled.main`
  flex-grow: 1;
  overflow: auto;
  justify-content: center;
`;*/


function Login({ }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(false)
    const theme = useTheme();
    const styles = {
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
        },
        /*    appBar: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },*/
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: 'none',
        },
        /*    title: {
                flexGrow: 1,
                fontSize: 20,
                color: theme.palette.primary.main,
                fontWeight: 'bold',

            },*/
        /*
            appBarSpacer: theme.mixins.toolbar,
        */
        paper: {
            display: 'flex',
            overflow: 'auto',
            width: '526px',
            height: '620px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#0e537b',
        },
        insidePaper: {
            display: 'flex',
            overflow: 'auto',
            width: '327px',
            height: '439px',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            alignItems: 'center'

        },
        fixedHeight: {
            height: 240,
        },
        formHeader: {
            fontSize: 20,
            fontWeight: 'bold',
            color: '#81d1ff',
            justifySelf: 'center',
            marginTop: '45px',
        },
        button: {
            backgroundColor: '#81d1ff',
            color: 'white',
        },
        errorPaper: {
            marginTop: '10px',
            display: 'flex',
            backgroundColor: '#FFDFDF',
            borderColor: '#B51111',
            border: '10px',
            width: '200px',
            height: '40px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        errorMessage: {
            fontSize: 15,
            color: '#B51111',
        },

    }

    const authContext = useContext(AuthenticationContext);

    const handleSubmit = async event => {
        event.preventDefault();
        setErrorMessage(false);
        try {
            await authContext.login({ username, password });
        } catch (error) {
            setErrorMessage(true)
            setPassword('')
            console.error(error);
        }
    }

    // const handleSubmit = async e => {
    //     e.preventDefault();
    //     setErrorMessage(false)
    //     const res = await loginUser({
    //         username,
    //         password
    //     });
    //     if (res) {
    //         setToken({'token': res['token']});
    //     } else {
    //         setErrorMessage(true)
    //         setPassword('')
    //     }
    // }

    return (<div>
        {/* TOP BAR*/}
        <AppBar position="static" color='default'>
            <Toolbar css={{paddingRight: 24, backgroundColor: 'white'}}>
                <LogoAikoHorizontal maxHeight='50px'/>
                <Box alignItems="center">
                    <Typography variant="h3" css={{flexGrow: 1}} align='center'>
                        MONITOREO DE FALLAS EN CABLES ELÉCTRICOS
                    </Typography>
                </Box>
                <LogoCollahuasiHorizontal/>
            </Toolbar>
        </AppBar>
        <main>
            <Container maxWidth="sm" disableGutters>
                <Paper sx={styles.paper}>
                    <Paper sx={styles.insidePaper}>
                        <div>
                            <Typography sx={styles.formHeader}>
                                INICIO DE SESION
                            </Typography>
                        </div>

                        <div>
                            <form noValidate autoComplete="on" onSubmit={handleSubmit}>
                                <TextField
                                    required
                                    id="username-input"
                                    label="Username"
                                    variant="filled"
                                    value={username}
                                    onChange={e => setUsername(e.target.value)}
                                    autoComplete="username"
                                />
                                <br/>
                                <TextField
                                    required
                                    id="password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    variant="filled"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <Container>
                                    <Button type="submit" variant="contained" sx={styles.button}
                                            fullWidth={true}>
                                        Iniciar Sesión
                                    </Button>
                                </Container>
                            </form>

                        </div>
                        {errorMessage &&
                            <Paper sx={styles.errorPaper}>
                                <Typography sx={styles.errorMessage}>
                                    Datos de acceso inválidos
                                </Typography>

                            </Paper>}
                    </Paper>
                </Paper>
            </Container>
        </main>
    </div>)
}

Login.propTypes = {
}

export default Login;