import {ReactComponent as IconSVG} from '../svg/logo_aiko_horizontal.svg';
import React from 'react';

function LogoAikoHorizontal({ maxHeight, maxWidth }) {
    return (
        <IconSVG alt="logo_aiko_horizontal" style={{
            borderRadius: "0%",
            margin: 'auto',
            display: 'block',
            maxWidth: 'auto',
            maxHeight: maxHeight,
        }}/>
    );
}

LogoAikoHorizontal.prototype.defaultProps = {
    maxHeight: '6em',
    maxWidth: 'auto'
};

export default LogoAikoHorizontal;
