import { useState, useEffect } from 'react';
import { AerialCableColors } from '../constants/single-line-diagram';
import { CableTypes } from '../constants/single-line-diagram';

//Actualmente los cables aereos solo pueden ser únicos o "dobles", esto es meramente una merca.
// El requerimiento requiere que la marca de "cable aereo" sea única, pero cada nuevo cable aereo pueda unirse a un grupo de un único color o irse a uno nuevo, esto simulando postes
// que impulsan el cable en el aire. De este modo, si hay 3 cables con el simbolo de "cable aereo" y los 3 son del mismo color, a nivel de simbología representa que los 3 cables están
// unidos a un mismo poste, pero si uno de los cables cambia de color, entonces se entiende que ese cable se ha unido a un nuevo poste.

const DEFAULT_INIT_TYPE = CableTypes.AERIAL + ":" + AerialCableColors.RED;

export function useAerialCables({
    paths,
}) {
    const [aerialCables, setAerialCables] = useState(
        Object.values(AerialCableColors).reduce((acc, color) => {
            return {
                ...acc,
                [color]: []
            };
        }, {})
    );
    const [cableIdtoAdd, setCableIdtoAdd] = useState(null);
    const [hasInitialized, setHasInitialized] = useState(false);
    const [aerialCopy, setAerialCopy] = useState(aerialCables);

    const getAerialCables = () => {
        return aerialCables;
    };

    const getAerialTypeByCableId = (cableId) => { //Dado el objeto aerialCables, retornar un parseo con forma "aerial:<color>", el color tomandolo de la key "color" que contenga el cableId
        if (Object.entries(aerialCables).find(([color, cables]) => cables.some((cable) => cable === cableId)) === undefined) {
            return null;
        }
        return CableTypes.AERIAL + ":" + Object.entries(aerialCables).find(([color, cables]) => cables.some((cable) => cable === cableId))[0];
    }

    const parseAerialCableType = (cableType) => {
        if (!cableType) {
            return { cableType: null, color: null };
        }
        const [cableTypePrefix, color] = cableType.split(":");
        return {
            cableType: cableTypePrefix,
            color
        };
    };

    const addAerialCable = (cable, color) => {
        setAerialCables((prevAerialCables) => {
            return {
                ...prevAerialCables,
                [color]: [...prevAerialCables[color], cable]
            };
        });
    };

    const removeAerialCable = (cable, color) => {
        setAerialCables((prevAerialCables) => {
            return {
                ...prevAerialCables,
                [color]: prevAerialCables[color].filter((cableId) => cableId !== cable)
            };
        });
    }

    const replaceAerialCable = (cable, oldColor, newColor) => {
        setAerialCables((prevAerialCables) => {
            return {
                ...prevAerialCables,
                [oldColor]: prevAerialCables[oldColor].filter((cableId) => cableId !== cable),
                [newColor]: [...prevAerialCables[newColor], cable]
            };
        });
    }

    const setPreviousState = () => {
        setAerialCables(aerialCopy);
    }

    const initDiagramAerials = () => {
        if (!paths) {
            return;
        }
        console.log("INIT DIAGRAM AERIALS paths: ", paths);
        const aerialCables = Object.entries(paths).filter(([cableId, values]) => {
            if (!values.cableType) {
                return false;
            } else {
                const { cableType } = values;
                const { cableType: parsedCableType } = parseAerialCableType(cableType);
                return parsedCableType === CableTypes.AERIAL;
            }
        });
        console.log("INIT AERIAL CABLES: ", aerialCables);
        aerialCables.forEach(([cableId, values]) => {
            const { color } = parseAerialCableType(values.cableType);
            console.log(color);
            addAerialCable(cableId, color);
        });
    };

    const isCableInAerialCables = (cableId) => { //Return the cableId and the color if the cableId is in the aerialCables object
        // the aerialCables has form: { [color]: [cable1, cable2, ...], ... }
        return Object.entries(aerialCables).find(([color, cables]) => cables.some((cable) => cable === cableId));
    };

    const addDefaultAerialCable = (cableId) => {
        addAerialCable(cableId, AerialCableColors.RED);
        return DEFAULT_INIT_TYPE;
    };

    //Paths is a object with the form { [cableId]: { cableType: "aerial:red" }, ... }

    useEffect(() => {
        if (!hasInitialized && Object.keys(paths).length > 0) {
            console.log("INITIALIZING AERIALS");
            initDiagramAerials();
            setHasInitialized(true);
        }
    }, [paths, hasInitialized]);

    return {
        cableIdtoAdd,
        setCableIdtoAdd,
        getAerialCables,
        setAerialCables,
        addAerialCable,
        removeAerialCable,
        isCableInAerialCables,
        getAerialTypeByCableId,
        replaceAerialCable,
        addDefaultAerialCable,
        initDiagramAerials,
        setAerialCopy,
        setPreviousState,
        parseAerialCableType
    };
};