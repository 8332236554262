import React, {useState} from 'react';
import {Box, Container, Paper, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import IconFuente from "../Icons/IconFuente";
import CableTooltip from "./CableTooltip";
import CadenaCablesEquipo from "./CadenaCablesEquipo";
import SplitterCadenas from "./SplitterCadenas";

const Cubiculo = ({cubiculo, setTopBarName, configuraciones}) => {
    const anchoCable = function () {
        let res = 4000
        res = res + cubiculo.cadena_cables.length * 244
        res = res + cubiculo.splitters.map((splitter) =>
            (splitter.cadena_salida1.length + splitter.cadena_salida2.length + splitter.cadena_salida3) * 250 + 100)
            .reduce((a, b) => a + b, 0)
        return res
    }

    return (
        <Box component={"span"} sx={{
            display: "block",
            marginBottom: '16px',
            height: "auto",

        }} width={`${anchoCable()}px`}>
            <Typography variant="h6" color={"initial"} style={{margin: '8px'}}>{cubiculo.nombre}</Typography>
            <Box sx={{
                display: "flex",
                marginBottom: '16px',
                height: "auto",
                flexDirection: "row",
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
            }} width={`${anchoCable()}px`}>
                <Box sx={{
                    backgroundColor: '#E4EBEF',
                    height: '66px',
                    width: '86px',
                    padding: 0,
                    marginLeft: '8px',
                    whiteSpace: "nowrap",
                    minWidth: '86px'
                }}>
                    <IconFuente fill_color='#0E537B'/>
                </Box>
                <Box sx={{
                    whiteSpace: "nowrap"
                }}><CadenaCablesEquipo cables={cubiculo.cadena_cables}
                                       setTopBarName={setTopBarName}
                                       configuraciones={configuraciones}
                                       cubiculo={cubiculo}
                /></Box>


            </Box>

        </Box>
    )
};

export default Cubiculo;