import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axios, {get} from "axios";
import LoadingGif from "../../assets/LoadingGif";
import Cubiculo from "./Cubiculo";
import {useTheme} from "@emotion/react";


const DetalleSubestacion = ({token, setTopBarName, topBarOpen}) => {
    const theme = useTheme();
    const styles = {
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            backgroundColor: '#E4EBEF',
            maxHeight: "auto",
            display: "flex",
            flexDirection: "column",
            visibility: "visible",
            width: "auto"
        },
        botonRegresar: {
            marginBottom: '10px',
        },

    }
    let {subestacionId} = useParams();
    const [subestacion, setSubestacion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [configuraciones, setConfiguraciones] = useState({});
    const [ultimaActualizacion, setUltimaActualizacion] = useState(Date.now());
    const [segsDesdeActualizacion, setSegsDesdeActualizacion] = useState(0);
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'};
    const color_desactivado = '#565D60';
    const navigate = useNavigate();

    useEffect(() => {
        setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        let timerID = setInterval(() => {
            setSegsDesdeActualizacion(Math.round((Date.now() - ultimaActualizacion) / 1000));
        }, 5000)
        return () => {
            clearInterval(timerID)
        }
    }, [ultimaActualizacion])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            axios.get(`/configuracion_faenas/ultima/`, config),
        ])
            .then(resArr => {
                setConfiguraciones(resArr[0].data);
            })
            .catch(function (error) {
                console.log(error)
            })
    }, [token, subestacionId])

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        get(`/subestaciones/${subestacionId}/?cubiculos=true&detalle=true`, config)
            .then((res) => {
                if (res.status === 200) {
                    setSubestacion(res.data);
                    setUltimaActualizacion(Date.now());
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            })

        const intervalID = setInterval(() => {
            get(`/subestaciones/${subestacionId}/?cubiculos=true&detalle=true`, config)
                .then((res) => {
                    if (res.status === 200) {
                        setSubestacion(res.data);
                        setUltimaActualizacion(Date.now());
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }, 30000)

        return () => {
            clearInterval(intervalID);
        }
    }, [token, subestacionId])

    return (
        <main style={styles.content} role="main">
            <div style={styles.appBarSpacer}/>
            <Box style={styles.container}>
                <Box style={{display: "flex", flexDirection: "row", marginBottom: '10px'}}>
                    <Box style={styles.botonRegresar}>
                        <Button
                            variant="contained"
                            startIcon={<ChevronLeftIcon/>}
                            onClick={() => {
                                setTopBarName();
                                navigate(-1);
                            }}>
                            Regresar
                        </Button>
                    </Box>
                    <Typography style={{position: "absolute", right: 0, paddingRight: 30, margin: 0}}>
                        Última Actualización : Hace {segsDesdeActualizacion} [s]</Typography>
                </Box>
                {
                    loading ?
                        <Container
                            maxWidth={"sm"}><LoadingGif/>
                        </Container> : subestacion ? (subestacion.cubiculos ? subestacion.cubiculos.map((cubiculo) => {
                            return (
                                <Cubiculo cubiculo={cubiculo}
                                          setTopBarName={setTopBarName}
                                          configuraciones={configuraciones}/>
                            )
                        }) : '-') : '-'
                }
            </Box>
        </main>
    );
};

export default DetalleSubestacion;