import { useEffect, useState } from "react";
import { isComponentConnectionPoint, parseConnectionCompoundId } from "../components/DiagramaUnilineal/utils";
import {
    CONNECTION_INPUT,
    CONNECTION_OUTPUT
} from "../constants/single-line-diagram";
import { isDeepEqual } from "../utils/is-deep-equal";
import { deepCopy } from "../utils/deep-copy";
import { SLDComponentEnum, SLDSymbols } from "../constants/single-line-diagram";
import { SLDComponentsManager } from "../managers/single-line-diagram/ComponentsManager";
import { getSimpleUUID } from "../utils/get-simple-uuid";

export function useSLDComponents(initialValue = {}) {

    const [remoteComponents, setRemoteComponents] = useState(initialValue);
    const [localComponents, setLocalComponents] = useState(initialValue);

    // useEffect(() => {
    //     console.log("USE EFFECT REMOTE/LOCAL COMPONENTS: ", {remoteComponents, localComponents, hasLocalChanges: hasLocalChanges()});
    // }, [remoteComponents, localComponents]);

    useEffect(() => {
        console.log("USE EFFECT LOCAL COMPONENTS: ", { localComponents });
    }, [localComponents]);

    const setRetrievedDiagramComponents = (components) => {
        console.log("USE SLD COMPONENTS: SET RETRIEVED DIAGRAM COMPONENTS: COMPONENTS: ", components);
        setRemoteComponents(components);
        setLocalComponents(components);
    };

    const hasLocalChanges = () => {
        return !isDeepEqual(remoteComponents, localComponents);
    };

    const resetLocalChanges = () => setLocalComponents(remoteComponents);

    const updateRemoteToLocal = () => {
        console.log("updateRemoteToLocal (components): ", { localComponents, deepCopy: deepCopy(localComponents)});
        setRemoteComponents(deepCopy(localComponents));
    };

    const setComponent = ({ key, value }) => {
        setLocalComponents(prev => ({
            ...prev, [key]: value
        }));
    };

    // TODO: check value and key non existing
    const addComponent = ({ key, value }) => {
        console.log("ADD COMPONENT: ", {key, value})
        setLocalComponents(prev => ({
            ...prev, [key]: value
        }));
    };

    // TODO: check value and key existing
    const updateComponent = (props) => {
        const { key, value } = props || {}; // Caution: If done it in the previous line it throws error
        if (!key || !value) {
            throw new Error("INVALID KEY OR VALUE TO UPDATE");
        }
        console.log("UPDATE COMPONENT: ", key, value);
        setLocalComponents(prev => {
            const newValue = {
                ...prev,
                [key]: {...prev[key], ...deepCopy(value)}
            };

            return newValue;
        });
    };

    const addNonInventoriedComponent = ({ type = "no-code-load", name, position = {} }) => {
        const id = getSimpleUUID();
        const key = [type, id].join("_");
        const { x = 500, y = 500 } = position;
        setLocalComponents(prev => ({
            ...prev,
            [key]: {
              type: "no-code-load",
              x, y, name,
              model: "-",
              inputs: {
                "0": {
                  "type": "in",
                  "connection": {
                    "pathId": null
                  }
                }
              },
              outputs: {}
            }
        }));
    };

    const updateComponentConnection = ({ compoundKey, pathId }) => {
        console.log("DEBUG: ", { compoundKey, pathId });
        const { componentId, connectionType, connectionId } = parseConnectionCompoundId(compoundKey);
        const connectionTypeKey = connectionType === "out" ? "outputs" : "inputs";

        console.log("DEBUG updateComponentConnection: ", {compoundKey, componentId, connectionType, connectionId, connectionTypeKey, pathId});

        setLocalComponents(prev => {
            try { // in case it's used with a cable instead of a component
                const newValue = deepCopy(prev);
                console.log("DEBUG prev state: ", prev);
                console.log("DEBUG component ID: ", componentId);
                console.log("DEBUG component: ", newValue[componentId]);
                newValue[componentId][connectionTypeKey][connectionId].connection.pathId = pathId;
                console.log("DEBUG new state: ", newValue);

                return newValue;
            } catch (e) {
                return prev
            }
        })
    };

    const getComponentOutputPoints = () => {
        const connectionPoints = {};

        Object.entries(localComponents).forEach(([componentsKey, component]) => {
            if (!component.outputs) {
                return;
            }
            Object.entries(component.outputs).forEach(([key, output]) => {
                const compoundKey = componentsKey + ":out:" + key;
                connectionPoints[compoundKey] = {...output};
            });

        });

        return connectionPoints;
    };

    const getOutputPoint = (id) => {
        return getComponentOutputPoints()[id];
    };

    const getComponentInputPoints = () => {
        const connectionPoints = {};

        Object.entries(localComponents).forEach(([componentsKey, component]) => {
            if (!component.inputs) {
                return;
            }
            Object.entries(component.inputs).forEach(([key, output]) => {
                const compoundKey = componentsKey + ":in:" + key;
                connectionPoints[compoundKey] = {...output};
            });

        });

        return connectionPoints;
    };

    const getInputPoint = (id) => {
        const { x, y } = getComponentInputPoints()[id];
        return { x, y };
    };

    const deleteComponent = (id) => { //TODO: when delete component, delete their connections (inputs/outputs) in the inventory.

        const deleteComponentConnectionsInDiagram = ({ componentId, newState }) => {
            const component = newState[componentId];
            const outputs = component.outputs || {};
            const inputs = component.inputs || {};

            Object.entries(outputs).forEach(([key, output]) => {
                if (output.connection) {
                    output.connection.pathId = null;
                }
            });

            Object.entries(inputs).forEach(([key, input]) => {
                if (input.connection) {
                    input.connection.pathId = null;
                }
            });
        }

        deleteComponentConnectionsInDiagram({ componentId: id, newState: localComponents });

        setLocalComponents((prevState) => {
            const newState = {};
            Object.entries(prevState)
                .filter(([key, value]) => key  !== id)
                .forEach(([key, value]) => {
                    newState[key] = value;
                });
            return newState;
        });
    };

    const getConnectionPathId = (compoundId) => {
        const { componentId, connectionType, connectionId } = parseConnectionCompoundId(compoundId);
        return localComponents?.[componentId]?.[connectionType+"puts"]
          ?.[connectionId]?.connection?.pathId || null;
    };

    const deleteCable = ({ sourceConnectionId = null, destinyConnectionId = null}) => {
        const deletePathComponentConnection = ({ compoundId, newState }) => {
            const { componentId, connectionType, connectionId } = parseConnectionCompoundId(compoundId);
            const connectionTypeKey = connectionType === CONNECTION_OUTPUT
                ? "outputs" : "inputs";
            if (newState?.[componentId]?.[connectionTypeKey]?.[connectionId]?.connection?.pathId) {
                newState[componentId][connectionTypeKey][connectionId].connection.pathId = null;
            }
        };

        const checkAndDeleteConnection = ({ compoundId, newState }) => {
            if(compoundId === null) {
                return
            }
            const isToComponentConnection = isComponentConnectionPoint(compoundId);
            if (!isToComponentConnection) {
                return;
            }
            deletePathComponentConnection({
                compoundId, newState
            });
        };

        setLocalComponents((prev) => {
            const newState = { ...prev };
            checkAndDeleteConnection({compoundId: sourceConnectionId, newState});
            checkAndDeleteConnection({compoundId: destinyConnectionId, newState});

            return newState;
        });
    };

    const replaceCableInConnection = ({ component, componentId, typeOfCableBehavior, newCableId, cableId }) => { //Works for sourceComponent and for destinyComponent
        const typeOfComponent = component.type;

        if (typeOfComponent === SLDComponentEnum.SUBSTATION) {
            const output = component.outputs?.[componentId];
            if (output && output.connection) {
                output.connection.pathId = newCableId;
            }

        } else if (typeOfComponent === SLDComponentEnum.LOAD) {
          const input = component.inputs?.[componentId];
          if (input && input.connection) {
            input.connection.pathId = newCableId;
          }

        } else if (typeOfComponent === SLDComponentEnum.SPLITTER) {
          if (typeOfCableBehavior === CONNECTION_OUTPUT) {
            const outputs = Object.values(component.outputs);
            outputs.forEach((output) => {
                if (output.connection.pathId !== null && output.connection.pathId === cableId) {
                output.connection.pathId = newCableId;
                }
            });
          }
          else {
            const input = component.inputs?.[componentId];
            if (input && input.connection) {
                input.connection.pathId = newCableId;
            }
          }
        }
    };

    /**
     * Replace a cable in the info of its adjacent components.
     */
    const replaceCable = ({ cableId = null, newCableId = null, pathManager }) => {
        console.log("REPLACE CABLE: ", { cableId, newCableId, pathManager });
        let stateChangeError = null;
        setLocalComponents((prevState) => {
            try {
                const componentsManager = new SLDComponentsManager({components: prevState});

                if (pathManager.prevIsComponent()) {
                    console.log("PREV IS COMPONENT:");
                    const prevComponentId = pathManager.getSourceComponentId();
                    console.log("PREV IS COMPONENT: PREV COMPONENT ID: ", prevComponentId);
                    const outputsEntries = Object.entries(prevState[prevComponentId].outputs);
                    console.log("PREV IS COMPONENT: OUTPUT ENTRIES: ", outputsEntries, { cableId });
                    const outputEntry = outputsEntries.find(([outputKey, value]) => value?.connection?.pathId === cableId);
                    console.log("PREV IS COMPONENT: OUTPUT ENTRY: ", outputEntry);
                    const outputId = outputEntry[0];
                    componentsManager.replaceCable({
                        componentId: prevComponentId, connectionType: CONNECTION_OUTPUT, outputId, newCableId
                    });
                }

                if (pathManager.nextIsComponent()) {
                    const nextComponentId = pathManager.getDestinyComponentId();
                    componentsManager.replaceCable({
                        componentId: nextComponentId, connectionType: CONNECTION_INPUT, newCableId
                    });
                }

                return componentsManager.components;
            } catch (e) {
                stateChangeError = e;
                return prevState;
            }
        });
        if(stateChangeError) {
            throw new Error(`Error while replacing cable at useSLDComponents: \n ${stateChangeError}`);
        }
    };

    const toggleSymbol = ({ componentId, symbol }) => {
        if (!Object.keys(localComponents).includes(componentId)) {
            return;
        }

        if (![SLDSymbols.TRANSFORMER, SLDSymbols.GENERATOR].includes(symbol)) {
            console.error(`Symbol ${symbol} not supported.`);
            throw new Error(`Symbol ${symbol} not supported.`);
        }

        setLocalComponents(p => {
            const { [componentId]: component = {}, ...otherComponents } = p;
            const { linkedSymbols = {}, ...otherProps } = component;
            const { [symbol]: symbolToToggle, ...otherSymbols} = linkedSymbols

            const newState = {
                ...otherComponents,
                [componentId]: {
                    ...otherProps,
                    linkedSymbols: {
                        ...otherSymbols
                    }
                }
            };

            if (!symbolToToggle) {
                newState[componentId].linkedSymbols[symbol] = {}
            }

            return newState;
        });
    };

    return {
        components: localComponents,
        loadComponents: setRetrievedDiagramComponents,
        hasLocalChanges,
        resetLocalChanges,
        updateRemoteToLocal,
        addComponent,
        updateComponent,
        addNonInventoriedComponent,
        updateComponentConnection,
        getComponentOutputPoints,
        getOutputPoint,
        getComponentInputPoints,
        getInputPoint,
        deleteComponent,
        getConnectionPathId,
        deleteCable,
        replaceCable,
        toggleSymbol
    };
}