import React from 'react';
import {Box, ButtonBase} from "@mui/material";


const Cable = ({setTopBarName}) => {
    const color = {'operativo': '#529454', 'peligro': '#F8991E', 'peligro_maximo': '#B51111'}
    return (
        <ButtonBase onClick={setTopBarName}>
            <Box sx={{
                width: '41.98px',
                height: '13.58px',
                marginTop: '19px',
                marginRight: '1px',
                marginLeft: '1px',
            }} style={{backgroundColor: color['operativo']}}>
            </Box>
        </ButtonBase>

    );
};

export default Cable;
