import { Circle, Group, Text } from "react-konva";
import React from "react";
import { PositionReferenceTypes, GENERATOR_RADIUS } from "../../constants/single-line-diagram";

const GENERATOR_COLORS = {
    STROKE: "#000000",
    FILL: "#00B050",
    TEXT: "#FFFFFF"
};

export function SLDGenerator({
    id,
    x = 0,
    y = 0,
    alignType = PositionReferenceTypes.CENTER
}) {


    const alignedPositionByAlignType = {
        [PositionReferenceTypes.TOP_LEFT]: { x: x + GENERATOR_RADIUS, y: y + GENERATOR_RADIUS },
        [PositionReferenceTypes.TOP_RIGHT]: { x: x - GENERATOR_RADIUS, y: y + GENERATOR_RADIUS },
        [PositionReferenceTypes.BOTTOM_LEFT]: { x: x + GENERATOR_RADIUS, y: y - GENERATOR_RADIUS },
        [PositionReferenceTypes.BOTTOM_RIGHT]: { x: x - GENERATOR_RADIUS, y: y - GENERATOR_RADIUS },
        [PositionReferenceTypes.CENTER]: { x, y },
        [PositionReferenceTypes.CENTER_LEFT]: { x: x + GENERATOR_RADIUS, y }
    };

    const alignedPosition = alignedPositionByAlignType?.[alignType] ?? { x, y };


    return (
        <>
            <Group
                x={alignedPosition.x}
                y={alignedPosition.y}
            >
                <Circle
                    key={`generator-circle:${id}`}
                    x={0} y={0}
                    radius={GENERATOR_RADIUS}
                    fill={GENERATOR_COLORS.FILL}
                    strokeWidth={1}
                    stroke={GENERATOR_COLORS.STROKE}
                />
                <Text
                    key={`generator-text:${id}`}
                    x={-GENERATOR_RADIUS}
                    y={-(GENERATOR_RADIUS/2)}
                    fontSize={GENERATOR_RADIUS}
                    width={2 * GENERATOR_RADIUS}
                    align={"center"}
                    text={"G"}
                    fill={GENERATOR_COLORS.TEXT}
                    backgroundColor={"black"}
                />
            </Group>
        </>
    )
}