import axios from "axios";

export async function getPlugReadings({ token, cableIds = [] }) {
    try {
        const headers = { Authorization: `Token ${token}`};
        const params = { cable_ids: cableIds.join(',') };
        const config = { headers, params };
        // console.log("getPlugReadings: config: ", config);
        const response = await axios.get('/inventario/plugs_info/', config);
        if (response.status !== 200) {
            throw Error("Status = " + response.status);
        }
        const data = {};
        console.log("getPlugReadings: response.data: ", response.data);
        Object.entries(response.data).forEach(([key, value]) => data["plug_"+key] = value)
        return data;
    } catch (e) {
        console.error("Error in getPlugReadings: ", e);
    }
}

export async function getPlugsLast24hReadings({ token, plugs_ids = [] }) {
    try {
        const headers = { Authorization: `Token ${token}`};
        const params = { plugs_ids: plugs_ids.join(',') };
        const config = { headers, params };

        const res = await axios.get(`/mediciones/voltaje/last_24_hours/?enchufes=${plugs_ids.join(',')}`, config);
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(`getPlugsLast24hReadings failed with status ${res.status}`);

    } catch (e) {
        console.error("Error in getPlugsLast24hReadings: ", e);
    }
}

export async function getPlugs({ token }) {
    try {
        const headers = { Authorization: `Token ${token}`};
        const config = { headers };

        const response = await axios.get(`/enchufes/`, config)
        console.log("PLUGS: ", response.data)
        return response.data;
    } catch (e) {
        console.error(e);
    }
}

export async function getCablesWithPosition({ config }) {
    try {
        const cablesResponse = await axios.get(`/cablesinventory/get_cables_with_context/`, config);
        console.log("GET CABLES WITH POSITION: ", cablesResponse)

        if (cablesResponse.status === 200) {
            return cablesResponse.data;
        }
        throw new Error(`getCablesWithPosition failed with status ${cablesResponse.status}`)
    } catch (e) {
        console.error("Error on getCablesWithPosition: ", e);
    }
}