import { Menu, MenuItem, Checkbox, Tooltip, Stack } from "@mui/material";
import React, { useEffect } from "react";

export function ContextualMenu({
    position = null,
    items = null,
    isOpen = false,
    close = () => console.log("close"),
    itemsWithCheckboxes = null,
}) {
    const [checks, setChecks] = React.useState([]);

    useEffect(() => {
        if (itemsWithCheckboxes) {
            setChecks(itemsWithCheckboxes.map(() => false));
        }
    }, [itemsWithCheckboxes]);


    return (
        <Menu
            open={Boolean(position)}
            onClose={close}
            onContextMenu={(event) => event.preventDefault()}
            anchorReference="anchorPosition"
            anchorPosition={position ?? undefined}
        >
            {(itemsWithCheckboxes ?? []).map(({ action, label, onChange, tooltipInfo }, index) => (
                <Stack direction="row" key={index} justifyContent="flex-start" alignItems="center" spacing={0}>
                    <MenuItem divider key={index} sx={{ width: "100%" }} onClick={() => {
                        action({ checked: checks[index] });
                        close();
                    }}>
                        {label}
                    </MenuItem>
                    <Tooltip title={tooltipInfo} arrow>
                        <Checkbox
                            checked={checks[index] ?? false}
                            onChange={() => {
                                setChecks((checks) => {
                                    const newChecks = [...checks];
                                    newChecks[index] = !newChecks[index];
                                    return newChecks;
                                });
                            }}
                            size="small"
                        />
                    </Tooltip>
                </Stack>
            ))}
            {(items ?? []).map(({ action, label }, index) => (
                <MenuItem divider key={index} onClick={() => {
                    action();
                    close();
                }}>
                    {label}
                </MenuItem>
            ))}
        </Menu>
    );
}
