import { Checkbox, Dialog, DialogActions, DialogContent,
    DialogTitle, Button, Grid, Typography, Paper } from "@mui/material";
import React from "react";


export function SLDAerialCableSelector({
    onCancel,
    onSelect,
    isOpen,
    aerialCables,
    allAerialsCables,
  }) {

    const {addAerialCable, removeAerialCable, cableIdtoAdd, replaceAerialCable } = aerialCables;
    const forLabelCableId = (cableIdtoAdd) ? cableIdtoAdd.replace("cable_", "Cable ") : null;
    
    const addAerialToState = ([color, cableId]) => {
        const isCableInAerial = aerialCables.isCableInAerialCables(cableId);
        if (isCableInAerial) {
            const [inAerialsColor, cables] = isCableInAerial;

            if (inAerialsColor !== color) {
                replaceAerialCable(cableId, inAerialsColor, color);
                
            } else {
                removeAerialCable(cableId, color);

            }
        } else {
            addAerialCable(cableId, color);

        }
    };

    const rows = Object.entries(allAerialsCables).map(([color, cables]) => {

        const currentChecked = cables.some((cable) => cable === cableIdtoAdd);

        return (
            <Paper key={color} sx={{padding: 1, margin: 1}} elevation={6}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item xs={2}>
                        <Checkbox 
                            checked={currentChecked}
                            onClick={() => {
                                addAerialToState([color, cableIdtoAdd]);
                            }}
                            sx={{
                                color: color,
                                '&.Mui-checked': {
                                    color: color,
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>{cables.map((cable) => cable.replace("cable_", "Cable ")).join(", ")}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    });

    const handleClose = () => {
        onCancel();
    }

    const handleConfirm = () => {
        onSelect();
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth>
            <DialogTitle>Seleccione donde agregar: {forLabelCableId}</DialogTitle>
            <DialogContent>
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {rows}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleConfirm}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );

}