import {ReactComponent as IconSVG} from '../../svg/gear.svg';
import React from 'react';
import {theme} from "../../App";


function IconGear() {
    return (
        <IconSVG alt="icon_gear" style={{
            width: "3em",
            height: "3em",
            borderRadius: "0%",
            textAlign: "center",
            lineHeight: "3em",
            verticalAlign: "middle",
            center: "true",
            fill: theme.palette.primary.main,
        }}></IconSVG>
    );
}

export default IconGear;