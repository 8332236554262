import axios from "axios";

export async function loginByUsernameAndPassword({
            username,
            password
}) {
    try {
        const credentials = { username, password };
        const response = await axios.post('/login/', credentials);
        if (response.status !== 200) {
            throw Error("loginByUsernameAndPassword failed with values: ", response);
        }
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}