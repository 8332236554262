import {ReactComponent as DashboardSvg} from '../../svg/dashboard.svg';
import React from 'react';

function IconDashboard({marginRightValue }) {
    return (
        <DashboardSvg alt="dashboard"style={{
            background: "white",
            width: "4em",
            height: "auto",
            borderRadius: "50%",
            textAlign: "center",
            lineHeight: "4em",
            verticalAlign: "middle",
            marginRight: marginRightValue,
        }}></DashboardSvg>
    );
}

export default IconDashboard;