import React, {useEffect, useState} from 'react';
import {
    Button,
    Container,
    Divider,
    InputAdornment,
    Paper,
    Snackbar,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import Alert from "../Icons/Alert";
import {useTheme} from "@emotion/react";


const NewNumberField = ({label, measure, value, onValueChange}) => {

    return (
        <TextField
            type="number" label={`${label}`}
            id="standard-size-small" margin="8px"
            style={{margin: '20px', width: '300px'}}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">{measure}</InputAdornment>,
            }}
            value={value}
            onChange={(event) => {
                onValueChange(event.target.value)
            }}
            variant="outlined"
        />
    )
};


async function submitForm(formData, token) {
    return fetch('/configuracion_faenas/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(formData)
    }).then(function (response) {
        if (response.ok) {
            return response.json()
        } else {
            console.log("ErrorMessage")
            return false
        }
    })
        .catch(function (err) {
            console.log(err)
        })
}


const Configuracion = ({token}) => {
    const theme = useTheme();
    const styles = {
        root: {
            display: "flex",
            flexDirection: "row",
            alignContent: "space-around",
            maxHeight: "100%",
        },
        appBarSpacer: {
            height: '60px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        paper: {
            backgroundColor: 'white',
            flex: 1,
            padding: "1em",
            margin: "1em",
            maxHeight: "100%",
            flexWrap: "wrap"
        },
        botonRegresar: {
            marginBottom: '10px',
        },
        titulo: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        formaUmbral: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
        },
        button: {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
            width: "500px",
            margin: "auto",
        },

    }
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackBarText, setSnackBarText] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info')
    const [umbralVoltajeP, setUmbralVoltajeP] = useState(0.0);
    const [umbralVoltajeC, setUmbralVoltajeC] = useState(0.0);
    const [umbralTIP, setUmbralTIP] = useState(0.0);
    const [umbralTIC, setUmbralTIC] = useState(0.0);
    const [umbralHP, setUmbralHP] = useState(0.0);
    const [umbralHC, setUmbralHC] = useState(0.0);
    const [nDatosVoltaje, setNDatosVoltaje] = useState(100);
    const [nDatosTemp, setNDatosTemp] = useState(100);
    const [nDatosSonido, setNDatosSonido] = useState(100);
    const [nDatosHumedad, setNDatosHumedad] = useState(100);
    const config = {
        headers: {Authorization: `Token ${token}`}
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const setValues = function (data) {
        setUmbralVoltajeP(data.umbral_diferencia_voltaje_peligro);
        setUmbralVoltajeC(data.umbral_diferencia_voltaje_critico);
        setUmbralTIP(data.umbral_diferencia_tinterior_peligro);
        setUmbralTIC(data.umbral_diferencia_tinterior_critico);
        setUmbralHP(data.umbral_diferencia_humedad_peligro);
        setUmbralHC(data.umbral_diferencia_humedad_critico);
        setNDatosVoltaje(data.datos_en_grafico_voltaje);
        setNDatosTemp(data.datos_en_grafico_temperatura);
        setNDatosSonido(data.datos_en_grafico_sonido);
        setNDatosHumedad(data.datos_en_grafico_humedad);

    };

    useEffect(() => {
        const config = {
            headers: {Authorization: `Token ${token}`}
        };
        axios.all([
            axios.get(`/configuracion_faenas/ultima/`, config),
        ])
            .then(resArr => {
                const res = resArr[0].data
                setValues(res);
            })
    }, [])

    const handleCreation = (data) => {
        axios.post("/configuracion_faenas/", data, config)
            .then(function (res) {
                    if (res.status === 201) {
                        setSnackBarText('La configuracion ha sido editada exitosamente');
                        setSnackbarSeverity('success');
                        setSnackbarOpen(true);
                        axios.get(`/configuracion_faenas/ultima/`, config)
                            .then(res => {
                                if (res.status === 200) {
                                    setValues(res.data);
                                }
                            })
                            .catch(function (err) {
                                console.log(err);
                            })
                    } else {
                        setSnackBarText('Ha habido un problema con la edición de configuraciones');
                        setSnackbarSeverity('error');
                        setSnackbarOpen(true);
                    }
                }
            ).catch(function (err) {
                console.log(err)
            }
        )

    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (umbralVoltajeC > umbralVoltajeP) {
            setSnackBarText('El umbral Critico de Voltaje debe ser igual o menor al umbral de Peligro');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        if (umbralTIC < umbralTIP) {
            setSnackBarText('El umbral Critico de Temperatura debe ser igual o mayor al umbral de Peligro');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        if (umbralHC < umbralHP) {
            setSnackBarText('El umbral Critico de Humedad debe ser igual o mayor al umbral de Peligro');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return
        }
        const data = {
            umbral_diferencia_voltaje_peligro: umbralVoltajeP,
            umbral_diferencia_voltaje_critico: umbralVoltajeC,
            umbral_diferencia_tinterior_peligro: umbralTIP,
            umbral_diferencia_tinterior_critico: umbralTIC,
            umbral_diferencia_humedad_peligro: umbralHP,
            umbral_diferencia_humedad_critico: umbralHC,
            datos_en_grafico_voltaje: Math.round(nDatosVoltaje),
            datos_en_grafico_temperatura: Math.round(nDatosTemp),
            datos_en_grafico_sonido: Math.round(nDatosSonido),
            datos_en_grafico_humedad: Math.round(nDatosHumedad),
        }
        handleCreation(data);
    }

    return (
        <main sx={styles.content}>
            <div sx={styles.appBarSpacer}/>
            <Container sx={styles.root} maxWidth="xl">
                <form sx={styles.formaUmbral} onSubmit={handleSubmit}>
                    <Paper component={Container} sx={styles.paper}>
                        <Typography align="center" sx={styles.titulo}>Configuración de Sitio</Typography>
                        <Divider/>
                        <Typography>URL Imagen Lateral</Typography>
                        <Typography>URL Imagen Rotada</Typography>
                        <Typography>Segundos Refresco Página</Typography>
                        <Typography>Segundos Refresco Gráfico</Typography>
                        <NewNumberField label="Cantidad de Datos de Voltaje en Gráfico" measure="" value={nDatosVoltaje}
                                        onValueChange={setNDatosVoltaje}/>
                        <NewNumberField label="Cantidad de Datos de Temperatura en Gráfico" measure=""
                                        value={nDatosTemp}
                                        onValueChange={setNDatosTemp}/>
                        <NewNumberField label="Cantidad de Datos de Sonido en Gráfico" measure="" value={nDatosSonido}
                                        onValueChange={setNDatosSonido}/>
                        <NewNumberField label="Cantidad de Datos de Humedad en Gráfico" measure="" value={nDatosHumedad}
                                        onValueChange={setNDatosHumedad}/>
                    </Paper>
                    <Paper component={Container} sx={styles.paper}>
                        <Typography align="center" sx={styles.titulo}>Umbrales</Typography>
                        <Divider/>

                        <NewNumberField label="Umbral diferencia voltaje (Peligro)" measure="V" value={umbralVoltajeP}
                                        onValueChange={setUmbralVoltajeP}/>
                        <NewNumberField label="Umbral diferencia voltaje (Crítico)" measure="V" value={umbralVoltajeC}
                                        onValueChange={setUmbralVoltajeC}/>
                        <NewNumberField label="Umbral temperatura interior (Peligro)" measure="°C" value={umbralTIP}
                                        onValueChange={setUmbralTIP}/>
                        <NewNumberField label="Umbral temperatura interior (Crítico)" measure="°C" value={umbralTIC}
                                        onValueChange={setUmbralTIC}/>
                        <NewNumberField label="Umbral humedad (Peligro)" measure="%" value={umbralHP}
                                        onValueChange={setUmbralHP}/>
                        <NewNumberField label="Umbral humedad (Crítico)" measure="%" value={umbralHC}
                                        onValueChange={setUmbralHC}/>

                    </Paper>
                    <Container maxWidth={"xl"}>
                        <Button type="submit" variant="contained" sx={styles.button}
                                style={{display: "block", margin: "auto"}}
                                fullWidth={false}>
                            Guardar
                        </Button>
                    </Container>
                </form>

            </Container>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackBarText}
                </Alert>
            </Snackbar>
        </main>
    );
};

export default Configuracion;
