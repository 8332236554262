import { CONNECTION_OUTPUT, CONNECTION_POINT_RADIUS } from "../../../constants/single-line-diagram";
import { isNullish } from "../../../utils/is-nullish";

export function getConnectionOffsets(params) {
  const requiredParams = ['connectionType', 'connectionTypeAmount', 'index', 'width', 'height'];
  const missingParams = requiredParams.filter(param => isNullish(params[param]));

  if (missingParams.length > 0) {
    throw new Error(`Missing parameters: [${missingParams.join(', ')}]`);
  }

  const { connectionType, connectionTypeAmount, index, width, height } = params;

  const xGroupOffset = connectionType === CONNECTION_OUTPUT
    ? width + CONNECTION_POINT_RADIUS
    : -CONNECTION_POINT_RADIUS;
  const yGroupOffset = getOutputYOffset({ index, height, connectionTypeAmount });

  return { x: xGroupOffset, y: yGroupOffset };
}

export const getOutputYOffset = ({index, height, connectionTypeAmount}) => {
  Object.entries({index, height, connectionTypeAmount})
    .forEach(([key, value]) => {
      if (typeof value !== "number") {
        throw new Error(`${key} is not a number`);
      }
    });

  return (index + 0.5) * (height / connectionTypeAmount);
};