import { Group, Line, Rect, Shape } from "react-konva";
import React from "react";
import { PositionReferenceTypes, GENERATOR_RADIUS, TransformerSize } from "../../constants/single-line-diagram";

export function SLDTransformer({
    id,
    x = 0,
    y = 0,
    alignType = PositionReferenceTypes.TOP_LEFT
}) {

    const alignedPositionByAlignType = {
        [PositionReferenceTypes.TOP_LEFT]: { x, y },
        [PositionReferenceTypes.TOP_RIGHT]: { x: x - TransformerSize.WIDTH, y },
        [PositionReferenceTypes.BOTTOM_LEFT]: { x, y: y - TransformerSize.HEIGHT },
        [PositionReferenceTypes.BOTTOM_RIGHT]: { x: x - TransformerSize.WIDTH, y: y - TransformerSize.HEIGHT },
        [PositionReferenceTypes.CENTER]: { x: x - TransformerSize.WIDTH/2, y: y - TransformerSize.HEIGHT/2 },
        [PositionReferenceTypes.CENTER_LEFT]: { x, y: y - TransformerSize.HEIGHT/2 }
    };

    const alignedPosition = alignedPositionByAlignType?.[alignType] ?? { x, y };

    const basePointRatios = [
        { x: 0, y: 0 },
        { x: 0.45, y: 1/6 },
        { x: 0, y: 2/6 },
        { x: 0.45, y: 3/6 },
        { x: 0, y: 4/6 },
        { x: 0.45, y: 5/6 },
        { x: 0, y: 1 },
    ];

    const leftLinePoints = basePointRatios.reduce((prev, curr) => {
        return [...prev, curr.x * TransformerSize.WIDTH, curr.y * TransformerSize.HEIGHT];
    }, []);

    const rightLinePoints = basePointRatios.reduce((prev, curr) => {
        return [...prev, (1 - curr.x) * TransformerSize.WIDTH, curr.y * TransformerSize.HEIGHT];
    }, []);

    return (
        <>
            <Group
                // onClick={onClick}
                // onDblClick={onDoubleClick}
                // onMouseEnter={onMouseEnter}
                // onMouseLeave={onMouseLeave}
                x={alignedPosition.x}
                y={alignedPosition.y}
            >
                <Rect {...{ width: TransformerSize.WIDTH, height: TransformerSize.HEIGHT }} />
                <Line
                    key={`transformer-left-line:${id}`}
                    points={leftLinePoints}
                    stroke={"black"}
                    strokeWidth={1}
                    bezier={true}
                    tension={0.3}
                />
                <Line
                    key={`transformer-right-line:${id}`}
                    points={rightLinePoints}
                    stroke={"black"}
                    strokeWidth={1}
                    bezier={true}
                    tension={0.3}
                />
            </Group>
        </>
    )
}