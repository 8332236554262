import { useState } from "react";

export function usePath(initialValue = []) {
    const [points, setPoints] = useState(initialValue);

    const setWithoutLast = (newPoints) => {
        setPoints((prev) => {
            const withoutLast = [...prev];
            withoutLast.pop();
            return [...withoutLast, newPoints];
        });
    };

    const addPoint = (newPoint) => {
        console.log("ADD POINT: ", newPoint);
        if (points.length > 1) {
            const last = points[points.length - 1];
            const beforeLast = points[points.length - 2];
            if ((last.x === beforeLast.x && last.x === newPoint.x) || (last.y === beforeLast.y && last.y === newPoint.y)) {
                setWithoutLast(newPoint);
                return;
            }
        }

        setPoints((prev) => [...prev, newPoint]);
    };

    const addPointBeforeLast = (newPoint) => {
        setPoints((prev) => {
            const withoutLast = [...prev]
            const last = withoutLast.pop();
            return [...withoutLast, newPoint, last]
        });
    };

    const reset = () => {
        setPoints(initialValue);
    }

    return { points, setPoints, addPoint, addPointBeforeLast, reset };
}
