import {ReactComponent as IconSVG} from '../svg/logo_aiko_vertical.svg';
import React from 'react';

function LogoAikoVertical() {
    return (
        <IconSVG alt="logo_aiko_vertical" style={{
            borderRadius: "0%",
            margin: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        }}/>
    );
}

export default LogoAikoVertical;
