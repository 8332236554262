import { set } from 'date-fns';
import { useEffect, useState } from 'react';

export function useVoltGraph() {
    const [firstPlugConnection, setFirstPlugConnection] = useState(null);
    const [secondPlugConnection, setSecondPlugConnection] = useState(null);
    const [flagBothPlugsSelected, setFlagBothPlugsSelected] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [graphData, setGraphData] = useState({});

    const resetPlugsSelection = () => {
        setFirstPlugConnection(null);
        setSecondPlugConnection(null);
        setFlagBothPlugsSelected(false);
    };

    const isFirstPlugSelected = () => {
        return firstPlugConnection !== null;
    }

    const selectPlug = (plug) => {
        if (!firstPlugConnection) {
            setFirstPlugConnection(plug);
        } else if (!secondPlugConnection) {
            setSecondPlugConnection(plug);
        } else {
            resetPlugsSelection();
            setFirstPlugConnection(plug);
        }
    }

    const getSelectedPlugs = () => {
        return {firstPlugConnection, secondPlugConnection};
    };

    const isSelectingTheSamePlug = (plugId) => {
        return firstPlugConnection === plugId;
    };

    useEffect(() => {
        console.log("firstPlugConnection: ", firstPlugConnection);
        console.log("secondPlugConnection: ", secondPlugConnection);
        if (firstPlugConnection && secondPlugConnection) {
            setFlagBothPlugsSelected(true);
        }
    }, [firstPlugConnection, secondPlugConnection]);

    return { selectPlug, 
        resetPlugsSelection, 
        isFirstPlugSelected, 
        getSelectedPlugs,
        flagBothPlugsSelected,
        isSelectingTheSamePlug, 
        graphData, 
        setGraphData, 
        setFirstPlugConnection, 
        setSecondPlugConnection,
        setSnackBarOpen,
        snackBarOpen,
    };
};