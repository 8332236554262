import { Circle, Group, Line, Text } from "react-konva";
import React, { useEffect, useRef, useState } from "react";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import {
    SLDConnection,
    CONNECTION_STATES,
} from "./single-line-diagram-connection-point";
import { SLDComponentEnum } from "./constants";
import { MouseButtons } from "./constants";
import {
    PositionReferenceTypes,
    CableTypes,
    PathSegmentTypes,
    roundToGridSize,
    sizesByCableType,
    CONNECTION_POINT_RADIUS,
    CONNECTION_CABLE_DESTINY,
    CONNECTION_CABLE_SOURCE,
    CONNECTION_AIR
} from "../../constants/single-line-diagram";
import { InsertCableSides } from "../../constants/single-line-diagram";
import { deepCopy } from "../../utils/deep-copy";
import { SLDInfoParser } from "../../managers/single-line-diagram/InfoParser";
import { InfoDisplayer, CableInfoDisplayer } from "../InfoDisplayer";
import { SLDCableSymbol } from "./SLDCableSymbol";
import { PathNode } from "../../managers/single-line-diagram/PathNode";
import { useKonvaShapeCache } from "../../hooks/single-line-diagram/useKonvaShapeCache";
import { isNullish } from "../../utils/is-nullish";
import { useNavigate } from "react-router-dom";
import { parse } from "date-fns";
import { string } from "prop-types";

export const CableStates = {
    "OK": "ok",
    "ALARM": "alarm",
    "YELLOW_ALARM": "yellow-alarm",
    "RED_ALARM": "red-alarm"
};

const BLACK = "black";
// const DARK_GRAY = "#333";
const CABLE_YELLOW = "#DDE051"
const CABLE_RED = "#922";
const viewModeCableColors = {
    [CableStates.OK]: BLACK,
    [CableStates.YELLOW_ALARM]: CABLE_YELLOW,
    [CableStates.RED_ALARM]: CABLE_RED
};

const HOVER_COLOR = "#222";
const EDITING_CABLE_BLUE = "blue";
const DELETE_CABLE_RED = "red";
const EDITED_CABLE_GRAY = "gray"
const HIGHLIGHT_COLOR = "orange";

export function Path({
    id,
    state = CableStates.OK,
    points = [],
    isCurrentPath = false,
    pathInfo = {},
    onlyConnection = false, // to make connections deploy over the paths when deploying Path array with a map
}) {
    const { singleLineDiagramMode: diagramMode,
        deleteCable,
        selectPathStart,
        inventoryEquipments,
        setIsBeingReplace,
        endPathOnAir,
        contextualMenu,
        insertCable: insertCableToContext,
        cableSelector,
        updatePathPoints,
        moveDestinyConnectionPoint,
        moveSourceConnectionPoint,
        modal,
        stageInteractions,
        hoveredComponent,
        updatePathInfo,
        selectPathEnd,
        dockComponentsInstance,
        handleUndockComponents,
        downloadingDiagram,
        aerialCables,
        diagramHighlight,
        componentSelector,
        voltGraphInstance,
        goToPlugsGraph
    } = React.useContext(SingleLineDiagramContext);
    const [isHovered, setIsHovered] = useState(false);
    const [hoveredPointIndex, setHoveredPointIndex] = useState(null);
    const lastPointIsHovered = hoveredPointIndex === points.length - 1;
    const navigate = useNavigate();

    const pathCache = useKonvaShapeCache({
        id: isNullish(id)
            ? null
            : onlyConnection
                ? "connections:"+id
                : "path:"+id,
        defaultCache: false
    });
    useEffect(() => {
		if(stageInteractions.isDragging || stageInteractions.isZooming) {
			pathCache.enableCache();
		} else {
			pathCache.disableCache();
		}
	}, [stageInteractions.isDragging, stageInteractions.isZooming, pathCache]);

    // Mocks of cache in case they are needed to be implemented later for performance.
    const useCacheMock = () => ({
        ref: useRef(null),
        enableCache: () => {},
        disableCache: () => {},
        redraw: () => {}
    });
    const verticesCache = useCacheMock();
    const linesCache = useCacheMock();
    const labelCache = useCacheMock();

    const isBeingEdited = () => {
        return diagramMode.inEditCableMode() && diagramMode.cableIsSelected(id);
    };

    const getAlarmState = () => {
        const alarmsIds = ['voltaje_ab', 'voltaje_bc', 'voltaje_ca'];
        const cableInfo = inventoryEquipments[id];
        const alarmStates = alarmsIds.map((alarmId) => {
            return cableInfo?.["voltage_alarms"]?.[alarmId] ?? null;
        });
        if (alarmStates.includes(CableStates.RED_ALARM)) {
            return CableStates.RED_ALARM;
        }
        if (alarmStates.includes(CableStates.YELLOW_ALARM)) {
            return CableStates.YELLOW_ALARM;
        }
        return CableStates.OK;
    };

    const getColor = () => {
        const cableInfo = inventoryEquipments[id];
        const cableInventoryState = cableInfo?.inventory_state ?? null;
        if (cableInventoryState === 'error') {
            return viewModeCableColors[CableStates.RED_ALARM];
        }

        const alarmState = getAlarmState();

        if (diagramHighlight.isHighlighted(id)) return HIGHLIGHT_COLOR;
        if (diagramMode.inOnlyViewMode() && alarmState !== CableStates.OK) {
            return viewModeCableColors[getAlarmState()];
        }
        if (isCurrentPath) return EDITING_CABLE_BLUE;
        if (diagramMode.inDeleteMode() && isHovered) return DELETE_CABLE_RED;
        // if (diagramMode.inEditCableMode() && isHovered) return EDITING_CABLE_BLUE;
        if (diagramMode.inReplaceCableMode() && isHovered) return EDITING_CABLE_BLUE;
        // if (isBeingEdited()) return EDITED_CABLE_GRAY;
        if (isHovered) return HOVER_COLOR;
        return BLACK;
    };

    const pathHoverMethods = {
        onMouseEnter: event => {
            setIsHovered(true);
            hoveredComponent.setHoveredComponentId(id);
            const container = event.target.getStage().container();
            container.style.cursor = 'pointer';
            diagramHighlight.removeHighlightedComponent(id);
        },
        onMouseLeave: event => {
            setIsHovered(false);
            hoveredComponent.resetHoveredComponent();
            const container = event.target.getStage().container();
            container.style.cursor = 'default';
        }
    };

    const getMiddlePoint = (point1, point2) => {
        return {
            x: (point1.x + point2.x) / 2,
            y: (point1.y + point2.y) / 2
        };
    };

    const onLeftClickCable = (event) => {
        if (diagramMode.inDeleteMode()) {
            selectPathToDelete(event);
        }
        if (diagramMode.inReplaceCableMode()) {
            setIsBeingReplace(true);
            diagramMode.selectCableToEdit(id);
            console.log("Cable selected to replace: ", id);
            console.log("Cable selected to replace: ", pathInfo);
        }
        const availableToChoseCableToEdit = diagramMode.inEditCableMode()
            && !diagramMode.hasSelectedCable();
        if (availableToChoseCableToEdit) {
            selectPathToEdit(event);
        }
        event.cancelBubble = true;
    };

    const CableParts = {
        VERTEX: "vertex",
        SEGMENT: "segment",
        TYPE_SYMBOL: "type-symbol"
    };

    const onRightClickCable = ({ event, type = CableParts.SEGMENT, index = null }) => {
        const { evt } = event;

        const menuItemsWithCheckboxes = [
            {
                label: "Eliminar cable",
                action: ({ checked }) => {
                    console.log("Eliminar cable en falla: ", checked);
                    selectPathToDelete(event, checked);
                },
                tooltipInfo: "Cable en falla"
            }
        ];

        const menuItems = [
            {
                label: "Ver Información",
                action: () => openCableInfo()
            }
        ];
        if (!diagramMode.inAnyEditMode()) {

            contextualMenu.open({
                position: { top: evt.y, left: evt.x },
                items: menuItems
            });

            event.cancelBubble = true;
            return;
        }

        if (index !== null) {
            menuItems.push({
                label: "Insertar cable a la izquierda",
                action: () => insertCable({ index, type, side: InsertCableSides.BEFORE })
            });
            menuItems.push({
                label: "Insertar cable a la derecha",
                action: () => insertCable({ index, type, side: InsertCableSides.AFTER })
            });
        }



        {
            const cableInfo = pathInfo.cableInfo ?? {};
            const cableTypes = Object.keys(cableInfo);

            console.log("cableInfo in SLDPATH: ", cableInfo);
            console.log("cableTypes in SLDPATH: ", cableTypes);

            const setCableType = (cableType, cableTypeColor = null) => updatePathInfo({ key: id, info: { 
                cableInfo: { 
                    ...cableInfo, 
                    [cableType]: { color: cableTypeColor } 
                } 
            }});

            const deleteCableType = (cableType) => {
                const newCableInfo = { ...cableInfo };
                delete newCableInfo[cableType]; //Si el objeto queda vacio, eliminar cableInfo, no dejarlo en null, sino que se borre del objeto.
                if (Object.keys(newCableInfo).length === 0) {
                    updatePathInfo({ key: id, info: { cableInfo: null } });
                    return;
                }
                updatePathInfo({ key: id, info: { cableInfo: newCableInfo } });
            };

            const cableTypeNames = {
                [CableTypes.AERIAL]: "aéreo",
                [CableTypes.PIPE_CABLE]: "cable tubería",
                [CableTypes.LED]: "led",
                // [CableTypes.DOUBLE_AERIAL]: "aéreo doble"
            };
            const getLabelByType = {
                [CableTypes.AERIAL]: () => "Editar aéreo",
                [CableTypes.PIPE_CABLE]: isActivated => (isActivated ? "Desmarcar " : "Marcar ") + "como cable tubería",
                [CableTypes.LED]: isActivated => (isActivated ? "Desmarcar " : "Marcar ") + "como cable led",
            }
            Object.entries(cableTypeNames).forEach(([type, name]) => {
                const label = getLabelByType[type](cableTypes.includes(type));

                const action = type === CableTypes.AERIAL
                    ? () => aerialCables.setCableIdtoAdd(id)
                    : () => {
                        (cableTypes.includes(type))
                            ? deleteCableType(type)
                            : setCableType(type);
                    }

                menuItems.push({ label, action });
            });
        }

        if (index !== null && type === CableParts.SEGMENT) {
            menuItems.push({
                label: "Agregar segmento",
                action: () => addSegment(index)
            });
        }

        if (index !== null && type === CableParts.VERTEX) {
            const isDeletable = index > 1 && index < points.length - 2;
            if (isDeletable) {
                menuItems.push(({
                    label: "Eliminar vértice",
                    action: () => deleteVertex(index)
                }));
            }
        }

        contextualMenu.open({
            position: { top: evt.y, left: evt.x },
            items: menuItems,
            itemsWithCheckboxes: menuItemsWithCheckboxes,
        });
        event.cancelBubble = true;
    };

    const openCableInfo = () => {
        const prevCableId = (pathInfo?.prevCableId ?? "").replace("cable_", "");
        const cableInfo = { ...getComponentInfo(), cable_anterior: prevCableId };
        console.log("Cable info: ", cableInfo);

        if (cableInfo) {
            const parsedInfo = SLDInfoParser.parseCableInfo(cableInfo);
            console.log("Parsed cable info: ", parsedInfo);
            let component = null;
            if (parsedInfo.voltage_alarms) {
                component = CableInfoDisplayer({ info: parsedInfo });
            } else {
                const parsedInfoWoVoltageAlarms = Object.entries(parsedInfo).filter(([key]) => key !== "voltage_alarms");
                component = InfoDisplayer({ info: Object.fromEntries(parsedInfoWoVoltageAlarms) });
            }
            modal.setModal({
                title: `Cable ${cableInfo.codigo_cliente}`,
                component,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        }
    };

    const getComponentInfo = () => {
        if (!Array.isArray(id)) {
            return inventoryEquipments?.[id] ?? null;
        }
        return id.map(id => inventoryEquipments?.[id] ?? null);
    };

    const insertCable = ({ index, type, side = InsertCableSides.BEFORE }) => {
            cableSelector.setSelector({
                title: 'Seleccione cable',
                confirmLabel: 'seleccionar',
                cancelLabel: 'cancelar',
                onSelect: (cableId) => {
                    const getSplittedPoints = () => {
                        if (type === CableParts.VERTEX) {
                            return [
                                points.slice(0, index + 1),
                                points.slice(index)
                            ];
                        }
                        const middlePoint = getMiddlePoint(points[index], points[index + 1]);
                        return [
                            [...points.slice(0, index + 1), middlePoint],
                            [middlePoint, ...points.slice(index + 1)]
                        ];
                    };
                    const [leftPoints, rightPoints] = getSplittedPoints();
                    insertCableToContext({
                        originalCableId: id,
                        newCableId: cableId,
                        side,
                        splittedPoints: [leftPoints, rightPoints]
                    });
                }
            });
        };

    const addSegment = (index) => {
        const point1 = points[index];
        const point2 = points[index + 1];

        const middlePoint = {
            x: (point1.x + point2.x) / 2,
            y: (point1.y + point2.y) / 2
        };

        const newPoints = deepCopy(points);
        newPoints.splice(index + 1, 0, middlePoint, middlePoint);
        updatePathPoints({ id, points: newPoints });
    };

    const deleteVertex = (index) => {
        const prevPoint = points[index - 1];
        const point = points[index];
        const nextPoint = points[index + 1];
        const newPoints = deepCopy(points);

        const getNewPoint = () => {
            if (point.x > prevPoint.x)
                return {
                    x: prevPoint.x,
                    y: nextPoint.y
                };
            return {
                x: nextPoint.x,
                y: prevPoint.y
            };
        };
        const newPoint = getNewPoint();
        newPoints.splice(index - 1, 3, newPoint);
        updatePathPoints({ id, points: newPoints });
    };

    const selectPathToDelete = (event, reasonFalla = false) => {
        deleteCable({ cableId: id, reasonFalla });
        event.cancelBubble = true;
    };

    const selectPathToEdit = (event) => {
        diagramMode.selectCableToEdit(id);
        event.cancelBubble = true;
    };

    /* deps = [
     * points
     * isCurrentPath, // radius, color
     * hoveredPointIndex, // radius, color
     * diagramMode, // getColor() => color
     * isHovered, // getColor() => color
     *]
     */
    const deployLines = () => {
        const pointsWithoutLast = points.slice(0, -1);
        const getLines = () => pointsWithoutLast.map(({ x, y }, index) => {
            const nextPoint = points[index + 1];
            const onClickLine = (event) => {
                const { button } = event?.evt;
                if (button === MouseButtons.LEFT) {
                    onLeftClickCable(event);
                }
                if (button === MouseButtons.RIGHT) {
                    onRightClickCable({
                        event, type: CableParts.SEGMENT, index
                    });
                }
            };

            const getWidth = () => {
                const baseWidth = 2;
                if (diagramHighlight.isHighlighted(id)) return 3 * baseWidth;
                if (isHovered) return 2 * baseWidth;
                if (diagramMode.inAnyEditMode() && isCurrentPath) return 3 * baseWidth;
                return baseWidth;
            };

            const type = y === nextPoint.y ?
                PathSegmentTypes.HORIZONTAL :
                PathSegmentTypes.VERTICAL;

            const draggable = diagramMode.inAnyEditMode()
                && index > 0
                && index < pointsWithoutLast.length - 1;

            const onDragStart = (e) => {};

            const onDragEnd = (e) => {
                if (type === PathSegmentTypes.VERTICAL) {
                    const deltaX = e.target.x();
                    const newPoints = deepCopy(points);
                    newPoints[index].x += deltaX;
                    newPoints[index + 1].x += deltaX;

                    updatePathPoints({ id, points: newPoints });
                }
                if (type === PathSegmentTypes.HORIZONTAL) {
                    const deltaY = e.target.y();
                    const newPoints = deepCopy(points);
                    newPoints[index].y += deltaY;
                    newPoints[index + 1].y += deltaY;

                    updatePathPoints({ id, points: newPoints });
                }
            };

            return (
                <>
                    <Line
                        key={`hover-from:${x},${y}to:${nextPoint.x},${nextPoint.y}`}
                        draggable={draggable}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        {...pathHoverMethods}
                        onClick={onClickLine}
                        points={[x, y, nextPoint.x, nextPoint.y]}
                        stroke={'transparent'}
                        strokeWidth={5 * getWidth() / stageInteractions.scale}
                    />
                    {getColor() === CABLE_YELLOW && (
                        <Line
                            key={`yellow-from:${x},${y}to:${nextPoint.x},${nextPoint.y}`}
                            draggable={draggable}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            {...pathHoverMethods}
                            onClick={onClickLine}
                            points={[x, y, nextPoint.x, nextPoint.y]}
                            stroke="black"
                            strokeWidth={getWidth() * 2}
                        />
                    )}
                    <Line
                        key={`from:${x},${y}to:${nextPoint.x},${nextPoint.y}`}
                        draggable={draggable}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                        {...pathHoverMethods}
                        onClick={onClickLine}
                        points={[x, y, nextPoint.x, nextPoint.y]}
                        stroke={getColor()}
                        strokeWidth={getWidth()}
                    />
                </>
            );
        });

        return (
            <Group ref={linesCache.ref}>
                {getLines()}
            </Group>
        );
    };

    /* deps = [
     * points
     * isCurrentPath, // radius, color
     * hoveredPointIndex, // radius, color
     * diagramMode, // getColor() => color
     * isHovered, // getColor() => color
     *]
     */
    const deployVertices = () => {
        const getVertices = () => points.map(({ x, y }, index) => {
            const isLastPoint = index >= points.length - 1;
            const lastSegmentIsHorizontal = points?.[index-1]?.y === y;
            const isFinishCablePoint = isLastPoint && points.length > 1 && lastSegmentIsHorizontal;

            const getRadius = () => {
                if (!isCurrentPath) return isHovered && diagramMode.inAnyEditMode() ? 4 : 3;
                if (!lastPointIsHovered || !isFinishCablePoint) return 5;
                return 8;
            };

            const isDeletable = index > 1 && index < points.length - 2 && !isCurrentPath;
            const vertexMouseEvents = {
                onMouseEnter: event => {
                    pathHoverMethods.onMouseEnter(event);
                    setHoveredPointIndex(index);
                },
                onMouseLeave: event => {
                    pathHoverMethods.onMouseLeave(event);
                    setHoveredPointIndex(null);
                }
            };

            const onClickVertex = (event) => {
                const { button } = event?.evt;
                if (button === MouseButtons.LEFT) {
                    onLeftClick(event);
                }
                if (button === MouseButtons.RIGHT) {
                    onRightClick(event);
                }
            };

            const onLeftClick = (event) => {
                const shouldEndPathOnAir = isCurrentPath && isFinishCablePoint;
                if (shouldEndPathOnAir) {
                    setHoveredPointIndex(null);
                    endPathOnAir();
                    event.cancelBubble = true;
                }
            };

            const onRightClick = (event) => {
                onRightClickCable({
                    event, type: CableParts.VERTEX, index
                });
            };

            const vertexIsHovered = hoveredPointIndex === index;

            const radius = vertexIsHovered && isDeletable
                ? getRadius() * 1.5
                : getRadius();
            const hoverRadius = 2 * getRadius() / stageInteractions.scale;
            const color = (() => {
                if (vertexIsHovered && isDeletable) {
                    return 'red';
                }
                if (isCurrentPath && isFinishCablePoint) {
                    return 'green';
                }
                return getColor();
            })();

            return (
                <>
                    <Circle
                        key={'hover-vertex:'+index}
                        x={x} y={y}
                        onClick={onClickVertex}
                        radius={hoverRadius}
                        stroke={'transparent'}
                        fill={'transparent'}
                        {...vertexMouseEvents}
                    />
                    <Circle
                        key={'vertex:'+index}
                        x={x} y={y}
                        onClick={onClickVertex}
                        radius={radius}
                        fill={color}
                        {...vertexMouseEvents}
                    />
                </>
            );
        });

        return (
            <Group ref={verticesCache.ref}>
                {getVertices()}
            </Group>
        );
    };

    const deployCableTypeSymbols = () => {
        const cableInfo = pathInfo.cableInfo ?? {};
        const cableTypes = Object.keys(cableInfo);
        if (points.length < 2 || !cableTypes.length) {
            return null;
        }

        //cableTypes tiene los tipos de cable que tiene el cable, por ejemplo, si el cable tiene un cable aéreo y un cable led, cableTypes será ["aerial", "led"]
        //Por cada tipo de cable, se creará un símbolo de cable, por ejemplo, si el cable tiene un cable aéreo y un cable led, se crearán dos símbolos de cable, uno para el cable aéreo y otro para el cable led.
        const pathManager = new PathNode(pathInfo);
        const prevIsComponent = pathManager.prevIsComponent();
        const nextIsComponent = pathManager.nextIsComponent();

        let offset = CONNECTION_POINT_RADIUS;

        const symbols = cableTypes.flatMap((cableType, index) => {
            const cableInfo = pathInfo.cableInfo[cableType]; //Se obtiene el tipo de cable y obtener el color.
            const color = cableInfo?.color ?? "black";
    
            const svgListInfo = [
                {
                    type: cableType,
                    x: points.at(0).x + offset
                        + (prevIsComponent ? CONNECTION_POINT_RADIUS : 0),
                    y: points.at(0).y,
                    positionReference: PositionReferenceTypes.CENTER_LEFT
                },
                {
                    type: cableType,
                    x: points.at(-1).x - offset
                        - (nextIsComponent ? CONNECTION_POINT_RADIUS : 0),
                    y: points.at(-1).y,
                    positionReference: PositionReferenceTypes.CENTER_RIGHT
                }
            ];
    
            const onClick = (event) => {
                const { button } = event?.evt;
                if (button === MouseButtons.LEFT) {
                    onLeftClickCable(event);
                }
                if (button === MouseButtons.RIGHT) {
                    onRightClickCable({
                        event, type: CableParts.TYPE_SYMBOL
                    });
                }
            };

            offset += sizesByCableType?.[cableType]?.width + 2 ?? 0;
    
            return svgListInfo.map((el, index) => (
                <Group {...pathHoverMethods} onClick={onClick} key={`cable-symbol-${cableType}-${index}`}>
                    <SLDCableSymbol
                        id={`cable-symbol-${cableType}-${index}`}
                        key={`cable-symbol-${cableType}-${index}`}
                        color={color}
                        {...el}
                    />
                </Group>
            ));
        });
    
        return symbols;
    };

    const deployLabels = () => {
        try {
            // const { scale } = stageInteractions;
            // const minScale = 0.4;
            // if(scale < minScale && !downloadingDiagram) { // Reduce level of detail for performance.
            //     return null
            // }

            if(points.length < 1) {
                return null
            }

            if (isBeingEdited()) {
                return null;
            }

            const cableInfo = inventoryEquipments?.[id] ?? {};
            const fontSize = 16;
            const { labelPosition = points[0] } = pathInfo;

            const onDragEnd = e => {
                const x = Math.round(e.target.x());
                const y = Math.round(e.target.y());

                const pointsWithDistance = points.map((p, index) => {
                    return { ...p, index, distance: Math.sqrt((p.x-x)**2 + (p.y-y)**2)}
                });

                const getSegmentType = segment => {
                    const [p1, p2] = segment;
                    return Math.abs(p1.y - p2.y) < Math.abs(p1.x - p2.x)
                        ? PathSegmentTypes.HORIZONTAL
                        : PathSegmentTypes.VERTICAL;
                    };

                const getNearestSegmentPoint = ({ point, segment }) => {
                    const [p1, p2] = segment;
                    const segmentType = getSegmentType(segment);
                    if (segmentType === PathSegmentTypes.HORIZONTAL) {
                        const xRange = segment.map(p => Math.round(p.x)).sort((a, b) => a-b);
                        const y = (p1.y + p2.y) / 2;
                        return {
                           x: Math.min(xRange[1], Math.max(xRange[0], point.x)),
                           y,
                           segmentType,
                           segment
                        };
                    }
                    if (segmentType === PathSegmentTypes.VERTICAL) {
                        const yRange = segment.map(p => p.y).sort();
                        const x = (p1.x + p2.x) / 2;
                        return {
                           x,
                           y: Math.min(yRange[1], Math.max(yRange[0], point.y)),
                           segmentType,
                           segment
                        };
                    }
                };

                const nearestPoint = pointsWithDistance.slice(0, -1).reduce((prevSegment, currPoint, index) => {
                    const nextPoint = pointsWithDistance[index+1];
                    const isLastSegment = index === pointsWithDistance.length - 2;
                    if (isLastSegment && getSegmentType([currPoint, nextPoint]) === PathSegmentTypes.HORIZONTAL) {
                        const estimatedLabelWidth = 75;
                        nextPoint.x = Math.max(currPoint.x, nextPoint.x - estimatedLabelWidth);
                    }

                    const segment = [currPoint, nextPoint];
                    let nearestSegmentPoint = getNearestSegmentPoint({
                        point: { x, y },
                        segment
                    });

                    nearestSegmentPoint = {
                        ...nearestSegmentPoint,
                        distance: Math.sqrt((x-nearestSegmentPoint.x)**2 + (y-nearestSegmentPoint.y)**2)
                    }
                    const shouldReplace = !prevSegment || (
                        nearestSegmentPoint.distance < prevSegment.distance
                    );
                    return shouldReplace ? nearestSegmentPoint : prevSegment;
                }, null);

                const newPosition = {
                    x: Math.round(nearestPoint.segmentType === PathSegmentTypes.HORIZONTAL
                        ? nearestPoint.x
                        : nearestPoint.segment[0].x) + Math.random(),
                    y: Math.round(nearestPoint.y) + Math.random()
                };
                updatePathInfo({ key: id, info: { labelPosition: newPosition }});
            };

            const xOffset = (() => {
                const hasLabelPosition = Boolean(pathInfo.labelPosition);
                const prevIsComponent = Boolean(pathInfo.sourceConnectionId) && !Boolean(pathInfo.prevCableId);
                if (hasLabelPosition) {
                    return 5;
                }
                if (prevIsComponent) {
                    return CONNECTION_POINT_RADIUS * 2 + 5
                }
                return CONNECTION_POINT_RADIUS + 5
            })();

            return (
                <Group ref={labelCache.ref} draggable={diagramMode.inAnyEditMode()} onDragEnd={onDragEnd}
                    x={labelPosition.x}
                    y={labelPosition.y}
                    {...pathHoverMethods}
                >
                    <Text key={`cable-label-name-and-calibre:${id}`}
                        text={`${cableInfo?.['codigo_cliente'] ?? "???"} (${cableInfo?.['calibre'] ?? "???"})`}
                        x={xOffset}
                        y={-fontSize}
                        fontSize={fontSize}
                    />
                    <Text key={`cable-label-large:${id}`}
                        text={`mts ${cableInfo?.['largo'] ?? "???"}`}
                        x={xOffset}
                        y={2}
                        fontSize={fontSize}
                    />
                    {/*<Circle radius={5} strokeWidth={2} fill={"red"} />*/}
                </Group>
            );
        } catch (e) {
            console.error("Error deploying cable labels: ", e);
            return null;
        }
    };

    const connectionCircleRef = useRef(null);
    useEffect(() => {
    if (connectionCircleRef.current) {
        connectionCircleRef.current.moveToTop();
        // Alternatively: circleRef.current.zIndex(someValue);
        }
    }, []);

    const deploySourceConnectionPoint = () => {
        if (isBeingEdited()) {
            return;
        }
        const sourceIsConnected = pathInfo.sourceConnectionId !== CONNECTION_AIR;

        if (sourceIsConnected || points.length < 1) {
            return;
        }

        const firstPoint = points[0];
        const isAvailable = pathInfo.prevCableId === null

        const connectionId = id + ":" + CONNECTION_CABLE_SOURCE + ":" + firstPoint.x + ":" + firstPoint.y;
        // const compoundId = id + ":" + CONNECTION_CABLE_SOURCE

        const openConnectionInfo = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();

            const prevPlugParsedInfo = SLDInfoParser.parsePlugInfo(prevPlug, prevPlug?.["alarms"]);
            const nextPlugParsedInfo = SLDInfoParser.parsePlugInfo(nextPlug, nextPlug?.["alarms"]);

            const component = (<table>
                <thead>
                    <tr>
                        {prevPlug?.["eclamp"] && <th>{prevPlug?.['display_name'] ?? "sin enchufe hembra"}</th>}
                        {nextPlug?.["eclamp"] && <th>{nextPlug?.['display_name'] ?? "sin enchufe macho"}</th>}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {prevPlug?.["eclamp"] && <td><InfoDisplayer info={prevPlugParsedInfo} alarms={prevPlug?.["alarms"]} /></td>}
                        {nextPlug?.["eclamp"] && <td><InfoDisplayer info={nextPlugParsedInfo} alarms={nextPlug?.["alarms"]} /></td>}
                    </tr>
                </tbody>
            </table>);
            modal.setModal({
                title: `Enchufes`,
                component,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        };

        const onClick = (event) => {
            const { evt } = event;
            const getSourcePointPosition = () => {
                const { x, y } = firstPoint;
                return { x, y };
            };

            if (evt.button === MouseButtons.LEFT && isAvailable && dockComponentsInstance.isFirstComponentInDocking() && !dockComponentsInstance.isDockingTheSameComponent(connectionId) && !diagramMode.inOnlyViewMode()) {
                dockComponentsInstance.dockComponents(connectionId);

            } else if (evt.button === MouseButtons.LEFT && isAvailable && !diagramMode.inDeleteMode()) {
                // selectPathStart(connectionId);
                console.log("PATH ENDS IN A 'ON-AIR' CONNECTION POINT: ", connectionId);
                console.log("SOURCE POINT POSITION: ", getSourcePointPosition());
                selectPathEnd(connectionId);
            } else if(evt.button === MouseButtons.RIGHT) {

                const menuItems = [
                    {
                        label: "Ver información",
                        action: openConnectionInfo
                    },
                ];
                if (isAvailable && !diagramMode.inDeleteMode() && !diagramMode.inOnlyViewMode()) {
                    menuItems.push({
                        label: "Acoplar a otra conexión",
                        action: () => {
                            dockComponentsInstance.dockComponents(connectionId);
                        }
                    });
                }
                contextualMenu.open({
                    position: { top: evt.y, left: evt.x },
                    items: menuItems
                });
            }
            event.cancelBubble = true;
        };

        const getPairedPlugIds = () => {
            const inventoryEntries = Object.entries(inventoryEquipments);

            const cableEntries = inventoryEntries
                .filter(([key, value]) => value.type === SLDComponentEnum.CABLE);
            const prevCableId = pathInfo.prevCableId;
            const nextCableId = id;
            const [keyIndex, valueIndex] = [0, 1];
            const prevCable = cableEntries.find(([key]) => key === prevCableId)?.[valueIndex] ?? null;
            const nextCable = cableEntries.find(([key]) => key === nextCableId)?.[valueIndex] ?? null;

            const prevPlugId = prevCable === null ? null : "plug_" + prevCable["enchufe_hembra"];
            const nextPlugId = nextCable === null ? null : "plug_" + nextCable["enchufe_macho"];

            return { prevPlugId, nextPlugId };
        };

        const getPairedPlugs = () => {
            const inventoryEntries = Object.entries(inventoryEquipments);
            const plugEntries = inventoryEntries
                .filter(([key, value]) => value.type === "plug");

            const { prevPlugId, nextPlugId } = getPairedPlugIds();
            const [keyIndex, valueIndex] = [0, 1];
            const prevPlug = plugEntries.find(([key]) => key === prevPlugId)?.[valueIndex] ?? null;
            const nextPlug = plugEntries.find(([key]) => key === nextPlugId)?.[valueIndex] ?? null;
            return { prevPlug, nextPlug };
        }

        const plugHasEclamp = (plug) => {
            return plug?.["eclamp"] ?? false;
        }

        const getConnectionAlarmStatus = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();

            if (!plugHasEclamp(prevPlug) && !plugHasEclamp(nextPlug)) {
                return CONNECTION_STATES.NO_SENSOR;
            }

            const alarmIds = [
                ["eclamp_no_data"],
                ["humidity"],
                ["inner_temperature", "temp_interior_ab"],
                ["inner_temperature", "temp_interior_bc"],
                ["inner_temperature", "temp_interior_ca"],
                ["voltages", "voltaje_ab"],
                ["voltages", "voltaje_bc"],
                ["voltages", "voltaje_ca"]
            ];
            const getPlugAlarms = (plug) => alarmIds.map((keysArray) => {
                if (plug === null)
                    return [];
                return ["alarms", ...keysArray].reduce((prev, key) => {
                    return prev?.[key] ?? null;
                }, plug);
            });
            const alarmsStatus = [...getPlugAlarms(prevPlug), ...getPlugAlarms(nextPlug)];

            if (alarmsStatus.includes(CONNECTION_STATES.NO_DATA)) {
                return CONNECTION_STATES.NO_DATA;
            }

            if (alarmsStatus.includes(CONNECTION_STATES.RED_ALARM)) {
                return CONNECTION_STATES.RED_ALARM;
            }

            if (alarmsStatus.includes(CONNECTION_STATES.YELLOW_ALARM)) {
                return CONNECTION_STATES.YELLOW_ALARM;
            }

            return CONNECTION_STATES.OK;
        }

        const onMouseEnterConnection = event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'pointer';

            const { prevPlugId, nextPlugId } = getPairedPlugIds();
            const plugIds = [prevPlugId, nextPlugId].filter(id => id !== null);
            console.log("PLUG IDS: ", plugIds);
            if (Array.isArray(plugIds) && plugIds.length) {
                hoveredComponent.setHoveredComponentId(plugIds);
            }
        }

        const onMouseLeaveConnection = event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'default';

            hoveredComponent.resetHoveredComponent();
        }

        const connectionHoverMethods = {
            onMouseEnter: onMouseEnterConnection,
            onMouseLeave: onMouseLeaveConnection
        };

        const dragMethods = {
            onDragMove: event => {},
            onDragEnd: event => {
                if(!diagramMode.inAnyEditMode()) {
                    return;
                }
                const x = roundToGridSize(event.target.x());
                const y = roundToGridSize(event.target.y());
                moveSourceConnectionPoint({
                    cableId: id,
                    dropPoint: { x, y },
                });
            }
        };

        const isSelectingTheSamePlug = () => {
            let { firstPlugConnection } = voltGraphInstance.getSelectedPlugs();
            if (!firstPlugConnection) {
                return;
            } else {
                if (typeof firstPlugConnection === "number") {
                    firstPlugConnection = "plug_" + firstPlugConnection;
                }
            }
            const { prevPlugId, nextPlugId } = getPairedPlugIds()
            console.log("SELECTED PLUGS: ", firstPlugConnection, prevPlugId, nextPlugId);
            return firstPlugConnection === prevPlugId || firstPlugConnection === nextPlugId;
        }

        const cableHasEclamp = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();
            return plugHasEclamp(prevPlug) || plugHasEclamp(nextPlug);
        }

        return (
            <SLDConnection
                key={`source-connection-point:${id}`}
                x={firstPoint.x}
                y={firstPoint.y}
                state={getConnectionAlarmStatus()}
                isSelected={false}
                isAvailable={isAvailable}
                hasEclamp={cableHasEclamp() && !isSelectingTheSamePlug()}
                type={CONNECTION_CABLE_SOURCE}
                draggable={diagramMode.inAnyEditMode()}
                onClick={onClick}
                {...connectionHoverMethods}
                {...dragMethods}
                ref={connectionCircleRef}
            />
        );
    };

    const deployDestinyConnectionPoint = () => {
        if (isBeingEdited()) {
            return;
        }
        const destinyIsConnected = pathInfo.destinyConnectionId !== null;

        if (destinyIsConnected || points.length < 1) {
            return;
        }

        const connectionId = id + ":" + CONNECTION_CABLE_DESTINY;

        const lastPoint = points.at(-1);
        const isAvailable = pathInfo.nextCableId === null;

        const compoundId = connectionId + ":" + lastPoint.x + ":" + lastPoint.y;

        const openConnectionInfo = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();

            const prevPlugParsedInfo = SLDInfoParser.parsePlugInfo(prevPlug, prevPlug?.["alarms"]);
            const nextPlugParsedInfo = SLDInfoParser.parsePlugInfo(nextPlug, nextPlug?.["alarms"]);

            const component = (<table>
                <thead>
                    <tr>
                        {prevPlug?.["eclamp"] && <th>{prevPlug?.['display_name'] ?? "sin enchufe hembra"}</th>}
                        {nextPlug?.["eclamp"] && <th>{nextPlug?.['display_name'] ?? "sin enchufe macho"}</th>}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {prevPlug?.["eclamp"] && <td><InfoDisplayer info={prevPlugParsedInfo} alarms={prevPlug?.["alarms"]} /></td>}
                        {nextPlug?.["eclamp"] && <td><InfoDisplayer info={nextPlugParsedInfo} alarms={nextPlug?.["alarms"]} /></td>}
                    </tr>
                </tbody>
            </table>);
            modal.setModal({
                title: `Enchufes`,
                component,
                isOpen: true,
                cancelLabel: "volver",
                onClose: modal.resetModal
            });
        };

        const onClick = (event) => {
            const { evt } = event;
            if (evt.button === MouseButtons.LEFT && isAvailable && dockComponentsInstance.isFirstComponentInDocking() && !dockComponentsInstance.isDockingTheSameComponent(compoundId) && !diagramMode.inOnlyViewMode()) {
                dockComponentsInstance.dockComponents(compoundId);

            } else if(evt.button === MouseButtons.LEFT && !diagramMode.inDeleteMode() && voltGraphInstance.isFirstPlugSelected()) {
                const { prevPlug, nextPlug } = getPairedPlugs();
                if (!plugHasEclamp(prevPlug) && !plugHasEclamp(nextPlug)) {
                    return;
                }
                const { displayPrevPlug, displayNextPlug } = {
                    displayPrevPlug: prevPlug?.display_name ?? null,
                    displayNextPlug: nextPlug?.display_name ?? null
                };

                const labelVoltPrev = "Comparar voltaje - " + displayPrevPlug;
                const labelVoltNext = "Comparar voltaje - " + displayNextPlug;

                const menuItems = [];
                if (plugHasEclamp(prevPlug)) {
                    menuItems.push({
                        label: labelVoltPrev,
                        action: () => voltGraphInstance.selectPlug(prevPlug?.id ?? null)
                    });
                }

                if (plugHasEclamp(nextPlug)) {
                    menuItems.push({
                        label: labelVoltNext,
                        action: () => voltGraphInstance.selectPlug(nextPlug?.id ?? null)
                    });
                }

                contextualMenu.open({
                    position: { top: evt.y, left: evt.x },
                    items: menuItems
                });
                    

            } else if(evt.button === MouseButtons.LEFT && isAvailable && !diagramMode.inDeleteMode()) {
                selectPathStart(connectionId, true);
                
            } else if(evt.button === MouseButtons.RIGHT) {
                const { prevPlug, nextPlug } = getPairedPlugs();
                const { displayPrevPlug, displayNextPlug } = {
                    displayPrevPlug: prevPlug?.display_name ?? null,
                    displayNextPlug: nextPlug?.display_name ?? null
                };

                const labelPrevPlug = `Gráfico Enchufe ${displayPrevPlug}`;
                const labelNextPlug = `Gráfico Enchufe ${displayNextPlug}`;

                const labelVoltPrev = `Caída de Tensión - ${displayPrevPlug}`;
                const labelVoltNext = `Caída de Tensión - ${displayNextPlug}`;

                const menuItems = [];

                if (cableHasEclamp()) {
                    menuItems.push({
                        label: "Ver información",
                        action: openConnectionInfo
                    });
                }
                if (isAvailable && !diagramMode.inDeleteMode() && !diagramMode.inOnlyViewMode()) {
                    menuItems.push({
                        label: "Agregar cable",
                        action: () => {
                            componentSelector.setType(SLDComponentEnum.CABLE);
                            componentSelector.setConnectionPoint(connectionId);
                        }
                    })
                }
                if (isAvailable && !diagramMode.inDeleteMode() && !diagramMode.inOnlyViewMode()) {
                    menuItems.push({
                        label: "Acoplar a otra conexión",
                        action: () => {
                            dockComponentsInstance.dockComponents(compoundId);
                        }
                    },
                    
                );
                } else if (!isAvailable && !diagramMode.inDeleteMode() && !diagramMode.inOnlyViewMode()) {
                    menuItems.push({
                        label: "Desacoplar conexión",
                        action: () => {
                            handleUndockComponents(compoundId);
                        }
                    });
                }

                if (!diagramMode.inDeleteMode() && plugHasEclamp(prevPlug)) {
                    menuItems.push({
                        label: labelPrevPlug,
                        action: () => goToPlugsGraph(prevPlug?.id ?? null)
                    });
                }

                if (!isAvailable && !diagramMode.inDeleteMode() && plugHasEclamp(nextPlug)) {
                    menuItems.push({
                        label: labelNextPlug,
                        action: () => goToPlugsGraph(nextPlug?.id ?? null)
                    });
                }

                if (!diagramMode.inDeleteMode() && plugHasEclamp(prevPlug)) {
                    menuItems.push({
                        label: labelVoltPrev,
                        action: () => voltGraphInstance.selectPlug(prevPlug?.id ?? null)
                    });
                }

                if (!isAvailable && !diagramMode.inDeleteMode() && plugHasEclamp(nextPlug)) {
                    menuItems.push({
                        label: labelVoltNext,
                        action: () => voltGraphInstance.selectPlug(nextPlug?.id ?? null)
                    });
                }

                contextualMenu.open({
                    position: { top: evt.y, left: evt.x },
                    items: menuItems
                });
            }
            event.cancelBubble = true;
        };

        const getPairedPlugIds = () => {
            const inventoryEntries = Object.entries(inventoryEquipments);

            const cableEntries = inventoryEntries
                .filter(([key, value]) => value.type === SLDComponentEnum.CABLE);
            const prevCableId = id;
            const nextCableId = pathInfo.nextCableId;
            const [keyIndex, valueIndex] = [0, 1];
            const prevCable = cableEntries.find(([key]) => key === prevCableId)?.[valueIndex] ?? null;
            const nextCable = cableEntries.find(([key]) => key === nextCableId)?.[valueIndex] ?? null;

            const prevPlugId = prevCable === null ? null : "plug_" + prevCable["enchufe_hembra"];
            const nextPlugId = nextCable === null ? null : "plug_" + nextCable["enchufe_macho"];

            return { prevPlugId, nextPlugId };
        };

        const getPairedPlugs = () => {
            const inventoryEntries = Object.entries(inventoryEquipments);
            const plugEntries = inventoryEntries
                .filter(([key, value]) => value.type === "plug");

            const { prevPlugId, nextPlugId } = getPairedPlugIds();
            const [keyIndex, valueIndex] = [0, 1];
            const prevPlug = plugEntries.find(([key]) => key === prevPlugId)?.[valueIndex] ?? null;
            const nextPlug = plugEntries.find(([key]) => key === nextPlugId)?.[valueIndex] ?? null;
            return { prevPlug, nextPlug };
        }

        const plugHasEclamp = (plug) => {
            return plug?.["eclamp"] ?? false;
        }

        const getConnectionAlarmStatus = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();

            if (!plugHasEclamp(prevPlug) && !plugHasEclamp(nextPlug)) {
                return CONNECTION_STATES.NO_SENSOR;
            }

            const alarmIds = [
                ["eclamp_no_data"],
                ["humidity"],
                ["inner_temperature", "temp_interior_ab"],
                ["inner_temperature", "temp_interior_bc"],
                ["inner_temperature", "temp_interior_ca"],
                ["voltages", "voltaje_ab"],
                ["voltages", "voltaje_bc"],
                ["voltages", "voltaje_ca"]
            ];
            const getPlugAlarms = (plug) => alarmIds.map((keysArray) => {
                if (plug === null)
                    return [];
                return ["alarms", ...keysArray].reduce((prev, key) => {
                    return prev?.[key] ?? null;
                }, plug);
            });
            const alarmsStatus = [...getPlugAlarms(prevPlug), ...getPlugAlarms(nextPlug)];

            if (alarmsStatus.includes(CONNECTION_STATES.NO_DATA)) {
                return CONNECTION_STATES.NO_DATA;
            }

            if (alarmsStatus.includes(CONNECTION_STATES.RED_ALARM)) {
                return CONNECTION_STATES.RED_ALARM;
            }

            if (alarmsStatus.includes(CONNECTION_STATES.YELLOW_ALARM)) {
                return CONNECTION_STATES.YELLOW_ALARM;
            }

            return CONNECTION_STATES.OK;
        }

        const onMouseEnterConnection = event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'pointer';

            const { prevPlugId, nextPlugId } = getPairedPlugIds();
            const plugIds = [prevPlugId, nextPlugId].filter(id => id !== null);
            if (Array.isArray(plugIds) && plugIds.length) {
                hoveredComponent.setHoveredComponentId(plugIds);
            }
        };

        const onMouseLeaveConnection = event => {
            const container = event.target.getStage().container();
            container.style.cursor = 'default';

            hoveredComponent.resetHoveredComponent();
        }

        const connectionHoverMethods = {
            onMouseEnter: onMouseEnterConnection,
            onMouseLeave: onMouseLeaveConnection
        };

        const dragMethods = {
            onDragMove: event => {},
            onDragEnd: event => {
                if(!diagramMode.inAnyEditMode()) {
                    return;
                }
                const x = roundToGridSize(event.target.x());
                const y = roundToGridSize(event.target.y());
                console.log("MOVING DESTINY CONNECTION POINT TO: ", { x, y });
                moveDestinyConnectionPoint({
                    cableId: id,
                    dropPoint: { x, y }
                });
            }
        };

        const cableHasEclamp = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();
            return plugHasEclamp(prevPlug) || plugHasEclamp(nextPlug);
        }

        const leftPlugHasEclamp = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();
            return plugHasEclamp(prevPlug);
        }

        const rightPlugHasEclamp = () => {
            const { prevPlug, nextPlug } = getPairedPlugs();
            return plugHasEclamp(nextPlug);
        }

        const isSelectingTheSamePlug = () => {
            let { firstPlugConnection } = voltGraphInstance.getSelectedPlugs();
            if (!firstPlugConnection) {
                return;
            } else {
                if (typeof firstPlugConnection === "number") {
                    firstPlugConnection = "plug_" + firstPlugConnection;
                }
            }
            const { prevPlugId, nextPlugId } = getPairedPlugIds()
            console.log("SELECTED PLUGS: ", firstPlugConnection, prevPlugId, nextPlugId);
            return firstPlugConnection === prevPlugId || firstPlugConnection === nextPlugId;
        }

        const firstEclamp = leftPlugHasEclamp() && !rightPlugHasEclamp() && !diagramMode.inAnyEditMode() && getPairedPlugIds().nextPlugId;
        const secondEclamp = rightPlugHasEclamp() && !leftPlugHasEclamp() && !diagramMode.inAnyEditMode() && getPairedPlugIds().prevPlugId;

        return (
            <SLDConnection
                key={`${connectionId}:${lastPoint.x},${lastPoint.y}`}
                x={lastPoint.x}
                y={lastPoint.y}
                ref={connectionCircleRef}
                type={CONNECTION_CABLE_DESTINY}
                isSelected={false}
                isAvailable={isAvailable}
                isEndEditingPoint={false}
                onClick={onClick}
                state={getConnectionAlarmStatus()}
                hasEclamp={cableHasEclamp() && !isSelectingTheSamePlug()}
                {...connectionHoverMethods}
                {...dragMethods}
                draggable={diagramMode.inAnyEditMode() && (getPairedPlugIds().prevPlugId || getPairedPlugIds().nextPlugId)}
                firstPlugEclamp={firstEclamp}
                secondPlugEclamp={secondEclamp}
            />
        );
    };

    return (
        <>
            { onlyConnection ?
                <Group ref={pathCache.ref}>
                    {deploySourceConnectionPoint()}
                    {deployDestinyConnectionPoint()}
                </Group> :
                <Group ref={pathCache.ref}>
                    {deployLabels()}
                    {deployLines()}
                    {deployVertices()}
                    {deployCableTypeSymbols()}
                </Group>

            }
        </>
    );
}