import { useEffect, useState } from "react";

const MS_FOR_AUTO_REMOVE = 3000;

export function useSLDHightlight () {
    const [highlightedComponents, setHighlightedComponents] = useState({});

    const resetHighlightComponents = () => {
        setHighlightedComponents({});
    };

    const addHighlightedComponent = (componentId, props = {}) => {
        const { highlightData = {}, autoRemove = true } = props;
        setHighlightedComponents((prev) => {
            return {
                ...prev,
                [componentId]: highlightData
            };
        });

        if(autoRemove) {
            setTimeout(() => {
                removeHighlightedComponent(componentId);
            }, MS_FOR_AUTO_REMOVE);
        }
    };

    const removeHighlightedComponent = (componentId) => {
        setHighlightedComponents((prev) => {
            const { [componentId]: _, ...rest } = prev;
            return rest;
        });
    };

    const isHighlighted = (componentId) => {
        return highlightedComponents[componentId] !== undefined;
    };

    return {
        highlightedComponents,
        setHighlightedComponents,
        resetHighlightComponents,
        addHighlightedComponent,
        removeHighlightedComponent,
        isHighlighted
    };
}