import { useState, useRef, useEffect, useMemo } from "react";
import {
    ACTIVATE_CACHE_WHILE_ZOOMING,
    CACHE_WHILE_ZOOMING_ACTIVATED,
    GRID_SIZE,
    roundToGridSize
} from "../constants/single-line-diagram";

const DEBOUNCE_ZOOM_MS = 500;

export function useStageInteractions () {
    const stageRef = useRef(null);
    const [scale, setScale] = useState(1);
    const [screenOffset, setScreenOffset] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [isZooming, setIsZooming] = useState(false);
    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
    const [pointerCoordinatesOnStage, setPointerCoordinatesOnStage] = useState({
      x: null,
      y: null,
    });
    const [screenshotStageSize, setScreenshotStageSize] = useState(null);

    // useEffect(() => {
    //     console.log("CHANGES IN ZOOM: ", {screenOffset, scale});
    // }, [screenOffset, scale]);

    const zoomingDebounceTimeout = useRef(null);
    const registerZoom = () => {
        if(zoomingDebounceTimeout.current) {
            clearTimeout(zoomingDebounceTimeout.current);
        }
        zoomingDebounceTimeout.current = setTimeout(() => {
            setIsZooming(false);
        }, DEBOUNCE_ZOOM_MS);
        setIsZooming(true);
    };


    // useEffect(() => {
    //     const handleKeyPress = (e) => {
    //         if (!stageRef.current) {
    //             return;
    //         }
    //
    //         switch (e.key) {
    //             // case 'ArrowLeft':
    //             //     stage.x(stage.x() + step);
    //             //     break;
    //             // case 'ArrowRight':
    //             //     stage.x(stage.x() - step);
    //             //     break;
    //             // case 'ArrowUp':
    //             //     stage.y(stage.y() + step);
    //             //     break;
    //             // case 'ArrowDown':
    //             //     stage.y(stage.y() - step);
    //             //     break;
    //             case '+': // Zoom in
    //                 zoom({ deltaZoom: 1 });
    //                 break;
    //             case '-': // Zoom out
    //                 zoom({ deltaZoom: -1 });
    //                 break;
    //             default:
    //                 break;
    //         }
    //     };
    //     document.addEventListener('keydown', handleKeyPress);
    //
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyPress);
    //     };
    // }, []);

    const limitToRange = ({ value, range }) => Math.min(Math.max(value, range[0]), range[1]);

    const ZOOM_RANGE = [0.2, 2.0];
    const setScaleInLimit = value => {
        registerZoom();
        setScale(limitToRange({ value, range: ZOOM_RANGE }));
    };

    const zoom = ({ deltaZoom }) => {
        const getNewScale = prevScale => {
            const step = 0.1;
            let newScale = prevScale + (step * deltaZoom);
            // console.log("GET NEW SCALE: BEFORE LIMIT: ", `${prevScale} + (${step} * ${deltaZoom}) = ${newScale}`);

            const range = ZOOM_RANGE;
            // console.log("GET NEW SCALE: RANGE: ", ZOOM_RANGE);

            return limitToRange({ value: newScale, range });
        };

        const getNewPosition = ({ prevScale, newScale }) => {
            const stage = stageRef.current;

            const pointer = stage.getPointerPosition();
            const mousePointTo = {
                x: (pointer.x - stage.x()) / prevScale,
                y: (pointer.y - stage.y()) / prevScale,
            };

            // console.log("ZOOM: MOUSE POINT TO: ", mousePointTo);
            return {
                x: pointer.x - mousePointTo.x * newScale,
                y: pointer.y - mousePointTo.y * newScale,
            };
        };

        setScale(prevScale => {
            const newScale = getNewScale(prevScale);
            // console.log("GET NEW SCALE: ", { prevScale, newScale, deltaZoom });
            if (newScale !== prevScale) {
                const newPos = getNewPosition({ prevScale, newScale });
                setScreenOffset(newPos);
            }
            return newScale;
        });
        registerZoom();


    }
  
    const startStageDrag = ({x, y}) => {
      setIsDragging(true);
      setLastPosition({ x, y });
    }
  
    const endStageDrag = () => {
      setIsDragging(false);
    }
  
    const dragStage = ({ x, y }) => {
      const dx = (x - lastPosition.x);
      const dy = (y - lastPosition.y); 
      setScreenOffset((prevState) => {
        const newX = prevState.x + dx;
        const newY = prevState.y + dy;
        return { x: newX, y: newY };
      });
      setLastPosition({ x, y });
    };
  
    const setMouseCoordinates = ({x: screenX, y: screenY}) => {
      const x = (screenX - screenOffset.x) / scale;
      const y = (screenY - screenOffset.y) / scale;
      setPointerCoordinatesOnStage({ x, y });
    }

    const [visibleRange, setVisibleRange] = useState({ minX: 0, maxX: 0, minY: 0, maxY: 0 });

    useEffect(() => {
      calculateVisibleRange();
    }, [scale, screenOffset]);

    const calculateVisibleRange = () => {
        const stage = stageRef.current;
        const transform = stage.getAbsoluteTransform().copy();
        transform.invert();

        const topLeft = transform.point({ x: 0, y: 0 });
        const bottomRight = transform.point({
            x: stage.width(),
            y: stage.height(),
        });

        setVisibleRange({
            minX: topLeft.x,
            maxX: bottomRight.x,
            minY: topLeft.y,
            maxY: bottomRight.y,
        });
    };

    const fitStageToRange = ({ xMin, xMax, yMin, yMax }) => {
        const width = stageRef.current.width();
        const height = stageRef.current.height();
        console.log("FIT TO RANGE: ", { width, height });
        console.log("FIT TO RANGE: ", { xMin, xMax, yMin, yMax });

        const xRange = xMax - xMin;
        const yRange = yMax - yMin;

        console.log("FIT TO RANGE: ", {
            xRange, yRange
        });
        console.log("FIT TO RANGE: ", {
            xScale: (width/xRange).toFixed(2),
            yScale: (height/yRange).toFixed(2)
        });

        const newScale = Math.min( width/xRange, height/yRange);
        setScale(newScale);
        registerZoom();
        setScreenOffset(p => {
            const getOffsetFromMin = min => min * -1 * newScale;
            return {
                x: getOffsetFromMin(xMin),
                y: getOffsetFromMin(yMin)
            };
        });
    };

    return {
        ZOOM_RANGE,
        stageRef,
        scale,
        setScale: setScaleInLimit,
        screenOffset,
        setScreenOffset,
        isDragging,
        isZooming,
        pointerCoordinatesOnStage,
        visibleRange,
        screenshotStageSize,
        // ********* Methods ********* //
        zoom,
        startStageDrag,
        endStageDrag,
        dragStage,
        setMouseCoordinates,
        calculateVisibleRange,
        fitStageToRange,
        setScreenshotStageSize
    };
  }