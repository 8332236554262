import {ReactComponent as IconSVG} from '../../svg/subestacion.svg';
import React from 'react';
import PropTypes from "prop-types";

function ButtonSubestacion() {
        return (
            <IconSVG alt="subestacion" style={{
                background: "rgba(0,0,0,0)",
                margin: '0px',
                padding: '0px',
                display: 'block',
                width: "3em",
                height: "3em",
                fill: "#0e537b",
            }}/>
        );
}


export default ButtonSubestacion;