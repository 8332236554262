import useImage from "use-image";
import { Image } from "react-konva";
import React from "react";

export const SVGImage = ({
    x = 0,
    y = 0,
    height = 100,
    width = 100,
    URL = ""
}) => {
    const [image] = useImage(URL);
    return <Image x={x} y={y} width={width} height={height} image={image} />;
};