import { NodeTypes, SLDComponentEnum } from "../../../constants/single-line-diagram";

export class InvalidCompoundIdError extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_COMPOUND_ID'
        this.message = message
    }
}

export class InvalidComponentId extends Error {
    constructor(message) {
        super(message)
        this.name = 'INVALID_COMPONENT_ID'
        this.message = message
    }
}

// This class should replace the helper methods at <PROJECT_DIR>/frontend/src/components/DiagramaUnilineal/utils.js
export class ConnectionIdReader {
    compoundId = '';

    constructor({compoundId}) {
        // TODO: check valid compoundId
        this.compoundId = compoundId;
    }

    getParentNodeType() {
        try {
            const idParts = this.compoundId.split(":");
            const typesByLength = {
                2: NodeTypes.CABLE,
                3: NodeTypes.COMPONENT
            };
            const type = typesByLength[idParts.length];
            if (type === undefined) {
                throw new InvalidCompoundIdError(
                    `The compound id ${this.compoundId} is not valid CABLE nor COMPONENT connection id`
                );
            }
            return type;
        } catch (e) {
            throw new InvalidCompoundIdError(e);
        }
    }

    isComponentConnectionPoint() {
        return this.getParentNodeType() === NodeTypes.COMPONENT;
    }

    isCableConnectionPoint() {
        return this.getParentNodeType() === NodeTypes.CABLE;
    }

    getParsedComponentConnection() {
        const nodeType = this.getParentNodeType();
        if(nodeType !== NodeTypes.COMPONENT) {
            throw new InvalidCompoundIdError(
                `The compound id ${this.compoundId} is not valid COMPONENT connection id`
            );
        }
        const [componentId, connectionType, connectionId] = this.compoundId.split(":");
        return { componentId, connectionType, connectionId };
    }

    getComponentTypeByConnection() {
        console.log("GET COMPONENT TYPE BY CONNECTION: ", this);
        const nodeType = this.getParentNodeType();
        if (nodeType === undefined) {
            throw new InvalidCompoundIdError(
                `The compound id ${this.compoundId} is not valid COMPONENT nor CABLE connection id`
            );
        }
        if(nodeType === NodeTypes.CABLE) {
            throw new InvalidCompoundIdError(
                `The compound id ${this.compoundId} is not valid COMPONENT connection id. It is a cable id instead.`
            );
        }
        const { componentId } = this.getParsedComponentConnection();
        const componentType = componentId.split("_")[0];

        if (!Object.values(SLDComponentEnum).includes(componentType)) {
          throw new InvalidComponentId(`Invalid component id ${componentId}`);
        }

        return componentType;
    }

    getParsedCableConnection() {
        const nodeType = this.getParentNodeType();
        if (nodeType !== NodeTypes.CABLE) {
            throw new InvalidCompoundIdError(
                `The compound id ${this.compoundId} is not a valid CABLE connection id`
            );
        }
        const [cableId, connectionType] = this.compoundId.split(':');
        return { cableId, connectionType };
    }
}