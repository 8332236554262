import { useContext } from "react";
import { SingleLineDiagramContext } from "./SingleLineDiagramContext";
import { Line } from "react-konva";

export function SLDCursor({
    point = {
        x: 0,
        y: 0
    },
    offset = {
        x: 0,
        y: 0
    },
    direction
}) {
    const { diagramZone } = useContext(SingleLineDiagramContext);

    const lineProps = {
        strokeWidth: 1,
        stroke: "gray",
        dash: [20, 10],
    };

    return (
        <>
            { direction === 'left' && (
                <Line
                    points={[
                        diagramZone.x - offset.x,
                        point.y - offset.y,
                        point.x - offset.x,
                        point.y - offset.y
                    ]}
                    {...lineProps}
                    listening={false}
                />
            )}
            { direction === 'right' && (
                <Line
                    points={[
                        point.x - offset.x,
                        point.y - offset.y,
                        diagramZone.x + diagramZone.width - offset.x,
                        point.y - offset.y
                    ]}
                    {...lineProps}
                    listening={false}
                />
            )}

        </>
    );
}