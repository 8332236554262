import React from 'react';
import logo from './loading.gif'
import {Container} from "@mui/material";
const LoadingGif = ({ size = "xl" }) => {
    return (
        <Container maxWidth={size} style={{margin:'24px', display:'flex', alignItems:'center'}}>
            <img src={logo} alt={"loading..."} style={{backgroundColor:'rgba(0,0,0,0)'}}/>
        </Container>

    );
};

export default LoadingGif;