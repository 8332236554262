import { useEffect, useState } from "react";

const useCableSelector = () => {
    /**
     * state: {
     *     isOpen,
     *     title,
     *     onConfirm
     *     confirmLabel,
     *     cancelLabel,
     * }
     */
    const [state, setState] = useState(null);

    // useEffect(() => {
    //     console.log("USE EFFECT IN USE CABLE SELECTOR: ", state);
    // }, [state]);

    const setSelector = (config) => {
        setState({...config,
            isOpen: true,
            close: closeSelector,
            onSelect: (cableId) => {
                config.onSelect(cableId);
                closeSelector();
        }});
    };

    const closeSelector = () => setState(null);

    return { state, setSelector, closeSelector };
};

export default useCableSelector;