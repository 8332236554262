import { styled, Button, Select, Modal, Popper } from '@mui/material';

export const AikoButton = styled(Button)(({ theme, clicked }) => ({
  minWidth: "200px",
  backgroundColor: clicked ? "lightblue" : null,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: clicked ? "#55A1DD" : null,
  },
}));

export const AikoSelect = styled(Select)({
  // Diseños
});

export const AikoModal = styled(Modal)({
  // Diseños
});

export const AikoPopper = styled(Popper)({
  // Diseños
});