import { useState } from "react";

export function useSLDHoveredComponent({inventoryEquipments}) {
    const [hoveredComponentId, setHoveredComponentId] = useState(null);

    const getHoveredComponentInfo = () => {
        if (!Array.isArray(hoveredComponentId)) {
          return inventoryEquipments?.[hoveredComponentId] ?? null;
        }
        return hoveredComponentId.map(id => inventoryEquipments?.[id] ?? null);
    };

    const resetHoveredComponent = () => setHoveredComponentId(null);

    return { setHoveredComponentId, resetHoveredComponent, getHoveredComponentInfo };

}
