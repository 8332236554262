import { Box, Paper, Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import React, { useState, useEffect } from "react";
import { DataGrid } from '@mui/x-data-grid';
import CreateCableModal from "./inventory/CreateCableModal";
import EditCableModal from "./inventory/EditCableModal";
import { CABLES_INVENTORY_MODALS, useCablesInventory } from "../hooks/useCablesInventory";
import ConfirmModal from "./ConfirmModal";
import { getCablesWithPosition } from "../services/api/inventory";


function Inventory({ token, setTopBarName, topBarOpen }) {
    const theme = useTheme();
    const styles = getStyles(theme);

    const columns = [
        { field: 'codigo_cliente', headerName: 'Codigo Cliente', width: 125, sortable: true },
        { field: 'largo', headerName: 'Largo', width: 100, sortable: true },
        { field: 'estado_cable', headerName: 'Estado', width: 100, sortable: true },
        { field: 'serial_hembra', headerName: 'Enchufe Hembra', width: 150, sortable: true },
        { field: 'serial_macho', headerName: 'Enchufe Macho', width: 150, sortable: true },
        {
            field: 'in_use',
            headerName: 'Disponibilidad',
            width: 150,
            sortable: true,
            valueGetter: (params) => params.row.in_use ? 'En uso' : 'Disponible',
        },
        { field: 'ubicacion_correlativa', headerName: 'Ubicación Correlativa', width: 200, sortable: true},
        {
            field: 'prev',
            valueGetter: (params) => params.row.prev
                ? `${params.row?.prev?.name ?? "-"} (${params.row?.prev?.type ?? ""})`
                : "-",
            headerName: 'Elemento Previo', width: 200, sortable: true
        },
        {
            field: 'next',
            valueGetter: (params) => params.row.next
                ? `${params.row?.next?.name ?? "-"} (${params.row?.next?.type ?? "-"})`
                : "-",
            headerName: 'Elemento Siguiente', width: 200, sortable: true 
        },
    ];

    const config = {
        headers: { Authorization: `Token ${token}` }
    };

    const handleCloseModal = () => {
        modalState.close();
    };

    //******************************************* LOAD INFORMATION *******************************************/

    const { cablesWithPositionInfo, eclamps, selectedRows, setSelectedRows,
        getEnchufesWithoutCable, getEclampsWithoutEnchufe,
        handleDelete, handleCreate, handleEditDB,
        handleSnackbarClose, snackbarText, snackbarOpen, snackbarSeverity,
        loading, modalState, confirmModal
    } = useCablesInventory({ config });

    useEffect(() => {
        setTopBarName("Inventario");
    }, []);

    //******************************************* CREATE CABLE *******************************************/
    //TODO: Se puede mover hacia adentro de CreateCableModal.js

    const handleOpenCreateCable = () => {
        modalState.open(CABLES_INVENTORY_MODALS.CREATE_CABLE);
    };

    const onClickCreate = (data) => {
        const onDone = handleCloseModal;
        handleCreate({ onDone, ...data });
    }

    //******************************************* EDIT CABLE *******************************************/

    const [codigoClienteEdit, setCodigoClienteEdit] = useState('');
    const [largoEdit, setLargoEdit] = useState('');
    const [eclampHembraEdit, setEclampHembraEdit] = useState('');
    const [eclampMachoEdit, setEclampMachoEdit] = useState('');

    const handleOpenEditCable = () => {
        const SelectedRowsEdit = cablesWithPositionInfo.find(cable => cable.codigo_cliente === selectedRows[0])
        const eclampHembra = eclamps.find(eclamp => eclamp.serial_number === SelectedRowsEdit.serial_hembra);
        const eclampMacho = eclamps.find(eclamp => eclamp.serial_number === SelectedRowsEdit.serial_macho);

        setCodigoClienteEdit(SelectedRowsEdit.codigo_cliente);
        setLargoEdit(SelectedRowsEdit.largo);
        setEclampHembraEdit(eclampHembra ? eclampHembra.id : '');
        setEclampMachoEdit(eclampMacho ? eclampMacho.id : '');
        modalState.open(CABLES_INVENTORY_MODALS.EDIT_CABLE);
    };

    const onClickEdit = () => {
        const onDone = handleCloseModal;
        const data = {
            codigoCliente: codigoClienteEdit,
            largo: largoEdit,
            eclampHembra: eclampHembraEdit ? eclampHembraEdit : null,
            eclampMacho: eclampMachoEdit ? eclampMachoEdit : null
        };
        handleEditDB({ onDone, ...data });
        setCodigoClienteEdit('');
        setLargoEdit('');
        setEclampHembraEdit('');
        setEclampMachoEdit('');
    }


    return (
        <main style={styles.content} role="main">
            <ConfirmModal {...(confirmModal.info)} />
            <div style={styles.appBarSpacer} />
            <Box style={styles.container}>
                <div style={{ display: 'flex' }}>
                    <CreateCableModal isOpen={modalState.isOpen(CABLES_INVENTORY_MODALS.CREATE_CABLE)} onClose={handleCloseModal} plugs={getEnchufesWithoutCable()} handleCreate={onClickCreate} listOfEclamps={getEclampsWithoutEnchufe()}/>
                    <EditCableModal isOpen={modalState.isOpen(CABLES_INVENTORY_MODALS.EDIT_CABLE)} onClose={handleCloseModal} eclamps={getEclampsWithoutEnchufe()} handleEdit={onClickEdit} 
                        dataEdit={{codigoClienteEdit, largoEdit, eclampMachoEdit, eclampHembraEdit}} setDataEdit={{setCodigoClienteEdit, setLargoEdit, setEclampHembraEdit, setEclampMachoEdit}} allEclamps={eclamps}/>

                    <Button variant="contained" color="primary" style={{ display: 'flex', marginRight: '3px' }} onClick={handleOpenCreateCable}>
                        Crear Cable
                    </Button>
                    <Button variant="contained" color="primary" disabled={!selectedRows || selectedRows.length !== 1} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '3px' }} onClick={handleOpenEditCable}>
                        Editar Cable
                    </Button>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button id="eliminar" variant="contained" color="error" disabled={!selectedRows || selectedRows.length === 0} style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={handleDelete} >
                        Eliminar Cable
                    </Button>
                </div>
            </Box>
            <Paper style={styles.tableSimulador}>
                {loading ? <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}>
                    <CircularProgress />
                </Box>
                    :
                    <DataGrid
                        rows={cablesWithPositionInfo}
                        getRowId={(row) => row.codigo_cliente}
                        columns={columns}
                        pageSize={20}
                        sortingOrder={['asc', 'desc', null]}
                        checkboxSelection
                        disableSelectionOnClick
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                    />}
            </Paper>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarText}
                </Alert>
            </Snackbar>
        </main>
    );
}

const getStyles = (theme) => {
    return {
        root: {
            display: 'flex',
        },
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            backgroundColor: '#E4EBEF',
            maxHeight: "auto",
            width: "auto",
            display: 'flex',
            justifyContent: 'space-between'
        },
        paper: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            maxHeight: "auto",
            visibility: "visible",
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'row',
            width: "auto"
        },
        fixedHeight: {
            height: 240,
        },
        tableSimulador: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            height: '100%'
        },
    };
};

export default Inventory;