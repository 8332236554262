import { useEffect, useState } from "react";
import axios from "axios";

const STORAGE_KEY = 'local-login';

export function useLocalLogin() {
    const defaultState = { username: null, userId: null, token: null };

    const [loginInfo, setLoginInfo] = useState(getLoginInfoFromStorage());

    function getLoginInfoFromStorage() {
        try {
            const loginInfoString = localStorage.getItem(STORAGE_KEY) ?? defaultState;
            return JSON.parse(loginInfoString);
        } catch (e) {
            return defaultState;
        }
    }

    function saveLoginInfo({ username, userId, token }) {
        const info = { username, userId, token }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(info));
        setLoginInfo(info);
    }

    function deleteLoginInfo() {
        localStorage.clear()
        setLoginInfo(defaultState)
    }

    const isLogged = () => Boolean(loginInfo.token);

    return {
        loginInfo,
        saveLoginInfo,
        deleteLoginInfo,
        isLogged
    }

}