import React from 'react';
import IconSplitter3 from "../Icons/IconSplitter3";
import IconSplitter2 from "../Icons/IconSplitter2";
import {Box} from "@mui/material";
import CadenaCablesEquipo from "./CadenaCablesEquipo";

const SplitterCadenas = ({splitter, configuraciones, setTopBarName, cubiculo}) => {
    const cadena_salida1 = splitter.cadena_salida1.length >= 1 ? 1 : 0
    const cadena_salida2 = splitter.cadena_salida2.length >= 1 ? 1 : 0
    const cadena_salida3 = splitter.cadena_salida3.length >= 1 ? 1 : 0
    const splitter_count = cadena_salida1 + cadena_salida2 + cadena_salida3
    return (
        <Box component="span" display="inline-block" width="auto" height="auto">
            <Box display="flex" flexDirection="row" width="auto">
                <Box component="span" display="inline-block">{splitter_count === 3 ? <IconSplitter3/> : <IconSplitter2/>}</Box>
                <Box component="span" display="inline-block">
                    <Box display="flex" flexDirection="column">
                        {Boolean(cadena_salida1) && <CadenaCablesEquipo cables={splitter.cadena_salida1} setTopBarName={setTopBarName} configuraciones={configuraciones} cubiculo={cubiculo}/>}
                        {Boolean(cadena_salida1) && <Box width={'60px'} height={'40px'}/>}
                        {Boolean(cadena_salida2) && <CadenaCablesEquipo cables={splitter.cadena_salida2} setTopBarName={setTopBarName} configuraciones={configuraciones} cubiculo={cubiculo}/>}
                        {Boolean(cadena_salida2) && <Box width={'60px'} height={'57px'}/>}
                        {Boolean(cadena_salida3) && <CadenaCablesEquipo cables={splitter.cadena_salida3} setTopBarName={setTopBarName} configuraciones={configuraciones} cubiculo={cubiculo}/>}
                    </Box>
                </Box>
            </Box>

        </Box>

    );
};

export default SplitterCadenas;