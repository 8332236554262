import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { SingleLineDiagram } from "../components/DiagramaUnilineal/SingleLineDiagram.js";
import SLDToolbar from "../components/DiagramaUnilineal/SLDToolbar";
import LoadingGif from "../assets/LoadingGif";
import { SingleLineDiagramContext } from "../components/DiagramaUnilineal/SingleLineDiagramContext";
import SLDInfo from "../components/DiagramaUnilineal/SLDInfo";
import ConfirmModal from "../components/ConfirmModal";
import { ContextualMenu } from "../components/DiagramaUnilineal/ContextualMenu";
import { CableSelectorPopUp } from "../components/DiagramaUnilineal/CableSelectorPopUp";
import { inDebugMode } from "../utils/flags";
import { logDiagramRegister } from "../services/api/single-line-diagram";

function useSLDErrorRegister ({
    onError = e => console.error("Caught in global error handler:", e),
    onUnhandledRejection = event => console.error("Promise rejection caught in global handler:", event.reason)
  } = {}) {
  const register = () => {
      window.onerror = (message, source, lineno, colno, error) => {
        onError(error);
        return true; // return true to prevent default
      };

      window.onunhandledrejection = (event) => {
        onUnhandledRejection();
        event.preventDefault();
      };

    return () => {
        window.onerror = null;
        window.onunhandledrejection = null;
      };
  };

  return {
    register
  }
}

export function SingleLineDiagramPage({ token }) {
  const { isLoading, failedLoading, modal, contextualMenu, cableSelector } = React.useContext(SingleLineDiagramContext);
  const [isPageLocked, setIsPageLocked] = useState(false);
  // let block = true;
  const errorRegister = useSLDErrorRegister({
    onError: (e) => {
      logDiagramRegister(token, {
        type: "error",
        message: `SLDStage failed to load correctly`,
        error: e?.stack
      }).then();
    }
  });

/* TODO: refactor lock diagram (it does not work)
 useEffect(() => {
    async function checkLockfile() {
      try {
        const response = await axios.get('/diagrama/instancia/check_lockfile/', {headers: {Authorization: `Token ${token}`}});
        setIsPageLocked(response.data.is_locked);
        block = response.data.is_locked;
        if (!response.data.is_locked) {
          lockPage();
          lockDate();
          setInterval(lockDate, 60000);
          block = false;
        } else {
          difDate();
        }
      } catch (error) {
        console.error(error);
      }
    }

    checkLockfile();

    return () => {
      if (!block) {
        console.log("desbloquear")
        unlockPage();
      }
    };
  }, []);*/


  useEffect(() => {
    const cleanUp = errorRegister.register();
    return cleanUp;
  }, []);

  async function unlockPage() {
    try {
      await axios.put('/diagrama/instancia/unlock_page/', {}, {headers: {Authorization: `Token ${token}`}});
    } catch (error) {
      console.error(error);
    }
  }

  async function lockPage() {
    try {
      await axios.put('/diagrama/instancia/lock_page/',{}, {headers: {Authorization: `Token ${token}`}});
    } catch (error) {
      console.error(error);
    }
  }

  async function lockDate() {
    try {
      await axios.put('/diagrama/instancia/lock_date/',{}, {headers: {Authorization: `Token ${token}`}});
    } catch (error) {
      console.error(error);
    }
  }

  async function difDate() {
    try {
      let response1 = await axios.put('/diagrama/instancia/dif_date/', {}, {headers: {Authorization: `Token ${token}`}});
      setIsPageLocked(response1.data.is_locked);
      lockPage();
    } catch (error) {
      console.error(error);
    }
  }

  // TODO: fix page lock
  return (
    <Box >
      {isPageLocked && false ? (
        <h2 style={styles.h2}>Lo siento, la pagina esta siendo ocupada por otra persona</h2>
      ) : (
          <Box style={styles.boxContainer} >
            <>
              {isLoading && (
                <div
                  style={styles.loadingContainer}
                >
                  <LoadingGif />
                </div>
              )}
                <ConfirmModal {...(modal.info)}/>
                <ContextualMenu {...contextualMenu}/>
                <CableSelectorPopUp {...cableSelector.state} cables={{}}/>
                <Box sx={styles.toolbarContainer}>
                    <SLDToolbar token={token}/>
                </Box>
                <Box sx={styles.diagramContainer}>
                  <SingleLineDiagram token={token}/>
                  {inDebugMode && (
                      <Box sx={styles.infoContainer}>
                          <SLDInfo />
                      </Box>
                  )}
                </Box>
            </>
          </Box>
      )}
    </Box>
  );
}

const styles = {
  h2: {
    paddingTop: "50px",
    paddingLeft: "100px"
  },
  boxContainer: {
    height: "100vh",
    flexDirection: "row"
  },
  loadingContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "175%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Change the opacity value (0.5) as desired
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1
  },
  toolbarContainer: {
    marginBottom: "1em"
  },
  diagramContainer: {
    borderWidth: 2,
    borderColor: "gray",
    borderStyle: "solid",
    position: "relative"
  },
  infoContainer: {
    maxWidth: 500,
    position: "absolute",
    height: "100%",
    backgroundColor: "#001c",
    left: 0,
    top: 0,
    pointerEvents: "none"
  }
}

export default SingleLineDiagramPage;