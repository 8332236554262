import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import IconPala from "./Icons/IconPala";
import {useTheme} from "@emotion/react";


export default function CardContadorPala({color_icono, palas, texto, setCurrentEstadoPala}) {
    const theme = useTheme();

    return (
        <Grid item xs>
            <ButtonBase onClick={setCurrentEstadoPala}>
                <Paper sx={{
                    padding: theme.spacing(2),
                    margin: 'auto',
                    width: '13em',
                    height: '6em',
                }}>
                    <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={5} style={{marginTop: '0em'}}>
                            <IconPala fill_color={color_icono}/>
                        </Grid>
                        <Grid item xs={7} container direction="column" spacing={1}>
                            <Typography gutterBottom variant="subtitle1" style={{color: color_icono}}>
                                {palas ? palas.length : '0'} PALAS
                            </Typography>
                            <Typography gutterBottom style={{fontSize: 10}}>
                                {texto ? texto : 'Sin Texto'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </ButtonBase>
        </Grid>

    );
}
