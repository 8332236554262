import { useEffect, useState } from "react";
import {
  CONNECTION_CABLE_DESTINY, CONNECTION_CABLE_SOURCE,
  CONNECTION_INPUT,
  CONNECTION_OUTPUT,
  CONNECTION_AIR
} from "../constants/single-line-diagram";
import { usePath } from "./use-single-line-diagram-path";

const CurrentCableStates = {
  "CREATE": "create",
  "EDIT": "edit"
}

export function useSingleLineDiagramCurrentCable({ paths, SLDComponents }) {
  const [selectedCable, setSelectedCable] = useState(null);
  const [selectedConnection, setSelectedConnection] = useState(null);

  const activateCable = (id) => {
    setSelectedCable(id);
  }

  const deactivateCable = () => {
    setSelectedCable(null);
    setSelectedConnection(null);
  }

  const getFocusedTypes = () => {
    if (selectedCable && !selectedConnection) {
      return [CONNECTION_OUTPUT, CONNECTION_CABLE_DESTINY];
    }
    if (selectedCable && selectedConnection) {
      return [CONNECTION_INPUT, CONNECTION_CABLE_SOURCE];
    }
    return [];
  };

  /**************************** EDITING CABLE PATH ****************************/

  const editingPath = usePath([ //TODO: delete default value
    { x: 100, y: 100},
    { x: 300, y: 500}
  ]);

  const startEditingCable = ({ cableId }) => {
    const editingCablePoints = paths[cableId].points;
    const edgePoints = [
      editingCablePoints[0],
      editingCablePoints[editingCablePoints.length - 1]
    ];
    editingPath.setPoints(edgePoints);
  };

  /*************************** CREATING CABLE PATH ***************************/

  const creatingPath = usePath([]);

  const selectPathStart = (key, isCableConnectionPoint = false, isWaitingForConnection = false) => {
    if (!selectedCable && !isWaitingForConnection) {
      return null;
    }
    setSelectedConnection(key);
    const { x, y } = getStartPoint(key, isCableConnectionPoint);
    creatingPath.addPoint({ x, y });
  };

  const selectPathStartOnAir = (x, y) => {
    console.log("SELECT PATH START ON AIR", x, y);
    // Create a SourceConnectionPoint on the air and set the connection.
    setSelectedConnection(CONNECTION_AIR);
    creatingPath.addPoint({ x, y });
  }

  const getStartPoint = (key, isCableConnectionPoint = false ) => {
    if (isCableConnectionPoint) {
      const [cableKey, connectionType] = key.split(":");
      let point = null;
      if (connectionType === CONNECTION_CABLE_DESTINY) {
        point = paths[cableKey].points.at(-1);
      } else if (connectionType === CONNECTION_CABLE_SOURCE) {
        point = paths[cableKey].points[0];
      } else {
        throw "Cannot find point for CONNECTION_CABLE: " + connectionType;
      }
      return point;
    }
    return SLDComponents.getOutputPoint(key);
  };

  useEffect(() => {
    //print selectedCable and selectedConnection
    console.log("selectedCable: ", selectedCable);
    console.log("selectedConnection: ", selectedConnection);
  }
  , [selectedCable, selectedConnection]);

  return {
    selectedCable, setSelectedCable,
    selectedConnection, setSelectedConnection,
    activateCable, deactivateCable,
    getFocusedTypes,
    editingPath, startEditingCable,
    creatingPath, selectPathStart,
    selectPathStartOnAir
  };
}
