import {Box} from "@mui/material";
import VistaPalas from "./dashboard/VistaPalas";
import {useTheme} from "@emotion/react";


function Dashboard({token, setTopBarName, topBarOpen}) {
    const theme = useTheme();
    const styles = {
        root: {
            display: 'flex',
        },
        appBarSpacer: {
            height: '50px'
        },
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            backgroundColor: '#E4EBEF',
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            backgroundColor: '#E4EBEF',
            maxHeight: "auto",
            width: "auto"
        },
        paper: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            disableGutters: false,
            maxHeight: "auto",
            visibility: "visible",
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'row',
            width: "auto"
        },
        fixedHeight: {
            height: 240,
        },
    }


    return (
        <main style={styles.content} role="main">
            <div style={styles.appBarSpacer}/>
            <Box style={styles.container}>
                <VistaPalas token={token} setTopBarName={setTopBarName}/>
            </Box>
        </main>

    );
}

export default Dashboard;