export const CONNECTION_POINT_HIGHLIGHTED_RADIUS = 12;
export const CONNECTION_POINT_RADIUS = 8;
export const CONNECTION_INPUT = "in";
export const CONNECTION_OUTPUT = "out";
export const CONNECTION_CABLE_DESTINY = "cable-destiny";
export const CONNECTION_CABLE_SOURCE = "cable-source";
export const CONNECTION_AIR = "on-air";

export const NodeTypes = {
    "COMPONENT": "component",
    "CABLE": "cable"
}

export const SLDComponentEnum = {
  "SUBSTATION": "substation",
  "SPLITTER": "splitter",
  "CELL": "cell",
  "LOAD": "load",
  "NO_INVENTORY_LOAD": "no-code-load",
  "CABLE": "cable"
};

export const SLDComponentSizes = {
    [SLDComponentEnum.SUBSTATION]: {
        "width": 400,
        "height": 400
    },
    [SLDComponentEnum.SPLITTER]: {
        "width": 50,
        "height": 75
    },
    [SLDComponentEnum.LOAD]: {
        "width": 75,
        "height": 25
    },
    [SLDComponentEnum.CELL]: {
        "width": 50,
        "height": 50
    }
};

export const InsertCableSides = {
    "BEFORE": "before",
    "AFTER": "after"
};

export const GRID_SIZE = 12.5;

export const MATRIX_GRID_SIZE = 100;

export const roundToGridSize = (value) => {
    return Math.round(value / GRID_SIZE) * GRID_SIZE;
};

export const PathSegmentTypes = {
    HORIZONTAL: 'horizontal-segment',
    VERTICAL: 'vertical-segment'
};

export const CableTypes = {
    AERIAL: "aerial",
    DOUBLE_AERIAL: "double-aerial",
    PIPE_CABLE: "pipe-cable",
    LED: "led-aerial"
}

export const MouseButtons = {
  "LEFT": 0,
  "MIDDLE": 1,
  "RIGHT": 2
}

export const PositionReferenceTypes = {
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right",
    CENTER: "center",
    CENTER_LEFT: "center-left",
    CENTER_RIGHT: "center-right"
}


/********************************* TEXT LABELS *********************************/

export const TextLabelSizes = {
    STEP: 2,
    MIN_TEXT_LABEL: 8,
    DEFAULT_TEXT_LABEL: 16,
    MAX_TEXT_LABEL: 64
}

/*********************************** SYMBOLS ***********************************/

export const SLDSymbols = {
    TRANSFORMER: "transformer",
    GENERATOR: "generator"
};

export const GENERATOR_RADIUS = 12.5;

export const TransformerSize = {
    WIDTH: 25,
    HEIGHT: 20
}

export const CANVAS_WIDTH_HEIGHT_RATIO = 433 / 279;

export const sizesByCableType = {
    [CableTypes.AERIAL]: {
        width: 2*CONNECTION_POINT_RADIUS,
        height: 2*CONNECTION_POINT_RADIUS
    },
    [CableTypes.DOUBLE_AERIAL]:  {
        width: 2*CONNECTION_POINT_RADIUS,
        height: 2*CONNECTION_POINT_RADIUS
    },
    [CableTypes.PIPE_CABLE]:  {
        width: CONNECTION_POINT_RADIUS,
        height: 4*CONNECTION_POINT_RADIUS
    },
    [CableTypes.LED]:  {
        width: 2*CONNECTION_POINT_RADIUS,
        height: 2*CONNECTION_POINT_RADIUS
    },
}

/********************************* AERIAL COLORS *********************************/

export const AerialCableColors = {
    BLACK: "black",
    BROWN: "brown",
    GRAY: "gray",
    RED: "red",
    PURPLE: "purple",
    BLUE: "blue",
    GREEN: "green",
    ORANGE: "orange",
    CYAN: "cyan",
    PINK: "pink"
};

export const PRINT_DIAGRAM_INFO_HEIGHT_RATIO = 8/100;
export const PRINT_DIAGRAM_HORIZONTAL_MARGIN_RATIO = 3/100;
export const PRINT_DIAGRAM_VERTICAL_MARGIN_RATIO = 2/100;