import {ReactComponent as IconSVG} from '../../svg/fuente.svg';
import React from 'react';

function IconFuente({ fill_color }) {
    return (
        <IconSVG alt="icon_fuente"style={{
            width: "100%",
            height: "100%",
            borderRadius: "0%",
            textAlign: "center",
            lineHeight: "3em",
            verticalAlign: "middle",
            center:"true",
            fill:fill_color,
        }}></IconSVG>
    );
}

export default IconFuente;